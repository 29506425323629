import React, { useEffect } from 'react';
import { insertOptionsForFiltersFromAvailableData, makeArraySpecificKeyAsObject } from '../../../utils/functions/tableFilters';
import { SearchField } from '../SearchField';
import { DateRangeFilter, SelectDropDownForFilters } from '../Form-Elements/Input';
import ViewTabs from '../../HR & Student Placement/View Applicants/ViewTabs';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';

interface TableFilterProps{
  setFilters: any,
  filters: any,
  dateRangeFilters?: any,
  filterBasedOnDateRange: any,
  checkBoxFilter?: any,
  searchFilterJSON?: any,
  filterFunc: any,
  searchFilterState: any,
  // resetFilter: any,
  tableData:any[],
  setChangedFilterKey: any,
  // filterFunc:any
  allowTabs?: any
}

const TableFilters = ({ filters, setFilters, dateRangeFilters: dateFilters, filterBasedOnDateRange, checkBoxFilter, searchFilterJSON, filterFunc, searchFilterState, tableData, setChangedFilterKey, allowTabs }: TableFilterProps) => {
  const { id:paramsID, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const { searchFilters, setSearchFilters } = searchFilterState;

    const searchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilters({...searchFilters,[event.target.name]:{...searchFilters[event.target.name],value:event.target.value}})
        if (!event.target.value.length) {
            filterFunc({ ...searchFilters, [event.target.name]: { ...searchFilters[event.target.name], value: event.target.value } });
        }
    }

  const handleFilters = (filterValue: string, apiKey: string) => {
      setChangedFilterKey(apiKey);
        let newFilter = { ...filters };
        if (!newFilter[apiKey]) {
            newFilter[apiKey]=[filterValue]
        }
        else if (newFilter[apiKey].includes(filterValue)) {
          let removeFilterValue = newFilter[apiKey].filter((fil: string) => fil !== filterValue);
          if (!removeFilterValue.length) {
              delete newFilter[apiKey];
          }
          else {
              newFilter[apiKey] = removeFilterValue;
          }
        }
        else {
            newFilter[apiKey]=[...newFilter[apiKey],filterValue];
        }
    if (JSON.stringify(filters) !== JSON.stringify(newFilter)) {
          setFilters(newFilter);
        }
    }

    useEffect(() => {
      // console.log(
      //   checkBoxFilter?.filter(
      //     (fl: any) =>
      //       fl?.apiKey === "status" && fl?.options?.includes("Completed")
      //   ),
      //   "TEST"
      // );
      if (checkBoxFilter?.some((filter: any) => filter?.apiKey === "status" && filter?.options?.includes("Completed")) && routeNameForHome==="action_center" && routeNameForSubSection === "calculate_trainer_payment") {
        handleFilters('Completed', 'status');
      } else if (checkBoxFilter?.some((filter: any) => filter?.apiKey === "status" && filter?.options?.includes("Open")) && routeNameForHome==="action_center" && routeNameForSubSection==="student_feedback_concerns" && paramsID==="student_concern") {
        handleFilters('Open', 'status')
      }
    },[routeNameForHome, routeNameForSubSection])

    const handleDateRangeFilterChange = (startDate: any, endDate: any, apiKey: string) => {
      filterBasedOnDateRange(startDate, endDate, apiKey);
    };

    const tabs = [
      { id: 1, title: 'ShortListed Student', titles:'shortListed' },
      { id: 2, title: 'Placed Student', titles:'placed' },
      { id: 3, title: 'Joined the Company', titles:'joined' },
    ];

  return (
    <div className="space-y-1">
      {searchFilterJSON?.length && (
        <div
          className={`flex items-center justify-between bg-white rounded-lg py-4 px-2`}
        >
          {searchFilterJSON &&
            searchFilterJSON.map((seaFil: any, index: number) => (
              <SearchField
                key={index}
                placeholder={seaFil.name}
                name={seaFil.searchKey}
                value={searchFilters[seaFil.searchKey].value}
                onChange={searchHandleChange}
                handleClick={() => filterFunc(searchFilters)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    filterFunc(searchFilters);
                  }
                }}
                searchStyle={seaFil.searchStyle}
              />
            ))}
        </div>
      )}
      <div className="bg-white rounded-lg p-4">
        <div className="flex items-center flex-wrap gap-2">
          {dateFilters?.map((filter: any, index: number) => (
            <DateRangeFilter
              key={index}
              filterJSON={filter}
              handleChange={handleDateRangeFilterChange}
              selectedItems={filters[filter["apiKey"]] || []}
              filters={filters}
              tableData={tableData}
            />
          ))}
          {checkBoxFilter?.map((filter: any, index: number) => (
            <SelectDropDownForFilters
              key={index}
              filterJSON={filter}
              handleChange={handleFilters}
              selectedItems={filters[filter["apiKey"]] || []}
              filters={filters}
              tableData={tableData}
            />
          ))}
          {allowTabs && <ViewTabs tabs={tabs} />}
        </div>
      </div>
    </div>
  );
}


export default TableFilters