// import {useState} from 'react'

// export const PastStudentsData = ({pastStudentList}:any) => {
//     const Added_Student_Table_Header = ["Student Name", "Student Phone Number", "Total Fee", "Amount Paid", "Balance Due", "Hours Attended"];
//     const cellStyle = (text?: any) => `text-[#686868] text-[14px] font-Roboto font-[400] ${text ? "w-[7%]" : "w-[10%]"} text-center border`;
//     const [searchTerm, setSearchTerm] = useState("");

//     const filteredData = pastStudentList?.filter((student:any) =>
//         student.course.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         student.student.name.includes(searchTerm) ||
//         student.student.phoneNumber.includes(searchTerm) || 
//         student.student.email.includes(searchTerm)
//     );
    
//     return (
//     <div>
//         {
//             filteredData?.length > 0 ?
//             <div className="space-y-2">
//                 <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
//                     Past Student Details
//                 </h1>
//                     <div className="container mx-auto">
//                         <input
//                             type="text"
//                             placeholder="Search by name or phone number..."
//                             className="mb-4 p-2 border rounded w-full"
//                             value={searchTerm}
//                             onChange={(e:any) => setSearchTerm(e.target.value)}
//                         />
//                         <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
//                             <div className="bg-[#EFF7FF] flex items-center justify-between px-4 py-1 pr-4">
//                                 {Added_Student_Table_Header.map((head:any) => (
//                                 <p
//                                     className={`text-[#686868] text-[14px] font-Roboto font-[500] ${
//                                     head === "Hours Attended"
//                                         ? "w-[7%]"
//                                         : "w-[10%]"
//                                     } text-center`}
//                                 >
//                                     {head}
//                                 </p>
//                                 ))}
//                             </div>
//                             <div className="min-h-[60px] max-h-[300px] overflow-y-auto pt-2 pr-4">
//                                 {filteredData?.map(
//                                     (body: any, i: number) => {
//                                     return (
//                                         <div
//                                         key={i}
//                                         className={`relative flex items-center justify-between px-10 py-1 ${
//                                             i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
//                                         }`}
//                                         >
//                                         <p className={`${cellStyle()} break-words`}>
//                                             {body?.student?.name}
//                                         </p>
//                                         <p className={`${cellStyle()}`}>
//                                             {body?.student?.phoneNumber}
//                                         </p>
//                                         <p className={`${cellStyle()}`}>
//                                             {body.total_fee > 0 && (
//                                             <span className="px-1">&#8377;</span>
//                                             )}
//                                             {body?.total_fee >= 0
//                                             ? body?.total_fee === 0
//                                                 ? 0
//                                                 : Number(body?.total_fee)?.toFixed(2)
//                                             : "-"}
//                                         </p>
//                                         <p className={`${cellStyle()}`}>
//                                             {body?.total_fee > 0 && (
//                                             <span className="px-1">&#8377;</span>
//                                             )}
//                                             {body?.total_fee >= 0
//                                             ? body?.total_fee === 0
//                                                 ? 0
//                                                 : (Number(body?.total_fee) - Number(body?.balance_due))?.toFixed(2)
//                                             : "-"}
//                                         </p>
//                                         <p className={`${cellStyle()}`}>
//                                             {body?.balance_due > 0 && (
//                                             <span className="px-1">&#8377;</span>
//                                             )}
//                                             {body?.balance_due >= 0
//                                             ? body?.balance_due === 0
//                                                 ? 0
//                                                 : Number(body.balance_due)?.toFixed(2)
//                                             : "-"}
//                                         </p>
//                                         <p className={`${cellStyle(0)}`}>
//                                             {body?.hours_attended !== null
//                                             ? body?.hours_attended
//                                             : 0}
//                                         </p>
//                                         </div>
//                                     );
//                                     }
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//             </div> : <></>
//         }
//     </div>
//   )
// }

import { useState } from "react";

export const PastStudentsData = ({ pastStudentList }: any) => {
    console.log(pastStudentList, "pastStudentList")
  const Added_Student_Table_Header = [
    "Student Name",
    "Student Phone Number",
    "Total Fee",
    "Amount Paid",
    "Balance Due",
    "Hours Attended",
  ];
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = pastStudentList?.filter(
    (student: any) =>
      student.course.course_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      student.student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.student.phoneNumber.includes(searchTerm) ||
      student.student.email.includes(searchTerm)
  );

  return (
    <div>
      {filteredData?.length > 0 ? (
        <div className="space-y-2">
          <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
            Past Student Details
          </h1>
          <div className="container mx-auto">
            <input
              type="text"
              placeholder="Search by name or phone number..."
              className="mb-4 p-2 border rounded w-full"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
              <div className="bg-[#EFF7FF] grid grid-cols-6 gap-4 px-4 py-1">
                {Added_Student_Table_Header.map((head: any, index: number) => (
                  <p
                    key={index}
                    className="text-[#686868] text-[14px] font-Roboto font-[500] text-center"
                  >
                    {head}
                  </p>
                ))}
              </div>
              <div className="min-h-[60px] max-h-[300px] overflow-y-auto pt-2 pr-4">
                {filteredData.map((body: any, i: number) => (
                  <div
                    key={i}
                    className={`grid grid-cols-6 gap-4 items-center px-4 py-1 ${
                      i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
                    }`}
                  >
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center break-words">
                      {body?.student?.name}
                    </p>
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center">
                      {body?.student?.phoneNumber}
                    </p>
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center">
                      {body.total_fee > 0 && (
                        <span className="px-1">&#8377;</span>
                      )}
                      {body?.total_fee >= 0
                        ? body?.total_fee === 0
                          ? 0
                          : Number(body?.total_fee).toFixed(2)
                        : "-"}
                    </p>
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center">
                      {body?.total_fee > 0 && (
                        <span className="px-1">&#8377;</span>
                      )}
                      {body?.total_fee >= 0
                        ? body?.total_fee === 0
                          ? 0
                          : (
                              Number(body?.total_fee) -
                              Number(body?.balance_due)
                            ).toFixed(2)
                        : "-"}
                    </p>
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center">
                      {body?.balance_due > 0 && (
                        <span className="px-1">&#8377;</span>
                      )}
                      {body?.balance_due >= 0
                        ? body?.balance_due === 0
                          ? 0
                          : Number(body.balance_due).toFixed(2)
                        : "-"}
                    </p>
                    <p className="text-[#686868] text-[14px] font-Roboto font-[400] text-center">
                      {body?.hours_attended !== null ? body?.hours_attended : 0}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
