import { ClimbingBoxLoader } from "react-spinners";
import { dateFormat } from "../../../Services/CommonFunctions";
import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { BulkUpdate, FetchData, InsertData, UpdateData } from "../../crud";
import { JobsInterface, StudentJobStatusUpdateInterface, RegisterInterface, UpdateHrDetails, RegisterCompanyInterface } from "./interface";
import { CreateUserWithEmailAndPassword } from "../../auth";
import { isAboveNinetyPercent } from "../../../Components/CommonFunctions/CommonFunc";

// page no: 166
// export const getHrStudentTableData = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.jobs, ["*", "technology:technologies(id, technology)"],)
//         const job_data = data?.map((el: any) => ({ ...el, technology: el.technology.technology, date: dateFormat(el.inserted_at, "DD MMM YYYY")}))
//         if (error) throw error
//         return job_data
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const getHrStudentTableData = async () => {
    try {

        const { data, error } = await FetchData(
            TableNames.jobs, 
            ["*"]
        ).order("id", { ascending: false })

        if (error) throw error;
        
        // Format the data with the desired structure
        const job_data = data?.map((el: any) => ({
            ...el,
            technology: (el.technology || []).map((tech : any) => tech?.technology || "").join(", "),
            date: dateFormat(el.inserted_at, "DD MMM YYYY")
        }));
        
        return job_data;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getjobId = async (jobId: string | number) => {
    try {

        const { data: jobs, error } = await FetchData(TableNames.jobs).eq("id", jobId);
        if (error) throw error;

        return jobs[0];

    } catch (error) {

        return errorNotifier(error);

    }

}
// page no: 167
export const createNewJob = async (jobData: JobsInterface) => {
  try {
    const { data, error } = await InsertData(TableNames.jobs, jobData);
    if (error) throw error;
    return data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 168
export const updateJob = async (
  jobId: number,
  jobData: Partial<JobsInterface>
) => {
  try {
    const { error } = await UpdateData(TableNames.jobs, jobData, {
      conditionKey: "id",
      conditionValue: jobId,
    });
    if (error) throw error;
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 169
export const viewApplicants = async (jobId: number, status: any) => {
  try {
    const { data, error } = await FetchData(TableNames.student_jobs, [
      "id",
      "student:students(name, email, phoneNumber, resume)",
      "training:trainings(course:courses(course_name))",
    ])
      .eq("job_id", jobId)
      .in("status", status); //.match({job_id: jobId, status: status})
    const updatedData = data?.map((el: any) => ({
      ...el,
      student_name: el.student.name,
      student_mobile: el.student.phoneNumber,
      student_email: el.student.email,
      student_resume: el.student.resume,
      course_name: el.training.course.course_name,
    }));
    if (error) throw error;
    return updatedData;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 170 - 175
export const updateStudentJobStatus = async (
  studentJobStatusUpdateData: StudentJobStatusUpdateInterface[]
) => {
  try {
    const newStudentJob = studentJobStatusUpdateData?.map((el: any) => ({
      ...el,
      shortlisted: el.status === "shortlisted" ? true : false,
      placed: el.status === "placed" ? true : false,
      joined: el.status === "joined" ? true : false,
    }));
    for (const data of newStudentJob) {
      const { id, ...updatedData } = data;

      const { error } = await UpdateData(TableNames.student_jobs, updatedData, {
        conditionKey: "id",
        conditionValue: id,
      });

      if (error) {
        console.error(`Error updating row with ID ${id}:`, error);
      } else {
        console.log(`Row with ID ${id} updated successfully.`);
      }
    }
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 168 - for technology dropdown
export const getHrStudentPreloadData = async () => {
    
    try {
        const { data, error } = await FetchData(TableNames.technologies, ["id", "technology"])
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

// Page no: 176
const calculateRelevantJobs = (jobData: any) => {
  const countObject: any = {};

  jobData?.forEach((job: any) => {
    countObject[job.technology] = countObject[job.technology]
      ? countObject[job.technology] + 1
      : 1;
  });

  return countObject;
};
const getTechName = (techData: any, techId: any) => {
  const techRec: any = techData.filter((rec: any) => (rec.id = techId));
  if (techRec.length > 0) return techRec[0].technology;
  else return "No rec found";
};

// export const getStudentForHRTableData = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.student_jobs,
//              ["student:students(uid, name, email, phoneNumber)",
//               "training:trainings(course:courses(id, course_name), batch:batches(branch:branches(branch_name), city:cities(city_name)))",
//                "created_at", "job:jobs(id, technology)", "shortlisted", "placed", "joined"])

//         const {data: jobs, error: jobs_error} = await FetchData(TableNames.jobs)

//         const {data: Techs, error: Tech_error} = await FetchData(TableNames.technologies)

//         const countObject = calculateRelevantJobs(jobs)

//         const updated_data = data?.map((el: any) => ({
//             ...el, technology: getTechName(Techs, el.job?.technology),
//             student_name: el.student?.name, student_email: el.student?.email, student_mobile: el.student?.phoneNumber,
//             student_id: el.student?.uid, course_name: el.training?.course?.course_name,
//             branch: el.training?.batch?.branch?.branch_name, city: el.training?.batch?.city?.city_name,
//             relevant_jobs: countObject[el.job?.technology] || 0 , applied_jobs: data.filter((ele: any) => (ele.student?.uid === el.student?.uid)).length, technology_id: el.job?.technology,shortlisted:el.shortlisted === true ? "Yes" : "No",joined:el.joined === true ? "Yes" : "No",placed:el.placed === true ? "Yes" : "No", date: dateFormat(el.created_at, "DD MMM YYYY")}))

//         if(error || jobs_error||Tech_error) throw error || jobs_error ||Tech_error

//         return updated_data

//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const getStudentForHRTableData = async () => {
  try {
    const { data, error } = await FetchData(TableNames.trainings, [
      "balance_due",
      "placement",
      "student:students(uid, name, email, phoneNumber)",
      "course:courses(id, course_name)",
      "batch:batches(batch_progress_in_hrs, duration, branch:branches(branch_name), city:cities(city_name))",
      "student_job:student_jobs(job:jobs(id, technology), created_at, shortlisted, placed, joined)",
    ]);

    const filterBasedCourseFee_CourseCompletion_Placement = data?.filter(
      (fil: any) =>
        Number(fil?.balance_due) === 0 &&
        Number(fil?.batch?.batch_progress_in_hrs) >
          Number(fil?.batch?.duration) - 11 &&
        fil?.placement
    );

    const { data: jobs, error: jobs_error } = await FetchData(TableNames.jobs);

    const { data: Techs, error: Tech_error } = await FetchData(
      TableNames.technologies
    );

    const { data: Student_Jobs, error: Student_Job_Error } = await FetchData(
      TableNames.student_jobs,
      ["student_id"]
    );

    const countObject = calculateRelevantJobs(jobs);
    const updated_data = filterBasedCourseFee_CourseCompletion_Placement?.map(
      (el: any) => ({
        ...el,
        technology: getTechName(Techs, el.student_job?.[0]?.job?.technology),
        student_name: el.student?.name,
        student_email: el.student?.email,
        student_mobile: el.student?.phoneNumber,
        student_id: el.student?.uid,
        course_name: el?.course?.course_name,
        branch: el?.batch?.branch?.branch_name,
        city: el?.batch?.city?.city_name,
        relevant_jobs: countObject[el.student_job?.[0]?.job?.technology] || 0,
        applied_jobs: Student_Jobs?.filter(
          (ele: any) => ele.student_id === el.student?.uid
        ).length,
        technology_id:
          el.student_job?.length > 0 ? el.student_job?.[0]?.job?.technology : 0,
        shortlisted: el.shortlisted === true ? "Yes" : "No",
        joined: el.joined === true ? "Yes" : "No",
        placed: el.placed === true ? "Yes" : "No",
        date: dateFormat(el.created_at, "DD MMM YYYY"),
      })
    );

    if (error || jobs_error || Tech_error)
      throw error || jobs_error || Tech_error || Student_Job_Error;

    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

/*export const getStudentForHRTableData = async () => {
    try {
        const { data, error } = await FetchData(TableNames.student_jobs, ["student:students(uid, name, email, phoneNumber)", "training:trainings(course:courses(id, course_name), batch:batches(branch:branches(branch_name), city:cities(city_name)))", "created_at", "job:jobs(id, technology)", "shortlisted", "placed", "joined"])

        const {data: jobs, error: jobs_error} = await FetchData(TableNames.jobs)

        const countObject = calculateRelevantJobs(jobs)

        const updated_data = data?.map((el: any) => ({ ...el, student_name: el.student.name, student_email: el.student.email,
             student_mobile: el.student.phoneNumber, student_id: el.student.uid, course_name: el.training?.course?.course_name,
              branch: el.training?.batch?.branch?.branch_name, 
              city: el.training.batch?.city.city_name, 
              relevant_jobs: countObject[el.job.technology] || 0 , applied_jobs: data.filter((ele: any) => (ele.student.uid === el.student.uid)).length, technology_id: el.job.technology, date: dateFormat(el.created_at, "DD MMM YYYY")}))

        if(error || jobs_error) throw error || jobs_error
        return updated_data
    } catch (error) {
        console.log(error)
        return errorNotifier(error)
    }
}  */

// Page no: 177
export const getRelevantJobsTableData = async (
  technology_id: number | string
) => {
  try {
    const { data, error }: any = await FetchData(TableNames.jobs, [
      "updated_at",
      "company_name",
      "position",
      "location",
      "technology:technologies(technology)",
    ]).eq("technology", technology_id);
    const updated_data = data?.map((el: any) => ({
      ...el,
      date: dateFormat(el.updated_at, "DD MMM YYYY"),
      technology: el.technology.technology,
    }));
    if (error) throw error;
    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// Page no: 178
export const getAppliedJobsTableData = async (studentUid: string) => {
  try {
    const { data, error } = await FetchData(TableNames.student_jobs, [
      "created_at",
      "shortlisted",
      "joined",
      "placed",
      "job:jobs(company, location, position)",
    ]).eq("student_id", studentUid);
    const updated_data = data?.map((el: any) => ({
      ...el,
      company: el.job?.company,
      location: el.job?.company,
      position: el.job?.position,
    }));

    if (error) throw error;
    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getAllCompaniesList = async () => {
  try {
    const { data, error } = await FetchData(TableNames.jobs, [
      "company_name"
    ]);
    if (error) throw error;
    const mapCompanyData = data?.map((mt:any)=>mt?.company_name);
    const uniqueArray = [...new Set(mapCompanyData)];
    return uniqueArray;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const companyManagementTableData = async () => {
    try {
      const { data, error } = await FetchData(
        TableNames.companies_registered,
        ["*"]
      ).order("id", { ascending: false });
      if (error) throw error;
      const { data: userData, error: userError } = await FetchData(TableNames.user_hr, ["*"]);
      if (userError) throw userError;

      const formattedData = data?.map((ft: any) => {
        const userCountBasedOnCompanies = userData?.filter((fil: any) => fil?.company === ft?.id);
        return {
          id: ft.id,
          created_at: dateFormat(ft?.created_at, "DD MMM YYYY"),
          company_name: ft?.name,
          company_location: ft?.location,
          salary_range: ft?.salary_range,
          company_size: ft?.company_size,
          recruiting_roles: ft?.recruiting_roles,
          status: ft?.status ? "Active" : "Inactive",
          user_count: userCountBasedOnCompanies?.length
        };
      })
      return formattedData;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const viewCompanyManagementTableData = async (id:number) => {
    try {
      const { data:companies, error:companies_error }:any = await FetchData(TableNames.companies_registered, ["*"]).eq("id", id)
      if (companies_error) throw companies_error;
      let companyData = {...companies?.[0]};
      const { data, error } = await FetchData(TableNames.user_hr, ["*"]).eq("company", companyData?.id)
      if (error) throw error;
      const formattedData = data?.map((ft: any) => {
        return {
          id: ft.id,
          created_at: dateFormat(ft?.created_at, "DD MMM YYYY"),
          name: ft?.name,
          email: ft?.email,
          phone_number: ft?.phone_number,
          location: ft?.location,
          status_hr: ft?.status_hr,
          is_admin: ft?.is_admin
        };
      })
      return {
        CompanyData: {...companyData, created_at: dateFormat(companyData?.created_at, "DD MMM YYYY")}, HrData: formattedData};
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateCompanyManagementTableData = async (id:number, updatedData:RegisterInterface, updatedHRData: UpdateHrDetails[]) => {
  // console.log(updatedData, updatedHRData, "PLEP")
    try {
      const { error:companyError } = await UpdateData(TableNames.companies_registered, updatedData, {
        conditionKey: "id",
        conditionValue: id,
      });
      if (companyError) throw companyError;
    
      // Update HR details
      const { data:hrData, error: hrError }:any = await BulkUpdate(TableNames.user_hr, updatedHRData, "id");
      if (hrError) throw hrError;
      const firstHrUser = updatedHRData?.[0]?.email;
      const newUser = await CreateUserWithEmailAndPassword(firstHrUser, "Test@1");
      const { error:userCreationError } = await UpdateData(TableNames.user_hr, { uid: newUser?.user?.id }, {
          conditionKey: "id",
          conditionValue: updatedHRData?.[0]?.id,
        })
      if (userCreationError) throw userCreationError;
      return true
    } catch (error) {
      return errorNotifier(error);
    }
}

export const resumeReviewDashboardTableData = async (UsersDetails:any) => {
    try {
      const { data, error } = await FetchData(TableNames.trainings, [
        "created_at",
        "city:cities(city_name)",
        "branch:branches(branch_name, ownership)",
        "course:courses(course_name)",
        "student:students(id,name,phoneNumber,placement_need,resume,resume_status)",
        "batch:batches(duration,batch_progress_in_hrs)",
        "status"
      ])
        .eq("balance_due", 0)
        .in("branch_id", UsersDetails?.branches);
      if (error) throw error;
      const filterData = data?.filter(
        (fil: any) =>
          isAboveNinetyPercent(
            Number(fil?.batch?.duration),
            Number(fil?.batch?.batch_progress_in_hrs)
          ) && fil?.student?.placement_need
      );
      // console.log(filterData, "filterData")
      const mapAllData = filterData?.map((mt: any) => {
        return {
          ...mt, created_at: dateFormat(mt?.created_at, "DD MM YYYY"),
          branch_name: mt?.branch?.branch_name,
          ownership: mt?.branch?.ownership,
          city_name: mt?.city?.city_name, 
          course_name: mt?.course?.course_name,
          name: mt?.student?.name,
          phone_number: mt?.student?.phoneNumber,
          resume: mt?.student?.resume,
          student_id: mt?.student?.id,
          resume_status: mt?.student?.resume_status,

        }
      })
      return mapAllData;
    } catch (error) {
      return errorNotifier(error);
    }
}

export const fetchCompanyList = async () => {
  try {
    const { data, error } = await FetchData(TableNames.jobs, ["company_name"])
    const updatedData = data?.map((el: any) => el?.company);
    if (error) throw error;
    return updatedData;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const createNewCompany = async (new_data: RegisterCompanyInterface) => {
  try {
      const {
          company_name,
          company_location,
          salary_range,
          company_size,
          recruiting_roles,
          hiring_count_per_year,
          name,
          email,
          phone_number,
          location,
          is_admin,
          about_us,
          // status_hr
      } = new_data;

      // Insert company details first
      const company_details = {
        name: company_name,
        location: company_location,
        salary_range,
        company_size,
        recruiting_roles,
        hiring_count_per_year,
        about_us,
        approval_status: 'Accept'
      };

      const { data: companyData, error: companyError } = await InsertData(
          TableNames.companies_registered,
          company_details
      );

      if (companyError) throw companyError;

      // Prepare HR details with company ID from the previous insert
      const hr_details = {
        name,
        email,
        phone_number,
        location,
        is_admin,
        company: companyData[0]?.id,
      //   status_hr,
      };

      // Insert HR details
      const { data: hrData, error: hrError } = await InsertData(
          TableNames.user_hr,
          hr_details
      );

      if (hrError) throw hrError;

      const newUser = await CreateUserWithEmailAndPassword(email, "Test@1");
      const { error:userCreationError } = await UpdateData(TableNames.user_hr, { uid: newUser?.user?.id }, {
          conditionKey: "id",
          conditionValue: hrData?.[0]?.id,
        })
      if (userCreationError) throw userCreationError;

      return {
          CompanyDetails: companyData,
          HrDetails: hrData,
      };
  } catch (error) {
      return errorNotifier(error);
  }
};

export const fetchStudentResume = async (id:number) => {
  try {
    const { data, error }:any = await FetchData(TableNames.students, ["resume", "resume_status"]).eq('id', id);
    if (error) throw error;
    return data?.[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const updateStudentResumeStatus = async (id:number, resume_status:string) => {
  try {
    const { error } = await UpdateData(TableNames.students, { resume_status: resume_status }, {
      conditionKey: "id",
      conditionValue: id,
    })
    if (error) throw error;
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
}