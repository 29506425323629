import { Components } from './ModuleImports';
import { CancellationSessionDetails } from './CancellationSessionDetails';
import { CompensationDetails } from './CompensationDetails';
import { updateBatchStatus } from '../../../functions/admin/Batches/Batches';

export const BatchInformation = ({ id, mode }: any) => {
  const { useState, useEffect, CardLayoutThree, ReadData, Loader, BasicInfo, SessionDetails, TrainerDetails, batchProgressById, getSessionsAndTrainersByBatchId }: any = Components;
  const [isLoading, setIsLoading] = useState(false);
  const [batchData, setBatchData] = useState({} as any);
  const [batchProgress, setBatchProgress] = useState({
    batch_progress_in_percent: '',
    progress_in_hrs: '',
    total_duration:''
  } as any)
  const [sessionData, setSessionData] = useState([] as any);
  const [sessionDataCompensate, setSessionDataCompensate] = useState([] as any);
  const [trainerData, setTrainerData] = useState([] as any);
  const isId: any = id;
  
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const readData:any = await batchProgressById(isId)
      // console.log(readData, "readData");
      if ((Number(readData?.batch_progress_in_percent) !== 0)&&(readData?.batch?.status==="Upcoming")) {
        await updateBatchStatus("Ongoing", readData?.batch?.id)
      }
      setBatchData(readData?.batch)
      setBatchProgress({
        batch_progress_in_percent: readData?.batch_progress_in_percent,
        progress_in_hrs: readData?.progress_in_hrs,
        total_duration: readData?.total_duration,
      });
      const SessionAndTrainers = await getSessionsAndTrainersByBatchId(isId);
      // console.log(SessionAndTrainers,"SessionAndTrainers")
      const checkSessionStatus = SessionAndTrainers?.updatedSessionData || [];
      const { success, others } = checkSessionStatus.reduce(
        (acc:any, el:any) => {
          if (el?.session_status === "Success") {
            acc.success.push(el);
          } else {
            acc.others.push(el);
          }
          return acc;
        },
        { success: [], others: [] }
      );
      setSessionData(success)
      setSessionDataCompensate(others)
      setTrainerData(SessionAndTrainers?.updateTrainerData)
      setIsLoading(false)
    })()
  },[])

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <CardLayoutThree>
          <form>
            <div className="text-left border-b-2 p-2 flex items-center justify-between px-10">
              <p className="font-Roboto font-medium text-[22px] 2xl:text-[24px] text-[#707070] my-3 ml-4">
                Batch Information
              </p>
              <div className="flex items-center gap-6">
                <ReadData
                  head={"Batch Code"}
                  body={batchData?.batch_code ? batchData?.batch_code : "-"}
                />
                <ReadData
                  head={"Batch Status"}
                  body={
                    batchData?.status
                      ? batchData?.status
                      : "-"
                  }
                />
              </div>
            </div>
            <div className="py-8 space-y-12">
              <div className="w-10/12 mx-auto">
                <BasicInfo
                  batchData={batchData}
                  batchProgress={batchProgress}
                />
              </div>
              <TrainerDetails trainerData={trainerData} />
              <SessionDetails id={id} sessionData={sessionData} batchData={batchData} />
              {/* <CancellationSessionDetails /> */}
              <CompensationDetails
                id={id}
                sessionDataCompensate={sessionDataCompensate}
              />
            </div>
          </form>
        </CardLayoutThree>
      )}
    </>
  );
}
