import { Components } from "./ModuleImports";

// const totalFeeTransaction = (transaction: any) => {
//   const getTotalFee = transaction?.length > 0 && transaction?.reduce((sum: any, trans: any) => sum + trans?.paid_amount, 0)
//   return Number(getTotalFee);
// }

export const StudentDetails = ({
  id,
  formik,
  mode,
  Student_Status,
  studentNames,
  studentData,
}: any) => {
  const {
    DropdownWithHandleOut,
    DropdownSingleInputWithCheckbox,
    updateStudentTraining,
  }: any = Components;
  const cellStyle = (text?: any) =>
    `text-[#686868] text-[14px] font-Roboto font-[400] ${
      text ? "w-[7%]" : "w-[10%]"
    } text-center`;
  const Added_Student_Table = [
    "Student Name",
    "Student Phone Number",
    "Total Fee",
    "Amount Paid",
    "Balance Due",
    "Hours Attended",
    "Student Status",
  ];

  const removeRow = async (i: number, trainingId: number) => {
    if (mode === "edit") {
      const response = await updateStudentTraining(
        {
          status: "Hold",
          batch_id: id,
        },
        trainingId
      );
    }
    const newArray = [...formik.values.add_student];
    newArray.splice(i, 1);
    formik.setFieldValue("add_student", newArray);
    const stateArray = [...formik.values.selected_student_list];
    stateArray.splice(i, 1);
    formik.setFieldValue("selected_student_list", stateArray);
  };

  const handleInputChange = (event: any) => {
    // console.log(event, "event")
    const { value, checked } = event.target;
    if (formik.values.add_student.includes(value)) {
      const filterOne = formik.values.add_student.filter(
        (fil: any) => fil !== value
      );
      console.log(formik.values.selected_student_list, "formik.values.selected_student_list", value, "value")
      const filterTwo = formik.values.selected_student_list.filter(
        (fil: any) =>
          fil.student?.name + `(${fil.student?.phoneNumber})` !== value
      );
      formik.setFieldValue("add_student", filterOne);
      formik.setFieldValue("selected_student_list", filterTwo);
    } else {
      formik.setFieldValue("add_student", [
        ...formik.values.add_student,
        value,
      ]);
      const filterTwo =
        studentData &&
        studentData.filter(
          (fil: any) =>
            fil.student?.name + `(${fil.student?.phoneNumber})` === value
        );
      const mapFilteredData = filterTwo.map((filter: any) => {
        return {
          ...filter,
          status: "Allocated", // Add an extra key using the index
        };
      });
      // console.log(filterTwo, "filterTwo", mapFilteredData, "mapFilteredData");
      formik.setFieldValue("selected_student_list", [
        ...formik.values.selected_student_list,
        ...mapFilteredData,
      ]);
    }
  };

  const handleOut = async (name: string, option: any, trainingId: any) => {
    if (trainingId && id !== "new-batch") {
      const trainingData: any = {
        status: option,
        batch_id: id,
      };
      const response = await updateStudentTraining(trainingData, trainingId);
      if (response) {
        formik.setFieldValue(name, option);
      }
    } else if (id === "new-batch") {
      formik.setFieldValue(name, option);
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
        Student Details
      </h1>
      <div className="space-y-2">
        <DropdownWithHandleOut
          topDivStyle={"flex flex-col w-4/12"}
          classNameInput={"w-full"}
          options={studentNames}
          label="Add Student"
          placeholder="Add Student"
          name={"add_student"}
          value={formik.values.add_student}
          handleInputChange={handleInputChange}
          onBlur={formik.handleBlur}
          formik={formik}
          zIndex={"z-20"}
        />
        <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
          <div className="bg-[#EFF7FF] flex items-center justify-between px-10 py-1">
            {Added_Student_Table.map((head) => (
              <p
                className={`text-[#686868] text-[14px] font-Roboto font-[500] ${
                  head === "Student Status"
                    ? "w-[15%]"
                    : head === "Hours Attended"
                    ? "w-[7%]"
                    : "w-[10%]"
                } text-center`}
              >
                {head}
              </p>
            ))}
          </div>
          <div className="min-h-[60px] pt-2">
            {formik.values?.selected_student_list?.length > 0 &&
              formik.values?.selected_student_list.map(
                (body: any, i: number) => {
                  // console.log(body, "body");
                  return (
                    <div
                      key={i}
                      className={`relative flex items-center justify-between px-10 py-1 ${
                        i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
                      }`}
                    >
                      <p className={`${cellStyle()} break-words`}>
                        {body?.student?.name}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.student?.phoneNumber}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body.total_fee > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.total_fee >= 0
                          ? body?.total_fee === 0
                            ? 0
                            : Number(body?.total_fee)?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.total_fee > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.total_fee >= 0
                          ? body?.total_fee === 0
                            ? 0
                            : (Number(body?.total_fee) - Number(body?.balance_due))?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.balance_due > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.balance_due >= 0
                          ? body?.balance_due === 0
                            ? 0
                            : Number(body.balance_due)?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle(0)}`}>
                        {body?.hours_attended !== null
                          ? body?.hours_attended
                          : 0}
                      </p>
                      <DropdownSingleInputWithCheckbox
                        topDivStyle={"flex flex-col w-[15%]"}
                        classNameInput={"w-full h-[70%]"}
                        options={Student_Status}
                        name={`selected_student_list[${i}]["status"]`}
                        value={
                          formik.values?.selected_student_list?.[i]?.status
                        }
                        onBlur={formik.handleBlur}
                        forId={"selected_student_list"}
                        mapThrough={"selected_student_list"}
                        formik={formik}
                        disabled={mode === "view"}
                        zIndex={`z-[${20 + i}]`}
                        handleOut={mode === "edit" && handleOut}
                        trainerId={body?.id}
                      />
                      <div
                        onClick={() => removeRow(i, body?.id)}
                        className="absolute -right-6 select-none bg-[#FF0000] px-2 w-4 h-4 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center"
                      >
                        {id === "new-batch" ? "-" : "x"}
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
    