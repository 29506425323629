import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getTrainerBasedBatchesData } from "../../functions/admin/Course Progress/courseProgress";
import { useAppSelector } from "../..";
import { CourseProgressSessionModal } from "./CourseProgressSessionModal";

const checkboxClasses =
  "absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600";

export const CourseProgressModal = () => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<Set<any>>(new Set());
  const [progress, setProgress] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState(""); // Search state
  const [changeModalInput, setChangeModalInput] = useState(false);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  const HeadersContent = [
    "#",
    "Batch Code",
    "Courses",
    "Schedule",
    "Batch Progress(%)",
    "Batch Status",
    "Branch",
    "City",
  ];

  useEffect(() => {
    (async () => {
      const response = await getTrainerBasedBatchesData(signInData?.data[0]);
      console.log(response, "response")
      setProgress(response);
    })();
  }, [signInData]);

  useLayoutEffect(() => {
    const totalItems = Object.values(progress).flat().length;
    const isIndeterminate =
      selectedPeople.size > 0 && selectedPeople.size < totalItems;
    setChecked(selectedPeople.size === totalItems);
    setIndeterminate(isIndeterminate);
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople, progress]);

  const toggleAll = (trainerKey: string, trainerData: any[]) => {
    const allSelected = trainerData.every((person: any) =>
      selectedPeople.has(person)
    );

    const updatedSelection = new Set(selectedPeople);
    if (allSelected) {
      trainerData.forEach((person: any) => updatedSelection.delete(person));
    } else {
      trainerData.forEach((person: any) => updatedSelection.add(person));
    }

    setSelectedPeople(updatedSelection);
  };

  const handleCheckboxChange = (person: any) => {
    const updatedSelectedPeople = new Set(selectedPeople);
    if (updatedSelectedPeople.has(person)) {
      updatedSelectedPeople.delete(person);
    } else {
      updatedSelectedPeople.add(person);
    }
    setSelectedPeople(updatedSelectedPeople);
  };

  return (
    <div className="flex items-center justify-center w-full">
      {changeModalInput ? (
        <CourseProgressSessionModal selectedPeople={selectedPeople} />
      ) : (
        <div className="px-4 sm:px-6 lg:px-8 w-full h-screen py-10">
          <h1 className="text-xl font-Roboto font-[500] text-blue-900">
            Course Progress Bulk Update
          </h1>

          {/* Search Field */}
          <div className="mt-4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search trainers by name"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:outline-none"
            />
          </div>

          <div className="mt-8 flow-root h-[95%] scroll-smooth overflow-y-scroll">
            <TrainerBatches
              data={progress}
              searchQuery={searchQuery} // Pass searchQuery
              checkboxRef={checkboxRef}
              checked={checked}
              toggleAll={toggleAll}
              handleCheckboxChange={handleCheckboxChange}
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
              HeadersContent={HeadersContent}
              setChangeModalInput={setChangeModalInput}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const TrainerBatches = ({
  data,
  searchQuery,
  checkboxRef,
  checked,
  toggleAll,
  handleCheckboxChange,
  selectedPeople,
  setSelectedPeople,
  HeadersContent,
  setChangeModalInput
}: any) => {
  const [selectedTrainer, setSelectedTrainer] = useState<string | null>(null);

  const handleTrainerSelection = (trainerKey: string) => {
    setSelectedTrainer((prev: any) =>
      prev === trainerKey ? null : trainerKey
    );
    setSelectedPeople(new Set());
  };

  // Filter trainers based on searchQuery
  const filteredTrainers = Object.keys(data).filter((trainerKey) =>
    trainerKey.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredTrainers, "filteredTrainers");

  return (
    <div>
      <div className="w-full">
        {filteredTrainers.map((trainerKey, index) => (
          <div key={trainerKey} className="py-2">
            <div
              onClick={() => handleTrainerSelection(trainerKey)}
              className={`select-none flex items-center justify-between p-4 border rounded-lg cursor-pointer transition hover:shadow-lg ${
                selectedTrainer === trainerKey
                  ? "bg-blue-50 border-blue-500"
                  : "bg-white"
              }`}
            >
              <div className="flex items-center gap-4">
                <span className="text-gray-600 font-small">{index + 1}.</span>
                <h1 className="text-[15px] font-semibold text-gray-800">
                  {trainerKey}
                </h1>
              </div>
              <div className="text-blue-600 text-sm">
                {selectedTrainer === trainerKey ? "Selected" : "Select"}
              </div>
            </div>
            {selectedTrainer === trainerKey && (
              <div className="mt-4">
                {selectedPeople.size > 0 && (
                  <div className="flex h-12 items-center space-x-3 bg-white">
                    <button
                      type="button"
                      onClick={() =>
                        setChangeModalInput(true)
                      }
                      className="inline-flex items-center rounded bg-blue-900 px-2 py-1 text-sm font-semibold text-white shadow-sm"
                    >
                      Proceed
                    </button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300 mt-4 select-none">
                  <thead>
                    <tr>
                      <th className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className={checkboxClasses}
                          ref={checkboxRef}
                          checked={
                            data[trainerKey]?.every((person: any) =>
                              selectedPeople.has(person)
                            ) || false
                          }
                          onChange={() => toggleAll(trainerKey, data[trainerKey])}
                        />
                      </th>
                      {HeadersContent.map((head: any, idx: any) => (
                        <th
                          key={idx}
                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data[trainerKey]?.map((person: any, index: number) => (
                      <tr
                        key={person.trainer?.email || index}
                        className={
                          selectedPeople.has(person) ? "bg-gray-50" : undefined
                        }
                      >
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedPeople.has(person) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className={checkboxClasses}
                            value={person.trainer?.email}
                            checked={selectedPeople.has(person)}
                            onChange={() => handleCheckboxChange(person)}
                          />
                        </td>
                        <td
                          className={`whitespace-nowrap py-4 pr-3 text-sm font-medium ${
                            selectedPeople.has(person)
                              ? "text-indigo-600"
                              : "text-gray-900"
                          }`}
                        >
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.batch_code}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.course?.course_name}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.schedule}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.batch_progress_in_percent}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.status}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.branch?.branch_name}
                        </td>
                        <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                          {person?.city?.city_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
