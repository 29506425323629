import { useState, useEffect } from "react";
import { InputBox } from "../../Elements/InputBox";
import { DropdownInputWithCheckbox } from "../../Elements/DropdownInputWithCheckbox";
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import { DropdownBox } from "../../Elements/DropdownBox";
import { UserInputs } from "./UserInputs";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { useFormik } from "formik";
import { AddUserValidation } from "../../Formik/FormikValidation";
import { StatusButton } from "../../Elements/StatusButton";
import { add_User } from "../../Formik/InitialValues";
import { onKeyDown } from "../../../Services/CommonFunctions";
import {
  CreateNewUser,
  getUserById,
  updateUser,
  createUserPreLoadData
} from "../../../functions/admin/User Mangement/userMangement";
import { Loader } from "../../Elements/Loader/Loader";
import toast from "react-hot-toast";

export const Components: any = {
    useState, useEffect, InputBox, DropdownInputWithCheckbox, DropdownSingleInputWithCheckbox, DropdownBox, UserInputs, useSharedLogic, useFormik, AddUserValidation, StatusButton, add_User, onKeyDown, CreateNewUser, getUserById, updateUser, createUserPreLoadData, Loader, toast
}