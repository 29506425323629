import { manageCourse, manageCustomizedCourse } from "../../../utils/json/managecourse";
import { BackButton } from "../../Elements/BackButton";
import { AddCourse } from "./CreateCourse/AddCourse";
import { MessageCard } from "../../Elements/MessageCard";
import {
  useSharedLogic,
} from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useState, useEffect } from "react";
import { useAppSelector } from '../../..';
import { getCourseTableData, getCustomisedCourseTableData } from "../../../functions/admin/Courses/Courses";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { TableComponent } from '../../Elements/Table/TableComponent';
import { QueueType } from "../../CommonFunctions/CommonFunc";
import { ButtonOnImageRight } from '../../Elements/ButtonOnImageRight';
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";

export const ManageCourses = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "By Course",
      len: null
    },
    {
      approval: "By Customized",
      len: null
    }
  ] as any);

  useEffect(()=>{
    setSearchParams({
      id: "by_course",
    });
  },[])

  useEffect(() => {
    (async () => { 
      if (signInData?.data?.length > 0) {
        const courseResponse: any = await getCourseTableData();
        const customizedResponse: any = await getCustomisedCourseTableData();
        const courseLength: any = courseResponse?.length;
        const customizedLength: any = customizedResponse?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: courseLength
          },
          {
            ...prevQueue[1],
            len: customizedLength
          }
        ]);
      }
    })() 
  }, [])

  return (
    <div>
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Course Created Successfully"
                : "Course Updated Successfully"
            }
            buttonText={"Manage Course"}
            handleClick={() =>
              navigate({
                pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
                search: createSearchParams({
                  id: `by_course`,
                }).toString(),
              })
            }
            closeFunc={() =>
              navigate({
                pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
                search: createSearchParams({
                  id: `by_course`,
                }).toString(),
              })
            }
          />
        </div>
      )}
      {(id === "new-course" || mode === "edit" || mode === "view") &&
        mode !== "add-videos" &&
        mode !== "edit-videos" && (
          <div className="space-y-4">
            <BackButton
              topDivStyle={"w-10/12 justify-between"}
              header={
                (id === "new-course" && "Add Course") ||
                (mode === "edit" && "Edit Course") ||
                (mode === "view" && "View Course")
              }
            />
            <AddCourse id={id} mode={mode} />
          </div>
        )}
      {allowOnlyActions("view") &&
        id !== "new-course" && id !== "created-success" && id !== "update-success" &&
        !mode && (
          <div className="space-y-2">
            <div className="w-[95%] mx-auto flex items-center justify-between">
              <h1 className="text-[30px] text-white font-Roboto font-medium">
                Manage Course
              </h1>
              <div className="flex gap-6">
                {allowOnlyActions("view") && (
                  <QueueType queue={queue} setQueue={setQueue} />
                )}
                <ButtonOnImageRight
                  text={"Add Course"}
                  onClick={() => createFunc("new-course")}
                  buttonImg={buttonWithRightArrow}
                />
              </div>
            </div>
            {id === "by_course" && (
              <TableComponent
                TABLE_JSON={manageCourse}
                backEndCallParams={signInData?.data[0]}
              />
            )}
            {id === "by_customized" && (
              <TableComponent
                TABLE_JSON={manageCustomizedCourse}
                backEndCallParams={signInData?.data[0]}
              />
            )}
          </div>
        )}
    </div>
  );
};