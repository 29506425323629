import { Headline } from "../../Elements/Headline/Headline";
import { ReadData } from "../../Elements/ReadData";

export const InformationJobViewCard = ({
  cardHeading,
  values,
  buildJSON,
}: any) => {
  return (
    <div className="space-y-4">
      <Headline cardHeading={cardHeading} />
      {values && (
        <div className="w-10/12 mx-auto py-6">
          {values &&
            buildJSON.map((view: any, index: number) => {
              return (
                <div key={index}>
                  <div className="flex">
                    <div className="w-1/2 space-y-4">
                      {view.left.map((leftSide: any, index: number) => {
                        return (
                          <div key={index}>
                            <ReadData
                              head={leftSide.head}
                              body={leftSide.body}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="w-1/2 space-y-4">
                      {view.right.map((rightSide: any, index: number) => {
                        return (
                          <div key={index}>
                            <ReadData
                              head={rightSide.head}
                              body={rightSide.body}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
