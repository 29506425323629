import React, { useState, useEffect, useRef } from 'react';
import boldArrow from "../../Assets/boldArrow.svg"; // Adjust the import path if needed


export const DropdownBox = ({
  label,
  placeholder,
  OptionsGiven,
  value,
  onChange,
  classNameInput,
  topDivStyle,
  formik,
  name,
  mandatory,
  mandatoryClass,
  id,
  single,
  error,
  isTouched
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterSection, setFilterSection] = useState([] as any);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const checkIsError = isTouched && error;

  useEffect(() => {
    setFilterSection(OptionsGiven);
  }, [OptionsGiven]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  // const handleOptionSelect = (selectedName: string, selectedID: any) => {
  //   const currentValues = formik.values[id] || [];
  //   let updatedValues = [...currentValues];

  //   if (currentValues.includes(selectedID)) {
  //     updatedValues = currentValues.filter((value: string) => value !== selectedID);
  //   } else {
  //     updatedValues = single ? selectedID : [...currentValues, selectedID];
  //   }

  //   formik.setFieldValue(name, updatedValues);
  //   if (single) setIsOpen(false);
  // };

  const handleOptionSelect = (selectedName: string, selectedID: any) => {
    const currentValues = formik.values[id] || (single ? null : []);
    let updatedValues;
  
    if (single) {
      // If single selection mode, set the value directly
      updatedValues = currentValues === selectedID ? null : selectedID;
    } else {
      // Multiple selection mode, ensure currentValues is an array
      updatedValues = Array.isArray(currentValues)
        ? currentValues.includes(selectedID)
          ? currentValues.filter((value: any) => value !== selectedID)
          : [...currentValues, selectedID]
        : [selectedID];
    }
  
    formik.setFieldValue(name, updatedValues);
    if (single) setIsOpen(false);
  };
  

  const valuesArray = Array.isArray(value) ? value : value ? [value] : [];
  const selectedNames = filterSection
    .filter((option: any) => valuesArray.includes(option.id))
    .map((option: any) => option.name);

  return (
    <div ref={dropdownRef} className={`${topDivStyle} space-y-0.5 relative`}>
      <div className="space-y-0.5">
        <label className="text-[#707070] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-medium">
          {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
        </label>
        <div className="relative">
          <input
            className={`appearance-none outline-none border-[1px] text-[black] 2xl:text-[16px] lg:text-[14px] md:text-[12px] rounded-[3px] px-2 py-2 font-Roboto leading-tight w-full ${classNameInput}`}
            value={selectedNames.length > 0 ? selectedNames.join(", ") : ""}
            onClick={handleDropdownClick}
            placeholder={placeholder}
            readOnly
          />
          <img
            src={boldArrow}
            alt="boldArrow"
            className={`absolute top-1/2 right-4 transform -translate-y-1/2 transition-transform duration-300 ${
              isOpen ? "rotate-180" : ""
            }`}
            onClick={handleDropdownClick}
          />
          {checkIsError && (
            <span className="text-red-500 absolute -bottom-6 right-0">
              {error}
            </span>
          )}
          {isOpen && (
            <div className="absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] max-h-[150px] overflow-auto snap-y snap-mandatory scrollBar scroll-smooth z-100">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-2 py-1 outline-none border-b-[1px] border-[#707070]"
              />
              {filterSection
                .filter((option: any) =>
                  option.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((option: any, index: number) =>
                  single ? (
                    <p
                      key={index}
                      className={`2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto px-2 py-1 cursor-pointer ${
                        valuesArray.includes(option.id) ? "bg-blue-100" : ""
                      }`}
                      onClick={() => handleOptionSelect(option.name, option.id)}
                    >
                      {option.name}
                    </p>
                  ) : (
                    <label
                      key={index}
                      className="flex items-center px-2 py-1 cursor-pointer 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={valuesArray.includes(option.id)}
                        onChange={() =>
                          handleOptionSelect(option.name, option.id)
                        }
                      />
                      {option.name}
                    </label>
                  )
                )}
            </div>
          )}
        </div>
        {/* {mandatory && formik.touched[name] && formik.errors[name] && (
          <p className="text-red-500 text-md mt-6 text-right">Required</p>
        )} */}
      </div>
    </div>
  );
};
