import { dateFormat } from "../../../../Services/CommonFunctions"
import { errorNotifier } from "../../../commonHelper"
import { TableNames } from "../../../config/Tables"
import { FetchData, InsertData, UpdateData, DeleteData } from "../../../crud"
import { calculatePaidCourseCompletion } from "../helper"
import { ApprovalRequestInterface } from "./interface"
import { UserLogInterface } from "../../User Logs/interface";
import {submitUserLogs} from "../../User Logs/userLogs"


export const getStudentActionsTableData = async (UsersDetails: any) => {
    try {
        let { data: trainingData, error: TrainingQueryError }: any = await FetchData(TableNames.trainings, ["course:courses(course_name, duration)", "batch:batches(id,start_date,course:courses(course_name,duration),sessions:sessions(*))", "city:cities(city_name)", "branch:branches(branch_name,ownership)", "student:students(name,phoneNumber,email)", "status", "total_fee", "balance_due", "id", "created_at", "duration"]).in("branch_id", UsersDetails?.branches);
        let { data: approvalData, error: approvalError }: any = await FetchData(TableNames.approval_queue, ["training_id", "approvalStatus"]);
        
        if (TrainingQueryError || approvalError) throw TrainingQueryError || approvalError;
        trainingData = trainingData?.map(
            (training: any) => ({
              student_name: training?.student?.name,
              student_phone_number: training?.student?.phoneNumber,
              student_email: training?.student?.email,
              course_name: training?.course?.course_name,
              status: training?.status,
              total_fee: Math.round(Number(training?.total_fee)),
              balanceDue:
                Math.round(Number(training?.balance_due)) === 0
                  ? "0"
                  : Math.round(Number(training?.balance_due)),
              branchName: training?.branch?.branch_name,
              ownership: training?.branch?.ownership,
              cityName: training?.city?.city_name,
              courseProgress:
                training?.batch === null
                  ? "-"
                  : calculatePaidCourseCompletion(
                      training?.course?.duration,
                      training?.batch?.sessions
                    ),
              trainingId: training?.id,
              registration_date: dateFormat(
                training?.created_at,
                "DD MMM YYYY"
              ),
            }
          )
        );

        // const filteredData = trainingData.filter((item1: any) => {
        //     const matchingItem2 = approvalData.find((item2: any) => {
        //         if (Number(item2.training_id) !== Number(item1.trainingId)) {
        //             return true;
        //         } else if (Number(item2.training_id) !== Number(item1.trainingId) && item2.approvalStatus !== "reject") {
        //             return true
        //         } else if (Number(item2.training_id) === Number(item1.trainingId) && item2.approvalStatus !== "reject") {
        //             return true
        //         } else return false;
        //     });
        //     return matchingItem2;
        // });
        // console.log(filteredData, "filtered");

        return trainingData;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const createApprovalRequest = async (actionData: ApprovalRequestInterface) => {
    // console.log(actionData, "actionData")
    try {
        const { data, error } = await InsertData(TableNames.approval_queue, actionData)
        const userlogObj:UserLogInterface={
            user_id: actionData.user_id, 
            changes: actionData.reason,
            module: "createApprovalRequest"
        }

        submitUserLogs(userlogObj);

        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

/*export const getApprovalQueueTableData = async () => { 
    try {
        const { data, error } = await FetchData(TableNames.approval_queue, 
            ["*", "training:trainings(status, duration,created_at, total_fee, balance_due, student:students(name, email,phoneNumber), course:courses(course_name), batch:batches(start_date,course:courses(course_name,duration),sessions:sessions(*)), city:cities(city_name), branch:branches(branch_name))"])
            //.eq(approvalStatus,approvalStatus)
        


        const trainingData = data?.map((training: any) => ({
            student_name: training?.training?.student?.name,
            student_phone_number: training?.training?.student?.phoneNumber,
            student_email: training?.training?.student?.email,
            course_name: training?.training?.batch?.course?.course_name,
            status: training?.status === "Drop" ? "Dropped" : training?.status,
            total_fee: training?.training?.total_fee,
            balanceDue: training?.training?.balance_due,
            branchName: training?.training?.branch?.branch_name,
            cityName: training?.training?.city?.city_name,
            courseProgress: training?.training?.batch ? calculatePaidCourseCompletion(training?.training?.duration, training?.training?.batch?.sessions):0,
            trainingId: training?.training_id,
            registration_date: dateFormat(training?.training?.created_at, "DD MMM YYYY")
        }))
        console.log("trainingData",trainingData)
        if (error) throw error
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}*/
export const getApprovalQueueTableData = async (UsersDetails: any) => {
    try {

        const { data, error } = await FetchData(TableNames.approval_queue, ["*", "training:trainings(status, duration,created_at, total_fee, balance_due, student:students(name, email,phoneNumber), course:courses(course_name), batch:batches(start_date,course:courses(course_name,duration),sessions:sessions(*)), city:cities(city_name,id), branch:branches(id, branch_name, ownership))"]).is("approved_date", null) 
        const trainingData = data?.filter((fil: any) => UsersDetails?.branches?.includes(fil?.training?.branch?.id)).map((training: any) => ({
            student_name: training?.training?.student?.name,
            student_phone_number: training?.training?.student?.phoneNumber,
            student_email: training?.training?.student?.email,
            course_name: training?.training?.course?.course_name,
            status: training?.status === "Drop" ? "Dropped" : training?.status,
            total_fee: Math.round(Number(training?.training?.total_fee)),
            balanceDue: Math.round(Number(training?.training?.balance_due)),
            branchName: training?.training?.branch?.branch_name,
            ownership: training?.training?.branch?.ownership,
            cityName: training?.training?.city?.city_name,
            courseProgress: training?.training?.batch === null ? '-' : calculatePaidCourseCompletion(training?.training?.duration, training?.training?.batch?.sessions),
            trainingId: training?.training_id,
            registration_date: dateFormat(training?.training?.created_at, "DD MMM YYYY"),
            requested_date: dateFormat(training?.requested_date, "DD MMM YYYY"),
        }));
        // console.log(trainingData, "trainingData")
        if (error) throw error
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getPastApprovalQueueTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.approval_queue, [
          "*",
          "training:trainings(status, duration,created_at, total_fee, balance_due, student:students(name, email,phoneNumber), course:courses(course_name), batch:batches(start_date,course:courses(course_name,duration),sessions:sessions(*)), city:cities(city_name), branch:branches(id, branch_name, ownership))",
        ])
          .not("approved_date", "is", null)
          .eq("approvalStatus", "accept")
          .order("id", { ascending: false });
        const trainingData = data?.filter((fil: any) => UsersDetails?.branches?.includes(fil?.training?.branch?.id)).map((training: any) => ({
            student_name: training?.training?.student?.name,
            student_phone_number: training?.training?.student?.phoneNumber,
            student_email: training?.training?.student?.email,
            course_name: training?.training?.course?.course_name,
            status: training?.status === "Drop" ? "Dropped" : training?.status,
            total_fee: Math.round(Number(training?.training?.total_fee)),
            balanceDue: Math.round(Number(training?.training?.balance_due)),
            branchName: training?.training?.branch?.branch_name,
            ownership: training?.training?.branch?.ownership,
            cityName: training?.training?.city?.city_name,
            courseProgress: training?.training?.batch === null ? '-' : calculatePaidCourseCompletion(training?.training?.duration, training?.training?.batch?.sessions),
            trainingId: training?.training_id,
            registration_date: dateFormat(training?.training?.created_at, "DD MMM YYYY"),
            requested_date: dateFormat(training?.requested_date, "DD MMM YYYY"),
        }));
        // console.log(trainingData, "trainingData")
        if (error) throw error
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getRejectApprovalQueueTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.rejected_approval_queues, ["*", "training:trainings(status, duration,created_at, total_fee, balance_due, student:students(name, email,phoneNumber), course:courses(course_name), batch:batches(start_date,course:courses(course_name,duration),sessions:sessions(*)), city:cities(city_name), branch:branches(id, branch_name, ownership))"]).not("approved_date", "is", null).eq("approvalStatus", 'reject').eq('user_id', UsersDetails?.uid)
        const trainingData = data
          ?.filter((fil: any) =>
            UsersDetails?.branches?.includes(fil?.training?.branch?.id)
          )
          .map(
            (training: any) => (
            //   console.log(training, "training"),
              {
                student_name: training?.training?.student?.name,
                student_phone_number: training?.training?.student?.phoneNumber,
                student_email: training?.training?.student?.email,
                course_name: training?.training?.course?.course_name,
                status:
                training?.status === "Drop" ? "Dropped" : training?.status,
                total_fee: Math.round(Number(training?.training?.total_fee)),
                balanceDue: Math.round(Number(training?.training?.balance_due)),
                branchName: training?.training?.branch?.branch_name,
                ownership: training?.training?.branch?.ownership,
                cityName: training?.training?.city?.city_name,
                courseProgress: training?.training?.batch === null ? "-" :
                  calculatePaidCourseCompletion(
                      training?.training?.duration,
                      training?.training?.batch?.sessions
                    ),
                trainingId: training?.training_id,
                registration_date: dateFormat(
                  training?.training?.created_at,
                  "DD MMM YYYY"
                ),
                requested_date: dateFormat(
                  training?.requested_date,
                  "DD MMM YYYY"
                ),
              }
            )
          );
        // console.log(trainingData, "trainingData")
        if (error) throw error
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseBasedOnTechnology = async (courseId: number, categoryName: string, branchId: number) => {
    // console.log(courseId, categoryName, branchId, "108")
    try {
        const { data: course_data, error: course_error } = await FetchData(TableNames.courses, ["id", "course_name", "duration", "training:trainings(id, course_fee)", "category:categories(category_name)", "branches"]).neq("id", courseId);
        const filterCourse = course_data?.filter((fil: any) => (fil?.category?.category_name === categoryName) && (fil?.branches?.includes(branchId)));
        if (course_error) throw course_error;
        return filterCourse;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseBasedOnTechnologyForExisting = async (courseId: number, categoryName: string, branchId: number) => {
    try {
        const { data: course_data, error: course_error } = await FetchData(TableNames.courses, ["id", "course_name", "duration", "training:trainings(id, course_fee)", "category:categories(category_name)", "branches"]);
        const filterCourse = course_data?.filter((fil: any) => (fil?.category?.category_name === categoryName) && (fil?.branches?.includes(branchId)));
        if (course_error) throw course_error;
        return filterCourse;
    } catch (error) {
        return errorNotifier(error)
    }
}

const updateTransactionData = async(approvalData: any) => {
  if (approvalData.payment_details && Array.isArray(approvalData.payment_details)) {
    for (const dt of approvalData.payment_details) {
      try {
        await UpdateData(
          TableNames.transactions,
          dt,
          { conditionKey: "id", conditionValue: dt?.id }
        );
      } catch (error) {
        console.error('Error updating training data:', error);
        // Handle the error appropriately
      }
    }
  }
}

export const updateApprovalStatus = async(approvalData: ApprovalRequestInterface, approvalId: number) => {

    try {
        if(approvalData.status === "Nullify Payment" && approvalData.approvalStatus!=='reject') {

            await UpdateData(TableNames.trainings, { balance_due: 0 }, { conditionKey: "id", conditionValue: approvalData.training_id })
            // const { data: transaction_data, error: transaction_error }: any = await FetchData(TableNames.transactions).eq("training_id", approvalData.training_id)
            // const last_transaction = transaction_data[transaction_data.length - 1]
            // const new_transaction_after_nullify = {
            //     training_id: approvalData.training_id,
            //     balance: 0,
            //     paid_amount: last_transaction?.balance,
            //     cgst: last_transaction?.cgst,
            //     sgst: last_transaction?.sgst,
            //     mode_of_payment: "Cash",
            // }
            // await InsertData(TableNames.transactions, new_transaction_after_nullify)
        }

        if ((approvalData.status === "Downgrade" || approvalData.status === "Upgrade") && approvalData.approvalStatus !== 'reject') {
            const sumFinalFee = approvalData.status === "Upgrade" ? (Number(approvalData.fee) + Number(approvalData.additional_amount)) : (Number(approvalData.fee) - Number(approvalData.additional_amount));
            const trainingData = {
              total_fee: approvalData.total_fee,
              course_fee: sumFinalFee,
              duration: approvalData.duration,
              course_id: approvalData.upgraded_or_downgraded_course_id,
              balance_due: approvalData.balance_due,
              payment_details: approvalData.payment_details.map((item:any, index:number) => {
                    if (index === 0) {
                    return item;
                    } else {
                        return {
                            paid_amount: item.paid_amount,
                            payment_date: item.payment_date,
                            payment_type: item.payment_type?.[0],
                            transaction_id: item.transaction_id,
                            mode_of_payment: item.mode_of_payment
                        };
                    }
                })
            };
            await updateTransactionData(approvalData);
            await UpdateData(TableNames.trainings, trainingData, { conditionKey: "id", conditionValue: approvalData?.training_id })
            await UpdateData(
                TableNames.approval_queue,
                { approved_date: new Date(), approvalStatus: "accept", latest_training: false},
                {
                conditionKey: "id",
                conditionValue: approvalId,
                }
            );
        } else if ((approvalData.status === "Downgrade" || approvalData.status === "Upgrade") && approvalData.approvalStatus === 'reject') {
            DeleteData(TableNames.approval_queue, {conditionKey: "id", conditionValue: approvalId,})
            InsertData(TableNames.rejected_approval_queues, approvalData) 
        }

        if ((approvalData.status === "Drop" || approvalData.status === "Hold") && approvalData.approvalStatus!=='reject') {
            await UpdateData(TableNames.trainings, { status: approvalData.status, student_action_request_pending: false }, { conditionKey: "id", conditionValue: approvalData.training_id })            
        }

        if (approvalData.status === "Hold" && approvalData.approvalStatus==='reject') {
            await UpdateData(TableNames.trainings, { student_action_request_pending: false }, { conditionKey: "id", conditionValue: approvalData.training_id })   
            InsertData(TableNames.rejected_approval_queues, approvalData)
        }

        if(approvalData.status !== "Downgrade" && approvalData.status !== "Upgrade") {
            const { data, error } = await
                UpdateData(TableNames.approval_queue, approvalData, { conditionKey: "id", conditionValue: approvalId })
            if (error) throw error;
        }

        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getApprovalActionData = async(training_id: number) => {
    try {
        const { data, error } = await FetchData(TableNames.approval_queue, ["*"]).eq("training_id", training_id).order("id", { ascending: false });
        if (error) throw error;
        const filter_data = data?.filter((ft: any) => !ft?.latest_training);
        return filter_data;
    } catch (error) {
        return errorNotifier(error);
    }
}