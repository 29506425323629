import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { sessionFeedback } from "../../../utils/json/SessionFeedback";
import { sessionFeedbackforabsent } from "../../../utils/json/SessionFeedbackForAbsent";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FeedbackInfo } from "./SessionFeedbackInfo/FBInfo";
import { BackButton } from "../../Elements/BackButton";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { useAppSelector } from "../../..";
import { getSessionFeedbackTableData, getSessionFeedbackTableDataForAbsent } from "../../../functions/admin/Action center/Session Feedback/sessionFeedback";
import { QueueType } from "../../CommonFunctions/CommonFunc";

export const SessionFeedback = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "All Sessions",
      len: null,
    },
    {
      approval: "Absent",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "all_sessions",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const sessionResponse: any = await getSessionFeedbackTableData(signInData?.data[0]);
      const absentResponse: any = await getSessionFeedbackTableDataForAbsent(signInData?.data[0]);
      const sessionLength: any = sessionResponse?.length;
      const absentLength: any = absentResponse?.length;

      setQueue((prevQueue: any) => [
        {
          ...prevQueue[0],
          len: sessionLength,
        },
        {
          ...prevQueue[1],
          len: absentLength,
        },
      ]);
    })();
  }, []);

  return (
    <div>
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"flex items-center justify-end w-[95%] mx-auto"}
          />
          <FeedbackInfo id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Session Feedback
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "all_sessions" && (
            <TableComponent
              TABLE_JSON={sessionFeedback}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "absent" && (
            <TableComponent
              TABLE_JSON={sessionFeedbackforabsent}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  );
};
