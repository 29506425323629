import { useState, useEffect } from 'react';
import { ImageListForSelected, ImageListForUnselected } from "../LeftNavbar/jsonList";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import openAndCloseNavbar from "../../Assets/openAndCloseNavbar.svg";
import { useAppDispatch, useAppSelector } from "../../index";
import { showSideNavStatus } from '../../Redux/Reducers/sideNavPosition';
import searchIcon from "../../Assets/searchIcon.svg";
import FITA_Logo from "../../Assets/FITA_Logo.png";
import dropArrowBlack from "../../Assets/dropArrow.svg";
import dropArrowWhite from "../../Assets/dropArrowWhite.svg";
import { getLoggedUserDetails } from '../../functions/admin/User Mangement/userMangement';
import UserLogs from "../../Assets/UserLogs.svg";
import { setUserRoles } from '../../Redux/Reducers/userSelectedRoles';
import { searchMenus } from '../AdminManagement/RoleManagement/helper';
import { FunctionForPrivileges } from '../CommonFunctions/Privileges';
import { SUPABASE_CLIENT } from '../../Services/Supabase';
import Log_Out from "../../Assets/Log_Out.svg";

export const LeftNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [openTab, setOpenTab] = useState(false)
  const [dropIndex, setDropIndex] = useState<any>([]); // [localStorage.getItem('arrowDirection')]||
  const [subSectionIndex, setDropSectionIndex] = useState([] as any)
  const [menuOptions, setMenuOptions] = useState([] as any);
  const [searchRole, setSearchRole] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const { dashboardScreen, subsection }: any = params;
  const DashboardSubMenus = [
    "admissions",
    "invoices&revenue",
    "operations",
    "batch",
    "session_feedback_dashboard",
    "feedback_concern",
    "placement",
  ];
  const { mapMainComponent, concatenatedSubArray, ForSubDomainsNew }: any = FunctionForPrivileges();
  const [{ sideNavOpenClose }, { userDetails }, { selectedRoles }] =
    useAppSelector(({ navStatus, login, roles }: any) => [
      navStatus,
      login,
      roles["userRoles"],
    ]);
  const checkSearchDataIsAvailable =
    menuOptions?.length ? menuOptions :
      selectedRoles; 

  const matchIdWithRoutes: any = {
    manage_courses: "by_course",
    pending_payment: "by_student",
    daily_transactions: "unverified",
    student_actions: "students_action_queue",
    student_actions_approval_queue: "current_approval_queue",
    issue_refund_adjust_payment: "issue_refund",
    student_attendance: "absent",
    cancellation_session: "verified_comments",
    operational_report: "batch",
    session_feedback: "all_sessions",
    calculate_trainer_payment: "yet_to_calculated",
    process_trainer_payment: "all_data",
    student_feedback_concerns: "student_concern",
    cancelled_session: "yet_to_speak"
  };

    useEffect(() => {
      (async () => {
        // const storedDirection = localStorage.getItem('arrowDirection');
        // if (storedDirection) {
        //   setDropIndex([...dropIndex, storedDirection]);
        // }
        setDropIndex([...dropIndex, dashboardScreen]);
        const response = await searchMenus(selectedRoles, searchRole);
        setMenuOptions(response);
        setTriggerSearch(false);
      })();
    }, [triggerSearch]);
  
  const SelectedDivision = (section: any) => {
    if (section?.submenus?.length > 0) {
    const checkSubsection = ForSubDomainsNew?.filter(
      (sub: any) => sub?.idkey === section.idkey
    );

    const filteredSubmenus = checkSubsection?.map((sub: any) => {
      let foundSub = null;
      sub?.submenus?.find((fil: any) => {
        if (concatenatedSubArray.includes(fil?.idkey)) {
          foundSub = fil?.idkey;
          return true; // Stop the loop
        }
        return false;
      });
      return foundSub;
    });

      dropMenu(section);
      const data = filteredSubmenus?.[0];;
      if (matchIdWithRoutes?.hasOwnProperty(data)) {
        navigate({
          pathname: `/${section.idkey}/${data}`,
          search: createSearchParams({
            id: `${matchIdWithRoutes[data]}`,
          }).toString(),
        });
      } else {
        navigate(`/${section.idkey}/${data}`);
      }
    } else {
      navigate(`/${section.idkey}`);
    }
  };
    
    const changeModalWidth = () => {
      dispatch(showSideNavStatus(!sideNavOpenClose));
      // setOpenTab(!openTab);
      setDropIndex([])
      setOpenTab(false);
    }
  
  const dropMenu = (section: any) => {
    if (section?.submenus) {
      if (dropIndex.includes(section.idkey)) {
        const removeIndex = [...dropIndex];
        const checkIsAvailable = removeIndex.filter(
          (idx: any) => idx !== section.idkey
        );
        setDropIndex(checkIsAvailable);
        // localStorage.setItem('arrowDirection', checkIsAvailable);
      } else setDropIndex([...dropIndex, section.idkey]);
    }
  }
  
  const subSectionsRoute = (section: any, subComp: any) => {
    if (matchIdWithRoutes?.hasOwnProperty(subComp.idkey)) {
      navigate({
        pathname: `/${section.idkey}/${subComp.idkey}`,
        search: createSearchParams({
          id: `${matchIdWithRoutes[subComp.idkey]}`,
        }).toString(),
      });
    } else {
      navigate(`/${section.idkey}/${subComp.idkey}`);
    }
  };

  const handleLogout = async () => {
    try {
      // Sign out the user
      await SUPABASE_CLIENT.auth.signOut();
      window.location.href = '/';
      // Redirect to the login page or update your app's state as needed
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  return (
    <div
      className={`${
        sideNavOpenClose
          ? "md:w-[10%] lg:w-[10%] xl:w-[5%]"
          : "md:w-[35%] lg:w-[30%] xl:w-[22%]"
      } z-10 relative space-y-6 flex flex-col items-center justify-between shadow-md shadow-[#00000029] mt-6`}
    >
      {sideNavOpenClose ? (
        <div className="w-[140px] h-[75px]"></div>
      ) : (
        <img src={FITA_Logo} alt="Logo" className="w-[120px] h-[70px] 2xl:w-[140px] 2xl:h-[75px] select-none" />
      )}
      <div className=" w-full flex items-center justify-center">
        {sideNavOpenClose ? (
          <img
            src={searchIcon}
            alt="Search"
            className="w-[20px] h-[20px] cursor-pointer"
          />
        ) : (
          <div className="relative flex items-center justify-center w-9/12">
            <input
              placeholder="Search..."
              name="search"
              value={searchRole}
              onChange={(e: any) => setSearchRole(e.target.value)}
              className="border border-[#707070] rounded-2xl h-[35px] w-full px-2"
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  setTriggerSearch(true);
                }
              }}
            />
            <img src={searchIcon} alt="Search" className="absolute right-3" />
          </div>
        )}
        <img
          src={openAndCloseNavbar}
          alt="Logo"
          className={`ml-[125px] absolute -right-5 select-none cursor-pointer ${
            sideNavOpenClose &&
            "rotate-180 transition-all duration-300 ease-in-out"
          }`}
          onClick={changeModalWidth}
        />
      </div>
      <div
        className={`${
          !sideNavOpenClose && "w-[95%]"
        } mx-auto h-[80vh] overflow-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth`}
      >
        {checkSearchDataIsAvailable &&
          checkSearchDataIsAvailable?.map((section: any, index: number) => {
            if (mapMainComponent?.includes(section?.idkey)) {
              return (
              <div key={index}>
                <div
                  className={`w-full select-none flex items-center justify-between rounded-[2px] cursor-pointer px-2 py-1.5 ${
                    section.idkey.includes(dashboardScreen) ||
                    (!dashboardScreen && section["idkey"] === "dashboard") ||
                    (section?.submenus &&
                      section?.submenus?.[index]?.idkey.includes(subsection))
                      ? "bg-[#036AD1] text-white shadow-md shadow-[#00000029]"
                      : "text-[#707070]"
                  }`}
                  onClick={() => SelectedDivision(section)}
                >
                  <div
                    className={`flex items-center gap-2 ${
                      sideNavOpenClose && "p-3"
                    }`}
                  >
                    <img
                      src={
                        section.idkey.includes(dashboardScreen)
                          ? ImageListForUnselected[section.idkey]
                          : ImageListForSelected[section.idkey]
                      }
                      alt={`${section.idkey}`}
                      className="w-[14px] 2xl:w-[15px]"
                      onClick={() => dispatch(showSideNavStatus(false))}
                    />
                    {!sideNavOpenClose && (
                      <h1 className="text-[15px] 2xl:text-[16px] font-Roboto font-[400]">
                        {section.label}
                      </h1>
                    )}
                  </div>
                  {!sideNavOpenClose && section?.submenus?.length !== 0 && section?.idkey!=="dashboard" && (
                    <img
                      src={
                        section.idkey.includes(dashboardScreen)
                          ? dropArrowWhite
                          : dropArrowBlack
                      }
                      alt="dropArrow"
                      className={`${
                        dropIndex.includes(section?.idkey) && "rotate-90"
                      }`}
                      onClick={() => dropMenu(section)}
                    />
                  )}
                </div>
                <div className="">
                  {dropIndex.includes(section?.idkey) &&
                    section?.submenus &&
                      section?.submenus?.map((subComp: any, index: number) => {
                        if ((concatenatedSubArray.includes(subComp?.idkey))&&(!DashboardSubMenus.includes(subComp?.idkey))) {
                          return (
                            <div
                              key={index}
                              className={`${
                                index === 0 && "mt-1"
                              } px-4 py-1 w-[90%] mx-auto cursor-pointer rounded-[2px] ${
                                (subComp.idkey?.replace(/_/g, "-") ===
                                  subsection?.replace(/_/g, "-")) ||
                                (!subsection &&
                                  subComp["idkey"] === subComp?.[0]?.["idkey"])
                                  ? "bg-[#B4D9FF] text-[#000000]"
                                  : "bg-white text-[#707070]"
                              }`}
                              onClick={() => subSectionsRoute(section, subComp)}
                            >
                              <p className="w-[15px]"></p>
                              <p className="font-Roboto font-[400] text-[13px] 2xl:text-[14px] px-2">
                                {subComp.label}
                              </p>
                            </div>
                          );
                          }
                    })}
                </div>
              </div>
            );
            }
          })}
      </div>
      <div className='flex items-center gap-2 w-full p-4 cursor-pointer' onClick={handleLogout}>
        <img src={Log_Out} alt="Log_Out" className='w-5 h-5' />
        {!sideNavOpenClose && <h1 className='text-[20px] font-Roboto font-[400] text-red-500'>Logout</h1>}
      </div>
    </div>
  );
}