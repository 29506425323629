import { useSearchParams } from "react-router-dom";
import tableEditIcon from "../../../Assets/tableEditIcon.svg"
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const navigateToEditPage = () => {
    setSearchParams({
      id: `${recordData.student_id}`,
      mode: `edit`,
    });
  };
    
  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions('edit') && <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img src={tableEditIcon} alt="editIcon" className="w-[14px] h-[14px]" />
      </div>}
    </div>
  );
};
