import { dateFormat } from "../../../Services/CommonFunctions";

export const AppliedJobs = ({appliedJobs}:any) => {
    const SessionHeader = ['#', 'Date of Applying', 'Company Name', 'Role', 'Location', 'Shortlisted Status', 'Placed Status', 'Company Joining Status'];
    const SessionClassName = (val?:any) => `${val?'w-[5%]':'w-[12%]'} text-[#686868] text-[14px] font-Roboto font-[400] text-center`;

  // const sampleData = [{
  //   created_at: "30 07 1998",
  //   job: {
  //     company: "Venzo",
  //     position: "Software Engineer",
  //     location: "Bangalore"
  //   },
  //   shortlisted: true,
  //   placed: true,
  //   joined: false
  // }]
  
  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Applied Jobs
      </h1>
      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
        <div className="flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4">
          {SessionHeader.map((head: any) => (
            <h1
              className={`${
                head === "#" ? "w-[5%]" : "w-[12%]"
              } text-[#000000] text-[16px] font-Roboto font-[500] text-center`}
            >
              {head}
            </h1>
          ))}
        </div>
        <div className="h-[300px] scroll-smooth overflow-y-scroll">
          {appliedJobs?.map((arr: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-between py-1.5 px-4 ${
                  index % 2 !== 0 && "bg-[#EFF7FF]"
                }`}
              >
                <p className={SessionClassName(index + 1)}>{index + 1}</p>
                <p className={SessionClassName()}>
                  {dateFormat(arr.created_at, "DD MMM YYYY")}
                </p>
                <p className={SessionClassName()}>{arr?.job?.company}</p>
                <p className={SessionClassName()}>{arr?.job?.position}</p>
                <p className={SessionClassName()}>{arr?.job?.location}</p>
                <p className={SessionClassName()}>
                  {arr?.shortlisted ? "Yes" : "No"}
                </p>
                <p className={SessionClassName()}>
                  {arr?.placed ? "Yes" : "No"}
                </p>
                <p className={SessionClassName()}>
                  {arr?.joined ? "Yes" : "No"}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
