import {useEffect, useState} from 'react';
import { Loader } from '../../Elements/Loader/Loader';
import { CardLayoutTwo } from '../../Elements/Cards/CardLayoutTwo';
import { ReadData } from '../../Elements/ReadData';
import { getCourseMappingById, getCourseMappingPreloadData } from "../../../functions/admin/Course Mapping/CourseMapping";
import { useAppSelector } from '../../..';

export const ViewCourseMapping = ({id, mode}:any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [Tech, setTech] = useState({} as any);
  const [courseName, setCourseName] = useState([] as any);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  
  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) { 
        setIsLoading(true)
        const allTech:any = await getCourseMappingById(id);
        setTech(allTech);
        const loadData:any = await getCourseMappingPreloadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
          const mapCoursesForID: any =
            loadData &&
            loadData.filter((data: any) =>
              allTech?.courses.includes(data.id)
          );
          const mapCoursesForName: any =
            mapCoursesForID &&
            mapCoursesForID.map((data: any) => data.course_name)
        setCourseName(mapCoursesForName?.join(', '))
        setIsLoading(false)
      }
  })()
  }, [])
  
  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <CardLayoutTwo>
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              View Course Mapping
            </p>
          </div>
          <div className="w-11/12 mx-auto py-8 flex flex-col gap-6">
            <ReadData head={"Course Mapping"} body={Tech?.mapping_course} />
            <ReadData head={"Course"} body={courseName} />
          </div>
        </CardLayoutTwo>
      )}
    </div>
  );
}
