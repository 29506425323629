// import { Components } from "./ModuleImports";
// import { CompModal } from "../../UI_Modal/CompModal";

// export const AbsentDetails = ({ Sessions }: any) => {
//   const { SessionData, useState }: any = Components;
//   const [show, setShow] = useState(false);
//   const [feedbackContent, setFeedbackContent] = useState("");
//   const [modalTitle, setModalTitle] = useState("");

//   const AbsentHeader = [
//     "Batch Code",
//     "Course",
//     "Student Name",
//     "Phone Number",
//     "Course Mode",
//     "Schedule",
//     "Feedback"
//   ];

//   const columnWidths = [
//     "w-[12%]",
//     "w-[12%]", 
//     "w-[15%]", 
//     "w-[20%]", 
//     "w-[10%]", 
//     "w-[10%]", 
//     "w-[10%]", 
//   ];

//   const AbsentClassName = (index: number, isClickable?: boolean) =>
//     `${columnWidths[index]} ${isClickable ? "text-[#036AD1] cursor-pointer" : "text-[#686868]"
//     } py-1 text-[14px] font-Roboto font-[400] text-center select-none`;

//   const openFeedbackModal = (feedback: string) => {
//     setFeedbackContent(feedback);
//     setModalTitle("Feedback Details"); 
//     setShow(true);
//   };

//   const closeModal = () => {
//     setShow(false);
//     setFeedbackContent("");
//   };

//   return (
//     <div className="space-y-4 w-11/12 mx-auto">
//       <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
//         Absent Details
//       </h1>

//       <CompModal
//         show={show}
//         fun={closeModal}
//         content={feedbackContent}
//         title={modalTitle}
//       />

//       <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
//         <div className="flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4">
//           {AbsentHeader.map((head, index) => (
//             <h1
//               key={head}
//               className={`${columnWidths[index]} text-[#000000] text-[16px] font-Roboto font-[500] text-center`}
//             >
//               {head}
//             </h1>
//           ))}
//         </div>
//         <div>
//           {Sessions.map((arr: any, index: number) => (
//             <div
//               key={index}
//               className={`flex items-center justify-between py-1.5 px-4 ${index % 2 !== 0 && "bg-[#EFF7FF]"}`}
//             >
//               <p className={AbsentClassName(0)}>{arr.batchCode}</p>
//               <p className={AbsentClassName(1)}>{arr.courseName}</p>
//               <p className={AbsentClassName(2)}>{arr.studentName}</p>
//               <p className={AbsentClassName(3)}>
//                 {arr.phoneNumber}
//               </p>
//               <p className={AbsentClassName(4)}>{arr.courseMode}</p>
//               <p className={AbsentClassName(5)}>{arr.schedule}</p>
//               <p
//                 className={AbsentClassName(6, Boolean(arr.feedback && arr.feedback!=="No Comments"))}
//                 onClick={() => arr.feedback && arr.feedback!=="No Comments" && openFeedbackModal(arr.feedback)}
//               >
//                 {arr.feedback==="No Comments" ? "No Feedback" : "View Feedback"}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };



import { Components } from "./ModuleImports";
import { CompModal } from "../../UI_Modal/CompModal";

export const AbsentDetails = ({ Sessions }: any) => {
  const { SessionData, useState }: any = Components;
  const [show, setShow] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const AbsentHeader = [
    "Batch Code",
    "Course",
    "Student Name",
    "Phone Number",
    "Course Mode",
    "Schedule",
    "Session Topic",
    "Feedback"
  ];

  const columnWidths = [
    "w-[12%]",
    "w-[12%]", 
    "w-[12%]", 
    "w-[12%]", 
    "w-[10%]", 
    "w-[10%]", 
    "w-[15%]", 
    "w-[10%]", 
  ];

  const AbsentClassName = (index: number, isClickable?: boolean) =>
    `${columnWidths[index]} ${isClickable ? "text-[#036AD1] cursor-pointer" : "text-[#686868]"
    } py-1 text-[14px] font-Roboto font-[400] text-center select-none`;

  const openFeedbackModal = (comment: string) => {
    setFeedbackContent(comment);
    setModalTitle("Feedback Details"); 
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setFeedbackContent("");
  };

  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Absent Details
      </h1>

      <CompModal
        show={show}
        fun={closeModal}
        content={feedbackContent}
        title={modalTitle}
      />

      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
        <div className="flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4">
          {AbsentHeader.map((head, index) => (
            <h1
              key={head}
              className={`${columnWidths[index]} text-[#000000] text-[16px] font-Roboto font-[500] text-center`}
            >
              {head}
            </h1>
          ))}
        </div>
        <div>
          {Sessions.map((arr: any, index: number) => (
            <div
              key={index}
              className={`flex items-center justify-between py-1.5 px-4 ${index % 2 !== 0 && "bg-[#EFF7FF]"}`}
            >
              <p className={AbsentClassName(0)}>{arr.batchCode}</p>
              <p className={AbsentClassName(1)}>{arr.courseName}</p>
              <p className={AbsentClassName(2)}>{arr.studentName}</p>
              <p className={AbsentClassName(3)}>{arr.phoneNumber}</p>
              <p className={AbsentClassName(4)}>{arr.courseMode}</p>
              <p className={AbsentClassName(5)}>{arr.schedule}</p>
              <p className={AbsentClassName(6)}>{arr.session_topic}</p>
              <p
                className={AbsentClassName(7, arr.feedback && arr.feedback !== "No Comments" && arr.feedback.length > 0 )}
                onClick={() =>
                  arr.feedback && arr.feedback !== "No Comments" && arr.feedback.length > 0 &&
                  openFeedbackModal(arr.feedback[0].comment)
                }
              >
                {arr.feedback === "No Comments" ? "No Feedback" : arr.feedback[0].status}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
