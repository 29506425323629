import {useState, useEffect} from 'react'
import { TableComponent } from '../../Elements/Table/TableComponent'
import { studentAction } from '../../../utils/json/studentAction'
import { rejectQueue } from "../../../utils/json/RejectQueue";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from '../../..';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { useSearchParams } from "react-router-dom";
import { getStudentActionsTableData, getRejectApprovalQueueTableData } from "../../../functions/admin/Action center/Student Actions/studentActions";

export const StudentActions = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

    const [queue, setQueue] = useState([
    {
      displayApproval: "Students Action Queue",
      approvalId: "students_action_queue",
      len: null,
    },
    {
      displayApproval: "Rejected",
      approvalId: "rejected_queue",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "students_action_queue",
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        const studentActionResponse: any = await getStudentActionsTableData(signInData?.data[0]);
        const rejectResponse: any = await getRejectApprovalQueueTableData(signInData?.data[0]);
        const studentActionLength = studentActionResponse?.length;
        const rejectLength = rejectResponse?.length;

        // Update queue state with the lengths
        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: studentActionLength,
          },
          {
            ...prevQueue[1],
            len: rejectLength,
          },
        ]);
      }
    })();
  }, []);

  return (
    <div>
      <div className="space-y-2">
        <div className="w-[95%] mx-auto flex items-center justify-between">
          <h1 className="text-[30px] text-white font-Roboto font-medium">
            Students Action
          </h1>
          {allowOnlyActions("view") && (
            <QueueType queue={queue} setQueue={setQueue} />
          )}
        </div>
        {id === "students_action_queue" && (
          <TableComponent TABLE_JSON={studentAction} backEndCallParams={signInData?.data[0]} />
        )}
        {id === "rejected_queue" && (
          <TableComponent TABLE_JSON={rejectQueue} backEndCallParams={signInData?.data[0]} />
        )}
      </div>
    </div>
  );
}

const QueueType = ({ queue, setQueue }: any) => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  // const linkOnclick = (q: any) => {
  //   const lowercaseString = q?.toLowerCase();
  //   setSearchParams({
  //     id: lowercaseString?.replace(/ /g, "_"),
  //   });
  // };
  const linkOnclick = (approvalId: string) => {
    setSearchParams({
      id: approvalId,
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${
              (index === 0) && "gap-8"
            }`}
            onClick={() => linkOnclick(que?.approvalId)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.displayApproval}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.approvalId ? (
              <hr />
            ) : (
              <p></p>
            )}
            </div>
            {(index === 0) && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
};
