import { Object } from "lodash";
import { dateFormat } from "../../../Services/CommonFunctions";
import { GSTInterface, PaymentDetailsInterface, calculateGSTFromAmountInterface } from "./interface";
import { getBranchForGST } from "./students";

export const assignCoursesToStudent = (studentData: any[], coursesData: any[], cityData: any, branchData: any[], trainingData: any) => {
    const updatedData = studentData.map((student: any) => {
        
        let course_ForStudent = trainingData.filter((training: any) => training.student_id === student.uid ?? training);
        const uniqueCityIds = [...new Set(course_ForStudent.map((city:any) => city.city_id))];
        let branch_ForStudent = trainingData.filter((training: any) => training.student_id === student.uid ?? training);
        const uniqueBranchIds = [...new Set(branch_ForStudent.map((branch:any) => branch.branch_id))];
        
        const filterCityNames = cityData?.filter((city: any) => uniqueCityIds.includes(city?.id))
        const mapCityNames = filterCityNames?.map((data:any)=>data?.city_name)
        const filterBranchNames = branchData?.filter((branch:any)=> uniqueBranchIds.includes(branch?.id) ?? branch.branch_name)
        const mapBranchNames = filterBranchNames?.map((data:any)=>data?.branch_name)
        // console.log(student, "student", mapCityNames, mapBranchNames);

        let courseForTheStudent = coursesData.filter((course: any) => student.courses?.includes(course.id)).map((course: any) => course.course_name);
        let courseModeForTheStudent = coursesData.filter((course: any) => student.courses?.includes(course.id)).map((course: any) => course.mode);
        
        // let branchForTheStudent = branchData.filter((branch: any) => student.branches?.includes(branch.id)).map((branch:any)=>branch.branch_name);
        return { ...student, courses: courseForTheStudent, mode: courseModeForTheStudent, branches: mapBranchNames, city: mapCityNames, created_at: dateFormat(student.created_at, "DD MMM YYYY") };
    });
    return updatedData
}

export const studentAndCoursesDataFormatting = (data: any[]) => {
    const studentData = data[0].student;
    const otherInfo = data.map((dat: any) => {
        let { course, overAllProgress } = progressByModuleCalculation(dat.course, dat.progress_by_module);
        return{ ...dat, ...course,overAllProgress, student: undefined, course: undefined }
    });
    return { student: studentData, courses:otherInfo };
}

export const progressByModuleCalculation = (courseData: any, trainingProgressByModule: any) => {
    // let totalPercentage = 0;
    let totalCompletedDuration = 0;
    let totalDuration = 0;
    courseData.videos = courseData.videos?.map((video: any) => {
        let progress = calculatePercentage(trainingProgressByModule[video.key] || 0, TimeFormatToSeconds(video.duration));
        totalDuration += TimeFormatToSeconds(video.duration);
        totalCompletedDuration += +trainingProgressByModule[video.key] || 0;
        // totalPercentage += +(trainingProgressByModule ? trainingProgressByModule[video.key] || "0" : 0)
        // progress: +(trainingProgressByModule ? trainingProgressByModule[video.key] || "0" : 0)
        // totalPercentage += +progress;
            return { ...video, progress }
    }) || [];
    return {course: courseData,overAllProgress:calculatePercentage(totalCompletedDuration,totalDuration)};
}

const calculatePercentage = (current: number, total: number = 1) => {
    return total === 0 ? 0 : Math.round((current / total) * 100);
}
const TimeFormatToSeconds = (timeString: string) => {
    const [sec, min, hr]: any = timeString.split(":").reverse();
    // console.log(timeString.split(":").reverse(),{sec},{hr},{min});
    let seconds=0;
    if (hr) {
        seconds += hr * 3600;
    }
    if (min) {
        seconds += min * 60;
    }
    if (sec) {
        seconds+=+sec
    }
    // console.log({seconds,hr,min,sec});
    return seconds;
}

export const calculateGSTFromAmount: calculateGSTFromAmountInterface = (gst, amount) => {
    let cgst = amount * (gst.cgst / 100);
    let sgst = amount * (gst.sgst / 100);
    return {
        paid_amount: amount-cgst-sgst,
        cgst,
        sgst
    }
}

const handleData = (data:any) => {
  if (Array.isArray(data)) {
    return data;
  } else {
    return [data];
  }
}

export const separateUpdateAndInsertDatas = async(data: PaymentDetailsInterface[], branch_id:any, trainingId:string|number) => {
    let updateData = [];
    let insertData = [];
    let balance: any = 0;
    // console.log({ data })
    for (let i = 0; i < data.length; i++){
        if ("id" in data[i]) {
            const checkDataPresent = handleData(data[i]?.payment_type);
            const finalData = {...data[i], payment_type: checkDataPresent}
            // console.log(finalData, "P_P")
            updateData.push(finalData);
            balance = finalData.balance
            // console.log(updateData, "updateData")
        }
        else {
            // console.log(balance,data[i].paid_amount,balance-data[i].paid_amount)
            const checkGSTwithBranch: any = await getBranchForGST(Number(branch_id));
            if (checkGSTwithBranch?.length > 0) {
                insertData.push({
                    ...data[i],
                    training_id: trainingId,
                    balance:Number(balance)-Number(data[i].paid_amount),
                    payment_date: new Date(new Date(data[i].payment_date).toISOString()),
                    payment_type: Array.isArray(data[i].payment_type) ? data[i].payment_type : [data[i].payment_type],
                        ...(checkGSTwithBranch[0]?.fee_type === "IGST" ? { igst: Number(checkGSTwithBranch[0]?.igst)} : {igst: null}),
                        ...(checkGSTwithBranch[0]?.fee_type === "SGST" ? { sgst: Number(checkGSTwithBranch[0]?.sgst)} : {sgst: null}),
                        ...(checkGSTwithBranch[0]?.fee_type === "SGST" ? { cgst: Number(checkGSTwithBranch[0]?.cgst)} : {cgst: null}),
                        paid_amount_after_gst: checkGSTwithBranch[0]?.fee_type === "IGST" ? Number(data[i].paid_amount)-(data[i].paid_amount * (Number(checkGSTwithBranch[0]?.igst)/100))
                            : Number(data[i].paid_amount)-(data[i].paid_amount * (Number(checkGSTwithBranch[0]?.sgst)/100))-(data[i].paid_amount * (Number(checkGSTwithBranch[0]?.cgst)/100)),
                })
            } else {
                console.log("No Branch Available");
            }
        }
    }
    return {
        updateData, insertData
    }
}