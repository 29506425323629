import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from '../../Elements/Table/TableComponent'
import { calculateTrainerPayment } from '../../../utils/json/CalculateTrainerPayment'
import { calculateTrainerPaymentforpc } from '../../../utils/json/CalculateTrainerPaymentPC'
import { calculateTrainerPaymentforfc } from '../../../utils/json/CalculateTrainerPaymentFC'
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from '../../..';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { getCalculateTrainerPaymentTableData, getCalculateTrainerPaymentTableDataForFC, getCalculateTrainerPaymentTableDataPC } from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";

export const CalculateTrainerPayment = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Yet To Calculated",
      len: null,
    },
    {
      approval: "Partially Calculated",
      len: null,
    },
        {
      approval: "Fully Calculated",
      len: null,
    }
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "yet_to_calculated",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const initialResponse: any = await getCalculateTrainerPaymentTableData(signInData?.data[0]);
      const partialResponse: any = await getCalculateTrainerPaymentTableDataPC(signInData?.data[0]);
      const fullyResponse: any = await getCalculateTrainerPaymentTableDataForFC(signInData?.data[0]);
      const initialLength: any = initialResponse?.length;
      const partiallyLength: any = partialResponse?.length;
      const fullyLength: any = fullyResponse?.length;

      setQueue((prevQueue: any) => [
        {
          ...prevQueue[0],
          len: initialLength,
        },
        {
          ...prevQueue[1],
          len: partiallyLength,
        },
        {
          ...prevQueue[2],
          len: fullyLength,
        },
      ]);
    })();
  }, []);

  return (
    <div>
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Calculate Trainer Payment
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "yet_to_calculated" && (
            <TableComponent
              TABLE_JSON={calculateTrainerPayment}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "partially_calculated" && (
            <TableComponent
              TABLE_JSON={calculateTrainerPaymentforpc}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "fully_calculated" && (
            <TableComponent
              TABLE_JSON={calculateTrainerPaymentforfc}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  )
}

const QueueType = ({ queue, setQueue }: any) => {
  const { id } = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLinkClick = (approval: string) => {
    setSearchParams({
      id: approval.toLowerCase().replace(/\s+/g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((queueItem: any, index: number) => { 
        const isSelected =
          id === queueItem.approval.toLowerCase().replace(/\s+/g, "_");
        return (
          <div
            key={index}
            className={`flex items-center ${index < queue.length - 1 && "gap-8"}`}
            onClick={() => handleLinkClick(queueItem?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {queueItem.approval}{" "}
                <span className="ml-1">{`(${queueItem.len || 0})`}</span>
              </p>
              {isSelected && <hr />}
            </div>
            {index < queue.length - 1 && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        )
      })}
    </div>
  );
};

