import { ViewEditDeleteActionComponent } from "../../Components/Action Center/TrainerTransaction/ActionComponent";
import { TrainerTransactionTableData } from "../../functions/admin/Action center/Trainer Payments/trainerPayment";

export const trainerTransaction = {
  headers: [
    {
      name: "Date",
      apiKey: "date",
      headerClass: "w-[120px] text-start",
      className: "w-[120px] text-start px-2",
    },
    {
      name: "Batch Code",
      apiKey: "batch_code",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start px-2 break-words",
    },
    {
      name: "Trainer Name",
      apiKey: "trainer_name",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Trainer Phone Number",
      apiKey: "trainer_number",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Last Paid Amount",
      apiKey: "paid_amount",
      headerClass: "w-[90px] text-start px-2",
      className: "w-[90px] text-start px-2 break-words",
    },
    {
      name: "Transaction Amount",
      apiKey: "transaction_amount",
      headerClass: "w-[110px] text-center px-2",
      className: "w-[110px] text-center px-2 break-words",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[90px] text-center",
      className: "w-[90px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return TrainerTransactionTableData(params);
  },
  filters: {
    dateFilters: [
      {
        name: "Date",
        apiKey: "created_at",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
    ],
    checkBox: [
      // {
      //   name: "Student",
      //   apiKey: "student_name",
      //   isSearchNeeded: true,
      //   className: "w-[180px]",
      // },
      // {
      //   name: "Trainer",
      //   apiKey: "trainer_name",
      //   isSearchNeeded: true,
      //   className: "w-[180px]",
      // },
      {
        name: "City",
        apiKey: "cityName",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Branch",
        apiKey: "branchName",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Ownership",
        apiKey: "ownership",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["trainer_name", "trainer_number"],
        searchKey: "trainer_nameCourse",
        name: "Trainer Search...",
        searchStyle: "w-[220px]",
      },
      {
        matchFields: ["student_list"],
        searchKey: "stud",
        name: "Student Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
