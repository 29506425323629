import React from 'react'
import { resume_review_dashboard } from '../../../utils/json/resume_review_dashboard'
import { BackButton } from "../../Elements/BackButton";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useAppSelector } from "../../..";
import { ViewResume } from './ViewResume';

export const ResumeReviewDashboard = () => {
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
    const { allowOnlyActions }: any = FunctionForPrivileges();
    const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  
  return (
    <div className="w-11/12 mx-auto">
      <CommonTableComp
        id={id}
        head={"Resume Review Dashboard"}
        TABLE_JSON={resume_review_dashboard}
        backEndCallParams={signInData?.data[0]}
      />
      {id && mode === "edit" && allowOnlyActions("edit") && (
        <div className="space-y-2">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={"Company Profile"}
          />
          <ViewResume id={id} />
        </div>
      )}
    </div>
  );
}