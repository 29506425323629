import { useState, useEffect } from "react";
import { Checkbox } from "../../Elements/Checkbox";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";
// import { feeAfterDiscountCalculation, gstCalculation, totalGstCalculation } from "../../../functions/admin/Students/students";
import {
  gstCalculationForStudent,
  totalGstCalculationForStudent,
  feeAfterDiscountCalculation,
} from "../../../functions/admin/Students/students";

export const CourseDetails = ({id, formik, mode, basicInfoDetails, updatedStudentData, setTotalVal, totalVal}:any) => {
    const headData = ['Course', 'Course Fee', 'Discount', 'Final Course Fee'];
    const [checked, setChecked] = useState([] as any);
  
    const foundVariable = (variable:any) => checked.find((element:any) => element === variable);
    const CheckValue = (label: any) => {
        if (checked.includes(label)) {
            setChecked(checked.filter((item: any) => item !== label));
        } else setChecked([...checked, label])
    };
    return (
      <div className="space-y-4 w-11/12 mx-auto">
        <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
          Course Details
        </h1>
        <div className="rounded-[7px] shadow-md shadow-[#00000029] space-y-2 pb-4">
          <div className="flex items-center bg-[#EFF7FF] py-2 w-full">
            {headData.map((head: any, index: number) => (
              <p key={index}
                className={`text-[20px] font-Roboto font-[500] ${
                  index === 3 ? "text-center" : "text-start"
                } ${
                  index === 0
                    ? "w-[35%] pl-8"
                    : index === 3
                    ? "w-[40%] px-8"
                    : "w-[15%]"
                }`}
              >
                {head}
              </p>
            ))}
          </div>
          <div className="flex">
            <CourseInCourseDetails
              checked={checked}
              setChecked={setChecked}
              foundVariable={foundVariable}
              CheckValue={CheckValue}
              basicInfoDetails={basicInfoDetails}
              updatedStudentData={updatedStudentData}
            />
            <p className="w-[15%] px-6">
              {basicInfoDetails?.updatedStudentData?.course_fee && <span className="px-1">&#8377;</span>}
              {basicInfoDetails?.updatedStudentData?.course_fee?basicInfoDetails?.updatedStudentData?.course_fee:'-'}
            </p>
            {basicInfoDetails?.updatedStudentData?.discount_need ? (
              <p className="w-[15%] px-6">
                {basicInfoDetails?.updatedStudentData?.discount_percentage ? basicInfoDetails?.updatedStudentData?.discount_percentage : ''}
                {basicInfoDetails?.updatedStudentData?.discount_percentage && <span className="px-0.5">%</span>}  
              </p>
            ) : (
              <p className="w-[15%] px-6">-</p>
            )}
            {/* <TotalFee
              pay={basicInfoDetails?.updatedStudentData?.course_fee}
              discount={basicInfoDetails?.updatedStudentData?.discount_percentage}
              setTotalVal={setTotalVal} 
              totalVal={totalVal}
            /> */}
            <TotalFeeCalculation formik={formik} mode={mode} id={id} />
          </div>
        </div>
      </div>
    );
}

const CourseInCourseDetails = ({ basicInfoDetails }: any) => {
    return (
      <div className="w-[35%] space-y-1 pl-8">
        <h1 className="text-[18px] text-[#707070] font-Roboto font-[500]">
          {basicInfoDetails?.updatedStudentData?.course_id?.course_name}
        </h1>
        {basicInfoDetails?.updatedStudentData?.customization_fee ||
        basicInfoDetails?.updatedStudentData?.discount_need ? (
          <textarea disabled={true} className="text-[#686868] border-[#707070] border-[0.5px] rounded-[3px] w-[90%] p-1 outline-none resize-none min-h-[60px] max-h-[70px]">
            {basicInfoDetails?.updatedStudentData?.cd_comment}
          </textarea>
        ) : (
          <div className="min-h-[60px] max-h-[70px]"></div>
        )}
        <Checkbox
          label={"Fee customization"}
          CheckValue={""}
          checked={basicInfoDetails?.updatedStudentData?.customization_fee && "Fee customization"}
          textStyle={"text-[#707070] text-[15px]"}
          show={false}
          boxSize={"w-4 h-4"}
        />
        {/* <Checkbox
          label={"Students Needs Discount?"}
          CheckValue={""}
          checked={basicInfoDetails?.updatedStudentData?.discount_need && "Students Needs Discount?"}
          textStyle={"text-[#707070] text-[15px]"}
          show={false}
          boxSize={"w-4 h-4"}
        /> */}
      </div>
    );
}

const TotalFeeCalculation = ({ formik, mode, id }: any) => {
  const feeInNumber = formik.values?.branch_based_GST?.course_fee;
  const branch_GST_Value = formik.values.branch_based_GST
  const check_feeType = branch_GST_Value && branch_GST_Value?.fee_type;
    const sendPercentageIGST =
      check_feeType === "IGST" && formik.values.branch_based_GST?.igst;
    const sendPercentageSGST =
      check_feeType === "SGST" && formik.values.branch_based_GST?.sgst;
    const sendPercentageCGST =
      check_feeType === "SGST" && formik.values.branch_based_GST?.cgst;
  
  const sendPercentage =
    check_feeType === "SGST"
      ? {
          cgst: formik.values.branch_based_GST?.cgst,
          sgst: formik.values.branch_based_GST?.sgst,
        }
      : check_feeType === "IGST"
      ? formik.values.branch_based_GST?.igst
        : null;
  
  const TotalExclGST = feeAfterDiscountCalculation(
    feeInNumber,
    formik.values?.branch_based_GST?.discount_percentage !== null &&
      formik.values?.branch_based_GST?.discount_percentage
  );
  const Sgst = gstCalculationForStudent(
    sendPercentageSGST,
    feeInNumber,
    formik.values?.branch_based_GST?.discount_percentage !== null &&
      formik.values?.branch_based_GST?.discount_percentage
  );
  const Cgst = gstCalculationForStudent(
    sendPercentageCGST,
    feeInNumber,
    formik.values?.branch_based_GST?.discount_percentage !== null &&
      formik.values?.branch_based_GST?.discount_percentage
  );
  const Igst = gstCalculationForStudent(
    sendPercentageIGST,
    feeInNumber,
    formik.values?.branch_based_GST?.discount_percentage !== null &&
      formik.values?.branch_based_GST?.discount_percentage
  );
  const TotalInclGST = totalGstCalculationForStudent(
    check_feeType,
    sendPercentage,
    feeInNumber,
    formik.values?.branch_based_GST?.discount_percentage !== null &&
      formik.values?.branch_based_GST?.discount_percentage
  );

    const mappingForTotal: any = [
      {
        left: "Total (Excl. GST)",
        right:
          formik.values?.branch_based_GST?.course_fee !== null
            ? typeof formik.values?.branch_based_GST?.course_fee === "number"
              ? parseFloat(TotalExclGST?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : Number(TotalExclGST)
                  ?.toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
      branch_GST_Value && check_feeType==="SGST" && {
        left: `SGST (${branch_GST_Value?.sgst}%)`,
        right:
          formik.values?.branch_based_GST?.course_fee !== null
            ? typeof formik.values?.branch_based_GST?.course_fee === "number"
              ? parseFloat(Sgst?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : parseFloat(Number(Sgst)?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
      branch_GST_Value && check_feeType==="SGST" && {
        left: `CGST (${branch_GST_Value?.cgst}%)`,
        right:
          formik.values?.branch_based_GST?.course_fee !== null
            ? typeof formik.values?.branch_based_GST?.course_fee === "number"
              ? parseFloat(Cgst?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : Number(Cgst)
                  ?.toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
      check_feeType==="IGST" && {
        left: `IGST (${branch_GST_Value?.igst}%)`,
        right:
          formik.values?.branch_based_GST?.course_fee !== null
            ? typeof formik.values?.branch_based_GST?.course_fee === "number"
              ? parseFloat(Igst?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : Number(Igst)
                  ?.toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
      {
        left: "Total (Incl. GST)",
        right:
          formik.values?.branch_based_GST?.course_fee !== null
            ? typeof formik.values?.branch_based_GST?.course_fee === "number"
              ? parseFloat(TotalInclGST?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : parseFloat(Number(TotalInclGST)?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
    ];

  // useEffect(() => {
  //   formik.setFieldValue(
  //     "total_fee",
  //     formik.values?.branch_based_GST?.course_fee
  //       ? totalGstCalculationForStudent(
  //           check_feeType,
  //           sendPercentage,
  //           formik.values?.branch_based_GST?.course_fee?.toString().replace(/,/g, ""),
  //           formik.values?.branch_based_GST?.discount_percentage !== null &&
  //             formik.values?.branch_based_GST?.discount_percentage
  //         )
  //       : 0
  //   );
  // }, [
  //   formik.values?.branch_based_GST,
  // ]);
  
    return (
        <div className='w-[40%] flex flex-col justify-between'>
        {mappingForTotal.map((text: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`flex justify-around w-full ${
                      index === 4 && "bg-[#EFF7FF] py-1.5"
                    }`}
                  >
                    <p className={`w-[40%] text-end ${index===4?'text-black font-Roboto font-[500]':'text-[#707070] font-Roboto font-[400]'}`}>{text.left}</p>
                    <p className={`w-[20%] ${index===4?'text-black font-Roboto font-[500]':'text-[#707070] font-Roboto font-[400]'}`}>
                      {text.left && <span className='px-1'>&#8377;</span>}
                      {text.right}
                    </p>
                  </div>
                );
            })}
        </div>
    )
}