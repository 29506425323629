import React from 'react'
import { company_management } from '../../../utils/json/company_management'
import { BackButton } from "../../Elements/BackButton";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { ViewProfile } from "./ViewProfile";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { RegisterCompany } from './RegisterCompany';

export const CompanyManagement = () => {
    const { id, mode, createFunc }: any = useSharedLogic();
    const { allowOnlyActions }: any = FunctionForPrivileges();
  
  return (
    <div className="w-11/12 mx-auto">
      {allowOnlyActions('view') && <CommonTableComp
        id={id}
        head={"Company Management"}
        buttonType={"right"}
        buttonText={"Create Company"}
        onClick={()=>createFunc("new-company")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={company_management}
        allowCondition={allowOnlyActions("create")}
      />}
      {id && mode === "edit" && allowOnlyActions("edit") && (
        <div className="space-y-2">
          <BackButton
            topDivStyle={"w-11/12 justify-between"}
            header={"Company Profile"}
          />
          <ViewProfile id={id} />
        </div>
      )}
      {(id === "new-company") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={
              (id === "new-company" && "Create Company Registration")
            }
          />
          <RegisterCompany  id={id} mode={mode}  />
        </div>
      )}
    </div>
  );
}
