import React, {useState, useEffect} from 'react'
import { DropDownInputBox } from '../Elements/DropDownInputBox'
import { DropdownSingleInputWithCheckbox } from '../Elements/DropdownSingleInputWithCheckbox';
import { useFormik } from "formik";
import { app_enquiries_status } from '../Formik/InitialValues';
import { FormikCommonFunc } from '../CommonFunctions/CommonFunc';
import { DropDownInputStatusBox } from '../Elements/DropDownInputStatusBox';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { updateAppEnquiry, getAppEnquiriesTableData } from '../../functions/admin/App Enquiries/app_enquiries';

export default function AppEnquiriesStatus({recordData, data} : any) {
    const [filteredOwnership, setFilteredOwnership] = useState("Active" as any);
    const ownershipType: string[] = ["Active", "Move To BC", "Not Interested"];
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate(); 
    const params = useParams();
   // const [searchParams] = useSearchParams();
    const routeNameForHome = params.dashboardScreen;
    const routeNameForSubSection = params.subsection;

  //   useEffect(() => {
  //     (async () => {
    
       
  
  //   //  if( mode && mode === "moved-to-crm"){
  //     //    const technologyResponse: any = await getAppEnquiriesTableData();
  //     const appEnquiriesResponse: any = await getAppEnquiriesTableData();
  //     console.log("View Data", appEnquiriesResponse)
  //     //   setTechnologyData(technologyResponse);
  //  //   const idToFind = parseInt(id);
      
  //     const enquiryData:any = appEnquiriesResponse.find((data: any) => data.status);
  //    // setMapAppEnquiryData(enquiryData);
  //     // const mapCityForId:any = courseResponses &&
  //     // courseResponses.filter((data: any) => data.course_name === enquiryData.course_name);
  //   //  console.log("Tech ID",mapCityForId)
  //   //  setCourseNames(enquiryData.course_name);
  //       // console.log("Map App Enquiry Data", mapAppEnquiryData)
  //       const { student_name,student_mobile,student_email,date,status }: any = enquiryData;
  //       if (enquiryData) {
  //         //  const lastDateApply: any = dateFormat(last_date_to_apply, "DD/MM/yyyy")
  //         //  console.log("Last", lastDateApply)
  //           setValues(
  //             {
  //               ...values,
  //               student_name,student_mobile,student_email,date,status
  //             },
  //             true
  //           );
  //           console.log("Value",values);
  //           //setCourseData(enquiryData.course_name);
  //           // setCourseData(mapCityForId?.[0]["course_name"]);
  //           // setCourseID(mapCityForId?.[0]["id"])
            
            
  //    //}
      
  //     }})()
  // },[])
    

    const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(app_enquiries_status,"","");
 
    useEffect(()=>{
      if( data === null){
        setFilteredOwnership("Active");
      }
      else{
        setFilteredOwnership(data);
      }
        
      
      

    },[data])

    const handleChangeForDropValue = async(
      name: string,
      value: string,
      mapThrough?: string
  ) => {
      if (mapThrough) {
         // setFieldValue(name);
       //   setFilteredOwnership(values.ownership);
      } else {
          setFilteredOwnership(value);
          // setSearchParams({
          //       id: `${recordData.id}`,
          //       mode: `${value}`,
          //     });
          // navigate({
          //   pathname: `/${routeNameForHome}`,
          //   search: createSearchParams({ id: `${recordData.id}`, mode: "moved-to-crm", }).toString(),
          // });
       
        //  setFieldValue(name, value);
      }
      if(value === "Move To BC"){
        navigate({
          pathname: `/${routeNameForHome}`,
          search: createSearchParams({ id: `${recordData.id}`, mode: "moved-to-crm", }).toString(),
        });
      }
      if(value === "Not Interested"){
       setFilteredOwnership("Not Interested");
       const appEnquiriesUpdate: any = {
        // student_name : values?.student_name,
        // student_email: values?.student_email,
        // student_mobile: values?.student_mobile,
       // branch: values?.branch_name,
    //  course: courseID,
      status:"Not Interested",
      //date:values?.date,
    //  student_id: isId,
      is_enquired:true,

    }
    const response = await updateAppEnquiry(appEnquiriesUpdate,recordData.id)
    if (response) {
          navigate({
          pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
          search: createSearchParams({ id: `${recordData.id === "new-jobs" ? "enquiry-created-success" : "enquiry-update-success"}` }).toString(),
          });
      }
      }
      
  };
  return (
     <div>
              <DropDownInputStatusBox
                  //  label="Status:"
                    placeholder="Status" 
                    classNameInput={"w-11/12"}
                    name={"status"}
                    // value={values.status}
                    // onBlur={handleBlur}
                     dataSetup={handleChangeForDropValue}
                     mapData={ownershipType}
                     showData={filteredOwnership}
                    setShowData={setFilteredOwnership}
                    zIndex={"z-auto relative"}
                    mapThrough={""}
                  />
                    {/* <DropdownSingleInputWithCheckbox
                  //  topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-11/12"}
                    // label="Ownership"
                    // placeholder="Ownership"
                    options={ownershipType}
                    name={"ownership"}
                    value={filteredOwnership}
                    onBlur={handleBlur}
                    // error={formik.errors?.ownership}
                   //  isTouched={formik.touched?.ownership}
                    mapThrough={"ownership"}
                    formik={formik}
                    zIndex={"z-auto relative"}
                     disabled={false}
                    //  showData={filteredOwnership}
                    //  setShowData={setFilteredOwnership}
                    // dataSetup={handleChangeForDropValue}
                    /> */}
                  
                  </div>
  )
}