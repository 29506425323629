import React from "react";
import { getImports } from "./ModuleImports";
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";

export const CourseStatus = ({
  id,
  formik,
  mode,
  Course_status,
  routeToBatchFollowUp,
}: any) => {
  const { useSharedLogic, createSearchParams, InputBox }: any = getImports;
  const { navigate, routeNameForHome }: any = useSharedLogic();

  return (
    <div className="space-y-10">
      <div className="w-1/2 flex items-end gap-4">
        <DropdownSingleInputWithCheckbox
          topDivStyle={"flex flex-col w-1/2"}
          classNameInput={"w-full"}
          label="Course Status"
          placeholder="Course Status"
          options={Course_status}
          name={"status"}
          value={formik.values.status}
          onBlur={formik.handleBlur}
          mapThrough={"status"}
          formik={formik}
          disabled={id === "new-student-course"}
          zIndex={"z-20"}
          mandatory={true}
          mandatoryClass = "text-red-500"
        />
        {(formik.values.status === "Unallocated" ||
          formik.values.status === "Hold") && (
          <button
            type="button"
            onClick={routeToBatchFollowUp}
            className="flex items-center justify-center p-1.5 w-1/2 bg-[#E95000] rounded-[3px] text-white text-[18px] 2xl:text-[20px] font-Roboto font-[400] select-none"
          >
            Batch Follow-Up Date
          </button>
        )}
      </div>
      <div className="flex items-center gap-4">
        <InputBox
          label="Registration Date"
          placeholder="Registration Date"
          type="date"
          topDivStyle={"flex flex-col w-1/3"}
          classNameInput={"w-full"}
          name={"created_at"}
          value={formik.values.created_at}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.created_at}
          isTouched={formik.touched.created_at}
          disabled={false}
          mandatory={true}
          mandatoryClass = "text-red-500"
          // index={0}
          // check={"course_fee"}
        />
        <InputBox
          label="Expected Start Date"
          placeholder="Expected Start Date"
          type="date"
          topDivStyle={"flex flex-col w-1/3"}
          classNameInput={"w-full"}
          name={"expected_start_date"}
          value={formik.values.expected_start_date}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.expected_start_date}
          isTouched={formik.touched.expected_start_date}
          disabled={false}
          min={
            mode === "edit"
              ? formik.values.expected_start_date
              : new Date().toISOString().split("T")[0]
          }
          mandatory={true}
          mandatoryClass = "text-red-500"
          // index={0}
          // check={"course_fee"}
        />
      </div>
    </div>
  );
};
