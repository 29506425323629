import { map } from "lodash";
import { useState, useEffect } from "react";
import { getManagerUser, getUserById } from "../../../functions/admin/User Mangement/userMangement";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { InformationViewCard } from "../../Elements/InformationViewCard/InformationViewCard";
import { dateFormat } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { useAppSelector } from "../../..";

export const ViewUserInfo = ({id}:any) => {
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

        const buildJSON = [
          {
            left: [
              {
                head: "User Name",
                body: user && user?.first_name + "" + user?.last_name,
              },
              {
                head: "Phone Number",
                body: user && user?.phone_number,
              },
              {
                head: "Email",
                body: user && user?.email,
              },
              {
                head: "City",
                body: user && user?.cityNames?.join(', '),
              },
              {
                head: "Branch",
                body: user && user?.branchesNames?.join(', '),
              },
              {
                head: "Trainer Location",
                body: user && user?.trainer_location,
              },
              {
                head: "Trainer Address",
                body: user && user?.trainer_address,
              },
              {
                head: "Professional From",
                body: user && user?.professional_from,
              },
              {
                head: "Onboarding Date",
                body: user && user?.onboarding_date,
              },
              {
                head: "Commercials",
                body: user && user?.commercials,
              },
            ].filter(item => item.body),

            right: [
              {
                head: "Created Date",
                body: user && dateFormat(user?.created_at, "DD MMM YYYY"),
              },
              {
                head: "Role",
                body: user && user?.rolesName,
              },
              {
                head: "Courses",
                body: user && user?.coursesNames?.join(', '),
              },
              {
                head: "Demo Attended By",
                body: user && user?.demo_attended_by,
              },
              {
                head: "Commercials Spoken By",
                body: user && user?.commercials_spoken_by,
              },
              {
                head: "Language",
                body: user && user?.languages?.join(', '),
              },
              {
                head: "Suitable For",
                body: user && user?.suitable_for,
              },
              {
                head: "Current Availability",
                body: user && user?.current_availability,
              },
              {
                head: "Course Schedule",
                body: user && user?.course_schedule?.join(', '),
              },
              {
                head: "Course Mode",
                body: user && user?.mode?.join(', '),
              },
              {
                head: "Comment",
                body: user && user?.comment,
              }
            ].filter(item => item.body),
          },
        ];
    
        useEffect(() => {
            (async () => {
            setIsLoading(true)
            let userData = await getUserById(id, signInData?.data[0]?.roles);
            const managerData : any = await getManagerUser({
              branches: signInData?.data[0]?.branches,
              cities: signInData?.data[0]?.cities
            });

            const demoAttendedData = managerData?.filter((item: any) => item?.uid === userData?.demo_attended_by)
            const commercialsSpokenByData = managerData?.filter((item: any) => item?.uid === userData?.commercials_spoken_by)
            
            userData = {
              ...userData, 
              "demo_attended_by" : demoAttendedData[0]?.name, 
              "commercials_spoken_by" : commercialsSpokenByData[0]?.name
            }

            setUser(userData);
            setIsLoading(false)
          })();
        }, []);

    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <CardLayoutTwo>
            <InformationViewCard
              cardHeading={"View User Information"}
              values={user}
              buildJSON={buildJSON}
            />
          </CardLayoutTwo>
        )}
      </>
    );
}