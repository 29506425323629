import { Fragment, useState, useRef } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { HeadingSection } from "../Elements/HeadingSection";
import { TableComponent } from "../Elements/Table/TableComponent";
import { useFormik } from "formik";

export const useSharedLogic = () => {
    const navigate = useNavigate(); 
    const params = useParams();
    const [searchParams] = useSearchParams();
    const routeNameForHome = params.dashboardScreen;
    const routeNameForSubSection = params.subsection;
    const id = searchParams.get("id") || "";
    const mode = searchParams.get("mode") || "";
    const courseId = searchParams.get("courseId") || "";
    const routePath = searchParams.get("routePath") || "";
    const by = searchParams.get("by") || "";

    const createFunc = (navigateTo:string) => {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: navigateTo }).toString(),
      });
    };

    const createFuncWithoutSubSection = (navigateTo:string) => {
        navigate({
            pathname: `/${routeNameForHome}`,
            search: createSearchParams({ id: navigateTo }).toString(),
        });
    };

    return {
        navigate, params, routeNameForHome, routeNameForSubSection, id, mode, courseId, routePath, by, createFunc, createFuncWithoutSubSection, createSearchParams
    }
}

export const CommonTableComp = ({allowCondition, id, head, buttonType, buttonText, onClick, buttonImg, TABLE_JSON, backEndCallParams, backEndCallParamsStatus, allowTabs, linkName, linkOnclick}:any) => {
    return (
      <Fragment>
        {!id && (
          <>
            <HeadingSection
              head={head}
              buttonType={buttonType}
              buttonText={buttonText}
              onClick={onClick}
              buttonImg={buttonImg}
              allowCondition={allowCondition}
              linkName={linkName}
              linkOnclick={linkOnclick}
            />
            <TableComponent
              TABLE_JSON={TABLE_JSON}
              backEndCallParams={backEndCallParams}
              backEndCallParamsStatus={backEndCallParamsStatus}
              allowTabs={allowTabs}
            />
          </>
        )}
      </Fragment>
    );
}

export const FormikCommonFunc = (initialValues:any, validationSchema:any, onSubmit:any) => {
        
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema ? validationSchema : "",
        onSubmit: () => {
          onSubmit();
        },
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setValues,
        setFieldValue,
    } = formik;

    return {
        formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue,
    };
}

export const QueueType = ({ queue, setQueue }: any) => {
  const { id } = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLinkClick = (approval: string) => {
    setSearchParams({
      id: approval.toLowerCase().replace(/\s+/g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue.map((que: any, index: number) => {
        const isSelected =
          id === que.approval.toLowerCase().replace(/\s+/g, "_");

        return (
          <div
            key={index}
            className={`flex items-center ${index === 0 ? "gap-8" : ""}`}
            onClick={() => handleLinkClick(que.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-white text-[17px] font-Roboto font-normal">
                {que.approval} <span className="ml-1">({que.len ?? 0})</span>
              </p>
              {isSelected && <hr />}
            </div>
            {index === 0 && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const transformData = (input:any) => {
    const result:any = {};
    input.forEach((item:any) => {
      const trainerKey = `${item?.batch_trainers?.first_name.trim()} ${item?.batch_trainers?.last_name.trim()}(${
        item.batch_trainers?.phone_number
      })
        ${item.start_time.trim()} - ${item.end_time.trim()}`;
        if (!result[trainerKey]) {
            result[trainerKey] = [];
        }
        result[trainerKey].push(item);
    });
    return result;
}

export const convertTimeTo12HourFormat = (time: string): string => {
  const [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12;
  return minutes === 0
    ? `${adjustedHours}${period}`
    : `${adjustedHours}:${minutes}${period}`;
};

type Props = {
  totalDuration: number;
  currentDuration: number;
};

export const isAboveNinetyPercent = (total: number, current: number): boolean => {
  if (total <= 0) {
    console.error("Total duration should be greater than 0.");
    return false;
  }
  return current >= 0.9 * total;
};