
export const sampleData = [
    {
        "id": 1,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "dashboard",
        "label": "Dashboard",
        "submenus": [],
        "mainmenuid": null,
        "updated_at": "2023-03-31T07:14:23.5008+00:00",
        "inserted_at": "2023-03-31T07:14:23.5008+00:00",
        "displayorder": 1
    },
    {
        "id": 2,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "admin_management",
        "label": "Admin Management",
        "submenus": [
            {
                "id": 3,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "role_management",
                "label": "Role management",
                "mainmenuid": 2,
                "updated_at": "2023-04-05T07:36:26.104388+00:00",
                "inserted_at": "2023-04-05T07:36:26.104388+00:00",
                "displayorder": 1
            },
            {
                "id": 4,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "admin_user_management",
                "label": "Admin user management",
                "mainmenuid": 2,
                "updated_at": "2023-04-05T12:42:58.163724+00:00",
                "inserted_at": "2023-04-05T12:42:58.163724+00:00",
                "displayorder": 2
            }
        ],
        "mainmenuid": null,
        "updated_at": "2023-03-31T07:46:43.890438+00:00",
        "inserted_at": "2023-03-31T07:46:43.890438+00:00",
        "displayorder": 2
    }
];

const secondRole = [
    {
        "id": 1,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "dashboard",
        "label": "Dashboard",
        "submenus": [],
        "mainmenuid": null,
        "updated_at": "2023-03-31T07:14:23.5008+00:00",
        "inserted_at": "2023-03-31T07:14:23.5008+00:00",
        "displayorder": 1
    },
    {
        "id": 2,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "admin_management",
        "label": "Admin Management",
        "submenus": [
            {
                "id": 3,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "role_management",
                "label": "Role management",
                "mainmenuid": 2,
                "updated_at": "2023-04-05T07:36:26.104388+00:00",
                "inserted_at": "2023-04-05T07:36:26.104388+00:00",
                "displayorder": 1
            },
            {
                "id": 4,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "admin_user_management",
                "label": "Admin user management",
                "mainmenuid": 2,
                "updated_at": "2023-04-05T12:42:58.163724+00:00",
                "inserted_at": "2023-04-05T12:42:58.163724+00:00",
                "displayorder": 2
            }
        ],
        "mainmenuid": null,
        "updated_at": "2023-03-31T07:46:43.890438+00:00",
        "inserted_at": "2023-03-31T07:46:43.890438+00:00",
        "displayorder": 2
    },
    {
        "id": 6,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "all_students",
        "label": "All Students",
        "submenus": [],
        "mainmenuid": null,
        "updated_at": "2023-04-05T12:57:03.781605+00:00",
        "inserted_at": "2023-04-05T12:57:03.781605+00:00",
        "displayorder": 3
    },
    {
        "id": 7,
        "data": [
            {
                "id": 1,
                "label": "All",
                "value": "all",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 2,
                "label": "View",
                "value": "view",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 3,
                "label": "Create",
                "value": "create",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 4,
                "label": "Edit",
                "value": "edit",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 5,
                "label": "Delete",
                "value": "delete",
                "checked": true,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            },
            {
                "id": 6,
                "label": "Not Applicable",
                "value": "not applicable",
                "checked": false,
                "updated_at": "2023-03-30T10:46:58.374035+00:00",
                "inserted_at": "2023-03-30T10:46:58.374035+00:00"
            }
        ],
        "idkey": "content_management",
        "label": "Content Management",
        "submenus": [
            {
                "id": 8,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "branches",
                "label": "Branches",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T12:58:13+00:00",
                "inserted_at": "2023-04-05T12:58:13+00:00",
                "displayorder": 1
            },
            {
                "id": 9,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "manage_courses",
                "label": "Manage Courses",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T12:58:37+00:00",
                "inserted_at": "2023-04-05T12:58:37+00:00",
                "displayorder": 2
            },
            {
                "id": 10,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "course_category",
                "label": "Course Category",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T12:59:02+00:00",
                "inserted_at": "2023-04-05T12:59:02+00:00",
                "displayorder": 3
            },
            {
                "id": 11,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "technology",
                "label": "Technology",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T12:59:41+00:00",
                "inserted_at": "2023-04-05T12:59:41+00:00",
                "displayorder": 4
            },
            {
                "id": 12,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "course_features",
                "label": "Course Features",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:00:06+00:00",
                "inserted_at": "2023-04-05T13:00:06+00:00",
                "displayorder": 5
            },
            {
                "id": 13,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "hiring_companies",
                "label": "Hiring Companies",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:00:40+00:00",
                "inserted_at": "2023-04-05T13:00:40+00:00",
                "displayorder": 6
            },
            {
                "id": 14,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "certificate",
                "label": "Certificate",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:01:07+00:00",
                "inserted_at": "2023-04-05T13:01:07+00:00",
                "displayorder": 7
            },
            {
                "id": 15,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "testimonials",
                "label": "Testimonials",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:01:34+00:00",
                "inserted_at": "2023-04-05T13:01:34+00:00",
                "displayorder": 8
            },
            {
                "id": 16,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "content_info",
                "label": "Content Info",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:01:58+00:00",
                "inserted_at": "2023-04-05T13:01:58+00:00",
                "displayorder": 9
            },
            {
                "id": 17,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "push_content",
                "label": "Push Content",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:02:24+00:00",
                "inserted_at": "2023-04-05T13:02:24+00:00",
                "displayorder": 10
            },
            {
                "id": 18,
                "data": [
                    {
                        "id": 1,
                        "label": "All",
                        "value": "all",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 2,
                        "label": "View",
                        "value": "view",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 3,
                        "label": "Create",
                        "value": "create",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 4,
                        "label": "Edit",
                        "value": "edit",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 5,
                        "label": "Delete",
                        "value": "delete",
                        "checked": true,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    },
                    {
                        "id": 6,
                        "label": "Not Applicable",
                        "value": "not applicable",
                        "checked": false,
                        "updated_at": "2023-03-30T10:46:58.374035+00:00",
                        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
                    }
                ],
                "idkey": "resources",
                "label": "Resources",
                "mainmenuid": 7,
                "updated_at": "2023-04-05T13:02:45+00:00",
                "inserted_at": "2023-04-05T13:02:45+00:00",
                "displayorder": 11
            }
        ],
        "mainmenuid": null,
        "updated_at": "2023-04-05T12:57:44.849338+00:00",
        "inserted_at": "2023-04-05T12:57:44.849338+00:00",
        "displayorder": 4
    }
]

export const roleDataForCheck = [{ menuaccess: sampleData }, { menuaccess: secondRole }];

export const menuAccesConversion = (rolesData: any[]) => {
    let menuAccessArray: any = []
    rolesData.forEach((role: any) => {
        menuAccessArray = [...menuAccessArray, ...role.menuaccess];
    });
    const menuAccess: any = {};
    
    menuAccessArray.forEach((menu: any) => {
        if (menuAccess[menu.idkey]) {
            menuAccess[menu.idkey] = {
                ...menuAccess[menu.idkey],
                submenus: makeSubmenusKeys(menu.submenus, menuAccess[menu.idkey].submenus),
                privileges: [...new Set([...filterAndrReturnKeys(menu.data), ...menuAccess[menu.idkey].privileges])],
            }
        }
        else {
            menuAccess[menu.idkey] = {
                idkey: menu.idkey,
                label: menu.label,
                privileges: filterAndrReturnKeys(menu.data),
                submenus: makeSubmenusKeys(menu.submenus),
                displayorder: menu.displayorder

            }
        }
    });
    return sortMenus(menuAccess);
    // return menuAccess;
}

const filterAndrReturnKeys = (data: any[]) => {
    return data?.flatMap((pri: any) => pri.checked ? [pri.value] : []) || [];
}

const makeSubmenusKeys = (submenus:any[],presentSubmenus={}) => {
    if (!submenus) return {};
    let resultSubMenus: any = {...presentSubmenus};
    submenus.forEach((submenu: any) => {
        if (resultSubMenus[submenu.idkey]) {
            resultSubMenus[submenu.idkey] = {
                ...resultSubMenus[submenu.idkey],
                privileges: [...new Set([...filterAndrReturnKeys(submenu.data), ...resultSubMenus[submenu.idkey].privileges])]
            }
        }
        else {
            resultSubMenus[submenu.idkey] = {
                idkey: submenu.idkey,
                label: submenu.label,
                privileges: filterAndrReturnKeys(submenu.data),
                displayorder: submenu.displayorder

            }
        }
    });
    return resultSubMenus;
}


const sortMenus = (menuData:any) => {
    const array = Object.values(menuData).sort(dynamicSort("displayorder")).map((menu: any) => ({
        ...menu,
        ...menu.submenus && { submenus: Object.values(menu.submenus).sort(dynamicSort("displayorder")) }
    }));
    return array;
}


function dynamicSort(property:string) {
    var sortOrder = 1;
    return function (a:any, b:any) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}