import { useEffect, useState } from "react";
import selectedCheckBox from "../../../Assets/selectedCheckbox.svg";
import unSelectedCheckBox from "../../../Assets/unSelectedCheckedBox.svg";
import dropDownIcon from "../../../Assets/dropDownIcon.svg";
import searchIconDark from "../../../Assets/searchIconDark.svg";
import InputBoxWithLabel from "../InputBoxWithLabel";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range } from "react-date-range";
import { addDays } from 'date-fns';
import moment from "moment";
import { filterAndSortRanges } from "../../../functions/commonHelper";

interface DateRangeFilterProps{
  handleChange: any,
  selectedItems: string[],
  filterJSON: any,
  filters: any,
  tableData:any[]
}

interface SelectBoxProps{
    handleChange: any,
    selectedItems: string[],
    filterJSON: any,
    filters: any,
    tableData:any[]
}

interface MiniSearchProps{
    value: string,
    setValue: any,
    apiKey:string
}

export const DateRangeFilter = ({ handleChange, filterJSON, filters, tableData }: DateRangeFilterProps) => {
  // console.log(filterJSON, filters, tableData, "CHECK_TABLE")

  // Array for Highlighting
  const ListOfUnverifiedDates = tableData?.map((tab: any) => tab?.unVerified_dates);
  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),  //addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    document.addEventListener("click", handlDropOpenClose);
    return () => {
        document.removeEventListener("click", handlDropOpenClose);
    }
    
},[])

const handlDropOpenClose = (event: any) => {
  if (((event?.target?.localName === "div") &&(event?.target?.dataset?.elementname !== filterJSON.apiKey)) || ((event?.target?.localName ==="input")&&(event?.target?.id !== filterJSON.apiKey))) {
      setIsOpen(false);
  }
}

  const changeDateRange = (value: Range) => {
    // console.log(value, "value", moment(value.startDate).format("DD MMM YYYY"), "??")
    setStartDate(moment(value.startDate).format("DD MMM YYYY"))
    setEndDate(moment(value.endDate).format("DD MMM YYYY"))
    setSelectedRange([value])
    handleChange(value.startDate, value.endDate, filterJSON.apiKey);
    //setIsOpen(false);
  };

  return (
    <div
      className={`relative ${filterJSON.className} mr-4 mb-3 rounded-[2px] p-[2px] bg-[#F8F8F8]`}
      data-elementname={filterJSON.apiKey}
    >
      <div
        className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070]"
        onClick={() => setIsOpen(!isOpen)}
        data-elementname={filterJSON.apiKey}
      >
        <p
          className="shrinkToTwoLines  shrinkToOneLine"
          data-elementname={filterJSON.apiKey}
        >
          {startDate && endDate ? `${startDate} - ${endDate}` : filterJSON.name}
        </p>
        <img
          src={dropDownIcon}
          data-elementname={filterJSON.apiKey}
          className={`${isOpen ? "rotate-180" : ""} transition`}
          alt="dropDownIcon"
        />
      </div>
      {isOpen && (
        <div
          data-elementname={filterJSON.apiKey}
          className="absolute shadow-lg top-11 left-0 z-[2] bg-white"
        >
        <DateRangePicker
          onChange={item => changeDateRange(item.selection)}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={selectedRange}
          direction="horizontal"
        />
        </div>
      )}
    </div>
  );
};

export const SelectDropDownForFilters = ({ selectedItems, handleChange, filterJSON,filters,tableData }: SelectBoxProps) => {
    const [options, setOptions] = useState(filterJSON.options);
    const [filterSearchTerm, setFilterSearchTerm] = useState("");

  const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const filteredSearchKeys = filterJSON.options?.filter((fil: string | number) => fil?.toString().toLowerCase().includes(filterSearchTerm.toLowerCase()));
        setOptions(filteredSearchKeys);
    }, [filterSearchTerm]);

    useEffect(() => { //Options setting based on linkedKey
        if (filters[filterJSON.linkedKey]) {
            const result = [...new Set(tableData.flatMap((data: any) => filters[filterJSON.linkedKey]?.includes(data[filterJSON.linkedKey])?[data[filterJSON.apiKey]]:[]))];
            // console.log({ result });
            setOptions(result);
        }
        else {
            setOptions(filterJSON.options)
        }
    }, [JSON.stringify(filters[filterJSON.linkedKey]), JSON.stringify(tableData)])

    useEffect(() => {//options change whenever props changes
        if (JSON.stringify(filterJSON.options) !== JSON.stringify(options)) {
            setOptions(filterJSON.options)
        }
    }, [JSON.stringify(filterJSON)]);

    const handlDropOpenClose = (event: any) => {
        if (((event?.target?.localName === "div") &&(event?.target?.dataset?.elementname !== filterJSON.apiKey)) || ((event?.target?.localName ==="input")&&(event?.target?.id !== filterJSON.apiKey))) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", handlDropOpenClose);
        return () => {
            document.removeEventListener("click", handlDropOpenClose);
        }
        
    },[])

    // console.log(options, "options", filterJSON.apiKey);

    return (
      <div
        className={`relative ${filterJSON.className} mr-4 mb-3 rounded-[2px] p-[2px] bg-[#F8F8F8]`}
        data-elementname={filterJSON.apiKey}
      >
        <div
          className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070] text-[14px] 2xl:text-[15px]"
          onClick={() => setIsOpen(!isOpen)}
          data-elementname={filterJSON.apiKey}
        >
          <p
            className="shrinkToTwoLines  shrinkToOneLine"
            data-elementname={filterJSON.apiKey}
          >
            {selectedItems.length ? selectedItems.join(", ") : filterJSON.name}
          </p>
          <img
            src={dropDownIcon}
            data-elementname={filterJSON.apiKey}
            className={`${isOpen ? "rotate-180" : ""} transition`}
            alt="dropDownIcon"
          />
        </div>
        {isOpen && options && (
          <div
            data-elementname={filterJSON.apiKey}
            className="absolute shadow-lg top-11 left-0 z-[2] bg-white scroll-smooth overflow-y-scroll min-h-[160px] max-h-[200px]"
          >
            {filterJSON.isSearchNeeded && (
              <MiniSearch
                value={filterSearchTerm}
                setValue={setFilterSearchTerm}
                apiKey={filterJSON.apiKey}
              />
            )}
            {/* {filterJSON.name !== "Batch Progress(%)" ? */}
            {(filterJSON.apiKey==="progress_range" ? filterAndSortRanges(options) : options).map((opt: string, index: number) => (
              <div
                data-elementname={filterJSON.apiKey}
                className="flex items-baseline mt-1 space-x-2 mr-4 px-3 cursor-pointer"
                key={index}
                onClick={() => handleChange(opt, filterJSON.apiKey)}
              >
                <img
                  data-elementname={filterJSON.apiKey}
                  className="w-[15px] h-[15px] rounded-[2px]"
                  src={
                    selectedItems.includes(opt)
                      ? selectedCheckBox
                      : unSelectedCheckBox
                  }
                  alt="checkBox"
                />
                <p
                  data-elementname={filterJSON.apiKey}
                  className="text-[18px] select-none"
                >
                  {opt}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
}

const MiniSearch = ({ value, setValue,apiKey }:MiniSearchProps) => {
    return (
        <div data-elementname={apiKey} className="border flex items-center w-full">
            <InputBoxWithLabel
                input={
                    {
                        value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
                        className: "outline-none w-10/12 pl-2 text-[14px] py-1",
                        // "data-elementname":apiKey
                        id:apiKey
                    }
                }
            />
            <img data-elementname={apiKey} src={searchIconDark} alt="search" />
        </div>
    )
}