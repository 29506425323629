export const TableNames={
    menus: "menus",
    adjust_payment: "adjust_payment",
    privileges: "privileges",
    branches: "branches",
    batches: "batches",
    batch_follow_up: "batch_follow_up",
    courses: "courses",
    roles: "roles",
    menutesting: "menutesting",
    users: "users",
    cities: "cities",
    students: "students",
    trainings: "trainings",
    courseFeatures: "course_features",
    hiringCompanies: "hiring_companies",
    certificates: "certificates",
    categories: "categories",
    jobs: "jobs",
    refunds: "refunds",
    resource_category: "resource_category",
    resources: "resources",
    technologies: "technologies",
    testimonials: "testimonials",
    transactions:"transactions",
    sessions: "sessions",
    feedbacks: "feedbacks",
    push_content: "push_content",
    contact_info: "contact_info",
    app_enquiries: "app_enquiries",
    gsts: "gsts",
    student_certificate: "student_certificate",
    batch_trainers: "batch_trainers",
    student_jobs: "student_jobs",
    student_concern_feedback: "student_concern_feedback",
    user_logs: "user_logs",
    payment_followups: "payment_followups",
    approval_queue: "approval_queues",
    student_absent_followups: "student_absent_followups",
    student_feedback_followup: "student_feedback_followup",
    cancelled_sessions: "cancelled_sessions",
    course_mapping: "course_mapping",
    trainer_transactions: "trainer_transactions",
    cancellation_followup: "cancellation_followup",
    rejected_approval_queues: "rejected_approval_queues",
    user_hr: "user_hr",
    companies_registered: "companies_registered"
}

export const EmailOTPContent = {
    html: "Your OTP for email verification is ",
    subject:"OTP for Email Verification"
};
 
export const EmailEndPoint = "https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/sendMail";

// https://us-central1-selfserviceportal-aa3f2.cloudfunctions.net/sendMail
// "https://us-central1-venzoadmindev.cloudfunctions.net/sendMail"
