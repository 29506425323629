import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { CancellationModal } from "./CancellationModal";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [showPopup, setShowPopup] = useState(false); 



  const navigateToEditPage = () => {
    setShowPopup(true);
    // setSearchParams({
    //   id: `${recordData.id}`,
    //   mode: `edit`,
    //   by: "verified"
    // });
  };

  return (
    <div>
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions('edit') && <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableEditIcon}
          alt="editIcon"
          className="w-[21px] h-[10px]"
        />
      </div> }
    </div>
    
    <CancellationModal
      open={showPopup}
      setOpen={setShowPopup}
      func={(flag : any) => console.log(`Action triggered with flag: ${flag}, recordId: ${recordData.id}`)}
      recordId={recordData.id}
    />
  </div>
    
  );
};
