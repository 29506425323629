import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { processTrainerPayment } from "../../../utils/json/ProcessTrainerPayment";
import { processTrainerPaymentforpp } from "../../../utils/json/ProcessTrainerPaymentPP";
import { processTrainerPaymentforfp } from "../../../utils/json/ProcessTrainerPaymentFP";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from "../../..";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import {
  getProcessTrainerPaymentTableData,
  getProcessTrainerPaymentTableDataForPP,
  getProcessTrainerPaymentTableDataForFP,
} from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";

export const ProcessTrainerPayment = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "All Data",
      len: null,
    },
    {
      approval: "Partially Processed",
      len: null,
    },
    {
      approval: "Fully Processed",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "all_data",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const initialResponse: any = await getProcessTrainerPaymentTableData(
        signInData?.data[0]
      );
      const partialResponse: any = await getProcessTrainerPaymentTableDataForPP(
        signInData?.data[0]
      );
      const fullyResponse: any = await getProcessTrainerPaymentTableDataForFP(
        signInData?.data[0]
      );
      const initialLength: any = initialResponse?.length;
      const partiallyLength: any = partialResponse?.length;
      const fullyLength: any = fullyResponse?.length;

      setQueue((prevQueue: any) => [
        {
          ...prevQueue[0],
          len: initialLength,
        },
        {
          ...prevQueue[1],
          len: partiallyLength,
        },
        {
          ...prevQueue[2],
          len: fullyLength,
        },
      ]);
    })();
  }, []);

  return (
    <div>
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Process Trainer Payment
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "all_data" && (
            <TableComponent
              TABLE_JSON={processTrainerPayment}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "partially_processed" && (
            <TableComponent
              TABLE_JSON={processTrainerPaymentforpp}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "fully_processed" && (
            <TableComponent
              TABLE_JSON={processTrainerPaymentforfp}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  )
}

const QueueType = ({ queue, setQueue }: any) => {
  const { id } = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLinkClick = (approval: string) => {
    setSearchParams({
      id: approval.toLowerCase().replace(/\s+/g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((queueItem: any, index: number) => { 
        const isSelected =
          id === queueItem.approval.toLowerCase().replace(/\s+/g, "_");
        return (
          <div
            key={index}
            className={`flex items-center ${index < queue.length - 1 && "gap-8"}`}
            onClick={() => handleLinkClick(queueItem?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {queueItem.approval}{" "}
                <span className="ml-1">{`(${queueItem.len || 0})`}</span>
              </p>
              {isSelected && <hr />}
            </div>
            {index < queue.length - 1 && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        )
      })}
    </div>
  );
};