import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables"
import { DeleteData, FetchData, InsertData, UpdateData } from "../../crud"
import { FreeCourseInterface, PaidCourseInterface, VideosInterface } from "./interface";

export const getAllCourses = async (UsersDetails:any, requiredFields: string[] = []) => {
    const { data: courses, error } = await FetchData(TableNames.courses, requiredFields);
    return courses;
}

export const getCourseById = async (courseId: string | number) => {
    try {
        const { data: courses, error } = await FetchData(TableNames.courses).eq("id",courseId);
        if (error) throw error; 
        return courses[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getCourseTableData = async () => {
    try {
        const { data: courses, error } = await FetchData(TableNames.courses, ["mode", "course_name","type","id","trending", "schedule", "category:categories(category_name)"]).eq("customized_course", false).order("id", { ascending: false });
        if (error) throw error;
        // const filterCourseAccessBased
        return courses?.map((course: any) => ({
            ...course,
            category: course.category?.category_name,
            course: {
                name: course.course_name,
                ...course.trending && {shortNote:"Trending"}
            },
            trending:course.trending?"Yes":"No"
        }));
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCustomisedCourseTableData = async () => {
    try {
        const { data: courses, error } = await FetchData(TableNames.courses, ["mode", "course_name","type","id","trending", "schedule", "category:categories(category_name)"]).eq("customized_course", true).order("id", { ascending: false });
        if (error) throw error;
        return courses?.map((course: any) => ({
            ...course,
            category: course.category?.category_name,
            course: {
                name: course.course_name,
                ...course.trending && {shortNote:"Trending"}
            },
            trending:course.trending?"Yes":"No"
        }));
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCoursePreLoadData = async (UsersDetails: any) => {
    // console.log(UsersDetails?.branches, "123")
    try {
        const { data: branches } = await FetchData(TableNames.branches,["id","branch_name"]).in("id",UsersDetails?.branches);
        const { data: categories } = await FetchData(TableNames.categories,["id","category_name"]);
        const { data: courseFeatures } = await FetchData(TableNames.courseFeatures, ["id", "feature"]);
        const { data: cities } = await FetchData(TableNames.cities, ["id", "city_name"]).in("id", UsersDetails?.cities)
        return {
            branches,
            categories,
            courseFeatures,
            cities
        }
    } catch (error) {
        return errorNotifier(error);
    }
}

export const createCourse = async (courseData: PaidCourseInterface | FreeCourseInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.courses, courseData);
        // const {data: updateData, error: updateError} = await UpdateData(TableNames.categories,  { conditionKey: "category", conditionValue: courseData.category })
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateCourse = async (courseData: Partial<PaidCourseInterface | FreeCourseInterface>, courseId: string) => {
    try {
        const { data, error } = await UpdateData(TableNames.courses,courseData, { conditionKey: "id", conditionValue: courseId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const deleteCourse = async (courseId: string | number) => {
    try {
        const { data, error } = await DeleteData(TableNames.courses, { conditionKey: "id", conditionValue: courseId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateVideos = async (videoData: VideosInterface,courseId:string|number) => {
    try {
        const { error } = await UpdateData(TableNames.courses, videoData, { conditionKey: "id", conditionValue: courseId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseByIdForCustomization = async (courseId: string | number) => {
    try {
        const { data: courses, error } = await FetchData(TableNames.courses,["*"]).eq("id",courseId);
        if (error) throw error; 
        return courses[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}