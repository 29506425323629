import { useState, useEffect, useRef } from 'react';
import { Loader } from '../../Elements/Loader/Loader';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { add_StudentCourse } from '../../Formik/InitialValues';
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { BasicInfo } from './BasicInfo';
import { CourseStatus } from './CourseStatus';
import { CourseDetails } from './CourseDetails';
import { Payment } from './Payment';
import { IsBalanceAvailable } from './IsBalanceAvailable';
import { CommentSection } from './CommentSection';
import {
  getPreloadData,
  getCourseById,
  createTrainingForStudent,
  registerNewStudent,
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent,
  addBatchFollowUp,
  calculateTotalBalanceDueAmount,
  getBatchFollowUp,
  getStudentManagementDetailsById,
  updateStudentManagementDetails,
  getBranchForGST,
  getStudentsData
} from "../../../functions/admin/Students/students";
import { InputBox } from '../../Elements/InputBox';
import { DropdownInputWithCheckbox } from '../../Elements/DropdownInputWithCheckbox';
import { Checkbox } from '../../Elements/Checkbox';
import { formatNumberWithCommas } from '../../../Services/CommonFunctions';
import { AddStudentCourse } from '../../Formik/FormikValidation';
import { createSearchParams } from 'react-router-dom';
import { CloseIcon } from '../../Elements/CloseIcon';
import { BatchFollowUp } from './BatchFollowUp';
import { Add_Batch } from './AddBatch';
import toast from 'react-hot-toast';
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import { DropDownInputBox } from '../../Elements/DropDownInputBox';

export const getImports = {
  useState,
  useEffect,
  useRef,
  Loader,
  onKeyDown,
  add_StudentCourse,
  useSharedLogic,
  FormikCommonFunc,
  BasicInfo,
  CourseStatus,
  CourseDetails,
  Payment,
  IsBalanceAvailable,
  CommentSection,
  getPreloadData,
  InputBox,
  DropdownInputWithCheckbox,
  DropDownInputBox,
  getCourseById,
  Checkbox,
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent,
  formatNumberWithCommas,
  AddStudentCourse,
  registerNewStudent,
  createSearchParams,
  CloseIcon,
  BatchFollowUp,
  Add_Batch,
  createTrainingForStudent,
  toast,
  addBatchFollowUp,
  DropdownSingleInputWithCheckbox,
  calculateTotalBalanceDueAmount,
  getBatchFollowUp,
  getStudentManagementDetailsById,
  updateStudentManagementDetails,
  getBranchForGST,
  getStudentsData
};