import { useState, useEffect } from "react";
import { getjobId } from "../../../functions/admin/HR Management/hr_management";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { dateFormat } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { InformationJobViewCard } from "./InformationJobViewCard";
import parse from "html-react-parser";
import "./customQuillStyles.css";

export const ViewJobsInfo = ({ id }: any) => {
  const [user, setUser] = useState<any>({});
  const [technology, setTechnology] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const userData: any = await getjobId(id);
      const mapTechNames = (userData.technology || [])
        ?.map((item: any) => item.technology)
        .join(", ");
      setTechnology(mapTechNames);
      setUser(userData);
      setIsLoading(false);
    })();
  }, []);

  const buildJSON = [
    {
      left: [
        {
          head: "Company Name",
          body: user && user?.company_name,
        },
        {
          head: "Job Role",
          body: user && user?.job_role,
        },
        {
          head: "Number Of Openings",
          body: user && user?.no_of_openings,
        },
        {
          head: "Last Date to Apply",
          body: user && dateFormat(user?.last_date_to_apply, "DD MMM YYYY"),
        },
        {
          head: "Key Skills",
          body: user && user?.skills?.join(", "),
        },
        {
          head: "Eligibility",
          body: user && typeof user?.eligibility ? "Yes" : "No",
        },
        {
          head: "Passed Out Year",
          body:
            user && user?.passedout_year !== null ? user?.passedout_year : "-",
        },
        {
          head: "Degree",
          body: user && user?.degree !== "" ? user?.degree : "-",
        },
        {
          head: "12th",
          body:
            user && user?.twelfth_marks !== null ? user?.twelfth_marks : "-",
        },
        {
          head: "UG",
          body: user && user?.ug_marks !== null ? user?.ug_marks : "-",
        },
        {
          head: "PG",
          body: user && user?.pg_marks !== null ? user?.pg_marks : "-",
        },
        {
          head: "Diploma",
          body:
            user && user?.diploma_marks !== null ? user?.diploma_marks : "-",
        },
      ],
      right: [
        {
          head: "Job Type",
          body: user && user?.job_type,
        },
        {
          head: "Location",
          body: user && user?.location,
        },
        {
          head: "Technology",
          body: technology && technology,
        },
        {
          head: "Status",
          body: user && user?.status,
        },
        {
          head: "Hired By",
          body: user && user?.status === "Closed" ? user?.hired_by : "-",
        },
        {
          head: "No of Candidates Recruited",
          body:
            user && user?.status === "Closed"
              ? user?.no_of_candidates_recruited
              : "-",
        },
        {
          head: "Bond",
          body: user && user?.bond ? "Yes" : "No",
        },
        {
          head: "No of Years Bond",
          body: user && user?.no_of_years_bond ? user?.no_of_years_bond : "-",
        },
        {
          head: "About Company",
          body:
            user && typeof user?.about_company === "string"
              ? parse(user?.about_company)
              : "-",
        },
        {
          head: "Job Description",
          body:
            user && typeof user?.job_description === "string"
              ? parse(user?.job_description)
              : "-",
        },
      ],
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-9/12"} />
      ) : (
        <CardLayoutTwo>
          <InformationJobViewCard
            cardHeading={"Jobs Information"}
            values={user}
            buildJSON={buildJSON}
          />
        </CardLayoutTwo>
      )}
    </>
  );
};
