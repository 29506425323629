// import { sampleFunc } from "../../Services/Supabase/databaseOperations";
import { ViewEditDeleteActionComponent } from "../../Components/Content Management/ManageCourse/ActionComponent";
import { TrendingTagComp } from "../../Components/Content Management/ManageCourse/TrendingTagComp";
import { getCourseTableData, getCustomisedCourseTableData } from "../../functions/admin/Courses/Courses";

export const manageCourse = {
  headers: [
    {
      name: "Category",
      apiKey: "category",
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start break-words px-2",
    },
    {
      name: "Course",
      apiKey: "course",
      isComponent: true,
      Comp: TrendingTagComp, //will render the component in Table,
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start break-words px-2",
    },
    {
      name: "Course Mode",
      apiKey: "mode",
      headerClass: "w-[140px] text-center px-2",
      className: "w-[140px] text-center break-words px-2",
    },
    {
      name: "Course Type",
      apiKey: "type",
      headerClass: "w-[80px] text-start",
      className: "w-[80px] text-start break-words",
    },
    // {
    //   name: "Discounted Fee",
    //   apiKey: "discounted_fee",
    //   headerClass: "w-[100px] text-center",
    //   className: "w-[100px] text-center break-words",
    // },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: getCourseTableData,
  filters: {
    checkBox: [
      {
        apiKey: "category",
        name: "Category",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
      // {
      //   apiKey: "course",
      //   name: "Course",
      //   isSearchNeeded: true,
      //   className: "w-[250px]",
      // },
      {
        apiKey: "mode",
        name: "Course Mode",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "type",
        name: "Course Type",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "schedule",
        name: "Schedule",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "trending",
        name: "Trending",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["category", "course_name"],
        searchKey: "category",
        name: "Search....",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
}

export const manageCustomizedCourse = {
  headers: [
    {
      name: "Category",
      apiKey: "category",
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start break-words px-2",
    },
    {
      name: "Course",
      apiKey: "course",
      isComponent: true,
      Comp: TrendingTagComp, //will render the component in Table,
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start break-words px-2",
    },
    {
      name: "Course Mode",
      apiKey: "mode",
      headerClass: "w-[140px] text-center px-2",
      className: "w-[140px] text-center break-words px-2",
    },
    {
      name: "Course Type",
      apiKey: "type",
      headerClass: "w-[80px] text-start",
      className: "w-[80px] text-start break-words",
    },
    // {
    //   name: "Discounted Fee",
    //   apiKey: "discounted_fee",
    //   headerClass: "w-[100px] text-center",
    //   className: "w-[100px] text-center break-words",
    // },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: getCustomisedCourseTableData,
  filters: {
    checkBox: [
      {
        apiKey: "category",
        name: "Category",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
      // {
      //   apiKey: "course",
      //   name: "Course",
      //   isSearchNeeded: true,
      //   className: "w-[250px]",
      // },
      {
        apiKey: "mode",
        name: "Course Mode",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "type",
        name: "Course Type",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "schedule",
        name: "Schedule",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        apiKey: "trending",
        name: "Trending",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["category", "course_name"],
        searchKey: "category",
        name: "Search....",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
