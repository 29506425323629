import { useState, useEffect } from 'react'
import { TableComponent } from '../../Elements/Table/TableComponent'
import { pendingPaymentForStudent } from '../../../utils/json/PendingPaymentForStudent';
import { pendingPaymentForBatch } from '../../../utils/json/PendingPaymentForBatch';
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { PaymentFollowUp } from './PaymentFollowUp';
import { AddNew } from './AddNew';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { useAppSelector } from '../../..';
import { useSearchParams } from 'react-router-dom';
import { getPendingPaymentsTableData } from '../../../functions/admin/Action center/PendingPayments/PendingPayments';
import { getBatchPendingPaymentsTableData } from '../../../functions/admin/Action center/PendingPayments/PendingPayments';
import { QueueType } from '../../CommonFunctions/CommonFunc';

export const PendingPayment = () => {
  const { id, mode, by }: any = useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "By Student",
      len: null
    },
    {
      approval: "By Batch",
      len: null
    }
  ] as any);

  useEffect(()=>{
    setSearchParams({
      id: "by_student",
    });
  },[])

  useEffect(() => {
    (async () => { 
      if (signInData?.data?.length > 0) {
        const studentPendingResponse: any = await getPendingPaymentsTableData(signInData?.data[0]);
        const batchPendingResponse: any = await getBatchPendingPaymentsTableData(signInData?.data[0]);
        const studentLength: any = studentPendingResponse?.length;
        const batchLength: any = batchPendingResponse?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: studentLength
          },
          {
            ...prevQueue[1],
            len: batchLength
          }
        ]);
      }
    })() 
  }, [])

  return (
    <div>
      {mode === "add_new_comment" && (
        <div className="space-y-2">
          <PaymentFollowUp id={id} by={by} />
        </div>
      )}
      {allowOnlyActions("view") && mode === "edit" && (
        <div className="space-y-2">
          <AddNew id={id} by={by} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Pending Payment
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "by_student" && (
            <TableComponent
              TABLE_JSON={pendingPaymentForStudent}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "by_batch" && (
            <TableComponent
              TABLE_JSON={pendingPaymentForBatch}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  );
}
