import { useState, useEffect } from "react";
import {
  createApprovalRequest,
  updateApprovalStatus,
  getCourseBasedOnTechnologyForExisting,
} from "../../../../functions/admin/Action center/Student Actions/studentActions";
import { useSharedLogic } from "../../../CommonFunctions/CommonFunc";
import { ExistingCourseDetails } from "./ExitingCourseDetails";
import { useAppSelector } from "../../../..";
import { getApprovalActionData } from "../../../../functions/admin/Action center/Student Actions/studentActions";

export const ExistingActions = ({
  id,
  updatedStudentData,
  paymentObject,
  branchDetails,
  setIsLoading,
  approval_data,
}: any) => {
  // console.log(approval_data, "approval_data", paymentObject);
  const { navigate, routePath }: any = useSharedLogic();
  const [feeTotal, setFeeTotal] = useState(null);
  const [courseData, setCourseData] = useState([] as any);
  const [courseName, setCourseName] = useState([] as any);
  const [checked, setChecked] = useState("Accept");
  const [approval_id, setApproval_Id] = useState(null as any);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [data, setData] = useState([] as any);
  const StudentStatus = [
    "Drop",
    "Hold",
    "Upgrade",
    "Downgrade",
    "Nullify Payment",
  ];
  const nullPayment = ["Bad Debt", "Discount"];
  const { userDetails } = useAppSelector(({ login }: any) => login);

  useEffect(() => {
    (async () => {
      if (Object.keys(updatedStudentData).length) {
        // console.log(updatedStudentData, "updatedStudentData")
        const courseResponse: any = await getCourseBasedOnTechnologyForExisting(
          updatedStudentData?.course_id?.id,
          updatedStudentData?.course_id?.category?.category_name,
          updatedStudentData?.branch_id?.id
        );
        console.log(courseResponse, "courseResponse")
        const response:any = await getApprovalActionData(id);
        // console.log(response, "response");
        if (response) {
        const updatedUpgradeInfos = response?.map((info:any) => {
          const matchingCourse = courseResponse?.find((course:any) => course.id === info.upgraded_or_downgraded_course_id);
          return matchingCourse
            ? { ...info, course: matchingCourse?.course_name }
            : info;
        });
          // console.log(updatedUpgradeInfos, "updatedUpgradeInfos")
        setData(updatedUpgradeInfos);
        }
      }
    })();
  }, []);

  return (
    <div className="space-y-4">
      {data?.length > 0 && <h1 className="w-11/12 mx-auto text-xl text-blue-600 underline underline-offset-2">Existing Actions</h1>}
      {data?.map((values: any, index: number) => {
      return (
        <div className="w-11/12 mx-auto py-4 flex flex-col items-center space-y-10 bg-white shadow-md shadow-[#00000029] rounded-[6px] px-2">
          <div className="flex flex-col items-end justify-between w-full space-y-10">
            <div className="flex items-end justify-between w-full">
              <InputFieldArea
                label="Perform Action"
                placeholder="Perform Action"
                value={values.status}
                topDivStyle={"flex flex-col w-[47%]"}
              />
              {(values.status === "Upgrade" ||
                values.status === "Downgrade") && (
                <InputFieldArea
                  label="Course"
                  placeholder="Course"
                  value={values.course}
                  topDivStyle={"flex flex-col w-[47%]"}
                />
              )}
              {(values.status === "Drop" || values.status === "Hold") && (
                <InputFieldArea
                  placeholder={`Enter the Reason for ${
                    values.status === "Drop" ? "Dropping" : "Holding"
                  } the Student from the Course`}
                  value={values.reason}
                  topDivStyle={"flex flex-col w-[47%]"}
                />
              )}
              {values.status === "Nullify Payment" && (
                <InputFieldArea
                  label="Nullify Payment"
                  placeholder="Nullify Payment"
                  value={values.nullType}
                  topDivStyle={"flex flex-col w-[47%]"}
                />
              )}
            </div>
            {values.status !== "Drop" && values.status !== "Hold" && (
              <div className="w-full flex items-center justify-between">
                {values.status === "Nullify Payment" && (
                  <div className="w-[47%] relative">
                    <textarea
                      name="nullReason"
                      value={values.nullReason}
                      disabled={true}
                      placeholder="Enter the Reason"
                      className="border-[#707070] border-[0.5px] p-1 w-full min-h-[100px] max-h-[150px] rounded-[3px] resize-none"
                    >
                      {values.nullReason}
                    </textarea>
                  </div>
                )}
                {(values.status === "Upgrade" ||
                  values.status === "Downgrade") && (
                  <div className="w-[47%] gap-2 flex items-center">
                    <InputFieldArea
                      label="Duration"
                      placeholder="Duration"
                      value={values.duration}
                      topDivStyle={"flex flex-col w-full"}
                    />
                    <InputFieldArea
                      label={`${
                        values.status === "Upgrade"
                          ? "Plus Duration"
                          : "Minus Duration"
                      }`}
                      placeholder={`${
                        values.status === "Upgrade"
                          ? "Plus Duration"
                          : "Minus Duration"
                      }`}
                      value={values.additional_duration}
                      topDivStyle={"flex flex-col w-full"}
                    />
                  </div>
                )}
                {(values.status === "Upgrade" ||
                  values.status === "Downgrade") && (
                  <div className="w-[47%] gap-2 flex items-center">
                    <InputFieldArea
                      label="Fee (₹)"
                      placeholder="Fee"
                      value={values.fee}
                      topDivStyle={"flex flex-col w-full"}
                    />
                    <InputFieldArea
                      label={`${
                        values.status === "Upgrade"
                          ? "Plus Fee (₹)"
                          : "Minus Fee (₹)"
                      }`}
                      placeholder={`${
                        values.status === "Upgrade"
                          ? "Plus Fee (₹)"
                          : "Minus Fee (₹)"
                      }`}
                      value={values.additional_amount}
                      topDivStyle={"flex flex-col w-full"}
                    />
                  </div>
                )}
              </div>
            )}
            {(values.status === "Upgrade" || values.status === "Downgrade") && (
              <ExistingCourseDetails
                values={values}
                courseData={courseData}
                paymentObject={paymentObject}
                branchDetails={branchDetails}
              />
            )}
          </div>
        </div>
      );
    })}
    </div>
  );
};

const InputFieldArea = ({topDivStyle, label, value, placeholder}:any) => {
    return (
        <div className={`${topDivStyle} space-y-0.5 relative`}>
        {label && <label
            className={`2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]`}
        >
            {label}
        </label>}
        <input
            value={value}
            disabled={true}
            placeholder={placeholder}
            className={`numberClass outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full`}
        />
        </div>
    )
}