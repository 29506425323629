import { ViewEditDeleteActionComponent } from "../../Components/Action Center/StudentFeedbackNotGiven/ActionComponent";
import { StudentFeedbackNotGivenTableData } from "../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const studentFeedbackNotGiven = {
  headers: [
    {
      name: "Student Name",
      apiKey: "student_name",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Phone Number",
      apiKey: "phone_number",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[140px] text-start px-2",
      className: "w-[140px] text-start px-2 break-words",
    },
    {
      name: "Course (%)",
      apiKey: "course_completed_percentage",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center px-2 break-words",
    },
    {
      name: "Followup Date",
      apiKey: "followup_date",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return StudentFeedbackNotGivenTableData(params);
  },
  filters: {
    // dateFilters: [
    //   {
    //     name: "Followup Date",
    //     apiKey: "followup_date",
    //     isSearchNeeded: true,
    //     className: "w-[200px]",
    //   },
    // ],
    checkBox: [
      {
        name: "Followup Date",
        apiKey: "followup_date",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
      {
        name: "Student Name",
        apiKey: "student_name",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Schedule",
        apiKey: "schedule",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "City",
        apiKey: "cityName",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Branch",
        apiKey: "branchName",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Ownership",
        apiKey: "ownership",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["student_name", "phone_number", "course_name"],
        searchKey: "student_nameCourse",
        name: "Student Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};