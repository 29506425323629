import { companyManagementTableData } from "../../functions/admin/HR Management/hr_management";
import { ViewEditDeleteActionComponent } from "../../Components/HR & Student Placement/CompanyManagement/ActionComponent";

export const company_management = {
    headers: [
        {
            name: "Date",
            apiKey: "created_at",
            headerClass: "w-[120px] text-center",
            className: "text-center",
        },
        {
            name: "Company Name",
            apiKey: "company_name",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start break-words px-2",
        },
        {
            name: "Location",
            apiKey: "company_location",
            headerClass: "w-[140px] text-center px-2",
            className: "w-[140px] text-center break-words px-2",
        },
        {
            name: "Salary Range",
            apiKey: "salary_range",
            headerClass: "w-[80px] text-start",
            className: "w-[80px] text-start break-words",
        },
        {
            name: "Company Size",
            apiKey: "company_size",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "Roles",
            apiKey: "recruiting_roles",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "User Count",
            apiKey: "user_count",
            headerClass: "w-[70px] text-center",
            className: "w-[70px] text-center break-words",
        },
        {
            name: "Action",
            apiKey: "companies",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent,
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center",
        },
    ],
     BackendFunctionCall: companyManagementTableData,
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            {
                name: "Company Name",
                apiKey: "company_name",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[200px] 2xl:w-[120px]",
            },
            {
                name: "Company Location",
                apiKey: "company_location",
                isSearchNeeded: true,
                className: "w-[170px]",
            },
            {
                name: "Salary Range",
                apiKey: "salary_range",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
            {
                name: "Company Size",
                apiKey: "company_size",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
            {
                name: "Roles",
                apiKey: "recruiting_roles",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[120px] 2xl:w-[120px]",
            },
            {
                name: "Status",
                apiKey: "status",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[100px] xl:w-[90px] 2xl:w-[110px]",
            },
        ],
        searchBox: [
            {
                matchFields: ["company_name"],
                searchKey: "hr_names",
                name: "Search...",
                searchStyle: "w-[200px]",
            }
        ]
    },
};