import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppSelector } from "../../..";
import { BulkCancelModal } from "./BulkCancelModal";
import {
  getAllCancellationData, getCancelledSessionsForPending, getCancelledSessionsOfContinuousTwoDays
} from '../../../functions/admin/Course Progress/courseProgress';
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";

const checkboxClasses =
  "absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600";

export const CancellationBulkUpdateModal = () => {
  const { id }: any = useSharedLogic();
  const checkboxRef: any = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(new Set());
  const [progress, setProgress] = useState([] as any);
  const [changeModalInput, setChangeModalInput] = useState(false);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const HeadersContent = [
    "#",
    "Date",
    "Batch Code",
    "Course",
    "Branch",
    "City"
  ];

    type FetchFunction = (UsersDetails: any) => Promise<
        boolean | Array<{ 
            id: any; 
            created_at: string; 
            batch_code: any; 
            course_name: any; 
            city_name: any; 
            branch_name: any; 
            batch_id: any;
        } | undefined>
    >;

    const fetchFunctions: Record<string, FetchFunction> = {
        yet_to_speak: getCancelledSessionsForPending,
        default: getCancelledSessionsOfContinuousTwoDays,
    };

    useEffect(() => {
        const fetchData = async () => {
            const fetchFunction = fetchFunctions[id] || fetchFunctions.default;
            const response:any = await fetchFunction(signInData?.data[0]);
            // console.log(response, "response")
            const checkResponseIds: any = await getAllCancellationData(
            signInData?.data[0]
            );
            const checkResponseIdsArray = Array.isArray(checkResponseIds)
            ? checkResponseIds
            : [];
            const unmatchedIds = response?.filter(
            (ft: any) =>
                checkResponseIdsArray.some((response: any) => response.id !== ft?.id)
            );
            // console.log(unmatchedIds, "unmatchedIds")
            setProgress(response);
        };
        fetchData();
    }, [id, signInData]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedBatch.size > 0 && selectedBatch.size < progress.length;
    setChecked(selectedBatch.size === progress.length);
    setIndeterminate(isIndeterminate);
    checkboxRef.current.indeterminate = isIndeterminate;
  }, [selectedBatch, progress]);

  const toggleAll = () => {
    setSelectedBatch(checked || indeterminate ? new Set() : new Set(progress));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const handleCheckboxChange = (person: any) => {
    const updatedSelectedPeople = new Set(selectedBatch);
    if (updatedSelectedPeople.has(person)) {
      updatedSelectedPeople.delete(person);
    } else {
      updatedSelectedPeople.add(person);
    }
    setSelectedBatch(updatedSelectedPeople);
  };

  return (
    <div className={`flex items-center justify-center w-full bg-blue-50`}>
      {changeModalInput ? (
        <BulkCancelModal selectedBatch={selectedBatch} />
      ) : (
        <div className="px-4 sm:px-6 lg:px-8 w-full h-screen py-10">
          <h1 className="text-xl font-Roboto font-[500] text-blue-900">
            Cancellation Bulk Update
          </h1>
          <div className="mt-8 flow-root h-[95%] scroll-smooth overflow-y-scroll">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  {selectedBatch.size > 0 && (
                    <div className="flex h-12 items-center space-x-3 bg-blue-50 sm:left-12">
                      <button
                        type="button"
                        onClick={() => setChangeModalInput(true)}
                        className="inline-flex items-center rounded bg-blue-900 px-2 py-1 text-sm font-semibold text-white shadow-sm"
                      >
                        Proceed
                      </button>
                    </div>
                  )}
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="relative px-7 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className={checkboxClasses}
                            ref={checkboxRef}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        {HeadersContent?.map((head: any) => (
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {progress.map((person: any, index: number) => (
                        <tr
                          key={person.email}
                          className={
                            selectedBatch.has(person) ? "bg-gray-50" : undefined
                          }
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedBatch.has(person) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className={checkboxClasses}
                              value={person.email}
                              checked={selectedBatch.has(person)}
                              onChange={() => handleCheckboxChange(person)}
                            />
                          </td>
                          <td
                            className={`whitespace-nowrap py-4 pr-3 text-sm font-medium ${
                              selectedBatch.has(person)
                                ? "text-indigo-600"
                                : "text-gray-900"
                            }`}
                          >
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.created_at}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.batch_code}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.course_name}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.branch_name}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.city_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
