import React,{ useState, useEffect } from 'react'
import { CloseIcon } from '../Elements/CloseIcon'
import { CardLayoutTwo } from '../Elements/Cards/CardLayoutTwo'
import { InputBox } from '../Elements/InputBox'
import { Loader } from '../Elements/Loader/Loader'
import { SubmitButton } from '../Elements/SubmitButton'
import { onKeyDown } from '../../Services/CommonFunctions'
import { DropDownInputBox } from '../Elements/DropDownInputBox'
import { getAppEnquiriesTableData, updateAppEnquiry } from '../../functions/admin/App Enquiries/app_enquiries'
import { FormikCommonFunc } from '../CommonFunctions/CommonFunc'
import { getAllBranches } from '../../functions/admin/Branches/branches'
import { DropdownInputWithCheckbox } from '../Elements/DropdownInputWithCheckbox'
import { getAllCourses } from '../../functions/admin/Courses/Courses'
import { useSharedLogic } from '../CommonFunctions/CommonFunc'
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { app_enquiries_update } from '../Formik/InitialValues'
import { appEnquiriesValidation } from '../Formik/FormikValidation'
import { useAppSelector } from '../..'

export default function AppEnquiriesUpdate({ message, buttonText, handleClick, closeFunc, id, mode }: any) {
  const { navigate, routeNameForHome, routeNameForSubSection, createFunc }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false)
  const [appEnquiryData, setAppEnquiryData] = useState<any>([]);
  const [appEnquiryCourseData, setAppEnquiryCourseData] = useState<any>([]);
  const [mapAppEnquiryData, setMapAppEnquiryData] = useState<any>([]);
  const [courseNames, setCourseNames] = useState([] as any);
  const [courseData, setCourseData] = useState([] as any);
  const [branchData, setBranchData] = useState([] as any);
  const [branchNames, setBranchNames] = useState([] as any);
  //const [branchID, setBranchID] = useState(null as any);
  const [courseID, setCourseID] = useState(null as any);
  const isId: any = id !== "new-jobs" && id;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  useEffect(() => {
    (async () => {
      if(signInData?.data?.length>0){
        const branchResponses: any = await getAllBranches({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
        setAppEnquiryData(branchResponses);
      
        if (branchResponses) {
          const mapBranch =
          branchResponses &&
          branchResponses.map((branch: any) => branch.branch_name);
          setBranchNames(mapBranch);
          
        }

        const courseResponses: any = await getAllCourses({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
        setAppEnquiryCourseData(courseResponses);

        if (courseResponses) {
          const mapCourse =
          courseResponses &&
          courseResponses.map((course: any) => course.course_name);
          setCourseNames(mapCourse);
        }

    if( mode && mode === "moved-to-crm"){
      //    const technologyResponse: any = await getAppEnquiriesTableData();
      const appEnquiriesResponse: any = await getAppEnquiriesTableData();
      //   setTechnologyData(technologyResponse);
      const idToFind = parseInt(id);
      
      const enquiryData:any = appEnquiriesResponse.find((data: any) => data.id===idToFind);
      setMapAppEnquiryData(enquiryData);
      const mapCityForId:any = courseResponses &&
      courseResponses.filter((data: any) => data.course_name === enquiryData.course_name);
    //  setCourseNames(enquiryData.course_name);
        const { student_name,student_mobile,student_email,date,student_id,status }: any = enquiryData;
        if (enquiryData) {
          //  const lastDateApply: any = dateFormat(last_date_to_apply, "DD/MM/yyyy")
            setValues(
              {
                ...values,
                student_name,student_mobile,student_email,date,student_id,status:"Moved To BC"
              },
              true
            );
            //setCourseData(enquiryData.course_name);
            setCourseData(mapCityForId?.[0]["course_name"]);
            setCourseID(mapCityForId?.[0]["id"])
            
            
          }
        }
      }
    })()
},[])
const handleChangeForDropValue = (
  name: string,
  value: string,
  mapThrough?: string
) => {
  if (mapThrough === "branch") {
    setBranchData(value);
    
      const data =
      appEnquiryData &&
      appEnquiryData.find((data: any) => data.branch_name == value);
      setFieldValue(name, data['id']);
       //setBranchID(data.id)
  } else if(mapThrough === "course") {
    const datas =
    appEnquiryCourseData &&
    appEnquiryCourseData.find((data: any) => data.course_name == value);
    setFieldValue(name, datas['id']);
    setCourseData(value);
    setCourseID(datas['id'])
     // setFilteredOwnership(value);
     //setFilteredOwnership(value);
      //setBranchData(name, value);
      
  }
  else{
    setFieldValue(courseID);
  }
};
const forNewAppEnquiries = async () => {
  setIsLoading(true);
  const {...rest} = values;
    const body: any = {
     
        ...rest
    }
  
    
      const appEnquiriesUpdate: any = {
        student_name : values?.student_name,
        student_email: values?.student_email,
        student_mobile: values?.student_mobile,
        branch: values?.branch_name,
      course: courseID,
      status:values?.status,
      //date:values?.date,
      student_id: values?.student_id,
      is_enquired:true,

    }
    const response = await updateAppEnquiry(appEnquiriesUpdate,isId)
    const dataBranch =
    appEnquiryData &&
    appEnquiryData.find((data: any) => data.id == values.branch_name);
    // const appEnquiriesCrm = {
    //   FirstName : values.student_name,
    //   EmailAddress: values.student_email,
    //   Mobile: values.student_mobile,
    //   mx_Branch: dataBranch.branch_name,
    //   mx_Course: values.course_name,

    // }
    const appEnquiriesCrm = [
      {
        Attribute: 'FirstName',
        Value: values.student_name,
      },
      {
        Attribute: 'EmailAddress',
        Value: values.student_email,
      },
      {
        Attribute: 'Mobile',
        Value: values.student_mobile,
      },
      {
        Attribute: 'mx_Branch',
        Value: dataBranch.branch_name,
      },
      {
        Attribute: 'mx_Course',
        Value: courseData,
      },
    ];
    try {
      const response = await fetch(
        'https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$ra489239012ad0168186144de86b6b60d&secretKey=ad9d9b7b7e5c1ddfa57e3fb811044421c5fd9da4',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(appEnquiriesCrm),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    // const response = await(
    //   id === "new-jobs"
    //     ? updateAppEnquiry(appEnquiriesUpdate,isId)
    //     : updateAppEnquiry(appEnquiriesUpdate,isId)
    // );
    if (response) {
        navigate({
        pathname: `/${routeNameForHome}`,
        search: createSearchParams({ id: `${id === "new-jobs" ? "enquiry-created-success" : "enquiry-update-success"}` }).toString(),
        });
    }
    // const response = await(
    //   id === "new-jobs"
    //     ? createNewJob(body, city_name.length>0 && addCity)
    //     : updateBranch(body, isId)
    // );
    // if (response) {
    //     navigate({
    //     pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
    //     search: createSearchParams({ id: `${id === "new-jobs" ? "created-success" : "update-success"}` }).toString(),
    //     });
    // }
    setIsLoading(false);
}

const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(app_enquiries_update, appEnquiriesValidation, forNewAppEnquiries);
  
return (
    
    <div className="bg-white flex flex-col mx-auto items-center justify-center w-[631px] h-[324px] drop-shadow-lg rounded-[6px] shadow-md shadow-[#00000029] relative">
      <CloseIcon
        closeFunc={() =>
          navigate(`/${routeNameForHome}`)
        }
        classStyle="text-[33px] w-[30px] h-[30px] absolute -top-3 -right-3"
      />
      <div className="flex flex-col w-11/12">
        {/* <h1 className="text-[#707070] text-[25px] font-Roboto font-medium">
          MESSAGE
        </h1> */}
            <form  onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        {isLoading ? (
          <Loader widthFix={"w-11/12"} />
        ) : (
          <>
            
              
              <div className="w-full mx-auto pt-16 gap-12">
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  
                  <InputBox
                    label="Name:"
                    placeholder="Name"
                    classNameInput={"flex flex-col w-full"}
                    name={"student_name"}
                     value={values.student_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                   <InputBox
                    label="Phone Number:"
                    placeholder="Phone Number"
                    classNameInput={"flex flex-col w-full"}
                    name={"student_mobile"}
                     value={values.student_mobile}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
                 
                </div>
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  
                  <InputBox
                    label="Email:"
                    placeholder="Email"
                    classNameInput={"flex flex-col w-full"}
                    name={"student_email"}
                     value={values.student_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                   <DropDownInputBox
                    label="Course:"
                    placeholder="Course"
                    classNameInput={"w-full"}
                    name={"course_name"}
                     value={values.course_name}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={courseNames}
                    showData={courseData}
                    setShowData={setCourseData}
                    mapThrough={"course"}
                    formik={formik}
                  />
                   {/* <InputBox
                    label="Course:"
                    placeholder="Job Location"
                    classNameInput={"flex flex-col w-full"}
                    name={"location"}
                    // value={values.location}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  /> */}
                 
                </div>
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  
                  {/* <InputBox
                    label="Branch:"
                    placeholder="Company Name"
                    classNameInput={"flex flex-col w-full"}
                    name={"company"}
                    // value={values.company}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  /> */}
                   <DropDownInputBox
                    label="Branch:"
                    placeholder="Branch"
                    classNameInput={"w-full"}
                    name={"branch_name"}
                    value={values.branch_name}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={branchNames}
                    showData={branchData}
                    setShowData={setBranchData}
                    mapThrough={"branch"}
                    formik={formik}
                    required
                     error={errors?.branch_name}
                    isTouched={touched?.branch_name}
                  />
                  {/* <DropdownInputWithCheckbox
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  label="Branch"
                  placeholder="Branch"
                  options={branchNames}
                  name={"branch_name"}
                  forId={"branch_name"}
                  value={branchNames.branch_name}
                  onBlur={handleBlur}
                  mapData={branchData}
                  mapThrough={"branch_name"}
                  formik={formik}
                  disabled={mode === "view"}
                /> */}
                   <InputBox
                    label="Status:"
                    placeholder="Status"
                    classNameInput={"flex flex-col w-full bg-neutral-300 text-stone-950"}
                    name={"location"}
                     value={"Move To BC"}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                 
                </div>

               
              </div>
              <div className="w-10/12 mx-auto pt-6 gap-12 text-center">
                <SubmitButton />
                {/* <div className='text-center'>
      <button type='submit' className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal">Submit</button>
      </div> */}
              </div>
           
              <div className="py-8">
                {/* <StatusButton
                  type={"submit"}
                  buttonText={
                    id === "new-branch" ? "Add New Branch" : "Update Branch"
                  }
                  CheckValue={CheckValue}
                  checked={checked}
                  buttonWidth={"w-9/12"}
                  show={mode !== "view"}
                /> */}
              </div>
            
          </>
        )}
      </form>
        
        {/* <button
          onClick={handleClick}
          className="bg-[#036AD1] rounded-[27px] p-1.5 px-4 text-white text-[20px] font-Roboto font-normal"
        >
          {buttonText}
        </button> */}
      </div>
    </div>
    
  )
}