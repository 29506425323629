import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createSearchParams } from "react-router-dom";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { InputBox } from "../../Elements/InputBox";
import { AddKeySkills } from "./AddKeySkills";
import { add_create_job } from "../../Formik/InitialValues";
import { AddCreatJobValidation } from "../../Formik/FormikValidation";
import {
  getHrStudentPreloadData,
  updateJob,
  createNewJob,
  getjobId,
  getAllCompaniesList,
} from "../../../functions/admin/HR Management/hr_management";
import { useAppSelector } from "../../..";
import {
  useSharedLogic,
  FormikCommonFunc,
} from "../../CommonFunctions/CommonFunc";
import { SubmitButton } from "../../Elements/SubmitButton";
import "./customQuillStyles.css";
import { DropdownInputWithCheckbox } from "../../Elements/DropdownInputWithCheckbox";
import { DropdownWithHandleOut } from "../../Elements/DropdownWithHandleOut";
import { Checkbox } from "../../Elements/Checkbox";
import toast from "react-hot-toast";

export const AddNewJobs = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [roomsCount, setRoomsCount] = useState([] as any);
  const [technologyNames, setTechnologyNames] = useState([] as any);
  const [technologyData, setTechnologyData] = useState([] as any);
  const [companyNames, setCompanyNames] = useState([] as any);
  const [checked, setChecked] = useState("Yes");
  const [checkedForBond, setCheckedForBond] = useState("Yes");
  const isId: any = id !== "new-jobs" && id;

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["blockquote", "code-block"],
      ["image"],
      [{ color: [] }, { background: [] }],
      ["clean"], // Remove formatting button
    ],
  };

  const formats = [
    "header",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "color",
    "background",
    "image",
    "blockquote",
    "code-block",
  ];

  useEffect(() => {
    (async () => {
      const res: any = await getAllCompaniesList();
      if (res?.length > 0) setCompanyNames(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const technologyResponse: any = await getHrStudentPreloadData();
      setTechnologyData(technologyResponse);
      if (technologyResponse) {
        const mapTechNames = technologyResponse.map(
          (tech: any) => tech?.technology
        );
        setTechnologyNames(mapTechNames);
      }

      if (mode && mode === "edit") {
        const responseBasedOnID = await getjobId(isId);
        const {
          company_name,
          location,
          job_role,
          job_type,
          technology,
          about_company,
          skills,
          job_description,
          eligibility,
          last_date_to_apply,
          no_of_openings,
          status,
          bond,
          degree,
          diploma_marks,
          hired_by,
          no_of_candidates_recruited,
          no_of_years_bond,
          passedout_year,
          pg_marks,
          twelfth_marks,
          ug_marks,
        }: any = responseBasedOnID;

        const mapTechNames = technology?.map((mt: any) => mt?.technology);

        if (responseBasedOnID) {
          setValues(
            {
              ...values,
              company_name,
              location,
              job_role,
              job_type,
              technology: mapTechNames,
              about_company,
              skills,
              job_description,
              no_of_openings,
              last_date_to_apply,
              eligibility,
              job_status:status,
              bond,
              degree,
              diploma_marks,
              hired_by: hired_by===""?"Hired through FITA":hired_by,
              no_of_candidates_recruited,
              no_of_years_bond,
              passedout_year,
              pg_marks,
              twelfth_marks,
              ug_marks,
            },
            true
          );
          if (skills !== null) {
            const pushData: any = [];
            for (let i = 0; i < skills.length - 1; i++) {
              pushData.unshift([]);
            }
            setRoomsCount(pushData);
          }
        }
      }
    })();
  },[]);

  const validateUserInput = (values: any) => {
    if (values.company_name === "") {
      toast.error("Enter Company Name");
      return false;
    }

    if (values.location?.length < 1) {
      toast.error("Select Atleast One Location");
      return false;
    }

    if (values.technology?.length < 1) {
      toast.error("Select Atleast One Tech");
      return false;
    }

    if (values.last_date_to_apply === "") {
      toast.error("Select Last Date To Apply");
      return false;
    }

    if (
      values.job_status === "Closed" &&
      values.hired_by === "Hired through FITA" &&
      values.no_of_candidates_recruited === null
    ) {
      toast.error("Enter Candidates Recruited Count");
      return false;
    }

    if (values.skills?.length < 1) {
      toast.error("Enter Atleast One Skill");
      return false;
    }

    if (values.about_company === "") {
      toast.error("Enter About Company");
      return false;
    }

    if (values.job_description === "") {
      toast.error("Enter Job Description");
      return false;
    }

    if (values.eligibility && values.passedout_year === null) {
      toast.error("Enter Passed Out Year");
      return false;
    }

    if (values.eligibility && values.degree === "") {
      toast.error("Enter Degree");
      return false;
    }

    if (values.bond && values.no_of_years_bond === null) {
      toast.error("Enter No of Years Bond");
      return false;
    }

    return true;
  };

  const forNewBranch = async () => {
    if (!validateUserInput(values)) {
      return;
    }
    setIsLoading(true);
    const formattedTechnologies = values.technology.map((techName: any) => {
      const techObj = technologyData.find(
        (tech: any) => tech.technology === techName
      );
      return {
        id: techObj?.id,
        technology: techObj?.technology,
      };
    });
    const { hired_by, job_status, ...rest } = values;
    const body = {
      ...rest,
      technology: formattedTechnologies,
      status: job_status,
      hired_by: rest?.status === "Closed" ? hired_by : "",
    };
    // console.log(body, "Body", technologyData);

    const response = await (id === "new-jobs"
      ? createNewJob(body)
      : updateJob(isId, body));

    if (response) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({
          id:
            id === "new-jobs" ? "jobs-created-success" : "jobs-update-success",
        }).toString(),
      });
    }
    setIsLoading(false);
  };

  const {
    formik,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  }: any = FormikCommonFunc(
    add_create_job,
    AddCreatJobValidation,
    forNewBranch
  );

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue("eligibility", label === "Yes" ? true : false);
  };

  const CheckValueForBond = (label: any) => {
    setCheckedForBond(label);
    setFieldValue("bond", label === "Yes" ? true : false);
  };

  const addMoreRooms = () => {
    const add = [...roomsCount, []];
    setRoomsCount(add);
  };

  const deleteRooms = (i: number, value: any) => {
    const updatedArray = [...values.skills];
    const filterData: any = updatedArray.filter((str: any) => str !== value);
    setFieldValue("skills", filterData);
    const deleteOne = [...roomsCount];
    deleteOne.splice(i, 1);
    setRoomsCount(deleteOne);
  };

  const handleChangeForRooms = (index: number, value: any) => {
    const updatedInputValues: any = [...values.skills];
    updatedInputValues[index] = value;
    setFieldValue("skills", updatedInputValues);
  };

  const handleOutForSingle = (event: any) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleOutForMultiple = (event: any) => {
    const { name, value } = event.target;
    if (formik.values[name]?.includes(value)) {
      formik.setFieldValue(
        name,
        formik.values[name]?.filter((sch: any) => sch !== value)
      );
    } else formik.setFieldValue(name, [...formik.values[name], value]);
  };

  const handleOutFields = (
    option: any,
    forId: any,
    name: any,
    mapData: any,
    mapThrough: any,
    indexValue: any
  ) => {
    if (formik.values[name]?.includes(option)) {
      formik.setFieldValue(
        name,
        formik.values[name]?.filter((sch: any) => sch !== option)
      );
    } else formik.setFieldValue(name, [...formik.values[name], option]);
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
      {isLoading ? (
        <Loader widthFix={"w-11/12"} />
      ) : (
        <>
          <CardLayoutTwo>
            <div className="text-left border-b-2 p-2">
              <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                {id === "new-branch" ? "Job Info" : "Job Info"}
              </p>
            </div>
            <div className="w-10/12 mx-auto space-y-10">
              <div className="w-full grid grid-cols-2 pt-3 gap-6">
                <DropdownWithHandleOut
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  options={["Intern", "Fresher", "Experienced"]}
                  label="Job Type"
                  placeholder="Job Type"
                  name={"job_type"}
                  value={formik.values.job_type}
                  handleInputChange={handleOutForSingle}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  zIndex={"z-50"}
                />
                <DropdownWithHandleOut
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  options={companyNames}
                  label="Company Name"
                  placeholder="Company Name"
                  name={"company_name"}
                  value={formik.values.company_name}
                  handleInputChange={handleOutForSingle}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  zIndex={"z-50"}
                />
                <DropdownWithHandleOut
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  options={["Chennai", "Bangalore"]}
                  label="Location"
                  placeholder="Location"
                  name={"location"}
                  value={formik.values.location}
                  handleInputChange={handleOutForMultiple}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  zIndex={"z-40"}
                />
                <InputBox
                  label="Job Role"
                  placeholder="Job Role"
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"job_role"}
                  value={formik.values.job_role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.job_role}
                  isTouched={formik.touched.job_role}
                  disabled={false}
                />
                <DropdownInputWithCheckbox
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  label="Technology"
                  placeholder="Technology"
                  options={technologyNames}
                  name={"technology"}
                  value={formik.values.technology}
                  handleOut={handleOutFields}
                  onBlur={formik.handleBlur}
                  error={formik.errors?.technology}
                  isTouched={formik.touched?.technology}
                  mapData={technologyData}
                  mapThrough={"technology"}
                  formik={formik}
                  disabled={false}
                />
                <InputBox
                  label="Number of Openings:"
                  placeholder="No of Openings"
                  classNameInput={"flex flex-col w-full"}
                  name={"no_of_openings"}
                  value={values.no_of_openings}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={"number"}
                  error={errors?.no_of_openings}
                  isTouched={touched?.no_of_openings}
                />
                <InputBox
                  label="Last Date to Apply:"
                  type={"date"}
                  name={"last_date_to_apply"}
                  placeholder="01/01/2023"
                  value={values.last_date_to_apply}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  classNameInput={
                    "2xl:text-[16px] cursor-pointer lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full"
                  }
                />
                <DropdownWithHandleOut
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  options={["Open", "Closed", "Opening on Hold", "Expired"]}
                  label="Status"
                  placeholder="status"
                  name={"job_status"}
                  value={formik.values.job_status}
                  handleInputChange={handleOutForSingle}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  zIndex={"z-40"}
                />
                {values.job_status === "Closed" && (
                  <DropdownWithHandleOut
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    options={[
                      "Hired through FITA",
                      "Hired from Any other source",
                      "Requirement is No longer",
                    ]}
                    label="Hired By"
                    placeholder="Hired By"
                    name={"hired_by"}
                    value={formik.values.hired_by}
                    handleInputChange={handleOutForSingle}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    zIndex={"z-40"}
                  />
                )}
                {values.job_status === "Closed" &&
                  values.hired_by === "Hired through FITA" && (
                    <InputBox
                      label="Candidate Recruited"
                      placeholder="No of counts"
                      type={"number"}
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"no_of_candidates_recruited"}
                      value={formik.values.no_of_candidates_recruited}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.no_of_candidates_recruited}
                      isTouched={formik.touched.no_of_candidates_recruited}
                      disabled={false}
                    />
                  )}
              </div>
              <div className="w-full pt-10">
                <AddKeySkills
                  addMoreRooms={addMoreRooms}
                  roomsCount={roomsCount}
                  deleteRooms={deleteRooms}
                  formik={formik}
                  name={"skills"}
                  dataSetup={handleChangeForRooms}
                />
              </div>
              <div className="space-y-0.5 flex flex-col w-full pt-3 relative">
                <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
                  About Company <span></span>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "5px", marginBottom: "40px" }}
                  value={values.about_company}
                  onChange={(content) =>
                    setFieldValue("about_company", content)
                  }
                  placeholder="About Company"
                  formats={formats}
                  modules={modules}
                  readOnly={mode === "view"}
                  className="min-h-[200px] py-2 font-[500] rounded-[3px]"
                />
                {touched.about_company && errors.about_company && (
                  <span className="text-red-500 absolute -bottom-6 right-0">
                    {errors.about_company}
                  </span>
                )}
              </div>
              <div className="space-y-0.5 flex flex-col w-full pt-3 relative">
                <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
                  Job Description <span></span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={values.job_description}
                  style={{ height: "5px", marginBottom: "40px" }}
                  onChange={(content) =>
                    setFieldValue("job_description", content)
                  }
                  placeholder="Job Description..."
                  readOnly={mode === "view"}
                  modules={modules}
                  formats={formats}
                  className="min-h-[200px] py-2 font-[500]  rounded-[3px]"
                />
                {touched.job_description && errors.job_description && (
                  <span className="text-red-500 absolute -bottom-6 right-0">
                    {errors.job_description}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-center gap-2">
                <p className="text-[#707070] text-[18px] font-Roboto font-medium">
                  Do you have any eligibility?
                </p>
                <div className="flex items-center justify-around gap-2">
                  <Checkbox
                    label={"Yes"}
                    checked={checked}
                    CheckValue={CheckValue}
                    textStyle={"text-[#707070] text-[15px]"}
                    show={true}
                  />
                  <Checkbox
                    label={"No"}
                    checked={checked}
                    CheckValue={CheckValue}
                    textStyle={"text-[#707070] text-[15px]"}
                    show={true}
                  />
                </div>
              </div>
              {values.eligibility && (
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  <InputBox
                    label="Year of Passed Out"
                    placeholder="Year of Passed Out"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"passedout_year"}
                    value={formik.values.passedout_year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.passedout_year}
                    isTouched={formik.touched.passedout_year}
                    disabled={false}
                  />
                  <InputBox
                    label="Degree"
                    placeholder="Degree"
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"degree"}
                    value={formik.values.degree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.degree}
                    isTouched={formik.touched.degree}
                    disabled={false}
                  />
                  <InputBox
                    label="12th"
                    placeholder="Marks Here"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"twelfth_marks"}
                    value={formik.values.twelfth_marks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.twelfth_marks}
                    isTouched={formik.touched.twelfth_marks}
                    disabled={false}
                  />
                  <InputBox
                    label="Under Graduation"
                    placeholder="Marks Here"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"ug_marks"}
                    value={formik.values.ug_marks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.ug_marks}
                    isTouched={formik.touched.ug_marks}
                    disabled={false}
                  />
                  <InputBox
                    label="Post Graduation"
                    placeholder="Marks Here"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"pg_marks"}
                    value={formik.values.pg_marks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.pg_marks}
                    isTouched={formik.touched.pg_marks}
                    disabled={false}
                  />
                  <InputBox
                    label="Diploma"
                    placeholder="Marks Here"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"diploma_marks"}
                    value={formik.values.diploma_marks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.diploma_marks}
                    isTouched={formik.touched.diploma_marks}
                    disabled={false}
                  />
                </div>
              )}
              <div className="flex items-center justify-center gap-2">
                <p className="text-[#707070] text-[18px] font-Roboto font-medium">
                  Bond
                </p>
                <div className="flex items-center justify-around gap-2">
                  <Checkbox
                    label={"Yes"}
                    checked={checkedForBond}
                    CheckValue={CheckValueForBond}
                    textStyle={"text-[#707070] text-[15px]"}
                    show={true}
                  />
                  <Checkbox
                    label={"No"}
                    checked={checkedForBond}
                    CheckValue={CheckValueForBond}
                    textStyle={"text-[#707070] text-[15px]"}
                    show={true}
                  />
                </div>
              </div>
              {values.bond && (
                <div className="w-full grid grid-cols-2 gap-6">
                  <InputBox
                    label="No of Years Bond"
                    placeholder="No of Years"
                    type={"number"}
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"no_of_years_bond"}
                    value={formik.values.no_of_years_bond}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.no_of_years_bond}
                    isTouched={formik.touched.no_of_years_bond}
                    disabled={false}
                  />
                </div>
              )}
            </div>
            <div className="w-10/12 mx-auto pt-7 gap-12 text-center">
              <SubmitButton />
            </div>
          </CardLayoutTwo>
        </>
      )}
    </form>
  );
};
