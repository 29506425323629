import {useState, useEffect, useRef, useCallback} from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableComponent } from '../../Elements/Table/TableComponent'
import { cancellationsessionForPending } from '../../../utils/json/cancellationsessionForPending';
import { cancellationsessionByFita } from '../../../utils/json/cancellationsessionByFita';
import { cancellationsessionByTrainer } from '../../../utils/json/cancellationsessionByTrainer';
import { cancellationsessionByStudent } from '../../../utils/json/cancellationsessionByStudent';
import { cancellationsessionContinuousTwoDays } from "../../../utils/json/cancellationsessionContinuousTwoDays";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector, useAppDispatch } from '../../..';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { BackButton } from '../../Elements/BackButton';
import { CancelledSessionFollowUp } from "./CancelledSessionFollowUp";
import { AddNew } from "./AddNew";
import {
  getCancelledSessionsForPending, getCancelledSessionsByFita,
  getCancelledSessionsByTrainer, getCancelledSessionsByStudent, getCancelledSessionsOfContinuousTwoDays
} from '../../../functions/admin/Course Progress/courseProgress';
import { StudentDiscountModal } from '../../UI_Modal/StudentDiscountModal';
import { toggleBulkUpdateCancellation } from "../../../Redux/Reducers/cancellationModal";
import { CancellationBulkUpdateModal } from "./CancellationBulkUpdateModal";

export const CancelledSession = () => {
  const { id, mode, by }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const dispatch = useAppDispatch();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Yet To Speak",
      len: null
    },
    {
      approval: "By FITA",
      len: null
    },
    {
      approval: "By Trainer",
      len: null
    },
    {
      approval: "By Student",
      len: null
    },
    {
      approval: "Continuous Two Days",
      len: null
    }
  ] as any);
  const cancellationSessionModalIsOpen = useAppSelector(
    (state) => state.cancellation
  );

  useEffect(()=>{
    setSearchParams({
      id: "yet_to_speak",
    });
  },[])

  useEffect(() => {
    (async () => { 
      if (signInData?.data?.length > 0) {
        const pendingResponse: any = await getCancelledSessionsForPending(signInData?.data[0]);
        const fitaResponse: any = await getCancelledSessionsByFita(signInData?.data[0]);
        const trainerResponse: any = await getCancelledSessionsByTrainer(signInData?.data[0]);
        const studentResponse: any = await getCancelledSessionsByStudent(signInData?.data[0]);
        const twodaysResponse: any = await getCancelledSessionsOfContinuousTwoDays(signInData?.data[0]);
        const PendingLength: any = pendingResponse?.length;
        const fitaLength: any = fitaResponse?.length;
        const trainerLength: any = trainerResponse?.length;
        const studentLength: any = studentResponse?.length;
        const twodaysLength: any = twodaysResponse?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: PendingLength
          },
          {
            ...prevQueue[1],
            len: fitaLength
          },
          {
            ...prevQueue[2],
            len: trainerLength
          },
          {
            ...prevQueue[3],
            len: studentLength
          },
          {
            ...prevQueue[4],
            len: twodaysLength
          }
        ]);
      }
    })() 
  }, [])

  return (
    <div>
      {mode === "add_new_comment" && (
        <div className="space-y-2">
          <CancelledSessionFollowUp id={id} />
        </div>
      )}
      {allowOnlyActions("view") && mode === "edit" && (
        <div className="space-y-2">
          <AddNew id={id} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Cancelled Session
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "yet_to_speak" && (
            <TableComponent
              TABLE_JSON={cancellationsessionForPending}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "by_fita" && (
            <TableComponent
              TABLE_JSON={cancellationsessionByFita}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "by_trainer" && (
            <TableComponent
              TABLE_JSON={cancellationsessionByTrainer}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "by_student" && (
            <TableComponent
              TABLE_JSON={cancellationsessionByStudent}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "continuous_two_days" && (
            <TableComponent
              TABLE_JSON={cancellationsessionContinuousTwoDays}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {cancellationSessionModalIsOpen && (
            <div className="fixed inset-0 z-50 overflow-y-auto px-4 sm:px-6 lg:px-8 d-flex justify-center items-center">
              <div
                className="fixed inset-0 bg-opacity-75 backdrop-filter blur(8px)"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                onClick={() => dispatch(toggleBulkUpdateCancellation(false))}
              >
                {/* Full screen blurred background */}
              </div>

              <div className={`mx-auto relative ${'w-90 h-90'} bg-white rounded-xl shadow-lg overflow-hidden`}>
                <CancellationBulkUpdateModal />
                <button
                  type="button"
                  onClick={() => dispatch(toggleBulkUpdateCancellation(false))}
                  className="absolute top-4 right-4 z-50 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-indigo-500 p-2 rounded-md"
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10L4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

interface QueueItem {
  approval: string;
  len: number | null;
}

interface QueueTypeProps {
  queue: QueueItem[];
  setQueue: React.Dispatch<React.SetStateAction<QueueItem[]>>;
}

const QueueType: React.FC<QueueTypeProps> = ({ queue, setQueue }) => {
  const { id } = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  const handleLinkClick = (approval: string) => {
    setSelectedOption(approval);
    setSearchParams({
      id: approval.toLowerCase().replace(/\s+/g, "_"),
    });
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="flex items-center gap-6">
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <button
          onClick={toggleDropdown}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {selectedOption || "Yet To Speak"}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06 0L10 10.94l3.72-3.72a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen && (
          <div className="z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-[300px] overflow-auto snap-y snap-mandatory scrollBar scroll-smooth">
            <div className="py-1">
              {queue.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleLinkClick(option.approval)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {option.approval} ({option?.len})
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


