import { Components } from "./ModuleImports";
import { useAppSelector } from "../../..";

export const AddNewUser = ({ id, mode }: any) => {
  const { useState, useEffect, UserInputs, useSharedLogic, useFormik, AddUserValidation, StatusButton, add_User, onKeyDown, CreateNewUser, getUserById, updateUser, createUserPreLoadData, Loader, toast }: any = Components;
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [checked, setChecked] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const isId: any = id !== "add-user" && id;
  const isEdit = id !== 'add-user' ? true : false
  const [courseData, setCourseData] = useState([] as any);
  const [scheduleData, setScheduleData] = useState([] as any)
  const [CourseModeData, setCourseModeData] = useState([] as any)
  const [courseNames, setCourseNames] = useState([] as any);
  const [cityData, setCityData] = useState([] as any);
  const [cityNames, setCityNames] = useState([] as any);
  const [branchData, setBranchData] = useState([] as any);
  const [branchNames, setBranchNames] = useState([] as any);
  const [roleData, setRoleData] = useState([] as any);
  const [roleNames, setRoleNames] = useState([] as any);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue('status', label)
  };

  const languageOptions = [
    { id: 'Tamil', name: 'Tamil' },
    { id: 'English', name: 'English' },
    { id: 'Malayalam', name: 'Malayalam' },
    { id: 'Kannada', name: 'Kannada' },
    { id: 'Telugu', name: 'Telugu' },
    // Add more languages as needed
  ];
  const suitableOptions = [
    { id: 'Fresher', name: 'Fresher' },
    { id: 'Experienced', name: 'Experienced' },
    { id: 'Both', name: 'Both' },
    // Add more suitableoptions as needed
  ];
  const availabilityOptions = [
    { id: 'Yet to Use', name: 'Yet to Use' },
    { id: 'Active', name: 'Active' },
    { id: 'Inactive', name: 'Inactive' },
    { id: 'Unavailable', name: 'Unavailable' },
    // Add more availabilityoptions as needed
  ];
  const scheduleOptions = [
    { id: 'Weekday', name: 'Weekday' },
    { id: 'Weekend', name: 'Weekend' },
    { id: 'Fasttrack', name: 'Fasttrack' },
    // Add more scheduleoptions as needed
  ];
  const modeOptions = [
    { id: 'Classroom', name: 'Classroom' },
    { id: 'Online', name: 'Online' },
    { id: 'Hybrid', name: 'Hybrid' },
    // Add more scheduleoptions as needed
  ];

  useEffect(() => {
    if (id !== "add-user") {
      singleUserByID();
    }
  }, []);

    useEffect(() => {
      (async () => {
        if(signInData?.data?.length>0) {
          setIsLoading(true);
          const readData: any = await createUserPreLoadData({
            branches: signInData?.data[0]?.branches,
            cities: signInData?.data[0]?.cities
          });
          const filterCourses = readData?.courses?.filter((course: any) => course?.type === "Paid");
          setCourseData(filterCourses);
          setCityData(readData?.cities);
          // setBranchData(readData?.branches);
          const filterRoles = readData?.roles?.filter((role: any) => role?.role === "Trainer")
          // filterRoles?.length>0 && setFieldValue('roles_array', [filterRoles?.[0]?.id])
          setRoleData(filterRoles);
          if (readData) {
            const mapCourse: any = filterCourses && filterCourses?.map((course: any) => course.course_name)
            setCourseNames(mapCourse)
            const mapCity: any = readData && readData?.cities.map((city: any) => city.city_name)
            setCityNames(mapCity)
            const mapRole: any = filterRoles && filterRoles?.map((role: any) => role.role)
            setRoleNames(mapRole)
            // mapRole?.length>0 && setFieldValue('role_names', mapRole[0])
          }
          setIsLoading(false);
        }
      })();
    }, []);

  const singleUserByID = async () => {
    const response = await getUserById(isId, signInData?.data[0]?.roles);
    const {
      first_name,
      last_name,
      email,
      phone_number,
      branches,
      branchesNames,
      alternate_phone_number,
      trainer_location,
      trainer_address,
      languages,
      current_availability,
      suitable_for,
      course_schedule,
      mode,
      professional_from,
      commercials_spoken_by,
      demo_attended_by,
      onboarding_date,
      commercials,
      comment,
      cities,
      cityNames,
      courses,
      coursesNames,
      roles_array,
      rolesName,
      status,
      recruiter
    }: any = response;
    if (response) {
        setValues(
          {
            ...values,
            first_name,
            last_name,
            email,
            phone_number,
            alternate_phone_number,
            trainer_location,
            trainer_address,
            languages,
            commercials_spoken_by,
            demo_attended_by,
            current_availability: current_availability,
            suitable_for: suitable_for,
            course_schedule,
            mode,
            commercials,
            comment,
            professional_from,
            onboarding_date,
            branches,
            branch_names:branchesNames,
            courses,
            course_names: coursesNames,
            cities,
            city_names: cityNames,
            roles_array: roles_array,
            role_names: rolesName,
            status: "Active",
            recruiter
          },
          true
        );
    setChecked(status)
    }
  };

  const forNewUser = async () => {
    setIsLoading(true);
    const { branch_names, course_names, city_names, role_names, confirm_password, ...rest }:any = values;
    const body: any = {
      ...rest
    };
    const dataResponse = await (id === "add-user" ? CreateNewUser(body) : updateUser(isId, body));
    if (dataResponse) {
      navigate(`/${routeNameForHome}/${routeNameForSubSection}`);
      toast.success(isEdit ? 'User Updated Successfully' : 'User Created Successfully')
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: add_User,
    validationSchema: () => AddUserValidation(isEdit),
    onSubmit: () => {
      forNewUser()
    },
  });
  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue }: any = formik;

  useEffect(() => {
    (async () => {
      if ((signInData?.data?.length>0)&&(values.cities?.length > 0)) {
        const readData: any = await createUserPreLoadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
        const filterBranch =
          readData?.branches?.filter(
            (branch: any) => values.cities.includes(branch.city?.id)
          );
        setBranchData(filterBranch);
        const filterNames = filterBranch && filterBranch.map((branch: any) => branch.branch_name);
        setBranchNames(filterNames);
      }
    })();
  }, [values.city_names]);

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <div className=" w-[95%] mx-auto bg-white rounded">
          <div className="text-left border-b-2 p-2">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "add-user" ? "Create User" : "Edit User Information"}
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="flex flex-col w-10/12 mx-auto py-12 space-y-12"
            >
            <UserInputs 
            formik={formik} 
            showChangePassword={showChangePassword} 
            isEdit={isEdit} 
            mode={mode} 
            cityNames={cityNames} 
            cityData={cityData} 
            branchNames={branchNames} 
            branchData={branchData} 
            roleNames={roleNames} 
            roleData={roleData} 
            courseNames={courseNames} 
            courseData={courseData} 
            scheduleData={scheduleData} 
            CourseModeData={CourseModeData} 
            languageOptions={languageOptions} 
            suitableOptions={suitableOptions} 
            availabilityOptions={availabilityOptions} 
            scheduleOptions={scheduleOptions}
            modeOptions={modeOptions}
            />
            <div className="relative">
              <StatusButton
                type={"submit"}
                buttonText={id === "add-user" ? "Add New User" : "Update User"}
                CheckValue={CheckValue}
                checked={checked}
                buttonWidth={"w-full"}
                show={true}
              />
              {isEdit && (
                <p
                  className="absolute right-12 top-0 text-[#036AD1] font-[500] font-Roboto cursor-pointer opacity-70"
                  onClick={() => setShowChangePassword(!showChangePassword)}
                >
                  Change Password
                </p>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
