import { useState } from "react";
import toast from "react-hot-toast";
import { useAppSelector, useAppDispatch } from "../../..";
import { createCancellationSession } from "../../../functions/admin/Course Progress/courseProgress";
import { toggleBulkUpdateCancellation } from "../../../Redux/Reducers/cancellationModal";

export const BulkCancelModal = ({ selectedBatch }: any) => {
  const dispatch = useAppDispatch();
  const data = Array.from(selectedBatch);
  const statusOptions = ["By FITA", "By Student", "By Trainer"]
  const [selectedStatus, setSelectedStatus] = useState("By FITA");
  const [comment, setComment] = useState("");
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const isProceedDisabled = !comment.trim();
  let cancellationIds:any = data?.map((ft: any) => ft?.id);

    const sendCancellationRequests = async(ids: number[], bodyData: any) => {
        let allSessionsSuccessful = true;

        for (const id of ids) {
            const body = {
            ...bodyData,
            cancellation_id: id,
            };

            try {
            const response = await createCancellationSession(body);
            if (!response) {
                allSessionsSuccessful = false;
                break;
            }
            } catch (error) {
            console.error("Error for cancellation_id", id, ":", error);
            allSessionsSuccessful = false;
            break;
            }
        }

        if (allSessionsSuccessful) {
            dispatch(toggleBulkUpdateCancellation(false));
        } else {
            toast.error('Something went wrong');
        }
    }

  return (
    <div className="w-[50%] min-h-[500px] py-2 flex flex-col justify-center bg-blue-50">
      <div className="flex flex-col justify-center items-center">
        <div className="mb-6 w-[70%]">
          <label
            htmlFor="status"
            className="block text-md font-medium text-gray-700"
          >
            Status
          </label>
          <select
            id="status"
            name="status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="w-full mt-1 border border-slate-300 rounded-md h-10 p-2"
          >
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-6 w-[70%]">
          <label
            htmlFor="comment"
            className="block text-md font-medium text-gray-700"
          >
            Comment
          </label>
          <textarea
            id="comment"
            name="comment"
            placeholder="Add your comment here"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className={`w-full mt-1 border border-slate-300 rounded-md h-24 p-2`}
          />
        </div>

        <div className="w-[70%] flex justify-end gap-2">
          <button
            type="button"
            className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white ${
              isProceedDisabled
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-600 hover:bg-red-500"
            }`}
            onClick={() => sendCancellationRequests(cancellationIds, {
                added_by: userDetails,
                comment,
                status: selectedStatus
            })}
            disabled={isProceedDisabled}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};
