import { Components } from './ModuleImports';
import certificateSample from "../../../Assets/certificateSample.png";
import dropArrow from "../../../Assets/dropArrow.svg";
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';

export const BasicInfo = ({ batch, progress, batchStat }: any) => {
    const { ReadData, BuildJSON }: any = Components;
    const { routePath }: any = useSharedLogic();
    const BasicInfo = BuildJSON(batch, routePath)
    return (
      <div className="space-y-4 w-11/12 mx-auto py-6 flex">
        <div className="w-1/2 space-y-3">
          {BasicInfo.map((read: any) => {
            return <ReadData head={read.head} body={read.body} />;
          })}
        </div>
        <div className="w-1/2 space-y-4">
          <Certificate batch={batch} />
          <CourseProgress progress={progress} />
          <ReadData
            head={
              routePath &&
              (routePath === "cal_trainer_pay" ||
                routePath === "process_trainer_pay")
                ? "Batch Payment Status"
                : "End Date"
            }
            body={
              routePath &&
              (routePath === "cal_trainer_pay" ||
                routePath === "process_trainer_pay")
                ? batch?.batch_payment_status !== ""
                  ? batch?.batch_payment_status
                  : batchStat !== ""
                  ? batchStat
                  : "-"
                : batch?.end_date
                ? batch?.end_date
                : "-"
            }
          />
        </div>
      </div>
    );
}

// batchStat --> Calculated && process_trainer_pay

const Certificate = ({batch}:any) => {
    const { ReadData }: any = Components;
    return(
        <div className='w-[60%]'>
            <ReadData head={'Course'} body={batch?.course?.course_name} />
            <img src={certificateSample} alt="Sample" />
        </div>
    )
}

const CourseProgress = ({progress, batch}:any) => {
    const { Progressbar, ReadData, useSharedLogic, createSearchParams }: any = Components;
    const { navigate, params, routeNameForHome, routeNameForSubSection, id, mode, routePath }: any = useSharedLogic();
    const studentCourseProgress = (course_id:any) => {
        navigate({
            pathname: `/${routeNameForHome}`,
            search: createSearchParams({id:`${id}`,mode:`${mode}`,courseId:`${1}`}).toString()
        });
    }
    return (
        <div className='w-[60%] flex items-center justify-around shadow-md shadow-[#00000029] py-1.5 rounded-[4px]'>
            <ReadData head={'Batch Progress'} body={`${progress?.progress_in_hrs?progress?.progress_in_hrs:0}/${progress?.total_duration?progress?.total_duration:0} Hrs`} />
            <Progressbar
                percentage={progress?.batch_progress_in_percent?(Number(progress?.batch_progress_in_percent)?.toFixed(1)):0}
                mainDivStyle={"w-[40px] h-[40px]"}
            />
            {/* <img src={dropArrow} alt={'Arrow'} className='cursor-pointer' /> */}
        </div>
    )
}