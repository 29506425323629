import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../Elements/Loader/Loader';
import { CardLayoutTwo } from '../../Elements/Cards/CardLayoutTwo';
import { BasicInfo } from './BasicInfo';
import { CourseDetails } from './CourseDetails';
import { PaymentSummary } from './PaymentSummary';
import { PaymentDetails } from './PaymentDetails';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { useFormik } from 'formik';
import {
  IssueRefundValues,
  AdjustPaymentValues,
} from "../../Formik/InitialValues";
import {
  issueRefund,
  adjustPayment
} from "../../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment";
import { onKeyDown } from '../../../Services/CommonFunctions';
import { useAppSelector } from '../../..';
import { TableComponent } from '../../Elements/Table/TableComponent';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { getRefundTableData, getAdjustPaymentTableData } from '../../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment';
import { refundData } from '../../../utils/json/refundTable';
import { adjustPaymentData } from '../../../utils/json/adjustPaymentTable';
import { IssueRefund } from "./IssueRefund/IssueRefund";
import { AdjustPayment } from "./AdjustPayment/AdjustPayment";
import { RefundTo } from './IssueRefund/RefundTo';
import { AdjustFor } from './AdjustPayment/AdjustFor';

export const IssueRefundOrAdjustPayment = () => {
  const { id, mode }:any = useSharedLogic()
  const [{ signInData }, {userDetails}]:any = useAppSelector(({ signInInput, login }: any) => [signInInput, login]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalVal, setTotalVal] = useState(0);
  const [basicInfoDetails, setBasicInfoDetails] = useState({}as any);
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [queue, setQueue] = useState([
    {
      approval: "Issue Refund",
      len: null
    },
    {
      approval: "Past Refund",
      len: null
    },
        {
      approval: "Adjust Payment",
      len: null
    },
        {
      approval: "Past Adjust Payment",
      len: null
    }
  ] as any);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        const refundResponse: any = await getRefundTableData(signInData?.data[0]);
        const adjustPaymentResponse: any = await getAdjustPaymentTableData(signInData?.data[0]);
        const refundLength = refundResponse?.length;
        const adjustLength = adjustPaymentResponse?.length;
        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: refundLength,
          },
          {
            ...prevQueue[1],
            len: refundLength,
          },
          {
            ...prevQueue[2],
            len: adjustLength,
          },
          {
            ...prevQueue[3],
            len: adjustLength,
          }
        ]);
      }
    })()
  }, [])

  const submitFunctionForIssueRefund = async() => {
    setIsLoading(true)
    const { branch_based_GST, courseId, course, studentUid, student, amount, user_id, branch_id, ...rest }:any = values;
    const body = {
      amount: amount?.replace(/,/g, ''),
      user_id: userDetails,
      ...rest
    };
    const postResponse = await issueRefund(body);
    if (postResponse) {
      formik.resetForm();
      setBasicInfoDetails({});
    }
    setIsLoading(false)
  }
  
  const submitFunctionForAdjustPayment = async() => {
    setIsLoading(true)
    const { branch_based_GST, amount, reason, from_training_id, to_training_id, mode_of_payment, from_branch_id, to_branch_id, student_training_id, ...rest }: any = values;
    if(to_training_id!=="") {
      const body:any = {
        amount: amount?.replace(/,/g, ''),
        user_id: userDetails,
        reason,
        from_branch_id,
        to_branch_id,
        from_training_id,
        to_training_id
      }
      const postResponse: any = await adjustPayment(body);
      if (postResponse) {
        formik.resetForm();
        setBasicInfoDetails({})
      }
    }
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues:
      id === "issue_refund" ? IssueRefundValues : AdjustPaymentValues,
    validationSchema: "",
    onSubmit: () => {
      id === "issue_refund"
        ? submitFunctionForIssueRefund()
        : submitFunctionForAdjustPayment();
    },
  });

  const {
    values,
    handleSubmit
  } = formik;

return (
  <>
    {isLoading ? (
      <Loader widthFix={"w-10/12"} />
    ) : (
      <div className="space-y-2">
        <div className="w-[95%] mx-auto space-y-3">
          <h1 className="text-[30px] text-white font-Roboto font-medium">
            Issue Refund / Adjust Payment
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )} 
        </div>
        {id === "past_refund" && allowOnlyActions("view") && (
          <TableComponent
            TABLE_JSON={refundData}
            backEndCallParams={signInData?.data[0]}
          />
        )}
        {id === "past_adjust_payment" && allowOnlyActions("view") && (
          <TableComponent
            TABLE_JSON={adjustPaymentData}
            backEndCallParams={signInData?.data[0]}
          />
        )}
        {(id=== "issue_refund" || id==="adjust_payment") && (
          <CardLayoutTwo>
            <form
              onSubmit={handleSubmit}
              onKeyDown={onKeyDown}
              className="space-y-4"
            >
              <div className="text-left border-b-2 p-2 flex items-center justify-between">
                <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                  {id === "issue_refund" ? "Issue Refund" : "Adjust Payment"}
                </p>
              </div>
              {id === "issue_refund" && <IssueRefund formik={formik} setIsLoading={setIsLoading} setBasicInfoDetails={setBasicInfoDetails} />}
              {id === "adjust_payment" && <AdjustPayment formik={formik} setIsLoading={setIsLoading} setBasicInfoDetails={setBasicInfoDetails} />}
              {isLoading ? (
                <Loader widthFix={"w-10/12"} />
              ) : (
                  Object.keys(basicInfoDetails?.updatedStudentData || {})?.length !== 0 && (
                  <div>
                    <BasicInfo basicInfoDetails={basicInfoDetails} />
                    <div className="space-y-4">
                      <CourseDetails
                        id={id}
                        formik={formik}
                        mode={mode}
                        basicInfoDetails={basicInfoDetails}
                        setTotalVal={setTotalVal}
                        totalVal={totalVal}
                      />
                      <PaymentSummary
                        basicInfoDetails={basicInfoDetails}
                        totalVal={totalVal}
                      />
                      <PaymentDetails
                        formik={formik}
                        basicInfoDetails={basicInfoDetails}
                        totalVal={totalVal}
                          />
                      {id === "issue_refund" && <RefundTo formik={formik} basicInfoDetails={basicInfoDetails} />}
                      {id === "adjust_payment" && <AdjustFor formik={formik} basicInfoDetails={basicInfoDetails} setBasicInfoDetails={setBasicInfoDetails} />}
                    </div>
                  </div>
                )
              )}
            </form>
          </CardLayoutTwo>
        )}
      </div>
    )}
  </>
);
}

const QueueType = ({ queue, setQueue }: any) => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
    setSearchParams({
      id: lowercaseString?.replace(/ /g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${
              (index === 0 || index === 1 || index === 2) && "gap-8"
            }`}
            onClick={() => linkOnclick(que?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.approval}{" "}
                {(index===1 || index===3) && <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>}
              </p>
              {id === que?.approval?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {(index === 0 || index === 1 || index === 2) && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
};