import { toast } from "react-hot-toast";
import { AuthErrorMessages, isDBError } from "./Error/error";
import { SUPABASE_CLIENT } from "../Services/Supabase";
import { FetchData } from "./crud";

export const errorNotifier = (error: any) => {
    // console.log({ error });
    switch (true) {
        case error.name === "AuthApiError":
            AuthErrorHandler(error);
            break;
        case isDBError(error):
            DBErrorHandler(error);
            break;
        default:
            toast.error(error.details || error.message || error.name);
    }
    return false
}

export const DBErrorHandler = (error: any) => {
    let message;
    switch (error.code) {
        case "23505":
        message = error.details?.split("Key (")[1];
        
            message = message.split(")")[0] + " already exists";
            break;
        default:
            message = error.details||error.message
            break;
    }
    toast.error(message);

}


export const AuthErrorHandler = (error: any) => {
    let message;
    switch (error.message) {
        case "duplicate key value violates unique constraint \"users_email_partial_key\"":
        case "A user with this email address has already been registered":
            message = AuthErrorMessages.emailExists;
            break;

        default:
            message = error.message
            break;
    }
    toast.error(message);
}



// export const DuplicationErrorHandler = async (table: string, key: string, checker: string | undefined) => {
//     if (!checker) {
//         return {isExist: false}
//     }
//     let errorObj = {
//         message: `${checker} already exists.`
//     }
//     const {data} = await FetchData(table, [key]);
//     const arrayOfValues: any = data?.map((obj: any) => obj?.category_name)
//     return {isExist: arrayOfValues.includes(checker), isError: errorObj}
// }

// export const UpdateChecker = async (table: string, key: string, id: string, checker: string | undefined) => {
//     const changedData : any= await FetchData(table, [key]).eq("id", id)
//     console.log(changedData.data[0]?.category_name, checker)
//     if (changedData.data[0]?.category_name !== checker) {
//         return DuplicationErrorHandler(table, key, checker)
//     }
//     return true
// }

type Time = {
  hours: number;
  minutes: number;
  period: 'am' | 'pm';
};

const parseTime = (time: string): Time => {
  const [hoursStr, minutesStr, period] = time.split(/[:\s]/);
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  return { hours, minutes, period: period.toLowerCase() as 'am' | 'pm' };
};


export const calculateTimeDifference = (startTime: any, endTime: any): any => {

    // Split the times into hours and minutes
  // const [hour1, minute1] = startTime.split(':').map(Number);
  // const [hour2, minute2] = endTime.split(':').map(Number);

  // // Convert times to milliseconds since midnight
  // const time1Milliseconds = hour1 * 60 * 60 * 1000 + minute1 * 60 * 1000;
  // const time2Milliseconds = hour2 * 60 * 60 * 1000 + minute2 * 60 * 1000;

  // // Calculate the hour difference
  // let hourDifference = Math.abs((time2Milliseconds - time1Milliseconds) / (60 * 60 * 1000));

  // // Ensure the result is positive
  // if (hourDifference > 12) {
  //   hourDifference = 24 - hourDifference;
  // }

  const start:any = parseTimeData(startTime);
  const end:any = parseTimeData(endTime);
  const differenceInMilliseconds = end - start;
  const hours = differenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
  // console.log(start, end, differenceInMilliseconds, hours, hours.toFixed(0), "TEST")
  return hours.toString(); // Return total hours with two decimal places
};

const parseTimeData = (timeStr:any) => {
  const [hours, minutes] = timeStr.split(":");
  const date = new Date();
  date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
  return date;
};

// const parseTime = (timeString: string) => {
  
//   const [time, period] = timeString?.split(' ');
//   const [hours, minutes] = time?.split(':');
    
//   return {
//     hours: parseInt(hours),
//     minutes: parseInt(minutes),
//     period: period.toLowerCase()
//   };
// }

// export const calculateTimeDifference = (startTime: any, endTime: any): any => {

//     const start = parseTime(startTime);
//     const end = parseTime(endTime);
    
//   let minutesDifference = end.minutes - start.minutes;
//   let hoursDifference = end.hours - start.hours;

//   // Handle negative time difference
//   if (minutesDifference < 0) {
//     minutesDifference += 60;
//     hoursDifference--;
//   }

//   // Convert AM/PM to 24-hour format
//   if (start.period === 'pm' || end.period === "pm") {
//     hoursDifference += 12;
//   }

//   return hoursDifference + minutesDifference / 60;
// }

export const calculatePercentage = (progress_duration: any, total_duration: any) => {
  const progress_percent = ((Number(progress_duration) / Number(total_duration)) * 100)===0?"0":((Number(progress_duration) / Number(total_duration)) * 100).toFixed(1);
  return progress_percent;
}

export const percentageBasedOnRange = (percentage:any) => {
  if (percentage <= 10) return "0 - 10%";
  else if (percentage <= 20) return "10 - 20%";
  else if (percentage <= 30) return "20 - 30%";
  else if (percentage <= 40) return "30 - 40%";
  else if (percentage <= 50) return "40 - 50%";
  else if (percentage <= 60) return "50 - 60%";
  else if (percentage <= 70) return "60 - 70%";
  else if (percentage <= 80) return "70 - 80%";
  else if (percentage <= 90) return "80 - 90%";
  else if (percentage <= 100) return "90 - 100%";
  else return "Above 100%";
};

// export const percentageBasedOnRange = (percentage: any) => {
//   const roundedPercentage = Math.floor(percentage / 10);
//   return `${roundedPercentage * 10}-${Math.min(roundedPercentage * 10 + 10, 100)}%`;
// };

// export const discountBasedOnRange = (discount: any) => {
//   const roundedDiscount = Math.floor(discount);
//   return `${roundedDiscount}-${Math.min(roundedDiscount + 1, 15)}%`;
// };

export const discountBasedOnRange = (percentage:any) => {
  if (percentage <= 1) return "0 - 1%";
  else if (percentage <= 2) return "1 - 2%";
  else if (percentage <= 3) return "2 - 3%";
  else if (percentage <= 4) return "3 - 4%";
  else if (percentage <= 5) return "4 - 5%";
  else if (percentage <= 6) return "5 - 6%";
  else if (percentage <= 7) return "6 - 7%";
  else if (percentage <= 8) return "7 - 8%";
  else if (percentage <= 9) return "8 - 9%";
  else if (percentage <= 10) return "9 - 10%";
  else if (percentage <= 11) return "10 - 11%";
  else if (percentage <= 12) return "11 - 12%";
  else if (percentage <= 13) return "12 - 13%";
  else if (percentage <= 14) return "13 - 14%";
  else return "14 - 15%";
};

export function getTimeDifferenceInHours(startTime: string, endTime: string): number {
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);

  const diffMs = end.getTime() - start.getTime();
  const diffHours = diffMs / (1000 * 60 * 60); // Convert milliseconds to hours

  return diffHours;
}

export const calculateDayDifference = (plannedDateOfCompletion: string, currentDate: string, scheduledDays: string[]) => {
  // Convert dates to Date objects
  const plannedDate:any = new Date(plannedDateOfCompletion);
  const currentDateObj:any = new Date(currentDate);

  // Calculate the initial difference in days
  let dayDifference = Math.ceil((currentDateObj - plannedDate) / (1000 * 60 * 60 * 24));

  // Adjust for scheduled days
  scheduledDays.forEach((dayOfWeek) => {
    let scheduledDate = new Date(plannedDate);
    while (scheduledDate <= currentDateObj) {
      if (scheduledDate.getDay() === getDayOfWeekIndex(dayOfWeek)) {
        dayDifference--;
      }
      scheduledDate.setDate(scheduledDate.getDate() + 1);
    }
  });

  return dayDifference;
};

const getDayOfWeekIndex = (dayOfWeek: string) => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return daysOfWeek.indexOf(dayOfWeek);
};

const sortPercentageRanges = (ranges:any) =>{
    return ranges.sort((a:any, b:any) => {
        const aLowerBound = parseInt(a.split(' - ')[0]);
        const bLowerBound = parseInt(b.split(' - ')[0]);

        // Handle 'Above 100%' case
        if (a === 'Above 100%') {
            return 1;
        } else if (b === 'Above 100%') {
            return -1;
        }

        return aLowerBound - bLowerBound;
    });
}

export const filterAndSortRanges = (ranges:any) => {
  const filteredRanges = ranges.filter((range:any) => range !== 'N/A' && range.trim() !== '');
  return sortPercentageRanges(filteredRanges);
}

export const calculateGstInfo = (fee:any, val:any) => {
  return fee !== null
    ? typeof fee === "number"
      ? parseFloat(val?.toFixed(2))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : Number(val)
          ?.toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0
}

export const filterContinuousDays = (data: any) => {
  // Step 1: Sort data by batch_code and date
  const sortedData = data.sort((a:any, b:any) => {
    if (a.batch_code === b.batch_code) {
      const dateA = new Date(a.date.split("-").reverse().join("-"));
      const dateB = new Date(b.date.split("-").reverse().join("-"));
      return dateA.getTime() - dateB.getTime();
    }
    return a.batch_code.localeCompare(b.batch_code);
  });

  // Step 2: Filter out continuous days
  const result: any = [];
  for (let i = 0; i < sortedData.length - 1; i++) {
    const current = sortedData[i];
    const next = sortedData[i + 1];

    // Check if the batch code is the same
    if (current.batch_code === next.batch_code) {
      const currentDate = new Date(current.date.split("-").reverse().join("-"));
      const nextDate = new Date(next.date.split("-").reverse().join("-"));

      // Check if dates are consecutive
      const diffInTime = nextDate.getTime() - currentDate.getTime();
      const diffInDays = diffInTime / (1000 * 60 * 60 * 24);

      if (diffInDays === 1) {
        result.push(current, next);
      }
    }
  }

  // Step 3: Remove duplicates (since continuous days will result in pairs)
  return result.filter((item:any, index:number, self:any) =>
    index === self.findIndex((t:any) => t.date === item.date && t.batch_code === item.batch_code)
  );
}
