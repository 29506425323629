// import { useState, useEffect } from "react";
// import { DateRange, DateRangePicker, DateRangeProps, Range } from "react-date-range";
// import { addDays, format, isWeekend } from 'date-fns';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import './TableWithDualCalendar.css'; // custom style for the calendar
// import './TableWithDualCalendarList.css'; // custom style for the list
// import { ClipLoader } from 'react-spinners';
// import { dateFormat } from "../../../Services/CommonFunctions";
// import dropDownIcon from "../../../Assets/dropDownIcon.svg";
// import { setTransaction } from "../../../Redux/Reducers/transactionIDS";
// import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
// import { useAppSelector, useAppDispatch } from "../../..";
// import { SummaryForDT } from "./SummaryForDT";

// interface TableData {
//   id: number;
//   branch_name: string;
//   city_name: string;
//   student_name: string;
//   student_number: string;
//   course_name: string;
//   created_at: string;
//   student_email: string;
//   paid_amount: number;
//   mode_of_payment: string;
//   is_verified?: boolean;
// }


// interface TableWithDualCalendarProps {
//   data: {
//     id: number;
//     name: string;
//     age: number;
//     city: string;
//     date: string;
//   }[];
//   verifiedDates: string[];
//   setCurrentVerifiedData: any;
//   isLoading: any;
//   setIsLoading:any;
//   handleDateRangeForFilter: any;
//   selectedRange: any;
// }

// export const VerifiedTableEntries: React.FC<TableWithDualCalendarProps> = ({ data, verifiedDates, setCurrentVerifiedData, isLoading, setIsLoading, handleDateRangeForFilter, selectedRange }) => {
//   const [filter, setFilter] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);
//   const [selectedBranch, setSelectedBranch] = useState('All Branches');
//   const itemsPerPage = 10;

//    // Get unique branches from data
//   const branches = ['All Branches', ...Array.from(new Set(data.map(item => item.branch_name)))];
    
//   // Calculate the index range for the current page
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   const handleFilterChange = (e: any) => {
//     // console.log(e.target.value, "e.target.value");
//     const enterValue = e.target.value.toString();
//     const inputValue = enterValue?.toLowerCase();
//     setFilter(inputValue);

//     const filteredResults = data && data?.filter((item: any) => {
//       // console.log(item, "item");
//         const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
//         const city_name = item.city_name ? item.city_name.toLowerCase() : '';
//         const student_name = item.student_name ? item.student_name.toString() : '';
//         const student_number = item.student_number ? item.student_number.toLowerCase() : '';
//         const course_name = item.course_name ? item.course_name.toLowerCase() : '';

//         return (
//             branch_name.includes(inputValue) ||
//             city_name.includes(inputValue) ||
//             student_name.includes(inputValue) ||
//             student_number.includes(inputValue) ||
//             course_name.includes(inputValue)
//         );
//     });
//     setCurrentVerifiedData(filteredResults);
// };

    
//   const handlePageChange = (page:any) => {
//     setCurrentPage(page);
//   };

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

//   const customDayContent = (day: any) => {
//     let extraDot = null;
//     if (verifiedDates?.includes(dateFormat(day, "DD-MM-YYYY"))) {
//         extraDot = (
//         <div
//             style={{
//             height: "8px",
//             width: "8px",
//             borderRadius: "100%",
//             background: "red",
//             position: "absolute",
//             top: 2,
//             right: 2,
//             }}
//         />
//         )
//     }
//     return (
//         <div className={``}>
//         {extraDot}
//         <span className={``}>{format(day, "d")}</span>
//         </div>
//     )
// }

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (endIndex < data.length) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("click", handlDropOpenClose);
//     return () => {
//         document.removeEventListener("click", handlDropOpenClose);
//     }
//   },[])

//   const handlDropOpenClose = (event: any) => {
//     if (
//       (event?.target?.localName === "div" &&
//         event?.target?.dataset?.elementname !== "daily_transaction") ||
//       (event?.target?.localName === "input" &&
//         event?.target?.id !== "daily_transaction") ||
//       (event?.target.localName === "button" &&
//         event?.target.parentNode?.parentNode?.localName !== "div") ||
//       (event?.target.localName === "p" &&
//         event?.target.parentNode?.parentNode?.parentNode?.localName !== "div")
//     ) {
//       setIsOpen(false);
//     }
//   }

//   return (
//     <div className="flex flex-col w-11/12 mx-auto space-y-4">
//       <div className="bg-white rounded-[3px] py-2 shadow-lg">
//         <div className="flex items-start p-4">
//           <div
//             className={`relative w-[200px] mr-4 mb-3 rounded-[2px] p-[2px] bg-[#F8F8F8]`}
//             data-elementname={"daily_transaction"}
//           >
//             <div
//               className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070]"
//               onClick={() => setIsOpen(!isOpen)}
//               data-elementname={"daily_transaction"}
//             >
//               <p
//                 className="shrinkToTwoLines  shrinkToOneLine"
//                 data-elementname={"daily_transaction"}
//               >
//                 {/* {startDate && endDate
//                   ? `${startDate} - ${endDate}`
//                   : "Date"} */}
//                 Date
//               </p>
//               <img
//                 src={dropDownIcon}
//                 data-elementname={"daily_transaction"}
//                 className={`${isOpen ? "rotate-180" : ""} transition`}
//                 alt="dropDownIcon"
//               />
//             </div>
//             {isOpen && (
//               <div
//                 data-elementname={"daily_transaction"}
//                 className="absolute shadow-lg top-11 left-0 z-[2] bg-white"
//               >
//                 <DateRangePicker
//                   onChange={item => handleDateRangeForFilter(item.selection)}
//                   // showSelectionPreview={true}
//                   moveRangeOnFirstSelection={false}
//                   months={2}
//                   ranges={selectedRange}
//                   direction="horizontal"
//                   dayContentRenderer={customDayContent}
//                   className={"w-1/2"}
//                   // ariaLabels={{
//                   //   dateInput: {
//                   //     selection1: { startDate: "start date input of selction 1", endDate: "end date input of selction 1" },
//                   //     selection2: { startDate: "start date input of selction 2", endDate: "end date input of selction 2" }
        
//                   //   },
//                   //   monthPicker: "month picker",
//                   //   yearPicker: "year picker",
//                   //   prevButton: "previous month button",
//                   //   nextButton: "next month button",
//                   // }}
//                 />
//               </div>
//             )}
//           </div>
//           {/* <input
//             type="text"
//             placeholder="Filters"
//             className="p-2 border rounded mr-2 w-[200px] bg-[#F8F8F8]"
//             value={filter}
//             onChange={handleFilterChange}
//           /> */}
//         </div>
//         <Table
//           data={data}
//           handlePrevPage={handlePrevPage}
//           handleNextPage={handleNextPage}
//           handlePageChange={handlePageChange}
//           currentData={currentData}
//           currentPage={currentPage}
//           itemsPerPage={itemsPerPage}
//           onClick={() => setIsOpen(!isOpen)}
//           isLoading={isLoading}
//         />
//       </div>
//       <SummaryForDT setIsLoading={setIsLoading} />
//     </div>
//   );
// };

import { useState, useEffect } from "react";
import { DateRange, DateRangePicker, DateRangeProps, Range } from "react-date-range";
import { addDays, format, isWeekend } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './TableWithDualCalendar.css';
import './TableWithDualCalendarList.css';
import { ClipLoader } from 'react-spinners';
import { dateFormat } from "../../../Services/CommonFunctions";
import dropDownIcon from "../../../Assets/dropDownIcon.svg";
import { setTransaction } from "../../../Redux/Reducers/transactionIDS";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useAppSelector, useAppDispatch } from "../../..";
import { SummaryForDT } from "./SummaryForDT";

// Updated interface to match your data structure
interface TableData {
  id: number;
  branch_name: string;
  city_name: string;
  student_name: string;
  student_number: string;
  course_name: string;
  created_at: string;
  student_email: string;
  paid_amount: number;
  mode_of_payment: string;
  is_verified?: boolean;
}

interface TableWithDualCalendarProps {
  data: TableData[];
  verifiedDates: string[];
  setCurrentVerifiedData: (data: TableData[]) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  handleDateRangeForFilter: (range: any) => void;
  selectedRange: any;
}

const VerifiedTableEntries: React.FC<TableWithDualCalendarProps> = ({ 
  data, 
  verifiedDates, 
  setCurrentVerifiedData, 
  isLoading, 
  setIsLoading, 
  handleDateRangeForFilter, 
  selectedRange 
}) => {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('All Branches');
  const itemsPerPage = 10;

  // Get unique branches from data
  const branches = ['All Branches', ...Array.from(new Set(data.map(item => item.branch_name)))];

  // Filter data based on selected branch and search filter
  const getFilteredData = () => {
    return data.filter(item => {
      const matchesBranch = selectedBranch === 'All Branches' || item.branch_name === selectedBranch;
      
      if (!filter) return matchesBranch;

      const searchTerm = filter.toLowerCase();
      const branch_name = item.branch_name?.toLowerCase() || '';
      const city_name = item.city_name?.toLowerCase() || '';
      const student_name = item.student_name?.toString().toLowerCase() || '';
      const student_number = item.student_number?.toLowerCase() || '';
      const course_name = item.course_name?.toLowerCase() || '';

      return matchesBranch && (
        branch_name.includes(searchTerm) ||
        city_name.includes(searchTerm) ||
        student_name.includes(searchTerm) ||
        student_number.includes(searchTerm) ||
        course_name.includes(searchTerm)
      );
    });
  };

  const filteredData = getFilteredData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handleBranchSelect = (branch: string) => {
    setSelectedBranch(branch);
    setIsBranchDropdownOpen(false);
    setCurrentPage(1);
    
    const filteredResults = getFilteredData();
    setCurrentVerifiedData(filteredResults);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    setFilter(inputValue);
    setCurrentPage(1);
    
    const filteredResults = getFilteredData();
    setCurrentVerifiedData(filteredResults);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const customDayContent = (day: any) => {
    let extraDot = null;
    const formattedDate = dateFormat(day, "DD-MM-YYYY");
    if (verifiedDates?.includes(formattedDate)) {
      extraDot = (
        <div
          style={{
            height: "8px",
            width: "8px",
            borderRadius: "100%",
            background: "red",
            position: "absolute",
            top: 2,
            right: 2,
          }}
        />
      );
    }
    return (
      <div>
        {extraDot}
        <span>{format(day, "d")}</span>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Handle date picker dropdown
      const target = event.target as HTMLElement;
      if (
        target.localName === "div" &&
        target.dataset?.elementname !== "daily_transaction"
      ) {
        setIsOpen(false);
      }

      // Handle branch dropdown
      const branchDropdown = document.querySelector('.branch-filter');
      if (branchDropdown && !branchDropdown.contains(event.target as Node)) {
        setIsBranchDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col w-11/12 mx-auto space-y-4">
      <div className="bg-white rounded-[3px] py-2 shadow-lg">
        <div className="flex items-start p-4">
          {/* Date Filter */}
          <div
            className={`relative w-[200px] mr-4 mb-3 rounded-[2px] p-[2px] bg-[#F8F8F8]`}
            data-elementname="daily_transaction"
          >
            <div
              className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070]"
              onClick={() => setIsOpen(!isOpen)}
              data-elementname="daily_transaction"
            >
              <p className="shrinkToTwoLines shrinkToOneLine" data-elementname="daily_transaction">
                Date
              </p>
              <img
                src={dropDownIcon}
                data-elementname="daily_transaction"
                className={`${isOpen ? "rotate-180" : ""} transition`}
                alt="dropDownIcon"
              />
            </div>
            {isOpen && (
              <div
                data-elementname="daily_transaction"
                className="absolute shadow-lg top-11 left-0 z-[2] bg-white"
              >
                <DateRangePicker
                  onChange={item => handleDateRangeForFilter(item.selection)}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedRange}
                  direction="horizontal"
                  dayContentRenderer={customDayContent}
                  className="w-1/2"
                />
              </div>
            )}
          </div>

          {/* Branch Filter */}
          <div className="relative w-[200px] mr-4 mb-3 rounded-[2px] p-[2px] bg-[#F8F8F8] branch-filter">
            <div
              className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070]"
              onClick={() => setIsBranchDropdownOpen(!isBranchDropdownOpen)}
            >
              <p className="shrinkToOneLine">
                {selectedBranch}
              </p>
              <img
                src={dropDownIcon}
                className={`${isBranchDropdownOpen ? "rotate-180" : ""} transition`}
                alt="dropDownIcon"
              />
            </div>
            {isBranchDropdownOpen && (
              <div className="absolute shadow-lg top-11 left-0 z-[2] bg-white w-full">
                <div className="max-h-[200px] overflow-y-auto">
                  {branches.map((branch, index) => (
                    <div
                      key={index}
                      className="px-2 py-2 hover:bg-[#F8F8F8] cursor-pointer"
                      onClick={() => handleBranchSelect(branch)}
                    >
                      {branch}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Search Filter */}
          {/* <input
            type="text"
            placeholder="Search..."
            className="p-2 border rounded mr-2 w-[200px] bg-[#F8F8F8]"
            value={filter}
            onChange={handleFilterChange}
          /> */}
        </div>

        <Table
          data={filteredData}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          handlePageChange={handlePageChange}
          currentData={currentData}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onClick={() => setIsOpen(!isOpen)}
          isLoading={isLoading}
        />
      </div>
      <SummaryForDT setIsLoading={setIsLoading} />
    </div>
  );
};

const Table = ({ isLoading, onClick, data, handlePrevPage, handleNextPage, handlePageChange, currentData, currentPage, itemsPerPage}:any) => {
  const dispatch = useAppDispatch();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { selectedIds } = useAppSelector(({ transaction }: any) => transaction);
  const mapIds = selectedIds?.transIds?.map((item: any) => item?.id)
  const TableHeader = `text-start px-2 py-5 font-Roboto font-[500]`;
  const TableBody = `text-start px-2 break-words py-4 font-Roboto font-[400]`;
  
    const navigateSummaryId = (item:any) => {
      if (mapIds.includes(item?.id)) {
        dispatch(setTransaction({transIds: selectedIds?.transIds?.filter((ft:any)=> ft.id !== item?.id)}));
      } else {
        dispatch(
          setTransaction({
            transIds: [
              ...selectedIds?.transIds,
              { id: item?.id, mode_of_payment: item?.mode_of_payment, amount: item?.paid_amount },
            ],
          })
        );
      }
    };
  
    return (
      <div
        className="relative overflow-x-auto scrollbar-show"
        data-elementname={"daily_transaction"}
        onClick={onClick}
      >
        {isLoading ? (
          <div className="flex items-center justify-center min-h-[400px]">
            <ClipLoader color="#036AD1" />
          </div>
        ) : (
          <div className="w-full">
            <table className="table-fixed w-full">
              <thead className="bg-[#EFF7FF]">
                <tr>
                  <th className={`w-[20px] text-start px-2 pl-4`}>#</th>
                  <th className={`${TableHeader} w-[100px] `}>
                    Transaction Date
                  </th>
                  <th className={`${TableHeader} w-[100px]`}>Student Name</th>
                  <th className={`${TableHeader} w-[100px]`}>Phone Number</th>
                  <th className={`${TableHeader} w-[100px]`}>Student Email</th>
                  <th className={`${TableHeader} w-[150px]`}>Course</th>
                  <th className={`${TableHeader} w-[100px]`}>Branch</th>
                  <th className={`${TableHeader} w-[90px]`}>Amount</th>
                  <th className={`${TableHeader} w-[90px]`}>Mode of payment</th>
                  <th
                    className={`w-[90px] text-center px-2 py-5 font-Roboto font-[500]`}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {/* {currentData?.length === 0 ? (
                <div className="w-full border">
                  No Data Found!
                </div>
              ) : ( */}
              <tbody>
                {currentData &&
                  currentData?.map((item: any, index: number) => (
                    <tr
                      key={item.id}
                      className={`space-y-10 ${
                        index % 2 !== 0 ? "bg-[#F8FBFF]" : ""
                      }`}
                    >
                      <td className={`w-[20px] text-start px-2 pl-4`}>
                        {index + 1}
                      </td>
                      <td className={`${TableBody} w-[100px]`}>
                        {item.created_at}
                      </td>
                      <td className={`${TableBody} w-[100px]`}>
                        {item.student_name}
                      </td>
                      <td className={`${TableBody} w-[100px]`}>
                        {item.student_number}
                      </td>
                      <td className={`${TableBody} w-[100px]`}>
                        {item.student_email}
                      </td>
                      <td className={`${TableBody} w-[150px]`}>
                        {item.course_name}
                      </td>
                      <td className={`${TableBody} w-[100px]`}>
                        {item.branch_name}
                      </td>
                      <td className={`${TableBody} w-[90px] `}>
                        {item.paid_amount}
                      </td>
                      <td className={`${TableBody} w-[90px] `}>
                        {item.mode_of_payment}
                      </td>
                      {allowOnlyActions("edit") && (
                        <td
                          className="w-[120px] cursor-pointer"
                          onClick={() => navigateSummaryId(item)}
                        >
                          <p
                            // onClick={(event:any)=>{event.stopPropagation()}}
                            className={`${
                              mapIds?.includes(item?.id) || item?.is_verified
                                ? "bg-[#006E16] select-none"
                                : "bg-[#FF0303]"
                            } text-center text-[#fff] p-0.5 px-4 rounded-[3px] w-[75%] mx-auto font-Roboto font-[400]`}
                          >
                            {mapIds?.includes(item?.id) || item?.is_verified
                              ? "Verified"
                              : "Verify"}
                          </p>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
              {/* )} */}
            </table>

            <div className="flex justify-end mt-4">
              <button className="mx-2 p-2 border" onClick={(event:any)=>{
                 event.stopPropagation();
                handlePrevPage()}}>
                &lt; Prev
              </button>
              {Array.from(
                { length: Math.ceil(data.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index + 1}
                    className={`mx-2 p-2 border ${
                      currentPage === index + 1 ? "bg-gray-300" : "bg-white"
                    }`}
                    onClick={(event:any) => {
                      event.stopPropagation();
                      handlePageChange(index + 1)}}
                  >
                    {index + 1}
                  </button>
                )
              )}
                <button className="mx-2 p-2 border" onClick={(event:any) => {
                  event.stopPropagation();
                  handleNextPage()
                }}>
                Next &gt;
              </button>
            </div>
          </div>
        )}
      </div>
    );
}

export { VerifiedTableEntries };