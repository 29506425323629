import { resumeReviewDashboardTableData } from "../../functions/admin/HR Management/hr_management";
import { ViewEditDeleteActionComponent } from "../../Components/HR & Student Placement/ResumeReviewDashboard/ActionComponent";

export const resume_review_dashboard = {
    headers: [
        {
            name: "Date",
            apiKey: "created_at",
            headerClass: "w-[120px] text-center",
            className: "text-center",
        },
        {
            name: "Name",
            apiKey: "name",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start break-words px-2",
        },
        {
            name: "Phone Number",
            apiKey: "phone_number",
            headerClass: "w-[140px] text-center px-2",
            className: "w-[140px] text-center break-words px-2",
        },
        {
            name: "Branch",
            apiKey: "branch_name",
            headerClass: "w-[80px] text-start",
            className: "w-[80px] text-start break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "Resume Status",
            apiKey: "resume_status",
            headerClass: "w-[70px] text-center",
            className: "w-[70px] text-center break-words",
        },
        {
            name: "Action",
            apiKey: "companies",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent,
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center",
        },
    ],
    BackendFunctionCall:(params: any) => {
        return resumeReviewDashboardTableData(params);
    },
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            {
                name: "Ownership",
                apiKey: "ownership",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[200px] 2xl:w-[120px]",
            },
            {
                name: "City",
                apiKey: "city_name",
                isSearchNeeded: true,
                className: "w-[170px]",
            },
            {
                name: "Branch",
                apiKey: "branch_name",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
            {
                name: "Course",
                apiKey: "course_name",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
            {
                name: "Status",
                apiKey: "status",
                isSearchNeeded: true,
                className: "w-[140px]",
            },
            {
                name: "Resume Status",
                apiKey: "resume_status",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
        ],
        searchBox: [
            {
                matchFields: ["name", "phone_number"],
                searchKey: "student_names",
                name: "Search...",
                searchStyle: "w-[200px]",
            }
        ]
    },
};