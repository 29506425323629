import { useEffect } from 'react';
import { formatNumberWithCommas } from '../../../../Services/CommonFunctions';
import {
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent
} from "../../../../functions/admin/Students/students";
import { type } from 'os';

export const gst = (fee: any) => {
  let gstAmount = (fee * 9) / 100
  return parseFloat(gstAmount.toFixed(1))
}

export const totalGstCal = (fee:any) => {
  let withSGST = (fee * 9) / 100
  let withCGST = (fee * 9) / 100
  let total = parseInt(fee) + withCGST + withSGST;
  return parseFloat(total.toFixed(1))
}

export const ExistingCourseDetails = ({ values, courseData, paymentObject, branchDetails }:any) => {
  const headData = ['Course', 'Course Fee', 'Plus Fee', 'Final Course Fee'];

    return (
      <div className="space-y-4 w-full">
        <div className="rounded-[7px] shadow-md shadow-[#00000029] space-y-2 pb-4">
          <div className="flex items-center bg-[#EFF7FF] py-2 w-full">
            {headData.map((head: any, index: number) => (
              <p
                className={`text-[20px] font-Roboto font-[500] ${
                  index === 3 ? "text-center" : "text-start"
                } ${
                  index === 0
                    ? "w-[35%] pl-8"
                    : index === 3
                    ? "w-[40%] px-8"
                    : "w-[15%]"
                }`}
              >
                {head}
              </p>
            ))}
          </div>
          <div className="flex">
            <CourseInCourseDetails
              values={values}
            />
            <p className="w-[15%] px-6">
              {values.fee && <span className="px-1">&#8377;</span>}
              {values.fee?values.fee:''}
            </p>
            <p className="w-[15%] px-6">
              {values.additional_amount && <span className="px-1">&#8377;</span>}
              {values.additional_amount?values.additional_amount:''}
            </p>
            <TotalFee
              pay={values.fee}
              additional_fee={values.additional_amount}
              values={values}
              paymentObject={paymentObject}
              branchDetails={branchDetails}
            />
          </div>
        </div>
      </div>
    );
}

const CourseInCourseDetails = ({ values }: any) => {
    return (
      <div className="w-[35%] space-y-1 pl-8">
        <h1 className="text-[18px] text-[#707070] font-Roboto font-[500]">
          {values.course}
        </h1>
      </div>
    );
}

const TotalFee = ({ pay, additional_fee, values, paymentObject, branchDetails }: any) => {
  const payment = values.status === "Downgrade" ? Number(pay) - Number(additional_fee) : Number(pay) + Number(additional_fee)
  const TotalExclGST = feeAfterDiscountCalculation(payment);
  const Sgst = gstCalculationForStudent(
    paymentObject?.sendPercentageSGST,
    payment
  );
  const Cgst = gstCalculationForStudent(
    paymentObject?.sendPercentageCGST,
    payment
  );
  const Igst = gstCalculationForStudent(
    paymentObject?.sendPercentageIGST,
    payment
  );
  const TotalInclGST = totalGstCalculationForStudent(
    paymentObject?.check_feeType,
    paymentObject?.sendPercentage,
    payment
  );

  const mappingForTotal: any = [
    {
      left: "Total (Excl. GST)",
      right:
        payment !== null
          ? typeof pay === "number"
            ? parseFloat(TotalExclGST?.toFixed(1))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : Number(TotalExclGST)
                ?.toFixed(1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
    },
    branchDetails &&
      paymentObject?.check_feeType === "SGST" && {
        left: `SGST (${branchDetails?.sgst}%)`,
        right:
          payment !== null
            ? typeof payment === "number"
              ? parseFloat(Sgst?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : parseFloat(Number(Sgst)?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
    branchDetails &&
      paymentObject?.check_feeType === "SGST" && {
        left: `CGST (${branchDetails?.cgst}%)`,
        right:
          payment !== null
            ? typeof payment === "number"
              ? parseFloat(Cgst?.toFixed(1))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : Number(Cgst)
                  ?.toFixed(1)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0,
      },
    paymentObject?.check_feeType === "IGST" && {
      left: `IGST (${branchDetails?.igst}%)`,
      right:
        payment !== null
          ? typeof payment === "number"
            ? parseFloat(Igst?.toFixed(1))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : Number(Igst)
                ?.toFixed(1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
    },
    {
      left: "Total (Incl. GST)",
      right:
        payment !== null
          ? typeof payment === "number"
            ? parseFloat(TotalInclGST?.toFixed(1))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : parseFloat(Number(TotalInclGST)?.toFixed(1))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
    },
  ];

//   useEffect(() => {
//     const addValue = formatNumberWithCommas(totalGstCalculationForStudent(
//       paymentObject?.check_feeType,
//       paymentObject?.sendPercentage,
//       payment))
//     console.log(addValue, "addValue")
//     setFieldValue("total_fee", payment ? addValue : 0);
//   },[values.fee, values.additional_amount, branchDetails])

  return (
    <div className="w-[40%] flex flex-col justify-between">
      {mappingForTotal.map((text: any, index: number) => {
        return (
          <div key={index}
            className={`flex justify-around w-full ${
              index === 4 && "bg-[#EFF7FF] py-1.5"
            }`}
          >
            <p className={`w-[40%] text-end`}>{text.left}</p>
            <p className={`w-[20%]`}>
              {text.left && <span className="px-1">&#8377;</span>}
              {text.right}
            </p>
          </div>
        );
      })}
    </div>
  );
};