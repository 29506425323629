import { useState } from "react";
import { Components } from "./ModuleImports";
import certificateSample from "../../../Assets/certificateSample.png";
import dropArrow from "../../../Assets/dropArrow.svg";
import { Checkbox } from '../../Elements/Checkbox';
import selectedCheckbox from "../../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../../Assets/emptyCheckbox.svg";
import { updateBatchStatus } from "../../../functions/admin/Batches/Batches";

export const BasicInfo = ({ batchData, batchProgress }: any) => {
  const [checked, setChecked] = useState(false);
  const { ReadData, basicDetails }: any = Components;
  const data = basicDetails(batchData);

  const handleCheckValue = async () => {
    if (!checked === true && (batchData.status !== "Review" && batchData.status !== "Completed")) {
      await updateBatchStatus("Review", batchData?.id)
    }
    setChecked(!checked)
  }

    return (
      <div className="w-11/12 mx-auto flex">
        <div className="w-1/2 space-y-3">
          {data.map((data: any) => (
            <ReadData head={data.head} body={data.body} />
          ))}
        </div>
        <div className="w-1/2 space-y-4">
          <Certificate batchData={batchData} />
          <CourseProgress batchProgress={batchProgress} />
          <ReadData head={"End Date"} body={batchData?.end_date ? batchData?.end_date : "-"} />
          <div
            className={`flex items-center gap-2 cursor-pointer`}
            onClick={handleCheckValue}
          >
            <img
              src={(checked || batchData.status === "Review" || batchData.status === "Completed") ? selectedCheckbox : emptyCheckbox}
              alt="CheckType"
              className={`w-4 h-4`}
            />
            <label className={`text-[#707070] text-[17px] font-bold font-Roboto cursor-pointer`}>
              Is Batch Completed?
            </label>
          </div>
        </div>
      </div>
    );
}

const Certificate = ({ batchData }: any) => {
    const { ReadData }: any = Components;
    return(
        <div className='w-[60%]'>
            <ReadData head={'Course'} body={batchData?.course?.course_name?batchData?.course?.course_name:''} />
            <img src={certificateSample} alt="Sample" />
        </div>
    )
}

const CourseProgress = ({ batchProgress }: any) => {
  console.log(batchProgress, "batchProgress");
  const { ReadData, Progressbar }: any = Components;
  return (
    <div className="w-[60%] flex items-center justify-around shadow-md shadow-[#00000029] py-1.5 rounded-[4px]">
      <ReadData head={'Batch Progress'} body={`${batchProgress?.progress_in_hrs?batchProgress?.progress_in_hrs:0}/${batchProgress?.total_duration?batchProgress?.total_duration:0}`} />
      <Progressbar
        percentage={
          batchProgress?.batch_progress_in_percent?parseFloat(Number(batchProgress?.batch_progress_in_percent).toFixed(1)):0
        }
        mainDivStyle={"w-[40px] h-[40px]"}
      />
      {/* <img src={dropArrow} alt={"Arrow"} className="cursor-pointer" /> */}
    </div>
  );
};