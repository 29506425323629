
import { errorNotifier, percentageBasedOnRange } from "../../../commonHelper";
import { TableNames } from "../../../config/Tables"
import { FetchData, InsertData } from "../../../crud"
import { PaymentFollowUpInterface } from "./interface";
import { UserLogInterface } from "../../User Logs/interface";
import { submitUserLogs } from "../../User Logs/userLogs";
import { formatPendingBatchPayments } from "../helper";
import { calculatePaidCourseCompletion } from "../helper";

export const getPendingPaymentsTableData = async (UsersDetails: any) => {
    try {
        const { data: TrainingData, error } = await FetchData(TableNames.trainings,
            ["follow_up_date:payment_followups(follow_up_date)", "city_id", "branch_id", "course_id", "course:courses(course_name,videos)", "course_schedule",
                "student:students(name,phoneNumber)", "batch:batches(schedule,status,trainer:users(first_name,last_name,phone_number),course:courses(course_name,duration),sessions:sessions(*))",
                "status", "total_fee", "trainer:users(first_name,last_name,phone_number)", "balance_due",
                "crm:users(first_name,last_name,phone_number)", "id", "city:cities(city_name,id)", "branch:branches(id,branch_name, ownership)"])
            .gt("balance_due", 0).in("branch_id", UsersDetails?.branches);

        if (error) throw error;
        // console.log("Initial Training Data:", TrainingData);
        const { data: users, error: userError } = await FetchData(TableNames.users, ["first_name", "last_name", "courses", "cities", "branches", "user_type"])
        if (userError) throw userError;
        // console.log("Users Data:", users);
        const filterDropStudents = TrainingData?.filter((bt:any)=> bt?.status !== 'Drop')
        // console.log("Filtered Training Data (no Drops):", filterDropStudents);
        const check_CRM_User = filterDropStudents?.map((bt: any) => {
            const matchingUser: any = users?.find((dt: any) =>
                dt?.cities?.includes(bt?.city_id) && dt?.branches?.includes(bt?.branch_id) && dt?.courses?.includes(bt?.course_id) && dt?.user_type === "BC"
            );
            return matchingUser && {
                id: bt?.id,
                name: `${matchingUser?.first_name} ${matchingUser?.last_name}`
            };
            
        });
            
        const alteredData = filterDropStudents?.map((training: any) => {
        const crmUserResponse = check_CRM_User?.length>0 ? check_CRM_User?.filter((user:any)=> user?.id === training?.id).map((filteredUser: any) => filteredUser.name) : '';   
            return {
              ...training,
              follow_up_date: training?.follow_up_date?.follow_up_date,
              course_name: training?.course?.course_name,
              student_name: training?.student?.name,
              student_number: training?.student?.phoneNumber,
              schedule: training?.course_schedule,
              trainer_name:
                training?.batch === null
                  ? "-"
                  : (training?.batch?.trainer?.first_name || "") +
                    " " +
                    (training?.batch?.trainer?.last_name || ""),
              trainer_number:
                training?.batch === null
                  ? "-"
                  : training?.batch?.trainer?.phone_number,
              batch_status: training?.batch?.status,
              total_fee: Math.round(Number(training?.total_fee)),
              balance_due:
                Math.round(Number(training?.balance_due)) === 0
                  ? "0"
                  : Math.round(Number(training?.balance_due)),
              total_paid: Math.round(
                Number(training?.total_fee - training?.balance_due)
              ),
              crm_name: crmUserResponse?.[0],
              city_name: training?.city?.city_name,
              city_id: training?.city?.id,
              branch_name: training?.branch?.branch_name,
              ownership: training?.branch?.ownership,
              branch_id: training?.branch?.id,
              // payment_completion: Math.floor(((training?.total_fee - training?.balance_due) / training?.total_fee) * 100) + "%",
              payment_completion:
                training?.batch === null
                  ? "-"
                  : calculatePaidCourseCompletion(
                      training?.batch?.course?.duration,
                      training?.batch?.sessions
                    ),
              city: null,
              branch: null,
              batch: null,
              course: null,
              trainer: null,
              student: null,
              crm: null,
              progress_range: percentageBasedOnRange(
                training?.batch !== null
                  ? calculatePaidCourseCompletion(
                      training?.batch?.course?.duration,
                      training?.batch?.sessions
                    )
                  : "0"
              ),
            };});
        return alteredData;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getBatchPendingPaymentsTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.batches, [
          "city:cities(city_name,id)",
          "branch:branches(id,branch_name,ownership)",
          "follow_up_date:payment_followups(follow_up_date)",
            "id",
          "start_time",
          "end_time",
          "city_id",
          "branch_id",
          "batch_code",
          "course:courses(id, user_type)",
          "status",
          "batch_progress_in_hrs",
          "duration",
          "trainer:users(first_name,last_name,phone_number)",
          "trainings(total_fee,crm:users(first_name,last_name),transactions(paid_amount))",
        ]).in("branch_id", UsersDetails?.branches);
        if (error) throw error
        // console.log(data, "data")
        const filterDropStudents = data?.filter((fil: any) => fil?.status !== "Drop");
        return formatPendingBatchPayments(filterDropStudents);
    } catch (error) {
        return errorNotifier(error);
    }
}

export const addPaymentFollowUpDate = async (followUpData:PaymentFollowUpInterface) => {
    try {
        let userlogData: any;

        if (followUpData?.payment_by === "student") {
        const { data }: any = await FetchData(TableNames.trainings, ["course:courses(course_name)", "student:students(name)"]).eq("id", followUpData.training_id);
        userlogData = data;
        } else {
        const { data }: any = await FetchData(TableNames.batches, ["course:courses(course_name)"]).eq("id", followUpData.batch_id);
        userlogData = data;
        }

        const userlogObj:UserLogInterface={
            user_id: followUpData.added_by, 
            changes: (followUpData?.payment_by === "batch")?(followUpData.comment  + followUpData.follow_up_date +"Course name "+ userlogData[0]?.course?.course_name):(followUpData.comment  + followUpData.follow_up_date +"Course name "+ userlogData[0]?.course?.course_name + "Student name"+userlogData[0]?.student?.name),
            module: `PaymentFollowUp_${followUpData?.payment_by}`
        }
        const { data: followUpInsertResponse, error: followUpInsertError } = await InsertData(TableNames.payment_followups, followUpData);
        submitUserLogs(userlogObj);
        if (followUpInsertError) throw followUpInsertError;
        return followUpInsertResponse;
    } catch (error) {
        return errorNotifier(error);
    }
}

// training_id === batch_id && comment === batch_comment

export const getAllPaymentFollowupsForTraining = async (Id: number, type:string) => {
    try {
        const { data: paymentFollowData, error: QueryError } = await FetchData(TableNames.payment_followups, ["created_at", "follow_up_date", "comment"]).eq(`${type==="student"?"training_id":"batch_id"}`, Id);
        if (QueryError) throw QueryError;
        return paymentFollowData;
    } catch (error) {
        return errorNotifier(error);
    }
}