import { ViewEditDeleteActionComponentForBatch } from "../../Components/Action Center/Pending Payment/ActionComponentForBatch";
import { getBatchPendingPaymentsTableData } from "../../functions/admin/Action center/PendingPayments/PendingPayments";

export const pendingPaymentForBatch = {
  headers: [
    {
      name: "Batch Code",
      apiKey: "batch_code",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start",
    },
    {
      name: "Trainer Name",
      apiKey: "trainerName",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Trainer Number",
      apiKey: "trainerNumber",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Batch Size",
      apiKey: "batchSize",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center px-2",
    },
    {
      name: "Completion(%)",
      apiKey: "batchCompletionPercentage",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center break-words px-2",
    },
    {
      name: "Total Fee",
      apiKey: "total_fee",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Paid",
      apiKey: "total_paid",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Balance Due",
      apiKey: "balance_due",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Batch Status",
      apiKey: "status",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "BC Name",
      apiKey: "crmName",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponentForBatch, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return getBatchPendingPaymentsTableData(params);
  },
  component: "PendingPaymentTotals",
  filters: {
    dateFilters: [
      {
        name: "Batch Created Date",
        apiKey: "date",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Follow-Up Date",
        apiKey: "follow_up_date",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    checkBox: [
      {
        name: "Completion(%)",
        apiKey: "batchCompletionPercentage",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Progress Range(%)",
        apiKey: "progress_range",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "BC Name",
        apiKey: "crmName",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Batch Code",
        apiKey: "batch_code",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Branch",
        apiKey: "branch",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Ownership",
        apiKey: "ownership",
        isSearchNeeded: true,
        className: "w-[170px]"       
      },
      {
        name: "Time Range",
        apiKey: "time_range",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["trainerName", "trainerNumber"],
        searchKey: "student_nameCourse",
        name: "Trainer Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};
