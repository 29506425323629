import { getImports } from "./ModuleImports";
import { useEffect, useRef, useState } from "react";
import { StudentDiscountModal } from "../../UI_Modal/StudentDiscountModal";
import { calculateGstInfo } from "../../../functions/commonHelper";
import { CustomizationCourse } from "./CustomizationCourse";
import { Checkbox } from "../../Elements/Checkbox";

export const CourseDetails = ({
  formik,
  mode,
  scheduleData,
  CourseModeData,
  cityData,
  cityNames,
  branchData,
  branchNames,
  handleInputChange,
  handleCourseInputEndButton,
  initialCourseFee,
  inputRef,
  handleOutForCity,
  setInitialCourseFeeForCustomization
}: // handleOutForBranch,
any) => {
  const {
    InputBox,
    DropdownInputWithCheckbox,
    DropdownSingleInputWithCheckbox,
    DropDownInputBox
  }: any = getImports;
  
  const [filteredCity, setFilteredCity] = useState(null as any);
  const [checked, setChecked] = useState(false);
  const [filteredOwnership, setFilteredOwnership] = useState(null as any);
  
  const handleChangeForDropValue = (
    name: string,
    value: string,
    mapThrough?: string
) => {
    if (mapThrough) {
        setFilteredCity(value);
        const data =
        cityData &&
        cityData.filter((data: any) => data["city_name"] === value);
        formik.setFieldValue(name, data[0]['id']);
    } else {
        setFilteredOwnership(value);
        formik.setFieldValue(name, value);
    }
};

  return (
    <div className="space-y-2">
      <h1 className="text-[23px] font-Roboto font-[500]">Course Details</h1>
      <div className="space-y-6">
        <div className="flex items-center gap-6">
          <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="City"
            placeholder="City"
            options={cityNames}
            name={"city_name"}
            forId={"city_id"}
            value={formik.values.city_name}
            handleOut={handleOutForCity}
            onBlur={formik.handleBlur}
            error={formik.errors?.city_name}
            isTouched={formik.touched?.city_name}
            mapData={cityData}
            mapThrough={"city_name"}
            formik={formik}
            disabled={mode === "view" || mode === "edit"}
            mandatory={true}
            mandatoryClass = "text-red-500"
          />
          {/* <DropDownInputBox
                    label="City:"
                    placeholder="City"
                    // classNameInput={"z-30"}
                    name={"city_name"}
                    value={formik.values.city_name}
                    onBlur={formik.handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={cityData}
                    showData={filteredCity}
                    setShowData={setFilteredCity}
                    mapThrough={"city_name"}
                    formik={formik}
            /> */}
          <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Branch"
            placeholder="Branch"
            options={branchNames}
            name={"branch_name"}
            forId={"branch_id"}
            value={formik.values.branch_name}
            // handleOut={handleOutForBranch}
            onBlur={formik.handleBlur}
            error={formik.errors?.branch_name}
            isTouched={formik.touched?.branch_name}
            mapData={branchData}
            mapThrough={"branch_name"}
            formik={formik}
            disabled={mode === "view" || mode === "edit"}
            mandatory={true}
            mandatoryClass = "text-red-500"
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Schedule"
            placeholder="Course Schedule"
            options={scheduleData}
            name={"course_schedule"}
            value={formik.values.course_schedule}
            onBlur={formik.handleBlur}
            error={formik.errors?.course_schedule}
            isTouched={formik.touched?.course_schedule}
            mapThrough={"schedule"}
            formik={formik}
            // zIndex={"z-10"}
            disabled={mode === "view"}
            mandatory={true}
            mandatoryClass = "text-red-500"
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Mode"
            placeholder="Course Mode"
            options={CourseModeData}
            name={"course_mode"}
            value={formik.values.course_mode}
            onBlur={formik.handleBlur}
            error={formik.errors?.course_mode}
            isTouched={formik.touched?.course_mode}
            mapThrough={"course_mode"}
            formik={formik}
            // zIndex={"z-10"}
            disabled={false}
            mandatory={true}
            mandatoryClass = "text-red-500"
          />
          {/* <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Mode"
            placeholder="course_mode"
            options={CourseModeData}
            name={"course_mode"}
            value={formik.values.course_mode}
            onBlur={formik.handleBlur}
            mapData={branchData}
            mapThrough={"course_mode"}
            formik={formik}
            disabled={false}
          /> */}
        </div>
        {mode!=="edit" && !formik.values.customized_course && <Checkbox show={true} checked={checked?"Customize Course Name":""} label={"Customize Course Name"} CheckValue={()=> {setChecked(!checked)}} textStyle={"text-[#707070] text-[17px] font-bold"} boxSize={"w-4 h-4"} />}
        {mode!=="edit" && !formik.values.customized_course && checked && <CustomizationCourse formik={formik} initialCourseFee={initialCourseFee} setInitialCourseFeeForCustomization={setInitialCourseFeeForCustomization} />}
        <CardDetails
          formik={formik}
          mode={mode}
          handleInputChange={handleInputChange}
          handleCourseInputEndButton={handleCourseInputEndButton}
          initialCourseFee={initialCourseFee}
          inputRef={inputRef}
        />
      </div>
    </div>
  );
};

const CardDetails = ({
  formik,
  mode,
  handleInputChange,
  handleCourseInputEndButton,
  initialCourseFee,
  inputRef,
}: any) => {
  // const inputTagRef:any = useRef(null);
  const { useState, useEffect, InputBox, formatNumberWithCommas }: any =
    getImports;
  const headData = [
    "Course",
    "Course Fee (₹)",
    "Discount (%)",
    "Final Course Fee",
  ];


  const [pendingDiscount, setPendingDiscount] = useState(null);

  // useEffect(() => {
  //   if (inputTagRef.current) {
  //     inputTagRef.current.focus();
  //   }
  // }, [!formik.values.customization_fee]);

  const CheckValue = (label: any) => {
    if (label === "Fee customization") {
      formik.setFieldValue(
        "customization_fee",
        formik.values.customization_fee === true ? false : true
      );
    }
    // else {
    //   !formik.values.discount_need &&
    //     formik.setFieldValue("discount_percentage", null);
    //   formik.setFieldValue(
    //     "discount_need",
    //     formik.values.discount_need === true ? false : true
    //   );
    // }
  };

  const handleChangeForCountRestriction = (
    index: any,
    value: any,
    check: any
  ) => {
    const inputValue = value;
    if (
      inputValue === "" ||
      (parseInt(inputValue) <= 15 && parseInt(inputValue) >= 0)
    ) {
      if (parseInt(inputValue) === 15){
        setPendingDiscount(inputValue);
      } else {
        formik.setFieldValue("discount_percentage", inputValue);
      }
    }
  };

  return (
    <div className="rounded-[7px] shadow-md shadow-[#00000029] space-y-2 pb-4">
      <div className="flex items-center bg-[#EFF7FF] py-2 w-full">
        {headData.map((head: any, index: number) => (
          <p
            key={index}
            className={`text-[20px] font-Roboto font-[500] ${
              index !== 0 ? "text-center" : "text-start"
            } ${
              index === 0
                ? "w-[30%] pl-8"
                : index === 3
                ? "w-[40%] px-8"
                : "w-[15%]"
            }`}
          >
            {head}
          </p>
        ))}
      </div>
      <div className="flex">
        <CourseInCourseDetails
          initialCourseFee={initialCourseFee}
          CheckValue={CheckValue}
          formik={formik}
          mode={mode}
        />
        {/* <InputBox
          placeholder="Course Fee"
          type="text"
          inputMode="numeric"
          topDivStyle={"flex flex-col w-[15%]"}
          classNameInput={"w-[70%]"}
          name={"course_fee"}
          value={formik.values.course_fee}
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          error={formik.errors.course_fee}
          isTouched={formik.touched.course_fee}
          disabled={((formik.values.customization_fee && formik.values.course_name) ? false : true) || mode === "edit"}
          index={0}
          check={"course_fee"}
          min={initialCourseFee}
        /> */}
        <div className="w-[15%] relative">
          <input
            type="text"
            name="Course Fee"
            placeholder="Course Fee"
            inputMode="numeric"
            value={formik.values.course_fee}
            onBlur={formik.handleBlur}
            // error={formik.errors.course_fee}
            // isTouched={formik.touched.course_fee}
            className="outline-none border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full"
            onChange={handleInputChange}
            disabled={(!formik.values.customization_fee || (mode === "view") || (mode === "edit"))}
            // ref={inputTagRef}
          />
          <button
            type="button"
            disabled={((mode === "view")||(mode === "edit"))}
            className="absolute right-0 rounded-r-md px-2 py-2 bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            onClick={handleCourseInputEndButton}
          >
            <svg
              className="w-5 h-5 fill-current text-green-500"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        {/* {formik.values.discount_need ? ( */}
          <InputBox
            placeholder=""
            type="number"
            topDivStyle={"flex flex-col w-[15%] flex items-center"}
            classNameInput={"w-[70%]"}
            name={"discount_percentage"}
            value={formik.values.discount_percentage}
            onChange={handleChangeForCountRestriction}
            onBlur={formik.handleBlur}
            error={formik.errors.discount_percentage}
            isTouched={formik.touched.discount_percentage}
            // disabled={formik.values.course_name ? false : true}
            disabled={true}
            max={"15"}
            index={0}
          />
        <TotalFee formik={formik} mode={mode} initialCourseFee={initialCourseFee} />
      </div>
    </div>
  );
};

const CourseInCourseDetails = ({
  initialCourseFee,
  formik,
  mode,
  CheckValue,
}: any) => {
  const { Checkbox }: any = getImports;

  // useEffect(() => {
  //   formik.setFieldValue("discount_percentage", null);
  //   formik.setFieldValue("discount_need", false);
  // }, [formik.values.customization_fee]);

  return (
    <div className="w-[30%] space-y-1 pl-8">
      <h1 className="text-[18px] text-[#707070] font-Roboto font-[500]">
        {formik.values.course_name}
      </h1>
      {formik.values.customization_fee || formik.values.discount_need ? (
        <textarea
          name="cd_comment"
          value={formik.values.cd_comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={((mode === "view")||(mode === "edit"))}
          placeholder={`Write Comments Here ${(initialCourseFee?.replace(/,/g, "") !==
          formik.values.course_fee?.replace(/,/g, "")) && formik.values.cd_comment === "" ? "*" : ""}`}
          className={`border-[#707070] border-[0.5px] rounded-[3px] w-[90%] p-1 outline-none resize-none min-h-[60px] max-h-[70px] ${(initialCourseFee?.replace(/,/g, "") !==
          formik.values.course_fee?.replace(/,/g, "")) && formik.values.cd_comment === "" && "placeholder:text-red-500"} `}
        >
          {formik.values.cd_comment}
        </textarea>
      ) : (
        <div className="min-h-[60px] max-h-[70px]"></div>
      )}
      <Checkbox
        label={"Fee customization"}
        CheckValue={CheckValue}
        checked={
          formik.values.customization_fee &&
          "Fee customization"
        }
        textStyle={"text-black text-[15px] font-Roboto font-[500]"}
        show={mode !== "edit"}
        boxSize={"w-4 h-4"}
      />
      {/* <Checkbox
        label={"Students Needs Discount?"}
        CheckValue={CheckValue}
        checked={formik.values.discount_need && "Students Needs Discount?"}
        textStyle={"text-black text-[15px] font-Roboto font-[500]"}
        show={formik.values.course_fee !== initialCourseFee}
        boxSize={"w-4 h-4"}
      /> */}
    </div>
  );
};

const TotalFee = ({ formik, mode, initialCourseFee }: any) => {
  const {
    useEffect,
    Checkbox,
    feeAfterDiscountCalculation,
    gstCalculationForStudent,
    totalGstCalculationForStudent,
    formatNumberWithCommas,
    useSharedLogic,
  }: any = getImports;
  const { id }: any = useSharedLogic();
  // const condition_value = Number(initialCourseFee) === Number(formik.values?.course_fee?.replace(/,/g, ""))
  //   ? Number(initialCourseFee) === Number(formik.values?.course_fee?.replace(/,/g, "")) : Number(initialCourseFee) > Number(formik.values?.course_fee?.replace(/,/g, ""))
  const feeInNumber = formik.values?.course_fee?.replace(/,/g, "")
  // const checkIsHigher = formik.values.customization_duration!=="" ? false : condition_value;
  const branch_GST_Value = formik.values.branch_based_GST;
  const check_feeType = branch_GST_Value && branch_GST_Value?.fee_type;
  const sendPercentageIGST =
    check_feeType === "IGST" && formik.values.branch_based_GST?.igst;
  const sendPercentageSGST =
    check_feeType === "SGST" && formik.values.branch_based_GST?.sgst;
  const sendPercentageCGST =
    check_feeType === "SGST" && formik.values.branch_based_GST?.cgst;

  // const sendPercentage =
  //   check_feeType === "SGST"
  //     ? {
  //         cgst: formik.values.branch_based_GST?.cgst,
  //         sgst: formik.values.branch_based_GST?.sgst,
  //       }
  //     : check_feeType === "IGST"
  //     ? formik.values.branch_based_GST?.igst
  //     : null;

  const TotalExclGST = feeAfterDiscountCalculation(
    // checkIsHigher ? Number(initialCourseFee) :
      feeInNumber,
    // (formik.values?.discount_percentage !== null || formik.values?.discount_percentage !== 0) && mode!=="edit" &&
    //   formik.values?.discount_percentage
  );
  const Sgst = gstCalculationForStudent(
    sendPercentageSGST,
    // checkIsHigher ? Number(initialCourseFee) :
      feeInNumber,
    // (formik.values?.discount_percentage !== null || formik.values?.discount_percentage !== 0) && mode!=="edit" &&
    //   formik.values?.discount_percentage
  );
  const Cgst = gstCalculationForStudent(
    sendPercentageCGST,
    // checkIsHigher ? Number(initialCourseFee) :
      feeInNumber,
    // (formik.values?.discount_percentage !== null || formik.values?.discount_percentage !== 0) && mode!=="edit" &&
    //   formik.values?.discount_percentage
  );
  const Igst = gstCalculationForStudent(
    sendPercentageIGST,
    // checkIsHigher ? Number(initialCourseFee) : 
    feeInNumber,
    // (formik.values?.discount_percentage !== null ||
    //   formik.values?.discount_percentage !== 0) &&
    //   mode !== "edit" &&
    //   formik.values?.discount_percentage
  );
  // const TotalInclGST = totalGstCalculationForStudent(
  //   check_feeType,
  //   sendPercentage,
  //   // checkIsHigher ? Number(initialCourseFee) :
  //     feeInNumber,
  //   // (formik.values?.discount_percentage !== null || formik.values?.discount_percentage !== 0) && mode!=="edit" &&
  //   //   formik.values?.discount_percentage
  // );

  const type_check = check_feeType === "SGST" ? (Number(Cgst) + Number(Sgst)) : check_feeType === "IGST" ? (Number(Igst)) : 0;
  const TotalInclGST = Number(TotalExclGST) + type_check;

  const mappingForTotal: any = [
    {
      left: "Total (Excl. GST)",
      right: calculateGstInfo(formik.values.course_fee, TotalExclGST)
    },
    branch_GST_Value &&
      check_feeType === "SGST" && {
        left: `SGST (${branch_GST_Value?.sgst}%)`,
        right: calculateGstInfo(formik.values.course_fee, Sgst)
      },
    branch_GST_Value &&
      check_feeType === "SGST" && {
        left: `CGST (${branch_GST_Value?.cgst}%)`,
        right: calculateGstInfo(formik.values.course_fee, Cgst)
      },
    check_feeType === "IGST" && {
      left: `IGST (${branch_GST_Value?.igst}%)`,
      right: calculateGstInfo(formik.values.course_fee, Igst)
    },
    {
      left: "Total (Incl. GST)",
      right: calculateGstInfo(formik.values.course_fee, TotalInclGST)
    },
  ];

  useEffect(() => {
    formik.setFieldValue(
      "total_fee",
      formik.values.course_fee
        ? TotalInclGST
        : 0
    );
  }, [
    formik.values?.discount_percentage,
    formik.values?.course_fee,
    formik.values?.branch_based_GST,
  ]);

  return (
    <div className="w-[40%] flex flex-col justify-between">
      {mappingForTotal.map((text: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex justify-around w-full ${
              index === 4 && "bg-[#EFF7FF] py-1.5"
            }`}
          >
            <p
              className={`w-[40%] text-end ${
                index === 4
                  ? "text-black font-Roboto font-[500]"
                  : "text-[#707070] font-Roboto font-[400]"
              }`}
            >
              {text.left}
            </p>
            <p
              className={`w-[20%] ${
                index === 4
                  ? "text-black font-Roboto font-[500]"
                  : "text-[#707070] font-Roboto font-[400]"
              }`}
            >
              {text.left && <span className="px-1">&#8377;</span>}
              {text.right}
            </p>
          </div>
        );
      })}
    </div>
  );
};
