import { map } from "lodash";
import { useState, useEffect } from "react";
import { getUserById } from "../../../functions/admin/User Mangement/userMangement";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { InformationViewCard } from "../../Elements/InformationViewCard/InformationViewCard";
import { dateFormat } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { useAppSelector } from "../../..";

export const ViewUserInfo = ({id}:any) => {
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

        const buildJSON = [
          {
            left: [
              {
                head: "User Name",
                body: user && user?.first_name + "" + user?.last_name,
              },
              {
                head: "Phone Number",
                body: user && user?.phone_number,
              },
              {
                head: "Email",
                body: user && user?.email,
              },
              {
                head: "City",
                body: user && user?.cityNames?.join(', '),
              },
              {
                head: "Branch",
                body: user && user?.branchesNames?.join(', '),
              },
            ],
            right: [
              {
                head: "Created Date",
                body: user && dateFormat(user?.created_at, "DD MMM YYYY"),
              },
              {
                head: "Role",
                body: user && user?.rolesName,
              },
              {
                head: "Courses",
                body: user && user?.coursesNames?.join(', '),
              },
            ],
          },
        ];
    
        useEffect(() => {
            (async () => {
            setIsLoading(true)
            const userData = await getUserById(id, signInData?.data[0]?.roles);
            setUser(userData);
            setIsLoading(false)
          })();
        }, []);

    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <CardLayoutTwo>
            <InformationViewCard
              cardHeading={"View User Information"}
              values={user}
              buildJSON={buildJSON}
            />
          </CardLayoutTwo>
        )}
      </>
    );
}