import { ViewEditDeleteActionComponent } from "../../Components/Action Center/StudentAttendance/ActionComponent";
import { AbsentStudentsTableData } from "../../functions/admin/Action center/Student Attendance/StudentAttendance";

export const absentStudentsList = {
  headers: [
    {
      name: "Date",
      apiKey: "date",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2",
    },
    {
      name: "Batch Code",
      apiKey: "batchCode",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2",
    },
    {
      name: "Course",
      apiKey: "courseName",
      headerClass: "w-[140px] text-start px-2",
      className: "w-[140px] text-start px-2 break-words",
    },
    {
      name: "Student Name",
      apiKey: "studentName",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Phone Number",
      apiKey: "phoneNumber",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    // {
    //   name: "EndTime",
    //   apiKey: "endTime",
    //   headerClass: "w-[120px] text-start px-2",
    //   className: "w-[120px] ttext-start px-2 break-words",
    // },
    {
      name: "Course Mode",
      apiKey: "courseMode",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Schedule",
      apiKey: "schedule",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start px-2 break-words",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return AbsentStudentsTableData(params);
  },
  //   component: "PendingPaymentTotals",
  filters: {
    dateFilters: [
      {
        name: "Session Date",
        apiKey: "date",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    checkBox: [
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Course Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Schedule",
        apiKey: "schedule",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Branch",
        apiKey: "branch",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Ownership",
        apiKey: "ownership",
        isSearchNeeded: true,
        className: "w-[170px]"       
      },
      {
        name: "Student Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    searchBox: [
      // {
      //   matchFields: ["trainer_name"],
      //   searchKey: "trainer_nameCourse",
      //   name: "Trainer Search...",
      //   searchStyle: "w-[220px]",
      // },
      {
        matchFields: ["student_list", "studentName", "phoneNumber"],
        searchKey: "stud",
        name: "Student Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
