// import { Fragment, useState } from 'react'
// import { Dialog, Transition } from '@headlessui/react'

// export const ModalForReason = ({open, setOpen, func, current_training}: any) => {
//     const [reason, setReason] = useState("");

//     const handleChangeForModal = (flag: any) => {
//         func(flag, reason, current_training);
//     };

//     return (
//         <Transition.Root show={open} as={Fragment}>
//             <Dialog as="div" className="relative z-20" onClose={setOpen}>
//                 <Transition.Child
//                     as={Fragment}
//                     enter="ease-out duration-300"
//                     enterFrom="opacity-0"
//                     enterTo="opacity-100"
//                     leave="ease-in duration-200"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                 >
//                     <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//                 </Transition.Child>

//                 <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
//                     <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//                         <Transition.Child
//                             as={Fragment}
//                             enter="ease-out duration-300"
//                             enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//                             enterTo="opacity-100 translate-y-0 sm:scale-100"
//                             leave="ease-in duration-200"
//                             leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//                             leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//                         >
//                             <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
//                                 <div className="mt-4">
//                                     <textarea
//                                         value={reason}
//                                         onChange={(e) => setReason(e.target.value)}
//                                         placeholder="Enter your reason here"
//                                         className="w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//                                         rows={3}
//                                         autoFocus
//                                     />
//                                 </div>
//                                 <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
//                                     <button
//                                         type="button"
//                                         className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
//                                         onClick={() => handleChangeForModal('Yes')}
//                                     >
//                                         Proceed
//                                     </button>
//                                     <button
//                                         type="button"
//                                         className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
//                                         onClick={() => handleChangeForModal('Cancel')}
//                                     >
//                                         Cancel
//                                     </button>
//                                 </div>
//                             </Dialog.Panel>
//                         </Transition.Child>
//                     </div>
//                 </div>
//             </Dialog>
//         </Transition.Root>
//     )
// }

import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';


export const ModalForReason = ({ open, setOpen, func, current_training }: any) => {
    const [reason, setReason] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleChangeForModal = (flag: any) => {
        func(flag, reason, current_training);
    };

    const handleCancel = () => {
        setReason(""); // Clear the textarea
        setOpen(false);
    };
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-20"
                static
                onClose={() => {}}
                initialFocus={textareaRef}  // Set initial focus here
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="mt-4">
                                    <textarea
                                        ref={textareaRef}
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        placeholder="Enter your reason here"
                                        className="w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        rows={3}
                                    />
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                                            reason ? 'bg-red-600 hover:bg-red-500' : 'bg-gray-400 cursor-not-allowed'
                                        }`}
                                        onClick={() => handleChangeForModal('Yes')}
                                        disabled={!reason}
                                    >
                                        Proceed
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};



