export const ReportJsonData: any = {
  // "Coursewise Registration": [
  //   "",
  //   "2D & 3D ANIMATION"
  // ],
  "Admission Report": [
    "S:No",
    "Branch",
    "All Students",
    "Paid Students",
    "Free Students",
    "App Students",
  ],
  "Revenue Vs Proforma Invoice": [
    "S:No",
    "Branch",
    "Classroom Revenue/ Proforma Invoice",
    "Online Revenue/ Proforma Invoice",
    "Hybrid Revenue/ Proforma Invoice",
    "Total Revenue/ Proforma Invoice",
    "Pending Revenue",
  ],
  Operations: [
    "S:No",
    "Branch",
    "Unallocated Students",
    "Hold Students",
    "Dropped Students",
    "Allocated Students",
    "Completed Students",
  ],
  Batch: [
    "S:No",
    "Branch",
    "Ongoing Batch",
    "Upcoming Batch",
    "Review Batch",
    "Completed Batch",
    "Hold Batch",
    "Dropped Batch",
  ],
  "Session Feedback": [
    "S:No",
    "Branch",
    "Poor",
    "Average",
    "Good",
    "Excellent",
    "Absent",
  ],
  "Feedback/Concern": [
    "S:No",
    "Branch",
    "Open",
    "In Progress",
    "Re-Open",
    "Closed",
  ],
  "Missed Revenue": [
    "S:No",
    "Branch",
    "Refund",
    "Refund Amount",
    "Nullified Count",
    "Nullified Amount",
    "Drop Count",
    "Drop Revenue",
    "Total Missed Revenue",
  ],
  Placement: [
    "S:No",
    "Branch",
    "Total Companies",
    "Jobs",
    "Openings",
    "Applied",
    "Shortlisted",
    "Placed",
    "Joined the Company",
  ],
  "Deviation - Pending Action": [
    "S:No",
    "BC Owners",
    "Unallocated",
    "Batch Not Started",
    "Cancelled Session",
    "Student Attendance",
    "Pending Payment",
    "Payment Overdue",
  ],
};