import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Session Feedback/ActionComponent";
import { getSessionFeedbackTableDataForAbsent } from "../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const sessionFeedbackforabsent = {
    headers: [
        {
            name: "Created Date",
            apiKey: "created_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Student Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Phone Number",
            apiKey: "student_phone",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[140px] text-start px-2",
            className: "w-[140px] text-start px-2 break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2",
        },
        {
            name: "Admin",
            apiKey: "admin_name",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Rating",
            apiKey: "response",
            headerClass: "w-[100px] text-center px-2",
            className: "w-[100px] text-center px-2",
        },
        {
            name: "Issue Priority",
            apiKey: "issue_priority",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-center px-2",
        },
        {
            name: "Feedback/Concerns",
            apiKey: "comment",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start px-2 break-words",
        },
        {
            name: "Action",
            apiKey: "Courses",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center",
        },
    ],
    BackendFunctionCall: getSessionFeedbackTableDataForAbsent,
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "created_date",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
        ],
        checkBox: [
            // {
            //     name: "Date",
            //     apiKey: "created_at",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[180px] xl:w-[90px] 2xl:w-[120px]",
            // },
            {
                name: "City",
                apiKey: "city",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Branch",
                apiKey: "branch",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Ownership",
                apiKey: "ownership",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Course",
                apiKey: "course_name",
                isSearchNeeded: true,
                className: "w-[250px]",
            },
            {
                name: "Schedule",
                apiKey: "schedule",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Rating",
                apiKey: "response",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Status",
                apiKey: "status",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Admin",
                apiKey: "admin_name",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Issue Priority",
                apiKey: "issue_priority",
                isSearchNeeded: true,
                className: "w-[200px]",
            }
        ],
        searchBox: [
            // {
            //     matchFields: ["created_at", "course_name"],
            //     searchKey: "batch_start_datecourse",
            //     name: "Trainer Search...",
            //     searchStyle: "w-[220px]",
            // },
            {
                matchFields: ["student_list", "student_name", "student_phone"],
                searchKey: "batch_start_studdatecourse",
                name: "Student Search...",
                searchStyle: "w-[220px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};