import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useAppSelector } from "../../..";
import * as Yup from 'yup';
import { BatchDropdown } from "./BatchDropdown";
import { Components } from "./ModuleImports";
import { PulseLoader } from 'react-spinners';
import { Course } from "./Course";
import { TrainerData } from './TrainerData';
import { StudentData } from './StudentData';
import { dateFormat } from "../../../Services/CommonFunctions";
import { PastStudentsData } from './PastStudentsData';
import { DropDownInputBox } from '../../Elements/DropDownInputBox';

export const NewBatchCreation = ({id, mode}:any) => {
    const {
      useState,
      useEffect,
      useRef,
      Loader,
      useFormik,
      add_Batch,
      onKeyDown,
      DropdownInputWithCheckbox,
      CourseDetails,
      TrainerDetails,
      StudentDetails,
      fetchCityData,
      getBatchPreloadData,
      useSharedLogic,
      FormikCommonFunc,
      DropdownSingleInputWithCheckbox,
      getBatchById,
      getAllBatches,
      createBatch,
      updateBatch,
      getTrainerBasedOnBranch,
      getTrainerBasedOnBatchTime,
      getStudentBasedOnTrainer,
      updateStudentTraining,
      toast,
      createSearchParams,
      AddBatch,
      isTime1GreaterThanTime2,
      ModalBox,
      getCourseBasedStudentsList,
      getPastStudentsPreloadData,
    }: any = Components;
    const { navigate, routeNameForHome, routeNameForSubSection, routePath }: any = useSharedLogic();
    const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
    const [isLoading, setIsLoading ] = useState(false);
    const [applicationLoader, setApplicationLoader] = useState(false); 
    const [show, setShow] = useState(false); 
    const [courseDetails, setCourseDetails] = useState([] as any);
    const [cityDetails, setCityDetails] = useState([] as any);
    const [citiesData, setCitiesData] = useState([] as any);
    const [branchDetails, setBranchDetails] = useState([] as any);
    const [branchNameDetails, setBranchNameDetails] = useState([] as any);
    const [branchRooms, setBranchRooms] = useState([] as any);
    const [studentDetails, setStudentDetails] = useState([] as any);
    const [studentNameDetails, setStudentNameDetails] = useState([] as any);
    const [courseModes, setCourseModes] = useState([] as any);
    const [courseScheduleList, setCourseScheduleList] = useState([] as any);
    const [scheduleDays, setScheduleDays] = useState([] as any);
    const [trainerList, setTrainerList] = useState([] as any);
    const [isTrainerChange, setIsTrainerChange] = useState(false);
    const [pastStudentList, setPastStudentsList] = useState([] as any);
    const [selectedOption, setSelectedOption] = useState("");
    const weekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const weekend = ['Saturday', 'Sunday'];
    const isId: any = id !== "new-batch" && id;
    const batch_status = ["Upcoming", "Ongoing", "Hold", "Dropped", "Review", "Completed"];
    


    const handleDataSetup = (name : string, value : string, mapThrough : string) => {
      setSelectedOption(value);
    };

    useEffect(()=>{
        (async()=>{
            setApplicationLoader(true);
            const response = await getBatchPreloadData(signInData?.data[0]);
            // console.log(response, "response")
            if(response) {
                setCourseDetails(response?.courses);
                setCitiesData(response?.cities);
                setBranchDetails(response?.branches);
                setStudentDetails(response?.student_data);
            }
            if (id === "new-batch") {
                const batchResponse = await getAllBatches(signInData?.data[0]);
                setFieldValue(
                    "batch_code",
                    batchResponse[batchResponse.length - 1]?.batch_code
                );
            } else {
                const responseData = await getBatchById(Number(isId));
                if (responseData) {
                    const { batch, student_data, updatedTrainerData }: any =
                    responseData;
                    const {
                    batch_code,
                    batch_type,
                    status,
                    batch_payment_status,
                    branch,
                    branch_id,
                    city,
                    city_id,
                    course,
                    mode,
                    course_id,
                    duration,
                    end_date,
                    end_time,
                    planned_date_of_completion,
                    rooms,
                    schedule,
                    start_date,
                    start_time,
                    trainer_change_reason,
                    schedule_days,
                    }: any = batch;
                    const mapStudents =
                    student_data &&
                    student_data?.map((stud: any) => stud?.student?.name);
                    // const mapTrainers = updatedTrainerData && updatedTrainerData?.length > 1 ?
                    //   updatedTrainerData?.filter((tra: any) => tra.current_trainer === false) :
                    //   updatedTrainerData?.filter((tra: any) => tra.current_trainer === true)
                    const filterCurrentTrainers =
                    updatedTrainerData &&
                    updatedTrainerData?.filter(
                        (tra: any) => tra.current_trainer === true
                    );
                    const filterPastTrainers =
                    updatedTrainerData &&
                    updatedTrainerData?.filter(
                        (tra: any) => tra.current_trainer === false
                    );
                    const filterCourse: any =
                      response &&
                      response?.courses?.filter(
                        (data: any) => data.course_name === course?.course_name
                      );
                    filterCourse && setCourseModes(filterCourse?.[0]?.mode);
                    setValues(
                    {
                        ...values,
                        batch_code,
                        batch_type,
                        status,
                        start_date,
                        planned_date_of_completion,
                        start_time,
                        end_date,
                        end_time,
                        rooms,
                        duration,
                        schedule,
                        trainer_change_reason,
                        branch: branch?.branch_name,
                        branch_id,
                        city: city?.city_name,
                        city_id,
                        course: course?.course_name,
                        course_mode: mode,
                        course_id,
                        add_student: mapStudents,
                        selected_student_list: student_data?.filter(
                        (data: any) => data?.status !== "Drop"
                        ),
                        trainer_id: filterCurrentTrainers[0]?.trainer?.uid,
                        trainer_name: filterCurrentTrainers[0]?.trainer?.first_name,
                        trainer_phone_number:
                        filterCurrentTrainers[0]?.trainer?.phone_number,
                        trainer_email: filterCurrentTrainers[0]?.trainer?.email,
                        current_trainer: filterCurrentTrainers[0]?.current_trainer,
                        past_trainers:
                        filterPastTrainers.length !== 0 && filterPastTrainers,
                        schedule_days,
                    },
                    true
                    );
                    const item = response?.courses?.find(
                        (item: any) => item.course_name === course?.course_name
                    );
                    if (item) {
                        setCourseModes(item?.mode);
                        setCityDetails(item?.city_based_course_fee);
                        setCourseScheduleList(item?.schedule);
                    }
                    const cityItem = response?.cities?.find(
                        (item: any) => item.city_name === city?.city_name
                    );
                    if(cityItem) {
                        const branchResponse = response?.branches?.filter((fil: any) => fil?.city === cityItem?.id)?.map((dt:any)=>dt?.branch_name);
                        setBranchNameDetails(branchResponse)
                    }
                    const branchItem = response.branches?.find(
                        (item: any) => item.branch_name === branch?.branch_name
                    );
                    if(branchItem) {
                        setBranchRooms(branchItem?.rooms)
                    }
                    // handleScheduleWithDays('schedule', schedule);
                    if (schedule === "Weekday") {
                        setScheduleDays(weekday)
                    } else if (schedule === "Weekend") {
                        setScheduleDays(weekend)
                    } else if (schedule === "Fasttrack") {
                        setScheduleDays([ ...weekday, ...weekend ])
                    } else {
                        setScheduleDays([])
                    }
                    const pastStudentsResponse = await getPastStudentsPreloadData(signInData?.data[0]);
                    const filterPastStudentsResponse = pastStudentsResponse?.filter((fil: any) => fil?.batch?.batch_code === batch_code);
                    setPastStudentsList(filterPastStudentsResponse)
                }
            }
            setApplicationLoader(false);
        })()
    }, [])

    const addingBatchData = async () => {
      console.log("adding batch data init")
        if (CF()) {
        setIsLoading(true);
        const {
            past_trainers,
            current_trainer,
            course,
            course_mode,
            city,
            branch,
            trainer_change_reason,
            trainer_name,
            trainer_phone_number,
            trainer_email,
            add_student,
            selected_student_list,
            batch_payment_status,
            ...rest
        }: any = values;
        const filterStudents = selected_student_list?.map((list: any) => ({
            id: list?.id,
            status: list?.status,
            ...(mode === "edit" && { batch_id: Number(isId) }),
            ...(mode === "edit" && { course_id: Number(rest.course_id) }),
            trainers_ids: [
            ...(list?.trainers_ids ?? []),
            ...(mode === "edit" && list?.trainers_ids?.includes(values.trainer_id)
                ? []
                : [values.trainer_id]),
            ],
        }));

        const body: any = {
            ...(id !== "new-batch" && { trainer_change_reason }),
            students: filterStudents,
            mode: course_mode,
            batch_payment_status: batch_payment_status==="" ? "Yet To Calculated" : batch_payment_status,
            ...rest,
        };
          // console.log(body, "body")
        const response = await (id === "new-batch"
            ? createBatch(body)
            : updateBatch(body, isId));
        if (response) {
            routePath && routePath === "completed_batch"
            ? navigate({
                pathname: `/action_center/completed_batches`,
                })
            : navigate({
                pathname: `/${routeNameForHome}`,
                search: createSearchParams({
                    id: `${
                    id === "new-batch" ? "created-success" : "update-success"
                    }`,
                }).toString(),
                });
        }
        setIsLoading(false);
        }
    };

    const CF = () => {
        if (values.trainer_id === "") {
        toast.error("Please Select Trainer");
        } else if (values.selected_student_list?.length === 0) {
        toast.error("Please Assign Students");
        } else if (isTrainerChange && values.trainer_change_reason === "") {
        toast.error("Enter Reason For Change Trainer");
        return false;
        } else {
          return true;
        }
    };

    const confirmationModal = (flag: any) => {
        setShow(false);
        if (flag === "yes") {
        addingBatchData();
        } else {
        setShow(false);
        }
    };

    const {
        formik,
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setValues,
        setFieldValue,
        isValid,
    }: any = FormikCommonFunc(add_Batch, AddBatch, addingBatchData);

    useEffect(() => {
        if (values.status === "Completed") {
            const currentDate: any = new Date();
            setFieldValue("end_date", dateFormat(currentDate, "DD/MM/YYYY"));
        } else setFieldValue("end_date", '');
    },[values.status])

    useEffect(() => {
        (async () => {
        if (
            values.branch_id !== null &&
            values.start_time !== "" &&
            values.end_time !== ""
        ) {
            const readTrainerWithTime = await getTrainerBasedOnBatchTime(
            values.start_time,
            values.end_time,
            values.branch_id
            );
            setTrainerList(readTrainerWithTime);
        }
        })();
    }, [values.branch_id, values.start_time, values.end_time]);

    useEffect(() => {
        if (values.course_mode !== "") {
            const filterBasedOnCourseMode = studentDetails?.filter((ft:any)=>ft?.mode === values.course_mode)
            setStudentDetails(filterBasedOnCourseMode);
            const mapStudents: any =
                filterBasedOnCourseMode?.length>0 &&
                filterBasedOnCourseMode?.map(
                    (stud: any) =>
                    stud.student?.name + `(${stud.student?.phoneNumber})` + `(${stud.course?.course_name})`
                );
                // console.log(mapStudents, "mapStudents")
            setStudentNameDetails(mapStudents);
        }
    },[values.course_mode])

    const handleCourseDetails = async(field: string, value: string | string[]) => {
        setFieldValue(field, value);
        const item = courseDetails?.length>0 && courseDetails?.find(
          (item: any) => item.course_name === value
        );
        if (item) {
            setFieldValue('course_id', item?.id);
            setCourseModes(item?.mode);
            setCityDetails(item?.city_based_course_fee);
            setCourseScheduleList(item?.schedule);
            setFieldValue("duration", Number(item?.duration));
            setFieldValue('schedule', "");
            setFieldValue('schedule_days', []);
            setFieldValue('branch_id', null);
            setFieldValue('branch', "");
            setFieldValue('rooms', "");
            setFieldValue('city_id', null);
            setFieldValue('city', "");
            setFieldValue('course_mode', "");
            setBranchNameDetails([])
            setBranchRooms([]);
            setScheduleDays([]);
        }
    }

    const handleFields = (field: string, value: string | string[]) => {
        setFieldValue(field, value);
    }

    const handleCityValues = async(field: string, value: string | string[]) => {
        setFieldValue(field, value);
        const item = citiesData?.length>0 && citiesData?.find(
          (item: any) => item.city_name === value
        );
        if(item) {
            setFieldValue('city_id', item?.id);
            const branchResponse = branchDetails?.length>0 && branchDetails?.filter((fil: any) => fil?.city === item?.id)?.map((dt:any)=>dt?.branch_name);
            setBranchNameDetails(branchResponse)
            setFieldValue("branch_id", null);
            setFieldValue("branch", "");
        }
    } 

    const handleBranchValues = async(field: string, value: string | string[]) => {
        setFieldValue(field, value);
        const item = branchDetails?.length>0 && branchDetails?.find(
          (item: any) => item.branch_name === value
        );
        if(item) {
            setFieldValue('branch_id', item?.id);
            setBranchRooms(item?.rooms)
            setFieldValue("rooms", "");
        }
    } 

    const handleScheduleWithDays = (field: string, value: string | string[]) => {
        setFieldValue(field, value);
        if (value === "Weekday") {
            formik.setFieldValue('schedule_days', [])
            setScheduleDays(weekday)
        } else if (value === "Weekend") {
            formik.setFieldValue('schedule_days', [])
            setScheduleDays(weekend)
        } else if (value === "Fasttrack") {
            setScheduleDays([ ...weekday, ...weekend ])
        } else {
            setScheduleDays([])
        }
    }

    const handleScheduleData = (field: string, value: string | string[]) => {
        if (formik.values.schedule_days.includes(value)) {
            const filterUpStudents = formik.values.schedule_days?.filter((fil: any) => fil !== value);
            formik.setFieldValue(field, filterUpStudents);
        } else {
            formik.setFieldValue(field, [...formik.values.schedule_days, value]);
        }
    }

    const handleStudentsData = (field: string, value: string | string[]) => {
        if (formik.values.add_student.includes(value)) {
            // console.log(value, "01")
            const filterUpStudents = formik.values.add_student?.filter((fil: any) => fil !== value);
            formik.setFieldValue(field, filterUpStudents);
            // console.log("01", formik.values.selected_student_list, "formik.values.selected_student_list", value, "value")
            const filterUpStudentList = formik.values.selected_student_list?.filter((fil: any) =>
                fil.student?.name + `(${fil.student?.phoneNumber})` + `(${fil.course?.course_name})` !== value)
            formik.setFieldValue("selected_student_list", filterUpStudentList);
        } else {
            // console.log(value, "02", studentDetails, "studentDetails")
            formik.setFieldValue(field, [...formik.values.add_student, value]);
            // const filterUpStudentList =  studentDetails?.filter((fil:any)=> (fil?.course?.course_name===formik.values.course) && fil.student?.name + `(${fil.student?.phoneNumber})` === value);
            const filterUpStudentList = studentDetails?.filter(
              (fil: any) =>
                fil.student?.name + `(${fil.student?.phoneNumber})` + `(${fil.course?.course_name})` === value
            );
            // console.log(filterUpStudentList, "filterUpStudentList")
            const mapFilteredData = filterUpStudentList?.map((dt: any) => {
                return {
                    ...dt, status: "Allocated"
                }
            })
            // console.log(mapFilteredData, "mapFilteredData")
            formik.setFieldValue("selected_student_list", [...formik.values.selected_student_list, ...mapFilteredData])
        }
    }

    // const handleDataSetup = (name, value, mapThrough) = {
    //   setSelectedOption(value)
    // };

    const calculateCompletionDate = () => {
        const durationPerDay =
        calculateStrTimeToMinutes(values.end_time) -
        calculateStrTimeToMinutes(values.start_time);
        let durationMinutesConvert = parseInt(values.duration) * 60;

        const daysObject: any = {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        };

        let startDateString = new Date(values.start_date);
        startDateString.setHours(0, 0, 0, 0); // Set time to 00:00:00

        while (durationMinutesConvert > durationPerDay) {
        let day = startDateString.getDay();

        if (values.schedule_days?.includes(daysObject[day])) {
            durationMinutesConvert -= durationPerDay;
        }
        startDateString.setDate(startDateString.getDate() + 1);
        }

        const formattedDateString = dateFormat(
        startDateString.toISOString(),
        "YYYY-MM-DD"
        );
        return startDateString.toISOString(), formattedDateString;
    };

    function calculateStrTimeToMinutes(time: string): number {
        let splitTimeString = time.split(":");
        return parseInt(splitTimeString[0]) * 60 + parseInt(splitTimeString[1]);
    }

    useEffect(()=>{
      const {
        start_date,
        start_time,
        end_time,
        duration,
        schedule,
        course_mode,
        schedule_days,
      }: any = values;
      if (
        start_date !== "" &&
        start_time !== "" &&
        end_time !== "" &&
        duration !== "" &&
        schedule !== "" &&
        course_mode !== "Hybrid" &&
        schedule_days?.length !== 0
      ) {
        const completionDate = calculateCompletionDate();
        setFieldValue("planned_date_of_completion", completionDate);
      }
    },[values.start_date,
    values.start_time,
    values.end_time,
    values.duration,
    values.schedule,
    values.course_mode,
    values.schedule_days])

  return (
    <div>
      {applicationLoader ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white w-[95%] mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 select-none"
        >
          <ModalBox
            show={show}
            fun={confirmationModal}
            message={`Are you sure, You want to ${
              id === "new-batch" ? "Create" : "Update"
            } Batch?`}
          />
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[22px] text-[#707070] my-3 ml-4">
              {id === "new-batch" ? "Create Batch" : "Edit Batch"}
            </p>
          </div>
          <div className="w-11/12 mx-auto py-8 space-y-10">
            <div className="flex items-center justify-between">
              <div className="w-8/12 flex items-center gap-8">
                <BatchDropdown
                  name="course"
                  label="Course"
                  showLabel={true}
                  options={courseDetails?.map((cr: any) => cr?.course_name)}
                  placeholder="Select a course"
                  value={values.course}
                  onChange={handleCourseDetails}
                  onBlur={handleBlur}
                  error={errors.course}
                  touched={touched.course}
                />
                <BatchDropdown
                  name="status"
                  label="Batch Status"
                  showLabel={true}
                  options={batch_status}
                  placeholder="Batch Status"
                  value={values.status}
                  onChange={handleFields}
                  onBlur={handleBlur}
                  className={'w-[70%]'}
                  disabled={id==="new-batch"}
                />
                <BatchDropdown
                  name="batch_type"
                  label="Batch Type"
                  showLabel={true}
                  options={["One to One", "Group"]}
                  placeholder="Batch Status"
                  value={values.batch_type}
                  onChange={handleFields}
                  onBlur={handleBlur}
                  className={'w-[70%]'}
                />

                {/* <DropDownInputBox
                  mapData={["One to One", "Group"]}
                  showData={selectedOption}
                  setShowData={setSelectedOption}
                  // mapThrough={null} 
                  label="Batch Type" 
                  classNameInput="border" 
                  placeholder="Select an option..." 
                  dataSetup={handleDataSetup} 
                  value={selectedOption}
                  name="dropdown"
                  // error={null}
                  // isTouched={false}
                  zIndex="z-20" 
                  // batchOptions={[]} 
                /> */}

              </div>
              <div>
                <p className="text-[#686868] text-[20px] font-Roboto font-[400]">
                    Batch Code
                </p>
                <h1 className="text-[#000000] text-[24px] font-Roboto font-[500]">
                    {values.batch_code}
                </h1>
              </div>
            </div>
            <Course formik={formik} courseModes={courseModes} handleFields={handleFields} cityDetails={cityDetails} citiesData={citiesData} handleCityValues={handleCityValues} branchNameDetails={branchNameDetails} handleBranchValues={handleBranchValues} branchRooms={branchRooms} courseScheduleList={courseScheduleList} handleScheduleWithDays={handleScheduleWithDays} scheduleDays={scheduleDays} handleScheduleData={handleScheduleData} />
            <TrainerData mode={mode} trainerList={trainerList} formik={formik} isTrainerChange={isTrainerChange} setIsTrainerChange={setIsTrainerChange} />
            <StudentData isId={isId} id={id} mode={mode} formik={formik} studentNameDetails={studentNameDetails} handleStudentsData={handleStudentsData} />
            {mode==="edit" && <PastStudentsData pastStudentList={pastStudentList} />}
          </div>
          <div className="select-none flex justify-center items-center mt-12 w-4/12 mx-auto">
            <button
              type="submit"
              className={`bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-8 font-Roboto font-[400]`}
            >
              {isLoading ? (
                <PulseLoader color="#FFF" size={8} />
              ) : id === "new-batch" ? (
                "Create Batch"
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
