import React, {useState, useEffect} from 'react'
import { Loader } from "../../Elements/Loader/Loader";
import toast from "react-hot-toast";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { useFormik } from "formik";
import { registerNewCompaniesData } from '../../Formik/FormikValidation';
import { registerInitialData } from '../../Formik/InitialValues';
import { InputBox } from '../../Elements/InputBox';
import { DropdownWithHandleOut } from '../../Elements/DropdownWithHandleOut';
import { StatusButton } from '../../Elements/StatusButton';
import { DropdownSingleInputWithCheckbox } from '../../Elements/DropdownSingleInputWithCheckbox';
import { fetchCompanyList, createNewCompany } from '../../../functions/admin/HR Management/hr_management';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';

export const RegisterCompany = ({id, mode}:any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState("Active");
  const [listOfCompaniesExisted, setListOfCompaniesExisted] = useState([] as any);

  const salaryOptions = [
    "> 2LPA",
    "2 - 4 LPA",
    "4 - 6 LPA",
    "6 - 8 LPA",
    "8 - 10 LPA",
    "< 10LPA"
  ];

  const companySizeOptions = [
    "Larger Sized(1000 Employees)",
    "Mid Sized(100 to 999 Employees)",
    "Small SIzed(10 to 99 Employees)",
    "Startup (< 10 Employees)"
  ];

  const recruitingRole = [
    "Developer",
    "Technical Consultant",
    "Project Manger",
    "Frontend Developer",
    "Backend Developer"
  ]

  useEffect(()=>{
    (async()=>{
        const companyListResponse = await fetchCompanyList();
        setListOfCompaniesExisted(companyListResponse)
    })()
  },[])

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue("status", label);
  };

  const validateUserInput = (values: any) => {
    if (listOfCompaniesExisted.includes(values.company_name)) {
      toast.error("Company Name Already Exists");
      return false;
    }
  
    if (values.location?.length < 1) {
      toast.error("Select Atleast One Location");
      return false;
    }
  
    if (values.company_location?.length < 1) {
      toast.error("Select Atleast One Company Location");
      return false;
    }
  
    if (values.recruiting_roles?.length < 1) {
      toast.error("Select Atleast One Role");
      return false;
    }
  
    return true; // All validations passed
  };
  
  const registerNewCompany = async() => {
    if (!validateUserInput(values)) {
        return;
    }
    setIsLoading(true);
    // console.log(values, "body");
    const dataResponse = await createNewCompany(values)
    if (dataResponse) {
      navigate(`/${routeNameForHome}/${routeNameForSubSection}`);
      toast.success(
        "Company Successfully Registered"
      );
    }
    setIsLoading(false);
  }

  const formik = useFormik({
    initialValues: registerInitialData,
    validationSchema: () => registerNewCompaniesData,
    onSubmit: () => {
      registerNewCompany()
    },
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue }: any = formik;

    const handleOutForDays = (event: any) => {
        const { name, value } = event.target;
        if (formik.values[name]?.includes(value)) {
          formik.setFieldValue(name, formik.values[name]?.filter((sch:any)=> sch !== value))
        } else formik.setFieldValue(name, [...formik.values[name], value])
    }

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <div className=" w-[95%] mx-auto bg-white rounded">
          <div className="text-left border-b-2 p-2">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              Register Info
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="flex flex-col w-10/12 mx-auto py-12 space-y-12"
            >
                <div className="space-y-10">
                  <div className="w-full grid grid-cols-2 gap-6">
                    <InputBox
                      label="Name"
                      placeholder="Name"
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"name"}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.name}
                      isTouched={formik.touched.name}
                      disabled={false}
                    />
                    <InputBox
                      label="Phone Number"
                      placeholder="Phone Number"
                      type={"number"}
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"phone_number"}
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.phone_number}
                      isTouched={formik.touched.phone_number}
                      disabled={false}
                    />
                    <InputBox
                      label="Email"
                      placeholder="Email"
                      type={"email"}
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"email"}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.email}
                      isTouched={formik.touched.email}
                      disabled={false}
                    />
                    <DropdownWithHandleOut
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        options={['Chennai', "Bangalore"]}
                        label="Location"
                        placeholder="Location"
                        name={"location"}
                        value={formik.values.location}
                        handleInputChange={handleOutForDays}
                        onBlur={formik.handleBlur}
                        formik={formik}
                        zIndex={"z-40"}
                    />
                    <InputBox
                      label="Company Name"
                      placeholder="Company Name"
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"company_name"}
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.company_name}
                      isTouched={formik.touched.company_name}
                      disabled={false}
                    />
                    <DropdownWithHandleOut
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        options={['Chennai', "Bangalore"]}
                        label="Company Locations"
                        placeholder="Company Locations"
                        name={"company_location"}
                        value={formik.values.company_location}
                        handleInputChange={handleOutForDays}
                        onBlur={formik.handleBlur}
                        formik={formik}
                        zIndex={"z-30"}
                    />
                    <DropdownSingleInputWithCheckbox
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        label="Salary Range"
                        placeholder="Salary Range"
                        options={salaryOptions}
                        name={"salary_range"}
                        value={formik.values.salary_range}
                        onBlur={formik.handleBlur}
                        error={formik.errors.salary_range}
                        isTouched={formik.touched.salary_range}
                        mapThrough={"salary_range"}
                        formik={formik}
                        zIndex={"z-40"}
                        disabled={false}
                    />
                    <DropdownSingleInputWithCheckbox
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        label="Company Size"
                        placeholder="Company Size"
                        options={companySizeOptions}
                        name={"company_size"}
                        value={formik.values.company_size}
                        onBlur={formik.handleBlur}
                        error={formik.errors.company_size}
                        isTouched={formik.touched.company_size}
                        mapThrough={"company_size"}
                        formik={formik}
                        zIndex={"z-20"}
                        disabled={false}
                    />
                    <DropdownWithHandleOut
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        options={recruitingRole}
                        label="Recruiting Roles"
                        placeholder="Recruiting Roles"
                        name={"recruiting_roles"}
                        value={formik.values.recruiting_roles}
                        handleInputChange={handleOutForDays}
                        onBlur={formik.handleBlur}
                        formik={formik}
                        zIndex={"z-30"}
                    />
                    <InputBox
                      label="Hiring Count Per Year"
                      placeholder="Hiring Count Per Year"
                      type={"number"}
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"hiring_count_per_year"}
                      value={formik.values.hiring_count_per_year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.hiring_count_per_year}
                      isTouched={formik.touched.hiring_count_per_year}
                      disabled={false}
                    />
                    <div className="space-y-0.5 flex flex-col w-full relative">
                        <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
                        About Us
                        </label>
                        <textarea
                        name="about_us"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Here you can write"
                        value={values.about_us}
                        disabled={mode === "view"}
                        className="min-h-[150px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
                        >
                        {values.description}
                        </textarea>
                        {touched.about_us && errors.about_us && (
                        <span className="text-red-500 absolute -bottom-6 right-0">
                            {errors.about_us}
                        </span>
                        )}
                    </div>
                  </div>
                <StatusButton
                    type={"submit"}
                    buttonText={'Add New Company'}
                    CheckValue={CheckValue}
                    checked={checked}
                    buttonWidth={"w-full"}
                    show={true}
                />
                </div>
          </form>
        </div>
      )}
    </div>
  )
}
