import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Cancelled Session/ActionComponentForVerified";
import { getCancelledSessionsByFita } from "../../functions/admin/Course Progress/courseProgress";

export const cancellationsessionByFita = {
  headers: [
    {
      name: "Date",
      apiKey: "created_at",
      headerClass: "w-[120px] text-center",
      className: "text-center break-words",
    },
    {
      name: "Batch Code",
      apiKey: "batch_code",
      headerClass: "w-[120px] text-center",
      className: "text-center break-words",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[100px] text-center",
      className: "text-center break-words",
    },
    {
      name: "Branch",
      apiKey: "branch_name",
      headerClass: "w-[120px] text-center",
      className: "text-center break-words",
    },
    {
      name: "City",
      apiKey: "city_name",
      headerClass: "w-[100px] text-center",
      className: "text-center break-words",
    },

    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return getCancelledSessionsByFita(params);
    },
  component: "bulkCancellation",
  filters: {
    dateFilters: [
      {
        name: "Cancelled Date",
        apiKey: "created_at",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
    ],
    checkBox: [
      {
        name: "City",
        apiKey: "city_name",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
      {
        name: "Branch",
        apiKey: "branch_name",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["course_name", "city_name, branch_name", "batch_code"],
        searchKey: "batch_start_datecourse",
        name: "Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
