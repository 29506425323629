import { ViewEditDeleteActionComponentForStudent } from "../../Components/Action Center/Pending Payment/ActionComponentForStudent";
import { getPendingPaymentsTableData } from "../../functions/admin/Action center/PendingPayments/PendingPayments";

export const pendingPaymentForStudent = {
    headers: [
      {
        name: "Student Name",
        apiKey: "student_name",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Phone Number",
        apiKey: "student_number",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Course",
        apiKey: "course_name",
        headerClass: "w-[140px] text-start px-2",
        className: "w-[140px] text-start break-words px-2",
      },
      {
        name: "Schedule",
        apiKey: "schedule",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start px-2",
      },
      {
        name: "Status",
        apiKey: "status",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start px-2",
      },
      {
        name: "Completion(%)",
        apiKey: "payment_completion",
        headerClass: "w-[120px] text-center px-2",
        className: "w-[120px] text-center break-words px-2",
      },
      {
        name: "Total Fee",
        apiKey: "total_fee",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Paid",
        apiKey: "total_paid",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Balance Due",
        apiKey: "balance_due",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      
      {
        name: "BC Name",
        apiKey: "crm_name",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Trainer Name",
        apiKey: "trainer_name",
        headerClass: "w-[100px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Trainer Phone Number",
        apiKey: "trainer_number",
        headerClass: "w-[120px] text-start px-2",
        className: "w-[120px] text-start break-words px-2",
      },
      {
        name: "Action",
        apiKey: "Courses",
        isComponent: true,
        Comp: ViewEditDeleteActionComponentForStudent, //Will render the component in Table,
        headerClass: "w-[100px] text-center",
        className: "w-[100px] text-center",
      },
    ],
  BackendFunctionCall: (params: any) => { return getPendingPaymentsTableData(params) },
  component: 'PendingPaymentTotals',
    filters: {
      dateFilters: [
        {
          name: "Batch Created Date",
          apiKey: "date",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Follow-Up Date",
          apiKey: "follow_up_date",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
      ],
      checkBox: [
        {
          name: "City",
          apiKey: "city_name",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Branch",
          apiKey: "branch_name",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Course",
          apiKey: "course_name",
          isSearchNeeded: true,
          className: "w-[250px]",
        },
        {
          name: "Completion(%)",
          apiKey: "payment_completion",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Progress Range(%)",
          apiKey: "progress_range",
          isSearchNeeded: true,
          className: "w-[180px]",
        },
        {
          name: "BC Name",
          apiKey: "crm_name",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Student Status",
          apiKey: "status",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Batch Status",
          apiKey: "batch_status",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Schedule",
          apiKey: "schedule",
          isSearchNeeded: true,
          className: "w-[170px]",
        },
        {
          name: "Ownership",
          apiKey: "ownership",
          isSearchNeeded: true,
          className: "w-[170px]"       
        },
      ],
      searchBox: [
        {
          matchFields: ["student_name", "student_number", "course"],
          searchKey: "student_nameCourse",
          name: "Student Search...",
          searchStyle: "w-[220px]",
        }
      ]
    },
    // ActionComponent: ViewEditDeleteActionComponent,
  };