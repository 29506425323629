import { errorNotifier } from "../../../commonHelper";
import { TableNames } from "../../../config/Tables";
import { FetchData, InsertData } from "../../../crud";
import { dateFormat } from "../../../../Services/CommonFunctions";
import _ from "lodash";
import { submitUserLogs } from "../../User Logs/userLogs";
import { PaymentFollowUpInterface } from "./interface";
import { UserLogInterface } from "../../User Logs/interface";

export const AbsentStudentsTableData = async (UsersDetails: any) => {
  try {
    const { data: batchData, error }: any = await FetchData(
      TableNames.batches,
      [
        "trainings(id, status, student: students(name, phoneNumber, uid), course:courses(course_name), city:cities(city_name), branch:branches(id, branch_name, ownership))",
        "batch_code",
        "end_time",
        "mode",
        "schedule",
        "sessions(*)",
        "branch:branches(id, branch_name)",
      ]
    )
      .in("branch_id", UsersDetails?.branches)
      .order("id", { ascending: false });
    if (error) throw error;
    const absentStudents: any = [];

    batchData.forEach((batch: any) => {
      const studentUidToTraining = _.keyBy(batch?.trainings, "student.uid");

      batch?.sessions.forEach((session: any) => {
        // Filter out sessions with "Compensate" status
        if (session.session_status !== "Compensate") {
          const studentUidToTrainingDuplicate = _.clone(studentUidToTraining);

          // Use lodash methods to iterate over session attendees
          _.forEach(session?.attendees, (attendent) => {
            if (_.has(studentUidToTrainingDuplicate, attendent)) {
              _.unset(studentUidToTrainingDuplicate, attendent);
            }
          });

          // Use lodash methods to iterate over the keys of the object
          _.forOwn(studentUidToTrainingDuplicate, (training, key) => {
            absentStudents.push({
              id: training?.id,
              session_id: session?.id,
              date: session?.session_date,
              batchCode: batch?.batch_code,
              studentName: training?.student?.name,
              phoneNumber: training?.student?.phoneNumber,
              endTime: session?.end_time,
              schedule: batch?.schedule,
              courseName: training?.course?.course_name,
              cityName: training?.city?.city_name,
              branchName: training?.branch?.branch_name,
              ownership: training?.branch?.ownership,
              status: training?.status,
              courseMode: batch?.mode,
              student_list:
                training?.training?.length > 0 &&
                training?.training?.map((tr: any) => tr?.student?.name),
            });
          });
        }
      });
    });
    console.log(absentStudents.reverse())
    return absentStudents.reverse();
  } catch (error) {
    return errorNotifier(error);
  }
};

export const addStudentAttendanceFollowUpDate = async (
  followUpData: PaymentFollowUpInterface
) => {
  try {
    const { data: userlogData }: any = await FetchData(TableNames.trainings, [
      "course:courses(course_name)",
      "student:students(name)",
    ]).eq("id", followUpData.training_id);
    const userlogObj: UserLogInterface = {
      user_id: followUpData.added_by,
      changes:
        followUpData.comment +
        followUpData.status +
        "Course name " +
        userlogData[0]?.course?.course_name +
        "Student name" +
        userlogData[0]?.student?.name,
      module: `StudentAttendanceFollowUp`,
    };
    const { data: followUpInsertResponse, error: followUpInsertError } =
      await InsertData(TableNames.student_absent_followups, followUpData);
    submitUserLogs(userlogObj);
    if (followUpInsertError) throw followUpInsertError;
    return followUpInsertResponse;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getAllStudentAttendanceFollowupsForTraining = async (
  Id: number
) => {
  try {
    const { data: studentFollowData, error: QueryError } = await FetchData(
      TableNames.student_absent_followups,
      ["created_at", "status", "comment"]
    ).eq(`training_id`, Id);
    if (QueryError) throw QueryError;
    return studentFollowData;
  } catch (error) {
    return errorNotifier(error);
  }
};
