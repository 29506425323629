// import { useState } from 'react';
// import { BatchDropdown } from "./BatchDropdown";
// import { updateStudentTraining, updateIndividualStudentInTraining } from '../../../functions/admin/Batches/Batches';
// import { ModalForReason } from '../../UI_Modal/ModalForReason';
// import { createApprovalRequest } from '../../../functions/admin/Action center/Student Actions/studentActions';
// import { useAppSelector } from '../../..';
// import { useNavigate } from 'react-router-dom';

// export const StudentData = ({isId, id, mode, formik, studentNameDetails, handleStudentsData}:any) => {
//     const Added_Student_Table_Header = [ "Student Name", "Student Phone Number", "Total Fee", "Amount Paid", "Balance Due", "Hours Attended", "Student Status"];
//     const cellStyle = (text?: any) => `text-[#686868] text-[14px] font-Roboto font-[400] ${text ? "w-[7%]" : "w-[10%]"} text-center`;
//     const Student_Status = ["Allocated", "Completed"];
//     const [changeStudentStatus, setChangeStudentStatus] = useState(false);
//     const { userDetails } = useAppSelector(({ login }: any) => login);
//     const navigate = useNavigate();

//     // const removeRow = async (i: number, trainingId: number) => {
//         // if (mode === "edit") {
//         // const response = await updateStudentTraining(
//         //     {
//         //         status: "Hold",
//         //         batch_id: id,
//         //     },
//         //     trainingId
//         // )}
//         // const newData = [...formik.values.add_student];
//         // newData.splice(i, 1);
//         // formik.setFieldValue("add_student", newData);
//         // const listData = [...formik.values.selected_student_list];
//         // listData.splice(i, 1);
//         // formik.setFieldValue("selected_student_list", listData);
//     // };

//     const proceedWithThatOneRow = async (reason: string, current_training: any) => {
//       const single_student_data = {
//         id: Number(current_training?.id),
//         status: current_training?.status,
//         ...(mode === "edit" && { batch_id: Number(isId) }),
//         ...(mode === "edit" && { course_id: Number(formik.values.course_id) }),
//         trainers_ids: [
//         ...(current_training?.trainers_ids ?? []),
//         ...(mode === "edit" && current_training?.trainers_ids?.includes(formik.values.trainer_id)
//             ? []
//             : [formik.values.trainer_id]),
//         ],
//         student_action_request_pending: true,
//       }
      
//       const data:any=[single_student_data]
//       console.log(data, "filterStudents")
//       const batchUpdateResponse = await updateIndividualStudentInTraining({students: data}, isId)
//       console.log(batchUpdateResponse, "response");
//       if(batchUpdateResponse) {
//         const body: any = {
//           status: "Hold",
//           reason: reason,
//           training_id: Number(current_training?.id),
//           approvalStatus: null,
//           user_id: userDetails,
//           approved_date: null,
//           latest_training: true,
//           balance_due: 0,
//           payment_details: []
//         };
//         const response = await createApprovalRequest(body);
//         if(response) navigate(0);
//       }
//     }

//     const handleStudentStatus = async(field: string, value: string | string[], trainerId?: any) => {
//         if (trainerId && mode === "edit") {
//         const trainingData: any = {
//             status: value,
//             batch_id: id,
//         };
//         const response = await updateStudentTraining(trainingData, trainerId);
//         if (response) {
//             formik.setFieldValue(field, value);
//         }
//         } else if (id === "new-batch") {
//             formik.setFieldValue(field, value);
//         }
//     }

//   const TriggerModal = (flag: any, reason:string, current_training:any) => {
//     setChangeStudentStatus(false)
//     if (flag === "Yes") {
//       proceedWithThatOneRow(reason, current_training)
//     }
//   }

//   return (
//     <div className="space-y-6">
//       <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
//         Student Details
//       </h1>
//       <div className="space-y-2">
//         <BatchDropdown
//           name="add_student"
//           label="Add Student"
//           showLabel={true}
//           options={studentNameDetails}
//           placeholder="Add Student"
//           value={formik.values.add_student}
//           onChange={handleStudentsData}
//           onBlur={formik.handleBlur}
//           useCheckbox={true}
//           multiSelect={true}
//         />
//         <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
//           <div className="bg-[#EFF7FF] flex items-center justify-between px-4 py-1 pr-4">
//             {Added_Student_Table_Header.map((head:any) => (
//               <p
//                 className={`text-[#686868] text-[14px] font-Roboto font-[500] ${
//                   head === "Student Status"
//                     ? "w-[15%]"
//                     : head === "Hours Attended"
//                     ? "w-[7%]"
//                     : "w-[10%]"
//                 } text-center`}
//               >
//                 {head}
//               </p>
//             ))}
//           </div>
//           <div className="min-h-[60px] max-h-[300px] overflow-y-auto pt-2 pr-4">
//             {formik.values?.selected_student_list?.length > 0 &&
//               formik.values?.selected_student_list.map(
//                 (body: any, i: number) => {
//                   return (
//                     <div
//                       key={i}
//                       className={`relative flex items-center justify-between px-10 py-1 ${
//                         i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
//                       }`}
//                     >
//                       <ModalForReason
//                         open={changeStudentStatus}
//                         setOpen={setChangeStudentStatus}
//                         func={TriggerModal}
//                         current_training={body}
//                       />
//                       {/* () => removeRow(i, body?.id) */}
//                       <p className={`${cellStyle()} break-words`}>
//                         {body?.student?.name}
//                       </p>
//                       <p className={`${cellStyle()}`}>
//                         {body?.student?.phoneNumber}
//                       </p>
//                       <p className={`${cellStyle()}`}>
//                         {body.total_fee > 0 && (
//                           <span className="px-1">&#8377;</span>
//                         )}
//                         {body?.total_fee >= 0
//                           ? body?.total_fee === 0
//                             ? 0
//                             : Number(body?.total_fee)?.toFixed(2)
//                           : "-"}
//                       </p>
//                       <p className={`${cellStyle()}`}>
//                         {body?.total_fee > 0 && (
//                           <span className="px-1">&#8377;</span>
//                         )}
//                         {body?.total_fee >= 0
//                           ? body?.total_fee === 0
//                             ? 0
//                             : (
//                                 Number(body?.total_fee) -
//                                 Number(body?.balance_due)
//                               )?.toFixed(2)
//                           : "-"}
//                       </p>
//                       <p className={`${cellStyle()}`}>
//                         {body?.balance_due > 0 && (
//                           <span className="px-1">&#8377;</span>
//                         )}
//                         {body?.balance_due >= 0
//                           ? body?.balance_due === 0
//                             ? 0
//                             : Number(body.balance_due)?.toFixed(2)
//                           : "-"}
//                       </p>
//                       <p className={`${cellStyle(0)}`}>
//                         {body?.hours_attended !== null
//                           ? body?.hours_attended
//                           : 0}
//                       </p>
//                       {body?.student_action_request_pending ? <p className={`text-[#686868] text-[14px] font-Roboto font-[400] w-[15%] text-center`}>Request Pending</p> : <BatchDropdown
//                         name={`selected_student_list[${i}]["status"]`}
//                         label=""
//                         showLabel={false}
//                         options={Student_Status}
//                         placeholder=""
//                         value={
//                           formik.values?.selected_student_list?.[i]?.status
//                         }
//                         onChange={handleStudentStatus}
//                         onBlur={formik.handleBlur}
//                         useCheckbox={true}
//                         className={"w-[15%]"}
//                         trainerId={body?.id}
//                       />}
//                       {!body?.student_action_request_pending && <div
//                         onClick={() => setChangeStudentStatus(true)}
//                         className="absolute -right-1 select-none bg-[#FF0000] px-2 w-4 h-4 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center"
//                       >
//                         {id === "new-batch" ? "-" : "x"}
//                       </div>}
//                     </div>
//                   );
//                 }
//               )}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

import { useState } from 'react';
import { BatchDropdown, BatchDropdownAsModal } from "./BatchDropdown";
import { updateStudentTraining, updateIndividualStudentInTraining } from '../../../functions/admin/Batches/Batches';
import { ModalForReason } from '../../UI_Modal/ModalForReason';
import { createApprovalRequest } from '../../../functions/admin/Action center/Student Actions/studentActions';
import { useAppSelector } from '../../..';
import { useNavigate } from 'react-router-dom';

export const StudentData = ({ isId, id, mode, formik, studentNameDetails, handleStudentsData }: any) => {
    const Added_Student_Table_Header = ["Student Name", "Student Phone Number", "Total Fee", "Amount Paid", "Balance Due", "Hours Attended", "Student Status"];
    const Student_Status = ["Allocated", "Completed"];
    const [changeStudentStatus, setChangeStudentStatus] = useState(false);
    const { userDetails } = useAppSelector(({ login }: any) => login);
    const navigate = useNavigate();

    const removeRow = async (i: number, body: any) => {
      if (mode === "edit" && body?.trainers_ids!==undefined) {
        // console.log("ENTERS")
      const response = await updateStudentTraining(
          {
              status: "Hold",
              batch_id: id,
          },
          body?.id
      )}
      const newData = [...formik.values.add_student];
      newData.splice(i, 1);
      formik.setFieldValue("add_student", newData);
      const listData = [...formik.values.selected_student_list];
      listData.splice(i, 1);
      formik.setFieldValue("selected_student_list", listData);
    };

    const proceedWithThatOneRow = async (reason: string, current_training: any) => {
        const single_student_data = {
            id: Number(current_training?.id),
            status: current_training?.status,
            ...(mode === "edit" && { batch_id: Number(isId) }),
            ...(mode === "edit" && { course_id: Number(formik.values.course_id) }),
            trainers_ids: [
                ...(current_training?.trainers_ids ?? []),
                ...(mode === "edit" && current_training?.trainers_ids?.includes(formik.values.trainer_id)
                    ? []
                    : [formik.values.trainer_id]),
            ],
            student_action_request_pending: true,
        };

        const data: any = [single_student_data];
        const batchUpdateResponse = await updateIndividualStudentInTraining({ students: data }, isId);
        if (batchUpdateResponse) {
            const body: any = {
                status: "Hold",
                reason: reason,
                training_id: Number(current_training?.id),
                approvalStatus: null,
                user_id: userDetails,
                approved_date: null,
                latest_training: true,
                balance_due: 0,
                payment_details: []
            };
            const response = await createApprovalRequest(body);
            if (response) navigate(0);
        }
    };

    const handleStudentStatus = async (field: string, value: string | string[], trainerId?: any) => {
        if (trainerId && mode === "edit") {
            const trainingData: any = {
                status: value,
                batch_id: id,
            };
            const response = await updateStudentTraining(trainingData, trainerId);
            if (response) {
                formik.setFieldValue(field, value);
            }
        } else if (id === "new-batch") {
            formik.setFieldValue(field, value);
        }
    };

    const TriggerModal = (flag: any, reason: string, current_training: any) => {
        setChangeStudentStatus(false);
        if (flag === "Yes") {
            proceedWithThatOneRow(reason, current_training);
        }
    };

    return (
      <div className="space-y-6">
        <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
          Student Details
        </h1>
        <div className="space-y-2">
          <BatchDropdown
            name="add_student"
            label="Add Student"
            showLabel={true}
            options={studentNameDetails}
            placeholder="Add Student"
            value={formik.values.add_student}
            onChange={handleStudentsData}
            onBlur={formik.handleBlur}
            useCheckbox={true}
            multiSelect={true}
          />
          <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
            <div className="bg-[#EFF7FF] grid grid-cols-7 gap-4 px-4 py-1">
              {Added_Student_Table_Header.map((head: any) => (
                <p
                  key={head}
                  className="text-[#686868] text-[14px] font-Roboto font-[500] text-center"
                >
                  {head}
                </p>
              ))}
            </div>
            <div className="min-h-[60px] max-h-[300px] overflow-y-auto pt-2">
              {formik.values?.selected_student_list?.length > 0 &&
                formik.values?.selected_student_list.map(
                  (body: any, i: number) => {
                    return (
                      <div
                        key={i}
                        className={`grid grid-cols-7 gap-4 items-center px-4 py-1 ${
                          i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
                        }`}
                      >
                        <ModalForReason
                          open={changeStudentStatus}
                          setOpen={setChangeStudentStatus}
                          func={TriggerModal}
                          current_training={body}
                        />

                        {/* Table Row Data */}
                        <p className="text-[#686868] text-[14px] font-Roboto text-center break-words">
                          {body?.student?.name}
                        </p>
                        <p className="text-[#686868] text-[14px] font-Roboto text-center">
                          {body?.student?.phoneNumber}
                        </p>
                        <p className="text-[#686868] text-[14px] font-Roboto text-center">
                          {body.total_fee > 0 && (
                            <span className="px-1">&#8377;</span>
                          )}
                          {body?.total_fee >= 0
                            ? Number(body?.total_fee)?.toFixed(2)
                            : "-"}
                        </p>
                        <p className="text-[#686868] text-[14px] font-Roboto text-center">
                          {body?.total_fee > 0 && (
                            <span className="px-1">&#8377;</span>
                          )}
                          {body?.total_fee >= 0
                            ? (
                                Number(body?.total_fee) -
                                Number(body?.balance_due)
                              )?.toFixed(2)
                            : "-"}
                        </p>
                        <p className="text-[#686868] text-[14px] font-Roboto text-center">
                          {body?.balance_due > 0 && (
                            <span className="px-1">&#8377;</span>
                          )}
                          {body?.balance_due >= 0
                            ? Number(body.balance_due)?.toFixed(2)
                            : "-"}
                        </p>
                        <p className="text-[#686868] text-[14px] font-Roboto text-center">
                          {body?.hours_attended ?? 0}
                        </p>

                        <div className="flex items-center justify-center">
                          {body?.student_action_request_pending ? (
                            <p className="text-[#686868] text-[14px] font-Roboto text-center">
                              Request Pending
                            </p>
                          ) : (
                            <>
                              <BatchDropdownAsModal
                                name={`selected_student_list[${i}]["status"]`}
                                label=""
                                showLabel={false}
                                options={Student_Status}
                                placeholder=""
                                value={
                                  formik.values?.selected_student_list?.[i]
                                    ?.status
                                }
                                onChange={handleStudentStatus}
                                onBlur={formik.handleBlur}
                                useCheckbox={true}
                                trainerId={body?.id}
                                className="text-center"
                              />
                              {!body?.student_action_request_pending && <div
                                onClick={(id === "new-batch" || body?.trainers_ids===undefined) ? () => removeRow(i, body) : () => setChangeStudentStatus(true)}
                                className="ml-2 bg-[#FF0000] w-6 h-6 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center"
                              >
                                {(id === "new-batch" || body?.trainers_ids===undefined) ? "-" : "x"}
                              </div>}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    );
};

