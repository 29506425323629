import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Calculate Trainer Payment/ActionComponent";
import { getCalculateTrainerPaymentTableDataForFC } from "../../functions/admin/Action center/Trainer Payments/trainerPayment";

export const calculateTrainerPaymentforfc = {
    headers: [
        {
            name: "Batch Code",
            apiKey: "batch_code",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[140px] text-start",
            className: "w-[140px] text-start px-2 break-words",
        },
        {
            name: "Schedule",
            apiKey: "schedule",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Trainer Name",
            apiKey: "trainer_name",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Trainer Phone Number",
            apiKey: "trainer_mobile",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        
        {
            name: "Batch Progress(%)",
            apiKey: "batch_progress",
            headerClass: "w-[100px] text-center px-2",
            className: "w-[100px] text-center px-2 break-words",
        },
        
        {
            name: "Batch Status",
            apiKey: "status",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Batch Start Date",
            apiKey: "batch_start_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Branch",
            apiKey: "branchName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "City",
            apiKey: "cityName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
       
        {
            name: "Action",
            apiKey: "Courses",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center",
        },
    ],
    BackendFunctionCall: (params:any)=>{return getCalculateTrainerPaymentTableDataForFC(params)},
    
    filters: {
        checkBox: [
            {
                name: "Batch Start Date",
                apiKey: "batch_start_date",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Batch Code",
                apiKey: "batch_code",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "City",
                apiKey: "cityName",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Branch",
                apiKey: "branchName",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Course",
                apiKey: "course_name",
                isSearchNeeded: true,
                className: "w-[250px]",
            },
            {
                name: "Schedule",
                apiKey: "schedule",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Status",
                apiKey: "status",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Batch Payment Status",
                apiKey: "batch_payment_status",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Ownership",
                apiKey: "ownership",
                isSearchNeeded: true,
                className: "w-[200px]",
            }

        ],
        searchBox: [
            {
                matchFields: ["trainer_name", "trainer_mobile", "course_name"],
                searchKey: "batch_start_datecourse",
                name: "Trainer Search...",
                searchStyle: "w-[220px]",
            },
            {
                matchFields: ["student_list"],
                searchKey: "stud",
                name: "Student Search...",
                searchStyle: "w-[220px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};