import { AddItemsButton } from "../../Elements/AddItemsButton";
import { InputBox } from "../../Elements/InputBox";

export const AddKeySkills = ({
  addMoreRooms,
  roomsCount,
  deleteRooms,
  formik,
  name,
  dataSetup,
}: any) => {
  return (
    <div className="w-12/12 pt-3 relative z-0">
      <AddItemsButton
        text={"Add New Skills"}
        classNameInput={"absolute right-0 -top-4 z-10"}
        onClick={addMoreRooms}
      />
      <div className="space-y-2 z-0">
        <InputBox
          label={"Skills:"}
          placeholder={"Skills"}
          classNameInput={"w-full"}
          name={name}
          value={formik.values.skills[0]}
          onChange={dataSetup}
          onBlur={formik.handleBlur}
          index={0}
        />
        {roomsCount.map((room: any, i: number) => {
          return (
            <div key={i} className="w-full flex items-center gap-2 relative">
              <InputBox
                placeholder={`Skills ${i + 2}`}
                classNameInput={"w-full"}
                topDivStyle={"w-full"}
                name={name}
                value={formik.values.skills[i + 1]}
                onChange={dataSetup}
                onBlur={formik.handleBlur}
                index={`${i + 1}`}
              />
              <div
                onClick={() => deleteRooms(i, formik.values.skills[i + 1])}
                className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9"
              >
                x
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};