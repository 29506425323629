import { useState, useEffect, useRef } from 'react';
import boldArrow from "../../Assets/boldArrow.svg";

export const DropdownWithHandleOut = ({
  formik,
  options,
  label,
  placeholder,
  name,
  value,
  onBlur,
  classNameInput,
  topDivStyle,
  zIndex,
  handleInputChange
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef: any = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClick = (event: any) => {
    event.stopPropagation(); // Prevent event bubbling
  };

  const searchFilter: any = (option: any) => {
    return option && option.toLowerCase().includes(searchTerm.toLowerCase());
  };

  return (
    <div className={`${topDivStyle} space-y-0.5`} ref={dropdownRef}>
      <label className="text-[#707070] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500]">
        {label}
      </label>
      <div
        className={`relative ${zIndex ? zIndex : "z-10"} cursor-pointer`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          value={(name==="student"||name==="to_student"||name==="job_type"||name==="company_name"||name==="job_status"||name==="hired_by") ? value : value && value.map((option: any) => option).join(", ")}
          onClick={toggleDropdown}
          placeholder={placeholder}
          readOnly
          className={`pr-14 outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 scroll-smooth overflow-y-scroll scrollbar-hide ${classNameInput}`}
        />
        <img
          src={boldArrow}
          alt="boldArrow"
          className={`absolute top-5 right-4 ${isOpen && "rotate-180"}`}
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen && (
          <div
            onClick={handleDropdownClick}
            className={`z-20 shadow-md shadow-[#00000029] absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px] scroll-smooth overflow-y-scroll`}
          >
            <div className="flex justify-between items-center px-2 py-2 border-b border-gray-200">
              <input
                type="text"
                className={`w-10/12 rounded-md outline-none border-gray-300 px-2 py-1`}
                placeholder="Search..."
                value={searchTerm}
                autoFocus
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="max-h-60">
              {options &&
                options.filter(searchFilter).map((opt: any, index: number) => (
                  <label
                    key={index}
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      // id={`checkbox_${index}`}
                      className="h-4 w-4"
                      placeholder={placeholder}
                      name={name}
                      value={opt}
                      checked={(name==="student"||name==="to_student"||name==="job_type"||name==="company_name"||name==="job_status"||name==="hired_by")?value===opt:value.includes(opt)}
                      onChange={handleInputChange}
                      onBlur={onBlur}
                    />
                    <span
                    //   htmlFor={`checkbox_${index}`}
                      className="ml-2 text-gray-900 font-Roboto select-none"
                    >
                      {opt}
                    </span>
                  </label>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

