import { Components } from "./ModuleImports";
import { CompModal } from "../../UI_Modal/CompModal";

export const SessionDetails = ({ Sessions }: any) => {
  const { SessionData, useState }: any = Components;
  const [show, setShow] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const SessionHeader = [
    "Session Date",
    "Trainer Name",
    "Trainer Phone Number",
    "Topic",
    "Start Time",
    "End Time",
    "Duration",
    "Feedback",
  ];

  const columnWidths = [
    "w-[12%]",
    "w-[20%]",
    "w-[20%]",
    "w-[20%]",
    "w-[12%]",
    "w-[12%]",
    "w-[12%]",
    "w-[20%]",
  ];

  const SessionClassName = (index: number, isClickable?: boolean) =>
    `${columnWidths[index]} ${isClickable ? "text-[#036AD1] cursor-pointer" : "text-[#686868]"
    } py-1 text-[14px] font-Roboto font-[400] text-center select-none`;
  
  const openFeedbackModal = (comment: string) => {
    setFeedbackContent([comment]);
    setModalTitle("Feedback Comment");
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setFeedbackContent("");
  };

  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Session Details
      </h1>

      <CompModal
        show={show}
        fun={closeModal}
        content={feedbackContent}
        title={modalTitle}
      />

      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
        <div className="flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4">
          {SessionHeader.map((head: any, index: number) => (
            <h1
              key={head}
              className={`text-[#000000] text-[16px] font-Roboto font-[500] text-center ${columnWidths[index]}`}
            >
              {head}
            </h1>
          ))}
        </div>
        <div className="min-h-[100px] max-h-[400px] scroll-smooth overflow-y-scroll">
          {Sessions.map((arr: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-between py-1.5 px-4 ${index % 2 !== 0 && "bg-[#EFF7FF]"}`}
              >
                <p className={SessionClassName(0)}>{arr.session_date}</p>
                <p className={SessionClassName(1)}>{arr.trainer_name}</p>
                <p className={SessionClassName(2)}>{arr.trainer_phone_number}</p>
                <p className={SessionClassName(3)}>
                  {arr.details?.topic}
                </p>
                <p className={SessionClassName(4)}>{arr.start_time}</p>
                <p className={SessionClassName(5)}>{arr.end_time}</p>
                <p className={SessionClassName(6)}>{`${arr.duration} ${Number(arr.duration) > 1 ? "Hrs" : "Hr"}`}</p>

                <p
                  className={SessionClassName(7, arr.feedback && arr.feedback[0]?.response!==undefined && arr.feedback?.[0]?.comment!=="")}
                  onClick={() => arr.feedback && arr.feedback[0]?.response!==undefined && arr.feedback?.[0]?.comment!=="" && openFeedbackModal(arr.feedback?.[0]?.comment)}
                >
                  {arr.feedback && arr.feedback[0] ? arr.feedback[0].response : "No Feedback"}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
