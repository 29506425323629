import { getImports } from "./ModuleImports";
import { useAppSelector } from "../../..";
import { dateFormat } from "../../../Services/CommonFunctions";
import { InvoiceData } from "./PDF/InvoiceData";
import { ModalForInvoice } from "./PDF/ModalForInvoice";
import { PulseLoader } from 'react-spinners';
import { CustomizationCourse } from "./CustomizationCourse";
import { createCourse, getCourseByIdForCustomization } from "../../../functions/admin/Courses/Courses";
import { StudentDiscountModal } from "../../UI_Modal/StudentDiscountModal";

export const CreateStudentCourse = ({
  id,
  mode,
  swapComp,
  setSwapComp,
}: any) => {
  const {
    useState,
    useEffect,
    useRef,
    Loader,
    onKeyDown,
    add_StudentCourse,
    useSharedLogic,
    FormikCommonFunc,
    BasicInfo,
    CourseStatus,
    CourseDetails,
    Payment,
    IsBalanceAvailable,
    CommentSection,
    getPreloadData,
    getCourseById,
    formatNumberWithCommas,
    AddStudentCourse,
    registerNewStudent,
    createSearchParams,
    BatchFollowUp,
    Add_Batch,
    createTrainingForStudent,
    toast,
    getStudentManagementDetailsById,
    updateStudentManagementDetails,
    getBatchFollowUp,
    getBranchForGST,
    getStudentsData,
  }: any = getImports;
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForEdit, setIsLoadingForEdit] = useState(false);
  const [courseData, setCourseData] = useState([] as any);
  const [courseNames, setCourseNames] = useState([] as any);
  const [scheduleData, setScheduleData] = useState([] as any);
  const [cityData, setCityData] = useState([] as any);
  const [cityNames, setCityNames] = useState([] as any);
  const [branchData, setBranchData] = useState([] as any);
  const [branchNames, setBranchNames] = useState([] as any);
  const Course_status = [
    // "Allocated",
    // "Unallocated",
    // "Hold",
    // "Dropped",
    // "Review",
    "Completed",
  ];
  const [initialCourseFee, setInitialCourseFee] = useState("");
  const [initialCourseFeeForCustomization, setInitialCourseFeeForCustomization] = useState("");
  const [studentData, setStudentData] = useState([] as any);
  const [existingStudent, setExistingStudent] = useState([] as any);
  const [courseQuery, setCourseQuery] = useState([] as any);
  const [cityBasedCourseQuery, setCityBasedCourseQuery] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adjustPaymentData, setAdjustPaymentData] = useState(0);
  const [showPopup, setShowPopup] = useState(false);     

  const Payment_Type = ["Registration", "Installment"];
  const Payment_Mode = ["Cash", "Swiping Machine", "RazorPay", "UPI"];
  const isId: any = id !== "new-student-course" && id;
  const [showBatches, setShowBatches] = useState([] as any);
  const [courseModeData, setCourseModeData] = useState([] as any);
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [courseDetails, setCourseDetails] = useState({} as any);
  const [continueWithFee, setContinueWithFee] = useState(false);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        setIsLoadingForEdit(true);
        const readData = await getPreloadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities,
        });
        const getStudentList = await getStudentsData();
        setCityBasedCourseQuery(readData?.cities);
        setCourseData(readData?.courses);
        // setCityData(readData?.cities);
        setStudentData(getStudentList);
        // setBranchData(readData?.branches);
        if (readData) {
          const mapCourse: any =
            readData &&
            readData?.courses.map((course: any) => course.course_name);
          setCourseNames(mapCourse);
          // const mapCity: any = readData && readData?.cities.map((city: any) => city.city_name)
          // setCityNames(mapCity)
        }
        if (id !== "new-student-course") {
          const UpdateDataById: any = await getStudentManagementDetailsById(
            isId
          );
          const { batch_start_date, paymentDetails, updatedStudentData, adjustedPayment }: any =
            UpdateDataById;
          const {
            balance_due,
            course_schedule,
            cd_comment,
            course_fee,
            customized_course,
            // customization_duration,
            discount_need,
            customization_fee,
            discount_percentage,
            batch_follow_up,
            status,
            total_fee,
            student_id,
            branch_id,
            city_id,
            course_id,
            comments,
            placement,
            expected_start_date,
            batch_follow_up_date,
            created_at,
            invoice_details,
            mode,
          }: any = updatedStudentData;

          if (adjustedPayment?.length > 0) {
            const filterAdjustedAmount = paymentDetails?.length > 0 && adjustedPayment?.filter((fil:any)=> Number(fil?.from_training?.id)===Number(id))
            const mapAdjustedAmount = filterAdjustedAmount?.map((amt: any) =>
              Number(amt?.amount)
            );
            const sumAdjustedAmount =
              mapAdjustedAmount &&
              mapAdjustedAmount.reduce(
                (accumulator: any, currentValue: any) => accumulator + currentValue,
                0
              );
            setAdjustPaymentData(sumAdjustedAmount);
          }
          const PaymentChecker =
            paymentDetails &&
            paymentDetails?.map((det: any) => {
              return {
                ...det,
                payment_date: dateFormat(det?.payment_date, "YYYY-MM-DD"),
                paid_amount: parseFloat(Number(det?.paid_amount).toFixed(1)),
              };
            });
          const filterCourses: any = readData?.courses?.filter(
            (course: any) => course?.course_name === course_id?.course_name
          );
          const mapCourse: any =
            filterCourses && filterCourses?.map((course: any) => course.id);
          const filterBranch: any = readData?.branches?.filter(
            (branch: any) => branch?.branch_name === branch_id?.branch_name
          );
          const mapBranch: any =
            filterBranch && filterBranch?.map((branch: any) => branch.id);
          const filterCity: any = readData?.cities?.filter(
            (city: any) => city?.city_name === city_id?.city_name
          );
          const mapCity: any =
            filterCity && filterCity?.map((city: any) => city.id);
          const getBatches = await getBatchFollowUp(batch_follow_up);
          const Data: any =
            getBatches &&
            getBatches?.map((get: any) => ({
              follow_up_date: get.follow_up_date,
              comment: get.comment,
            }));
          setShowBatches(getBatches);
          const hourly_fees = (Number(course_fee) / values.duration)
          if (UpdateDataById) {
            setInitialCourseFee(formatNumberWithCommas(course_fee));
            const splitCodeAndNumber = student_id.phoneNumber.split(' ', 2);
            setValues(
              {
                ...values,
                expected_start_date,
                customized_course,
                // customization_duration,
                created_at: dateFormat(created_at, "YYYY-MM-DD"),
                batch_follow_up_show: Data,
                payment_details:
                  PaymentChecker?.length > 0
                    ? PaymentChecker
                    : [
                        {
                          payment_type: ["Registration"],
                          paid_amount: null,
                          payment_date: "",
                          mode_of_payment: "RazorPay",
                          transaction_id: "",
                        },
                      ],
                hourly_fee: hourly_fees,
                balance_due,
                course_schedule,
                cd_comment,
                course_fee: formatNumberWithCommas(course_fee),
                discount_need,
                customization_fee,
                discount_percentage,
                batch_follow_up,
                status,
                total_fee,
                number_code: splitCodeAndNumber?.[0],
                phone_number: splitCodeAndNumber?.[1],
                email: student_id?.email,
                student_name: student_id?.name,
                student_id: student_id?.uid,
                comments,
                course_name: course_id?.course_name,
                course_mode: mode,
                // course_mode: course_id?.mode,
                branch_name: branch_id?.branch_name,
                city_name: city_id?.city_name,
                course_id: mapCourse?.[0],
                branch_id: mapBranch?.[0],
                city_id: mapCity?.[0],
                placement,
                invoice_details,
                batch_follow_up_date:
                  batch_follow_up_date !== null ? batch_follow_up_date : "",
              },
              true
            );
          }
        }
        setIsLoadingForEdit(false);
      }
    })();
  }, []);

  const validateUserInput = (values: any) => {
    const errors = [];

    // Course Fee Validation
    if (!values.course_fee) {
      errors.push("Course Fee is required.");
    } else if (
      initialCourseFee?.replace(/,/g, "") !==
      values.course_fee?.replace(/,/g, "")
    ) {
      if (values.cd_comment === "") {
        errors.push("Course fee has changed. Please enter a Course Comment.");
      }
    }

    // Discount Validation
    // if (values.discount_need && values.discount_percentage === null) {
    //   errors.push("Please Enter Discount %");
    // }

    // Batch Follow Up Validation
    if (!values.batch_follow_up.length) {
      errors.push("Please Select Batch Follow Up Date");
    }

    if (values.customization_course_name!=="" && values.customization_duration==="") {
      errors.push("Please Enter Course Duration");
    }

    if (errors.length) {
      errors.forEach((error) => toast.error(error));
      return false;
    }

    return true;
  };

  const forStudentNewCourse = async () => {
    if (!validateUserInput(values)) {
      return;
    }
    setIsLoading(true);
    try {
      let {
        branch_based_GST,
        created_at,
        crm_id,
        course_mode,
        course_fee,
        // total_fee,
        phone_number,
        student_name,
        email,
        discount,
        batch_id,
        course_name,
        branch_name,
        city_name,
        batch_follow_up_show,
        student_id,
        number_code,
        customized_course,
        customization_duration,
        customization_course_name,
        hourly_fee,
        ...rest
      }: any = values;

      let courseCustomizationID = null;

      if(customization_duration!=="" && !customized_course) {
        const { id, created_at, ...rest } = courseDetails;
        const courseCustomizationData = {
          ...rest,
          course_name: customization_course_name,
          duration: customization_duration,
          customized_course: true
        }
        const new_course_response:any = await createCourse(courseCustomizationData);
        if (new_course_response) {
          courseCustomizationID = new_course_response?.[0]?.id;
        }
      }

      const body: any = {
        ...rest,
        customized_course: customization_duration!=="" ? true : customized_course,
        discount_percentage: rest?.discount_percentage===0 ? null : rest?.discount_percentage,
        created_at: dateFormat(created_at, "YYYY-MM-DD"),
        crm_id: userDetails,
        course_fee: course_fee?.replace(/,/g, ""),
        total_fee: rest?.total_fee,
        balance_due: rest?.balance_due,
        mode: course_mode,
        student_id,
        course_id: courseCustomizationID!==null ? courseCustomizationID : rest?.course_id,
      };

      const studentDetails: any = {
        email: email,
        phone_number: `${number_code} ${phone_number?.toString()}`,
        name: student_name, 
        uid: student_id,
        course_fee: course_fee?.replace(/,/g, ""),
      };

      let apiResponse;
      if (id === "new-student-course") {
        apiResponse = await createTrainingForStudent(
          body,
          email,
          `${number_code} ${phone_number?.toString()}`,
          student_name
        );
      } else {
        apiResponse = await updateStudentManagementDetails(
          body,
          isId,
          studentDetails
        );
      }
      if (
        id === "new-student-course" &&
        apiResponse.message === "Course already enrolled for this student"
      ) {
        toast.error(apiResponse.message);
      } else if (
        id === "new-student-course" ? apiResponse?.status : apiResponse
      ) {
        navigate({
          pathname: `/${routeNameForHome}`,
          search: createSearchParams({
            id: `${
              id === "new-student-course" ? "created-success" : "update-success"
            }`,
          }).toString(),
        });
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const {
    formik,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  }: any = FormikCommonFunc(
    add_StudentCourse,
    AddStudentCourse,
    forStudentNewCourse
  );

  useEffect(() => {
    (async () => {
      if (values.phone_number) {
        const filterStudent =
          studentData?.length > 0 &&
          studentData.filter(
            (stu: any) => stu.phoneNumber === `${values.number_code} ${values.phone_number?.toString()}`
          );
        if (filterStudent.length > 0) {
          setExistingStudent(filterStudent);
          setValues(
            {
              ...values,
              student_name: filterStudent[0]?.name,
              email: filterStudent[0]?.email,
              student_id: filterStudent[0]?.uid,
            },
            true
          );
        } else if (id === "new-student-course" && filterStudent.length === 0) {
          setExistingStudent([]);
          setValues(
            {
              ...values,
              student_name: "",
              email: "",
              student_id: "",
            },
            true
          );
        }
      }
    })();
  }, [values.phone_number, values.phone_number.length === 10]);

  useEffect(() => {
    (async () => {
      if (values.branch_id !== null) {
        const branchGSTDetails = await getBranchForGST(values.branch_id);
        if (branchGSTDetails?.length > 0) {
          setFieldValue("branch_based_GST", branchGSTDetails[0]);
        }
      }
    })();
  }, [values.branch_id]);

  useEffect(() => {
    if (values.course_name !== "") {
      getCourseHelpOfID(values.course_id);
    }
  }, [values.course_name]);

  const routeToBatchFollowUp = () => {
    setSwapComp([...swapComp, "add-batch"]);
  };

  // const handleInputChange = (e: any) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   setFieldValue("course_fee", formattedValue);
  // };

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    let formattedValue = numericValue;

    // Check for decimal places
      const decimalIndex = numericValue.indexOf(".");
    if (decimalIndex !== -1) {
      const integerPart = numericValue.slice(0, decimalIndex);
      const decimalPart = numericValue.slice(decimalIndex + 1);

      if (decimalPart.length > 2) {
        formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
      } else {
        formattedValue = numericValue;
      }
    }
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setFieldValue("course_fee", formattedValue);
  };

  const handleCourseInputEndButton = () => {
    const replaceCommaInValues = values.course_fee.replace(/\,/g, "");
    const replaceCommaInInitialValues = values.customization_duration!=="" ? initialCourseFeeForCustomization.replace(/\,/g, "") : initialCourseFee.replace(/\,/g, "");

    if (values.course_fee !== "" && Number(replaceCommaInInitialValues) === Number(replaceCommaInValues)) {
      setFieldValue("discount_percentage", 0);
      return;
    }
    
    if (values.course_fee !== "" && Number(replaceCommaInInitialValues) < Number(replaceCommaInValues)) {
      setFieldValue("discount_percentage", 0);
      return;
    }

    if (values.course_fee !== "" && Number(replaceCommaInInitialValues) > Number(replaceCommaInValues)) {
      const numericValue = values.course_fee.replace(/[^0-9.]/g, "");
      const IC = initialCourseFee
        ? Number(initialCourseFee?.replace(/\,/g, "")) * 0.85
        : 0;
      const ICC = initialCourseFeeForCustomization
        ? Number(initialCourseFeeForCustomization?.replace(/\,/g, "")) * 0.85
        : 0;
      const minAllowedValue = values.customization_duration === "" ? IC : ICC;
      if (Number(numericValue) < minAllowedValue) {
        toast.error(
          `Course fee reduced below 15% limit. Minimum allowed: ${minAllowedValue}`
        );
        setFieldValue(
          "course_fee",
          minAllowedValue?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setFieldValue("discount_percentage", 15);
        // setShowPopup(true)
        return minAllowedValue;
      }

      const formattedValue = numericValue.replace(/(?<!\.\d+)(?=(\d{3})+(?!\d\.))/g, ",");
      const check_1 = initialCourseFee
        ? Number(initialCourseFee?.replace(/\,/g, "")) - Number(numericValue)
        : 0
      const check_2 = initialCourseFeeForCustomization
        ? Number(initialCourseFeeForCustomization?.replace(/\,/g, "")) - Number(numericValue)
        : 0
      const decreasedAmount = values.customization_duration === "" ? check_1 : check_2;
      const condition_1 = (decreasedAmount / (Number(initialCourseFee?.replace(/\,/g, "")) || 0)) * 100;
      const condition_2 = (decreasedAmount / (Number(initialCourseFeeForCustomization?.replace(/\,/g, "")) || 0)) * 100;
      const decreasedPercentage = values.customization_duration === "" ? condition_1 : condition_2;
      
      const roundedPercentage = Math.round(decreasedPercentage * 100) / 100;

      setFieldValue("discount_percentage", Math.abs(roundedPercentage));
    }
  };

  const handleOutForCourse = async (
    option: any,
    forId: any,
    name: any,
    mapData: any,
    mapThrough: any,
    indexValue: any
  ) => {
    setFieldValue(name, option);
    const filterForId = mapData?.filter(
      (data: any) => data?.course_name === option
    );
    if (filterForId?.length > 0) {
      setFieldValue(forId, filterForId?.[0]?.id);
      setFieldValue("city_id", null);
      setFieldValue("city_name", "");
      setFieldValue("customized_course", false);
      setFieldValue("branch_id", null);
      setFieldValue("branch_name", "");
      setFieldValue("course_schedule", "");
      setFieldValue("course_mode", "");
      setFieldValue("course_fee", "");
      setFieldValue("customization_duration", "");
      setFieldValue("customization_course_name", "");
      setFieldValue("hourly_fee", null);
      setFieldValue("discount_percentage", 0);
      setFieldValue("duration", null);
      setFieldValue("branch_based_GST", {});
      setInitialCourseFee("");
      getCourseHelpOfID(filterForId?.[0]?.id);
    }
  };

  const getCourseHelpOfID = async (id: any) => {
    const scheduleData = await getCourseById(id);
    const customScheduleData = await getCourseByIdForCustomization(id);
    setCourseDetails(customScheduleData);
    setCourseModeData(scheduleData?.mode);
    setCourseQuery(scheduleData);
    setScheduleData(scheduleData?.schedule);
    setFieldValue("duration", Number(scheduleData?.duration));
    setFieldValue("hourly_fee", null);
    const mergedCityIds = scheduleData?.city_based_course_fee?.reduce(
      (acc: any, item: any) => {
        return acc.concat(item.city_id);
      },
      []
    );
    const verifyCityIds = cityBasedCourseQuery?.filter((data: any) =>
      mergedCityIds?.includes(data?.id)
    );
    setCityData(verifyCityIds);
    const mapCity: any =
      verifyCityIds && verifyCityIds?.map((city: any) => city.city_name);
    setCityNames(mapCity);
  };

  const handleOutForCity = async (
    option: any,
    forId: any,
    name: any,
    mapData: any,
    mapThrough: any,
    indexValue: any
  ) => {
    setFieldValue(name, option);
    const filterForId = mapData?.filter(
      (data: any) => data?.city_name === option
    );
    if (filterForId?.length > 0) {
      setFieldValue(forId, filterForId?.[0]?.id);
      setFieldValue("branch_id", null);
      setFieldValue("branch_name", "");
      const filterCityBasedCourseFee: any =
        courseQuery?.city_based_course_fee?.filter((cityBased: any) =>
          cityBased?.city_id?.includes(filterForId?.[0]?.id)
        );
      const numericValue = String(
        filterCityBasedCourseFee?.[0]?.discounted_fee
      )?.replace(/[^0-9]/g, "");
      const formattedValue = numericValue?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      setFieldValue("course_fee", formattedValue);
      formik.setFieldValue("customization_duration", "")
      const hourly_fees = (Number(formattedValue.replace(/[^0-9]/g, "")) / values.duration)
      setFieldValue("hourly_fee", Number(hourly_fees))
      setFieldValue("customization_fee", false);
      setFieldValue("cd_comment", "");
      // setFieldValue("discount_need", false);
      // setFieldValue("autoDiscount", false);
      setFieldValue("discount_percentage", null);
      setInitialCourseFee(String(filterCityBasedCourseFee[0]?.discounted_fee));
      const readData = await getPreloadData({
        branches: signInData?.data[0]?.branches,
        cities: signInData?.data[0]?.cities,
      });
      const filterBranch =
        readData &&
        readData?.branches.filter(
          (branch: any) => branch?.city === filterForId?.[0]?.id
        );
      setBranchData(filterBranch);
      const filterNames =
        filterBranch && filterBranch?.map((branch: any) => branch?.branch_name);
      setBranchNames(filterNames);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const check_feeType =
    values.branch_based_GST && values.branch_based_GST?.fee_type;
  const sendPercentageIGST =
    check_feeType === "IGST" && values.branch_based_GST?.igst;
  const sendPercentageSGST =
    check_feeType === "SGST" && values.branch_based_GST?.sgst;
  const sendPercentageCGST =
    check_feeType === "SGST" && values.branch_based_GST?.cgst;
  const sendPercentage =
    check_feeType === "SGST"
      ? {
          cgst: values.branch_based_GST?.cgst,
          sgst: values.branch_based_GST?.sgst,
        }
      : check_feeType === "IGST"
      ? values.branch_based_GST?.igst
      : null;

  const paymentObject = {
    check_feeType: check_feeType,
    sendPercentageIGST: sendPercentageIGST,
    sendPercentageSGST: sendPercentageSGST,
    sendPercentageCGST: sendPercentageCGST,
    sendPercentage: sendPercentage,
  };

  // const TriggerModal = (flag: any) => {
  //   setContinueWithFee(false)
  //   if (flag === "Yes") {
  //     forSession()
  //   }
  // }

  return (
    <div>
      {isLoadingForEdit ? (
        <Loader widthFix={"w-[95%]"} />
      ) : isModalOpen ? (
        <ModalForInvoice
          onClose={handleCloseModal}
          values={formik.values}
          paymentObject={paymentObject}
          // paymentDetails={paymentDetails}
          // currentModalId={currentModalId}
          // updatedStudentData={updatedStudentData}
          // paymentObject={paymentObject}
          // branchDetails={branchDetails}
        />
      ) : swapComp.includes("batch-all") ? (
        <BatchFollowUp
          formik={formik}
          setSwapComp={setSwapComp}
          showBatches={showBatches}
          setShowBatches={setShowBatches}
        />
      ) : swapComp.includes("add-batch") ? (
        <Add_Batch formik={formik} setSwapComp={setSwapComp} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-[95%] mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6"
        >
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "new-student-course"
                ? "Create Student Course"
                : "Edit Student Course"}
            </p>
          </div>
          <div className="w-11/12 mx-auto py-8 space-y-10">
            <BasicInfo
              formik={formik}
              courseData={courseData}
              courseNames={courseNames}
              mode={mode}
              handleOutForCourse={handleOutForCourse}
            />
            <CourseDetails
              formik={formik}
              mode={mode}
              scheduleData={scheduleData}
              CourseModeData={courseModeData}
              cityData={cityData}
              cityNames={cityNames}
              branchData={branchData}
              branchNames={branchNames}
              handleInputChange={handleInputChange}
              handleCourseInputEndButton={handleCourseInputEndButton}
              initialCourseFee={initialCourseFee?.replace(/\,/g, "")}
              handleOutForCity={handleOutForCity}
              setInitialCourseFeeForCustomization={setInitialCourseFeeForCustomization}
              // handleOutForBranch={handleOutForBranch}
            />
            <CourseStatus
              id={id}
              formik={formik}
              mode={mode}
              Course_status={Course_status}
              routeToBatchFollowUp={routeToBatchFollowUp}
            />
            <Payment
              formik={formik}
              mode={mode}
              Payment_Type={Payment_Type}
              Payment_Mode={Payment_Mode}
            />
            <IsBalanceAvailable
              formik={formik}
              id={id}
              mode={mode}
              adjustPaymentData={adjustPaymentData}
            />
            {mode === "edit" && (
              <div className="w-full">
                <div className="flex flex-col w-[30%] xl:w-[40%]">
                  <label className={`text-[23px] font-Roboto font-[500]`}>
                    Adjusted Amount
                  </label>
                  <div className="relative min-h-[45px] w-[70%] xl:w-[80%] bg-[#C5C5C5] outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2">
                    {adjustPaymentData !== 0 && (
                      <span className="px-1">&#8377;</span>
                    )}
                    {typeof adjustPaymentData === "number"
                      ? formatNumberWithCommas(
                          parseFloat(adjustPaymentData?.toFixed(2))
                        )
                      : formatNumberWithCommas(adjustPaymentData)}
                  </div>
                </div>
              </div>
            )}
            {mode === "edit" &&
              values?.payment_details[values.payment_details?.length - 1]
                ?.balance === 0 && (
                <InvoiceData
                  formik={formik}
                  // invoiceDetail={invoiceDetail}
                  // setInvoiceDetail={setInvoiceDetail}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
            <CommentSection formik={formik} mode={mode} />
          </div>
          <SubmitButton id={id} isLoading={isLoading} />
        </form>
      )}
      {/* <StudentDiscountModal
        open={continueWithFee}
        setOpen={setContinueWithFee}
        func={TriggerModal}
      /> */}
    </div>
  );
};

const SubmitButton = ({ id, isLoading}: any) => {
  return (
    <div className="flex justify-center items-center w-4/12 mx-auto">
      <button
        type="submit"
        className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-1 px-8 font-Roboto font-[400]"
      >
        {isLoading ? <PulseLoader color="#FFF" size={8} /> : (id === "new-student-course" ? "Submit" : "Update")}
      </button>
    </div>
  );
};
