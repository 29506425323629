import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables"
import { FetchData, InsertData, UpdateData, BulkUpdate } from "../../crud"
import { CityInterface } from "../Cities";
import { BranchInterface } from "./interface";

export const getAllBranches = async (UsersDetails:any, requiredFields: string[] = []) => {
    const { data: branches, error } = await FetchData(TableNames.branches, requiredFields);
    return branches;
}

export const getBranchTableData = async () => {
    try {
        const { data: branches, error } = await FetchData(TableNames.branches, ["*", "city:cities(city_name)"]).order("id", { ascending: false });
        if (error) throw error;
        return branches.map((branch:any)=>({...branch,city:branch.city.city_name}));
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getBranchById = async (branchId: string | number) => {
    try {
        const { data: branches, error } = await FetchData(TableNames.branches).eq("id", branchId);
        if (error) throw error;
        return branches[0];
    } catch (error) {
        return errorNotifier(error);
    }
}

export const createBranch = async (branchData:BranchInterface,newCityData:CityInterface={}) => {
    try {
        let isNewCity = Object.keys(newCityData).length;
        const { data: getCity, error: getCityError }: any = await FetchData(TableNames.cities).eq("city_name", newCityData?.city_name);
        const { data: getSuperadminUser, error: getUserError } = await FetchData(TableNames.users, ["*", "id", "cities", "branches"]).eq("user_type", "Super Admin");
        if (getCityError || getUserError) throw getCityError || getUserError;
        let city:any;
        let branch:any;
        if (isNewCity && getCity?.length === 0) {
            let { data: cities, error }:any = await InsertData(TableNames.cities, newCityData);
            if (error) throw error;
            city = cities[0].id
        } else city=getCity[0]?.id
        const { data, error }:any = await InsertData(TableNames.branches, {
            ...branchData,
            ...isNewCity&& {city}
        });
        branch = data[0].id;
        const mapUserDataForBranches = getSuperadminUser?.map((mt: any) => {
            return {
                ...mt,
                ...(city !== undefined && { cities: [...(mt?.cities || []), city] }),
                branches: [...(mt?.branches || []), branch],
            };
        });
        await BulkUpdate(TableNames.users, mapUserDataForBranches);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateBranch = async (branchData: Partial<BranchInterface>,branchId:string|number, newCityData:CityInterface={}) => {
    try {
        let isNewCity = Object.keys(newCityData).length;
        const { data: getCity, error: getCityError }:any = await FetchData(TableNames.cities).eq("city_name", newCityData?.city_name);
        if (getCityError) throw getCityError;
        let city;
        if (isNewCity && getCity?.length === 0) {
            let { data: cities, error }:any = await InsertData(TableNames.cities, newCityData);
            if (error) throw error;
            city=cities[0].id
        } else city=getCity[0]?.id
        const { data, error } = await UpdateData(TableNames.branches, {
            ...branchData,
            ...isNewCity&& {city}
        },{conditionKey:"id",conditionValue:branchId});
        if (error) throw error;
        // console.log({data});
        return true;
    } catch (error) {
        return errorNotifier(error);
    }
}