import { useState, useEffect, useRef } from "react";
import boldArrow from "../../Assets/boldArrow.svg";

export const DropdownInputWithCheckbox = ({
  mandatory,
  mandatoryClass,
  topDivStyle,
  options,
  placeholder,
  label,
  name,
  value,
  onBlur,
  mapData,
  mapThrough,
  formik,
  forId,
  classNameInput,
  zIndex,
  disabled,
  error,       // <-- Error prop from Formik
  isTouched,   // <-- Touched prop from Formik
  handleOut,
  indexValue,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const dropdownRef: any = useRef(null);

  const checkSingleSelection = [
    "category",
    "resource_category",
    "course_id",
    "city_id",
    "branch_id",
    "courseId",
    "from_city_id",
    "from_branch_id",
    "to_course_id",
    "to_city_id",
    "to_branch_id",
    "course_ID",
    "course_mode",
    "schedule_days",
  ];

  const checkIsError = isTouched && error;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClick = (event: any) => {
    event.stopPropagation(); // Prevent event bubbling
  };

  const searchFilter: any = (option: any) => {
    return option && option.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const selectOption = (option: any) => {
    if (!disabled) {
      if (value.includes(option)) {
        formik.setFieldValue(
          name,
          value.filter((item: any) => item !== option)
        );
        if (forId) {
          const filterForID =
            mapData && mapData.filter((data: any) => data[mapThrough] === option);
          formik.setFieldValue(
            forId,
            formik.values[forId]?.filter(
              (item: any) => item !== filterForID[0]["id"]
            )
          );
        }
      } else {
        formik.setFieldValue(name, [...value, option]);
        if (forId) {
          const mappingForID =
            mapData && mapData.filter((data: any) => data[mapThrough] === option);
          formik.setFieldValue(forId, [
            ...formik.values[forId],
            mappingForID[0]["id"],
          ]);
        }
      }
    }
  };

  const selectOptionTwo = (option: any) => {
    if (!disabled) {
      formik.setFieldValue(name, option);
      const filterForID =
        mapData && mapData.filter((data: any) => data[mapThrough] === option);
      formik.setFieldValue(forId, Number(filterForID[0]["id"]));
    }
  };

  const selectAllOptions = () => {
    if (selectAll) {
      formik.setFieldValue(name, []);
      setSelectAll(false);
      if (forId) {
        formik.setFieldValue(forId, []);
      }
    } else {
      formik.setFieldValue(name, options);
      setSelectAll(true);
      if (forId) {
        const mappingForID = mapData && mapData.map((data: any) => data.id);
        formik.setFieldValue(forId, mappingForID);
      }
    }
  };

  return (
    <div className={`${topDivStyle} space-y-0.5`} ref={dropdownRef}>
      <label className="text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]">
        {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
      </label>
      <div
        className={`relative ${zIndex ? zIndex : "z-10"} cursor-pointer`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          className={`pr-14 outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 scroll-smooth overflow-y-scroll scrollbar-hide ${classNameInput}`}
          placeholder={placeholder}
          disabled={disabled}
          onClick={toggleDropdown}
          value={
            checkSingleSelection &&
            checkSingleSelection?.includes(forId) &&
            name !== "technology_names" &&
            mapThrough !== "multiple_city_name"
              ? value
              : (value &&
                  value?.length > 0 &&
                  value?.map((option: any) => option)?.join(", ")) ||
                ""
          }
          readOnly
        />
        <img
          src={boldArrow}
          alt="boldArrow"
          className={`absolute top-5 right-4 ${isOpen && "rotate-180"}`}
          onClick={() => setIsOpen(!isOpen)}
        />
        
        {/* Error Message */}
        {checkIsError && (
          <span className="text-red-500 absolute -bottom-6 right-0">
            Required
          </span>
        )}

        {isOpen && (
          <div
            onClick={handleDropdownClick}
            className={`z-20 shadow-md shadow-[#00000029] absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px] scroll-smooth overflow-y-scroll`}
          >
            <div className="flex justify-between items-center px-2 py-2 border-b border-gray-200">
              <input
                type="text"
                className={`${
                  mapThrough === "schedule" ||
                  mapThrough === "payment_type" ||
                  mapThrough === "branch_name"
                    ? "w-7/12"
                    : "w-10/12"
                } rounded-md outline-none border-gray-300 px-2 py-1`}
                placeholder="Search..."
                value={searchTerm}
                autoFocus
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {!disabled &&
                !checkSingleSelection.includes(forId) &&
                mapThrough !== "multiple_city_name" && (
                  <button
                    type="button"
                    className="text-sm rounded-md px-2 py-2 border-[0.5px] border-[#707070] text-black font-Roboto"
                    onClick={selectAllOptions}
                  >
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                )}
            </div>
            <div className="max-h-60">
              {options &&
                options.filter(searchFilter).map((option: any, i: number) => (
                  <label
                    key={i}
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      name={name}
                      value={value}
                      checked={
                        checkSingleSelection &&
                        checkSingleSelection?.includes(forId) &&
                        name !== "technology_names" &&
                        name !== "schedule_days" &&
                        mapThrough !== "multiple_city_name"
                          ? value && value === option
                          : value && value?.includes(option)
                      }
                      onChange={() =>
                        !disabled && handleOut
                          ? handleOut(
                              option,
                              forId,
                              name,
                              mapData,
                              mapThrough,
                              indexValue
                            )
                          : (checkSingleSelection &&
                              checkSingleSelection?.includes(forId) &&
                              name !== "technology_names"
                              ? selectOptionTwo
                              : selectOption)(option)
                      }
                      onBlur={onBlur}
                    />
                    <span className="ml-2 text-gray-900 font-Roboto select-none">
                      {option}
                    </span>
                  </label>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};










// import { useState, useEffect, useRef } from "react";
// import boldArrow from "../../Assets/boldArrow.svg";

// export const DropdownInputWithCheckbox = ({
//   mandatory,
//   mandatoryClass,
//   topDivStyle,
//   options,
//   placeholder,
//   label,
//   name,
//   value,
//   onBlur,
//   mapData,
//   mapThrough,
//   formik,
//   forId,
//   classNameInput,
//   zIndex,
//   disabled,
//   error,
//   isTouched,
//   handleOut,
//   indexValue,
// }: any) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectAll, setSelectAll] = useState(false);
//   const dropdownRef: any = useRef(null);
//   const checkSingleSelection = [
//     "category",
//     "resource_category",
//     "course_id",
//     "city_id",
//     "branch_id",
//     "courseId",
//     "from_city_id",
//     "from_branch_id",
//     "to_course_id",
//     "to_city_id",
//     "to_branch_id",
//     "course_ID",
//     "course_mode",
//     "schedule_days",
//   ];
//   const checkIsError = isTouched && error;

//   useEffect(() => {
//     const handleClickOutside = (event: any) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   // useEffect(() => {
//   //   if (mapThrough === "multiple_city_name") {
//   //     console.log(value, "values");
//   //   }
//   // }, [mapThrough === "multiple_city_name", value]);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleDropdownClick = (event: any) => {
//     event.stopPropagation(); // Prevent event bubbling
//   };

//   const searchFilter: any = (option: any) => {
//     return option && option.toLowerCase().includes(searchTerm.toLowerCase());
//   };

//   const selectOption = (option: any) => {
//     if (!disabled) {
//       if (value.includes(option)) {
//         formik.setFieldValue(
//           name,
//           value.filter((item: any) => item !== option)
//         );
//         if (forId) {
//           const filterForID =
//             mapData && mapData.filter((data: any) => data[mapThrough] === option);
//           formik.setFieldValue(
//             forId,
//             formik.values[forId]?.filter(
//               (item: any) => item !== filterForID[0]["id"]
//             )
//           );
//         }
//       } else {
//         // console.log(option, value, name, forId, "OP_T --> 01")
//         formik.setFieldValue(name, [...value, option]);
//         if (forId) {
//           const mappingForID =
//             mapData && mapData.filter((data: any) => data[mapThrough] === option);
//           // console.log(mappingForID, "filterForID", formik.values.city_based_course_fee?.[0]["city_id"], forId, "//");
//           formik.setFieldValue(forId, [
//             ...formik.values[forId],
//             mappingForID[0]["id"],
//           ]);
//         }
//       }
//     }
//   };

//   const selectOptionTwo = (option: any) => {
//     if(!disabled) {
//       formik.setFieldValue(name, option);
//       const filterForID =
//         mapData && mapData.filter((data: any) => data[mapThrough] === option);
//       formik.setFieldValue(forId, Number(filterForID[0]["id"]));
//     }
//   };

//   const selectAllOptions = () => {
//     if (selectAll) {
//       formik.setFieldValue(name, []);
//       setSelectAll(false);
//       if (forId) {
//         formik.setFieldValue(forId, []);
//       }
//     } else {
//       formik.setFieldValue(name, options);
//       setSelectAll(true);
//       if (forId) {
//         const mappingForID = mapData && mapData.map((data: any) => data.id);
//         formik.setFieldValue(forId, mappingForID);
//       }
//     }
//   };

//   return (
//     <div className={`${topDivStyle} space-y-0.5`} ref={dropdownRef}>
//       <label className="text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]">
//         {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
//       </label>
//       <div
//         className={`relative ${zIndex ? zIndex : "z-10"} cursor-pointer`}
//         onClick={toggleDropdown}
//       >
//         <input
//           type="text"
//           className={`pr-14 outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 scroll-smooth overflow-y-scroll scrollbar-hide ${classNameInput}`}
//           placeholder={placeholder}
//           disabled={disabled}
//           onClick={toggleDropdown}
//           value={
//             checkSingleSelection &&
//             checkSingleSelection?.includes(forId) &&
//             name !== "technology_names" &&
//             mapThrough !== "multiple_city_name"
//               ? value
//               : (value &&
//                   value?.length > 0 &&
//                   value?.map((option: any) => option)?.join(", ")) ||
//                 ""
//           }
//           readOnly
//         />
//         <img
//           src={boldArrow}
//           alt="boldArrow"
//           className={`absolute top-5 right-4 ${isOpen && "rotate-180"}`}
//           onClick={() => setIsOpen(!isOpen)}
//         />
//         {checkIsError && (
//           <span className="text-red-500 absolute -bottom-6 right-0">
//             {error}
//           </span>
//         )}
//         {isOpen && (
//           <div
//             onClick={handleDropdownClick}
//             className={`z-20 shadow-md shadow-[#00000029] absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px] scroll-smooth overflow-y-scroll`}
//           >
//             <div className="flex justify-between items-center px-2 py-2 border-b border-gray-200">
//               <input
//                 type="text"
//                 className={`${
//                   mapThrough === "schedule" ||
//                   mapThrough === "payment_type" ||
//                   mapThrough === "branch_name"
//                     ? "w-7/12"
//                     : "w-10/12"
//                 } rounded-md outline-none border-gray-300 px-2 py-1`}
//                 placeholder="Search..."
//                 value={searchTerm}
//                 autoFocus
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//               {!disabled && !checkSingleSelection.includes(forId) &&
//                 mapThrough !== "multiple_city_name" && (
//                   <button
//                     type="button"
//                     className="text-sm rounded-md px-2 py-2 border-[0.5px] border-[#707070] text-black font-Roboto"
//                     onClick={selectAllOptions}
//                   >
//                     {selectAll ? "Deselect All" : "Select All"}
//                   </button>
//                 )}
//             </div>
//             {/* <div className="max-h-60">
//               {options &&
//                 options.filter(searchFilter).map((option: any, i: number) => (
//                   <label
//                     key={i}
//                     className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
//                   >
//                     <input
//                       type="checkbox"
//                       className="h-4 w-4"
//                       name={name}
//                       value={value}
//                       checked={
//                         checkSingleSelection &&
//                         checkSingleSelection?.includes(forId) &&
//                         name !== "technology_names" &&
//                         name !== "schedule_days" &&
//                         mapThrough !== "multiple_city_name"
//                           ? value && value === option
//                           : value && value?.includes(option)
//                       }
//                       onChange={() =>
//                         !disabled && handleOut
//                           ? handleOut(
//                               option,
//                               forId,
//                               name,
//                               mapData,
//                               mapThrough,
//                               indexValue
//                             )
//                           : (checkSingleSelection &&
//                               checkSingleSelection?.includes(forId) &&
//                               name !== "technology_names"
//                               ? selectOptionTwo
//                               : selectOption)(option)
//                       }
//                       onBlur={onBlur}
//                     />
//                     <span className="ml-2 text-gray-900 font-Roboto select-none">
//                       {option}
//                     </span>
//                   </label>
//                 ))}
//             </div> */}
//             <div className="max-h-60">
//   {options &&
//     options.filter(searchFilter).map((option: any, i: number) => (
//       <label
//         key={i}
//         className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
//         onClick={() =>
//           !disabled && handleOut
//             ? handleOut(
//                 option,
//                 forId,
//                 name,
//                 mapData,
//                 mapThrough,
//                 indexValue
//               )
//             : (checkSingleSelection &&
//                 checkSingleSelection?.includes(forId) &&
//                 name !== "technology_names"
//                 ? selectOptionTwo
//                 : selectOption)(option)
//         }
//       >
//         <span className="ml-2 text-gray-900 font-Roboto select-none">
//           {option}
//         </span>
//       </label>
//     ))}
// </div>

//           </div>
//         )}
//       </div>
//     </div>
//   );
// };


