import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { createCancellationSession } from '../../../functions/admin/Course Progress/courseProgress';
import { useAppSelector } from '../../..';
import { getAllCancellationFollowupData } from '../../../functions/admin/Course Progress/courseProgress';

export const CancellationModal = ({open, setOpen, func, recordId}:any) => {
  const statusOptions = ["By FITA", "By Student", "By Trainer"]
  const [selectedStatus, setSelectedStatus] = useState("By FITA");
  const [comment, setComment] = useState("");
  const [isExistingRecord, setIsExistingRecord] = useState(false);
  const { userDetails } = useAppSelector(({ login }: any) => login);

  useEffect(() => {
    const fetchData = async () => {
      if (recordId) {
        const existingData : any = await getAllCancellationFollowupData(recordId);
        if (existingData && existingData.length > 0) {
          setComment(existingData[0].comment || "");
          setSelectedStatus(existingData[0].status || "By FITA");
          setIsExistingRecord(true); 
        } else {
          setIsExistingRecord(false);
        }
      }
    };
    if (open) fetchData();
  }, [open, recordId]);

const handleChangeForModal = async (flag : any) => {
  if (flag === 'CANCEL') {
    setSelectedStatus(" ");
    setComment("");
    setOpen(false);
  } else {
    try {
      const body : any = {
        added_by: userDetails,
        cancellation_id: recordId,
        comment,
        status: selectedStatus
      };
      
      const response = await createCancellationSession(body);
      if (response) {
        setOpen(false); 
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  }
};

const isProceedDisabled = isExistingRecord ||  !comment.trim();
      
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 flex items-center justify-center p-4 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-xl transition-all">
              <div className="mb-6">
                <label
                  htmlFor="status"
                  className="block text-md font-medium text-gray-700"
                >
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="w-full mt-1 border border-slate-300 rounded-md h-10 p-2"
                  disabled={isExistingRecord}
                >
                  {statusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="comment"
                  className="block text-md font-medium text-gray-700"
                >
                  Comment
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  placeholder="Add your comment here"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className={`w-full mt-1 border border-slate-300 rounded-md h-24 p-2 ${
                    isExistingRecord && "opacity-70 resize-none select-none"
                  }`}
                  disabled={isExistingRecord}
                />
              </div>

              {!isExistingRecord && (
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white ${
                      isProceedDisabled
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-red-600 hover:bg-red-500"
                    }`}
                    onClick={() => handleChangeForModal("YES")}
                    disabled={isProceedDisabled}
                  >
                    Proceed
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => handleChangeForModal("CANCEL")}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
