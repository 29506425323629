import { useState, useRef, useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import {
  FreePaidContent,
  SelfAndInstructorContent,
  conditionStatement,
  CourseMode,
} from "../JsonData";
import { useFormik } from "formik";
import { onKeyDown } from "../../../../Services/CommonFunctions";
import { InputBox } from "../../../Elements/InputBox";
import { SelectModuleType } from "../SelectModuleType";
import { add_Course } from "../../../Formik/InitialValues";
import { getCoursePreLoadData } from "../../../../functions/admin/Courses/Courses";
import { DropdownInputWithCheckbox } from "../../../Elements/DropdownInputWithCheckbox";
import { AddCourseMaterials } from "./AddCourseMaterials";
import { AddFAQ } from "./AddFAQ";
import {
  createCourse,
  updateCourse,
  getCourseById,
} from "../../../../functions/admin/Courses/Courses";
import { Loader } from "../../../Elements/Loader/Loader";
import { AddCourseValidation } from "../../../Formik/FormikValidation";
import { useSharedLogic } from "../../../CommonFunctions/CommonFunc";
import { UploadOrUpdateBucket } from "../../../CommonFunctions/CommonFileFunction";
import toast from "react-hot-toast";
import { AddVideo } from "./AddVideos";
import { TypeConversion } from "../../../../Services/CommonFunctions";
import selectedCheckbox from "../../../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../../../Assets/emptyCheckbox.svg";
import { useAppSelector } from "../../../..";
import { AddItemsButton } from "../../../Elements/AddItemsButton";
import { TestUploadButton } from "./TestUploadButton";
import upload from "../../../../Assets/upload.svg";
import { SUPABASE_CLIENT } from "../../../../Services/Supabase";

interface UploadOptions {
  bucketName: string;
  fileName: string;
  key: string;
}

export const AddCourse = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const scrollRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([] as any);
  const [branchData, setBranchData] = useState([] as any);
  const [cityData, setCityData] = useState([] as any);
  const [courseFeatureData, setCourseFeatureData] = useState([] as any);
  const [categoryNames, setCategoryNames] = useState([] as any);
  const [branchNames, setBranchNames] = useState([] as any);
  const [cityNames, setCityNames] = useState([] as any);
  const [courseFeatureNames, setCourseFeatureNames] = useState([] as any);
  const FieldData = ["Duration (Hrs)", "Ratings", "Students Enrolled"];
  const courseShedule = ["Weekday", "Weekend", "Fasttrack"];
  const isId: any = id !== "new-course" && id;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const fileInputRef: any = useRef(null);
  const [bulkuploadJSON, setBulkuploadJSON] = useState({
    thumbnails: { file: null, fileName: "", key: "" },
    syllabus: { file: null, fileName: "", key: "" },
    coursematerials: [],
    videos: [],
  } as any);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        setIsLoading(true);
        const categoryResponse: any = await getCoursePreLoadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities,
        });
        if (categoryResponse) {
          setBranchData(categoryResponse?.branches);
          setCategoryData(categoryResponse?.categories);
          setCourseFeatureData(categoryResponse?.courseFeatures);
          setCityData(categoryResponse?.cities);
          if (categoryResponse) {
            const mapCategory: any =
              categoryResponse &&
              categoryResponse?.categories?.map(
                (cate: any) => cate.category_name
              );
            setCategoryNames(mapCategory);
            const mapBranches: any =
              categoryResponse &&
              categoryResponse?.branches?.map(
                (branch: any) => branch.branch_name
              );
            setBranchNames(mapBranches);
            const mapCities: any =
              categoryResponse &&
              categoryResponse?.cities?.map((city: any) => city.city_name);
            setCityNames(mapCities);
            const mapCourseFeature: any =
              categoryResponse &&
              categoryResponse?.courseFeatures?.map((fea: any) => fea.feature);
            setCourseFeatureNames(mapCourseFeature);
          }
          if (id !== "new-course" || mode === "view") {
            const editResponse = await getCourseById(isId);
            const {
              trending,
              type,
              category,
              course_name,
              availability,
              course_fee,
              discounted_fee,
              schedule,
              branches,
              description,
              thumbnail,
              duration,
              ratings,
              students_enrolled,
              faqs,
              certificate_description,
              syllabus,
              material,
              course_materials,
              course_features,
              videos,
              mode,
              city_based_course_fee,
            }: any = editResponse;
            const mapCategoryForName: any =
              categoryResponse?.categories &&
              categoryResponse?.categories.filter(
                (data: any) => data.id === category
              );
            const mapBranchesForID: any =
              type === "Paid" &&
              categoryResponse?.branches &&
              categoryResponse?.branches.filter((data: any) =>
                branches.includes(data.id)
              );
            const mapBranchesForName: any =
              type === "Paid" &&
              mapBranchesForID &&
              mapBranchesForID.map((data: any) => data.branch_name);
            const mapFeaturesForID: any =
              categoryResponse?.courseFeatures &&
              categoryResponse?.courseFeatures.filter((data: any) =>
                course_features.includes(data.id)
              );
            const mapFeaturesForName: any =
              mapFeaturesForID &&
              mapFeaturesForID.map((data: any) => data.feature);
            const mapCourseRecord = city_based_course_fee?.map(
              (cou: any) => cou?.city_id
            );
            const updatedArraysForCity = mapCourseRecord?.map((subArray: any) =>
              subArray?.map(
                (id: any) =>
                  categoryResponse?.cities?.find(
                    (cityObj: any) => cityObj.id === id
                  )?.city_name || id
              )
            );
            const cityBasedFee = city_based_course_fee?.map(
              (data: any, index: number) => ({
                ...data,
                city: updatedArraysForCity[index],
                course_fee: TypeConversion(data?.course_fee),
                discounted_fee: TypeConversion(data?.discounted_fee),
              })
            );
            if (editResponse) {
              setValues(
                {
                  ...values,
                  trending,
                  type,
                  category,
                  category_name: mapCategoryForName?.[0]?.category_name,
                  course_name,
                  // availability,
                  // course_fee,
                  // discounted_fee,
                  // schedule,
                  // branches,
                  // branches_name: mapBranchesForName,
                  description,
                  thumbnail,
                  duration,
                  ratings: ratings
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  students_enrolled,
                  faqs,
                  certificate_description,
                  material,
                  course_materials,
                  course_features,
                  course_feature_names: mapFeaturesForName,
                  ...(type === "Free" && { videos: videos }),
                  ...(type === "Paid" && {
                    city_based_course_fee: cityBasedFee,
                  }),
                  // ...(type === "Paid" && { course_fee: course_fee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}),
                  // ...(type === "Paid" && { discounted_fee: discounted_fee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}),
                  ...(type === "Paid" && { availability: availability }),
                  ...(type === "Paid" && { branches: branches }),
                  ...(type === "Paid" && { syllabus: syllabus }),
                  ...(type === "Paid" && { schedule: schedule }),
                  ...(type === "Paid" && { branches_name: mapBranchesForName }),
                  ...(type === "Paid" && { course_mode: mode }),
                },
                true
              );
            }
          }
        }
        setIsLoading(false);
      }
    })();
  }, []);

  const forNewCourse = async () => {
    if (values.category === null) {
      toast.error("Please Select Category");
    } else if (values.type === "Paid" && values.branches?.length === 0) {
      toast.error("Please Select Branch");
    } else if (values.course_features?.length === 0) {
      toast.error("Please Select Course Features");
    } else {
      setIsLoading(true);
      const {
        course_mode,
        material,
        course_materials,
        videos,
        ratings,
        category_name,
        branches_name,
        course_feature_names,
        key,
        type,
        city_based_course_fee,
        availability,
        branches,
        syllabus,
        schedule,
        ...rest
      } = values;
      const body: any = {
        type: type,
        material: material,
        ratings: TypeConversion(ratings),
        // ...(type === "Paid" && { course_fee: TypeConversion(course_fee)}),
        // ...(type === "Paid" && { discounted_fee: TypeConversion(discounted_fee)}),
        ...(type === "Paid" && {
          city_based_course_fee: city_based_course_fee?.map(
            ({ city, ...rest }) => ({
              ...rest,
              course_fee: TypeConversion(rest?.course_fee),
              discounted_fee: TypeConversion(rest?.discounted_fee),
            })
          ),
        }),
        ...(type === "Paid" && { availability: availability }),
        ...(type === "Paid" && { branches: branches }),
        ...(type === "Paid" && { syllabus: syllabus }),
        ...(type === "Paid" && { schedule: schedule }),
        ...(type === "Paid" && { mode: course_mode }),
        ...(material && { course_materials: course_materials }),
        ...(type === "Free" && { videos: videos }),
        ...rest,
      };
      const response: any = await (id === "new-course"
        ? createCourse(body)
        : updateCourse(body, isId));
      if (response) {
        navigate({
          pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
          search: createSearchParams({
            id: `${id === "new-course" ? "created-success" : "update-success"}`,
          }).toString(),
        });
      }
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: add_Course,
    validationSchema: () => AddCourseValidation(values.type, values.material),
    onSubmit: () => {
      forNewCourse();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = formik;

  const addMoreFAQ = () => {
    const add = [...values.faqs, { title: "", description: "" }];
    setFieldValue("faqs", add);
  };

  const deleteFAQ = (i: number) => {
    const newArray = [...values.faqs];
    newArray.splice(i, 1);
    setFieldValue("faqs", newArray);
  };

  const addMoreMaterials = () => {
    const add = [
      ...values.course_materials,
      { title: "", show_percentage: "", url: "", key: "", fileName: "" },
    ];
    setFieldValue("course_materials", add);
    // scrollRef.current.scrollTop =
    //   scrollRef.current.scrollHeight;
  };

  const deleteMaterials = (indexNumber: number) => {
    const newArray = [...values.course_materials];
    newArray.splice(indexNumber, 1);
    setFieldValue("course_materials", newArray);
  };

  const selectSectionType = (name: string, label: string) => {
    if (["Yes", "No"].includes(label)) {
      setFieldValue(name, label === "Yes" ? true : false);
    } else setFieldValue(name, label);
    // if (name === "material" && label === "Yes") {
    //   setFieldValue("course_materials", { title: "", show_percentage: "", url: "", key: "", fileName: "" });
    // }
  };

  const handleChangeForFaqs = (index: number, value: string, data: any) => {
    setFieldValue(`faqs[${index}][${data}]`, value);
  };

  const handleChangeForCourseMaterial = (
    index: number,
    value: string,
    data: any
  ) => {
    setFieldValue(`course_materials[${index}][${data}]`, value);
  };

  const handleValues: any = (
    response: any,
    Method: string,
    index: number,
    subBucket: string,
    file?: any,
    name?: any,
    randomKey?: string
  ) => {
    if (["Upload", "Update"].includes(Method)) {
      const cacheBuster = Date.now();
      (subBucket === "syllabus" || subBucket === "thumbnails") &&
        setFieldValue(subBucket === "thumbnails" ? "thumbnail" : "syllabus", {
          url:
            mode !== "edit"
              ? response?.publicUrl
              : `${response?.publicUrl}?cb=${cacheBuster}`,
          fileName: file?.name,
          key: mode !== "edit" && randomKey,
        });
      const updatedArray: any = [...values.course_materials];
      updatedArray[index] = {
        ...updatedArray[index],
        url:
          mode !== "edit"
            ? response?.publicUrl
            : `${response?.publicUrl}?cb=${cacheBuster}`,
        key: randomKey,
        fileName: file?.name,
      };
      subBucket === "coursematerials" &&
        setFieldValue("course_materials", updatedArray);
    } else {
      (subBucket === "syllabus" || subBucket === "thumbnails") &&
        setFieldValue(subBucket === "thumbnails" ? "thumbnail" : "syllabus", {
          url: "",
          fileName: "",
          key: "",
        });
      const updatedArray: any = [...values.course_materials];
      updatedArray[index] = {
        ...updatedArray[index],
        url: "",
        key: "",
        fileName: "",
      };
      subBucket === "coursematerials" &&
        setFieldValue("course_materials", updatedArray);
    }
  };

  const handleInputChange = (index: number, value: any, check: any) => {
    // Remove non-numeric characters from input value
    const numericValue: any = value?.replace(/[^0-9]/g, "");
    // Format the number with comma separation
    const formattedValue = numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setFieldValue(check, formattedValue);
  };

  const handleDuration = (index: number, value: string, check: any) => {
    const allowedChars = /^[0-9\.]+$/;
    if (allowedChars.test(value)) {
      setFieldValue(check, value);
    }
    if (value === '') {
      setFieldValue(check, '');
    }
  };

  const CheckValue = (label: any) => {
    if (values.course_mode.includes(label)) {
      const courseMode = [...values.course_mode];
      const filterMode = courseMode.filter((course: any) => course !== label);
      setFieldValue("course_mode", filterMode);
    } else setFieldValue("course_mode", [...values.course_mode, label]);
  };

  const addMoreCity = () => {
    const add = [
      ...values.city_based_course_fee,
      { city: [], city_id: [], course_fee: "", discounted_fee: "" },
    ];
    setFieldValue("city_based_course_fee", add);
  };

  const deleteCityAdded = (index: number) => {
    const deletedOne = [...values.city_based_course_fee];
    deletedOne.splice(index, 1);
    setFieldValue("city_based_course_fee", deletedOne);
  };

  const handleOutForCities = async (
    option: string,
    forId: any,
    name: any,
    mapData: any[],
    mapThrough: any,
    indexValue: number
  ) => {
    const filterCity = mapData?.find(
      (val: { city_name: string; id: number }) => val?.city_name === option
    );


    if (filterCity) {
      const cityId: number = filterCity.id;
      const cityName: string = filterCity.city_name;

      const cityIndex =
        values.city_based_course_fee[indexValue && indexValue]?.city.indexOf(
          cityName
        );

      if (cityIndex !== -1) {
        // If the city exists, remove it and its corresponding ID
        const updatedCityIds = values.city_based_course_fee[
          indexValue && indexValue
        ]?.city_id.filter((id: number, index: number) => index !== cityIndex);
        const updatedCities = values.city_based_course_fee[
          indexValue && indexValue
        ]?.city.filter((city: string, index: number) => index !== cityIndex);

        setFieldValue(
          `city_based_course_fee[${indexValue && indexValue}]["city_id"]`,
          updatedCityIds
        );
        setFieldValue(
          `city_based_course_fee[${indexValue && indexValue}]["city"]`,
          updatedCities
        );
      } else {
        // If the city does not exist, add it and its corresponding ID
        setFieldValue(
          `city_based_course_fee[${indexValue && indexValue}]["city_id"]`,
          [
            ...values.city_based_course_fee[indexValue && indexValue]?.city_id,
            cityId,
          ]
        );
        setFieldValue(
          `city_based_course_fee[${indexValue && indexValue}]["city"]`,
          [
            ...values.city_based_course_fee[indexValue && indexValue]?.city,
            cityName,
          ]
        );
        
      }
      // const cityArrays = values.city_based_course_fee?.map((item: any) => item?.city);
      // const cities = cityArrays.flat();
      // cities.push(cityName);
      // const categoryResponse: any = await getCoursePreLoadData({
      //   branches: signInData?.data[0]?.branches,
      //   cities: signInData?.data[0]?.cities,
      // });
      // console.log(
      //   cityArrays,
      //   "cityArrays",
      //   cities,
      //   "cities"
      // );
      // const filterCityOnceAgain =
      //   categoryResponse?.cities?.length > 0 &&
      //   categoryResponse?.cities?.filter(
      //     (fil: any) => !cities?.includes(fil?.city_name)
      //   );
      // console.log(filterCityOnceAgain, "filterCityOnceAgain");
      // setCityData(filterCityOnceAgain);
      // const mapCities: any =
      //   filterCityOnceAgain?.length > 0 &&
      //   filterCityOnceAgain?.map((city: any) => city.city_name);
      // console.log(mapCities, "mapCities");
      // setCityNames(mapCities);
    }
  };

  // Handle file selection
  const handleFileChange = (event: any, key: string) => {
    const file = event.target.files[0];
    const keyValue = file.name ? `${Date.now()}-${file.name}` : file.name;

    // Check if the key is 'coursematerials' or 'videos'
    if (key === "coursematerials" || key === "videos") {
      setBulkuploadJSON((prev: any) => ({
        ...prev,
        [key]: [
          ...prev[key],
          { file, fileName: file.name, key: `${key}/${keyValue}` },
        ],
      }));
    } else {
      // For other keys, just update the state with the new object
      setBulkuploadJSON((prev: any) => ({
        ...prev,
        [key]: { file, fileName: file.name, key: `${key}/${keyValue}` },
      }));
    }
  };

  // Upload files to Supabase
  const uploadFilesToSupabase = async () => {
    try {
      const { thumbnails, syllabus, coursematerials, videos } = bulkuploadJSON;
      let thumbnailsURL = bulkuploadJSON.thumbnails;
      let syllabusURL = bulkuploadJSON.syllabus;
      let courseMaterialsURL = bulkuploadJSON.coursematerials;
      let videosURL = bulkuploadJSON.videos;

      if (
        !thumbnails ||
        !syllabus ||
        typeof thumbnails !== "object" ||
        typeof syllabus !== "object"
      ) {
        console.error("Error: Thumbnails or syllabus is missing or invalid.");
        return;
      }

      if (thumbnails?.file !== null) {
        const [thumbnailsUpload] = await Promise.all([
          uploadFile(thumbnails.file, {
            bucketName: "thumbnails",
            fileName: thumbnails.fileName,
            key: thumbnails.key,
          }),
        ]);
        thumbnailsURL = thumbnailsUpload;
      }

      if (syllabus?.file !== null) {
        const [syllabusUpload] = await Promise.all([
          uploadFile(syllabus.file, {
            bucketName: "syllabus",
            fileName: syllabus.fileName,
            key: syllabus.key,
          }),
        ]);
        syllabusURL = syllabusUpload;
      }

      if (coursematerials?.length > 0) {
        const courseMaterialsUploadPromises = coursematerials.map((file: any) =>
          uploadFile(file, {
            bucketName: "coursematerials",
            fileName: file.fileName,
            key: file.key,
          })
        );
        const uploadedCourseMaterials = await Promise.all(
          courseMaterialsUploadPromises
        );
        console.log(uploadedCourseMaterials, "uploadedCourseMaterials");
        courseMaterialsURL = uploadedCourseMaterials;
      }

      if (videos?.length > 0) {
        const videosUploadPromises = videos.map((file: any) =>
          uploadFile(file, {
            bucketName: "videos",
            fileName: file.fileName,
            key: file.key,
          })
        );

        const uploadedVideos = await Promise.all(videosUploadPromises);
        videosURL = uploadedVideos[0];
      }

      const updatedBulkFiles = {
        thumbnails: thumbnailsURL,
        syllabus: syllabusURL,
        coursematerials: courseMaterialsURL,
        videos: videosURL,
      };
      console.log("updatedBulkFiles:", updatedBulkFiles);
      setBulkuploadJSON(updatedBulkFiles);
    } catch (error: any) {
      console.error("Error uploading files:", error.message);
    }
  };

  const transformResponse = (response: any, file: any) => {
    return {
      file: file,
      fileName: response.path.split("/").pop(),
      key: response.path,
    };
  };

  const uploadFile = async (file: any, options: UploadOptions) => {
    const { bucketName, fileName, key } = options;
    const keyValue = fileName ? `${Date.now()}-${fileName}` : file?.name;
    const path = `${bucketName}/${keyValue}`;

    let contentType;
    if (file && file?.file?.type) {
      // Determine content type based on file type
      if (file?.file?.type.startsWith("image/")) {
        contentType = "image/jpeg"; // Set content type for images
      } else if (file?.file?.type.startsWith("video/")) {
        contentType = "video/mp4"; // Set content type for videos
      } else if (file?.file?.type === "application/pdf") {
        contentType = "application/pdf"; // Set content type for PDF files
      } else {
        contentType = file?.file?.type; // Use default content type for other file types
      }
    } else {
      // Handle case where file is undefined or type property is missing
      console.error("File is undefined or missing type property.");
    }

    const { data, error } = await SUPABASE_CLIENT.storage
      .from("courses")
      .upload(path, file, {
        cacheControl: "3600",
        upsert: false,
        contentType: contentType,
      });
    if (error) {
      console.error("Error uploading file:", error.message);
      return null;
    }
    // console.log(data, "data");
    const transformedData = transformResponse(data, file);
    return transformedData;
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleKeyPress = (event: any, data: any) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-'];
    if (data === "Duration (Hrs)") {
      if (!allowedKeys.includes(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    } else event.preventDefault();
  };

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-10/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6"
        >
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "new-course"
                ? "Add Course"
                : mode === "edit"
                ? "Edit Course"
                : "View Course"}
            </p>
            <SelectModuleType
              text={"Trending *:"}
              name={"trending"}
              value={values.trending}
              onBlur={handleBlur}
              onChange={selectSectionType}
              JSON={conditionStatement}
              topDivStyle={"mr-4"}
              disabled={mode === "view"}
              // checked={values.trending}
            />
          </div>
          <div className="py-4 space-y-16">
            <div className="w-11/12 mx-auto space-y-10">
              <div className="w-full space-y-6">
                <SelectModuleType
                  text={"Course Type:"}
                  name={"type"}
                  value={values.type}
                  onBlur={handleBlur}
                  onChange={selectSectionType}
                  JSON={FreePaidContent}
                  disabled={mode === "edit"}
                />
                <div className="flex items-center justify-between w-full">
                  <DropdownInputWithCheckbox
                    topDivStyle={"flex flex-col w-4/12"}
                    classNameInput={"w-full"}
                    label="Category"
                    placeholder="Category"
                    mandatory={true}
                    mandatoryClass={`text-[#FF0000]`}
                    options={categoryNames}
                    name={"category_name"}
                    forId={"category"}
                    value={values.category_name}
                    onBlur={handleBlur}
                    mapData={categoryData}
                    mapThrough={"category_name"}
                    formik={formik}
                    disabled={mode === "view"}
                    zIndex={"z-50"}
                  />
                  <InputBox
                    label="Course"
                    placeholder="Course"
                    topDivStyle={"flex flex-col w-7/12"}
                    classNameInput={"w-full"}
                    mandatory={true}
                    mandatoryClass={`text-[#FF0000]`}
                    name={"course_name"}
                    value={values.course_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.course_name}
                    isTouched={touched.course_name}
                    disabled={mode === "view"}
                  />
                </div>
              </div>
              <div></div>
              {values.type === "Paid" && (
                <div className="space-y-2">
                  <div className="w-[95%] flex gap-4 relative">
                    <DropdownInputWithCheckbox
                      topDivStyle={"flex flex-col w-4/12"}
                      classNameInput={"w-full"}
                      label="City"
                      placeholder="City"
                      options={cityNames}
                      name={`city_based_course_fee[${0}]["city"]`}
                      forId={`city_based_course_fee[${0}]["city_id"]`}
                      value={values.city_based_course_fee?.[0]?.city}
                      handleOut={handleOutForCities}
                      onBlur={handleBlur}
                      mapData={cityData}
                      mapThrough={"multiple_city_name"}
                      formik={formik}
                      disabled={false}
                      zIndex={"z-40"}
                      indexValue={0}
                    />
                    <InputBox
                      label="Course Fee"
                      placeholder="Course Fee"
                      topDivStyle={"flex flex-col w-7/12"}
                      classNameInput={"w-full"}
                      name={`city_based_course_fee[${0}]["course_fee"]`}
                      type={"text"}
                      inputMode="numeric"
                      value={values?.city_based_course_fee?.[0]?.course_fee}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      // error={
                      //   errors.city_based_course_fee &&
                      //   errors?.city_based_course_fee?.[0] &&
                      //   errors.city_based_course_fee[0]?.course_fee
                      // }
                      // isTouched={
                      //   touched.city_based_course_fee &&
                      //   touched?.city_based_course_fee?.[0] &&
                      //   touched.city_based_course_fee?.[0]?.course_fee
                      // }
                      disabled={false}
                      check={`city_based_course_fee[${0}]["course_fee"]`}
                      // check={"course_fee"}
                      index={0}
                    />
                    <InputBox
                      label="Discounted Fee"
                      placeholder="Discounted Fee"
                      topDivStyle={"flex flex-col w-7/12"}
                      classNameInput={"w-full"}
                      name={`city_based_course_fee[${0}]["discounted_fee"]`}
                      type={"text"}
                      inputMode="numeric"
                      value={values?.city_based_course_fee?.[0]?.discounted_fee}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      // error={errors.discounted_fee}
                      // isTouched={touched.discounted_fee}
                      disabled={false}
                      check={`city_based_course_fee[${0}]["discounted_fee"]`}
                      index={0}
                    />
                    <button
                      type="button"
                      onClick={addMoreCity}
                      className="absolute -right-10 bottom-0.5 select-none bg-[#DF0952] flex items-center gap-1.5 rounded-[4px] text-white text-4xl text-[20px] font-Roboto font-[500] px-3"
                    >
                      +
                    </button>
                  </div>
                  {values.city_based_course_fee?.map(
                    (val: any, index: number) => {
                      if (index !== 0) {
                        return (
                          <div className="flex gap-4 relative w-[95%]">
                            <DropdownInputWithCheckbox
                              topDivStyle={"flex flex-col w-4/12"}
                              classNameInput={"w-full"}
                              label="City"
                              placeholder="City"
                              options={cityNames}
                              name={`city_based_course_fee[${index}]["city"]`}
                              forId={`city_based_course_fee[${index}]["city"]`}
                              value={val?.city}
                              handleOut={handleOutForCities}
                              onBlur={handleBlur}
                              mapData={cityData}
                              mapThrough={"multiple_city_name"}
                              formik={formik}
                              disabled={false}
                              zIndex={index ? `z-${40 + 1 + index}` : "z-40"}
                              indexValue={index}
                            />
                            <InputBox
                              label="Course Fee"
                              placeholder="Course Fee"
                              topDivStyle={"flex flex-col w-7/12"}
                              classNameInput={"w-full"}
                              name={`city_based_course_fee[${index}]["course_fee"]`}
                              type={"text"}
                              inputMode="numeric"
                              value={val?.course_fee}
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              // error={
                              //   errors.city_based_course_fee &&
                              //   errors?.city_based_course_fee?.[0] &&
                              //   errors.city_based_course_fee[0]?.course_fee
                              // }
                              // isTouched={
                              //   touched.city_based_course_fee &&
                              //   touched?.city_based_course_fee?.[0] &&
                              //   touched.city_based_course_fee?.[0]?.course_fee
                              // }
                              disabled={false}
                              // check={"city_based_course_fee?.[0]?.course_fee"}
                              check={`city_based_course_fee[${index}]["course_fee"]`}
                              index={index}
                            />
                            <InputBox
                              label="Discounted Fee"
                              placeholder="Discounted Fee"
                              topDivStyle={"flex flex-col w-7/12"}
                              classNameInput={"w-full"}
                              name={`city_based_course_fee[${index}]["discounted_fee"]`}
                              type={"text"}
                              inputMode="numeric"
                              value={val?.discounted_fee}
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              // error={
                              //   formik.errors?.city_based_course_fee &&
                              //   formik.errors?.city_based_course_fee[index] &&
                              //   formik.errors?.city_based_course_fee[index]?.discounted_fee
                              // }
                              // isTouched={
                              //   formik.touched?.city_based_course_fee &&
                              //   formik.touched?.city_based_course_fee[index] &&
                              //   formik.touched?.city_based_course_fee[index]?.discounted_fee
                              // }
                              disabled={false}
                              check={`city_based_course_fee[${index}]["discounted_fee"]`}
                              index={index}
                            />
                            <div
                              onClick={() => deleteCityAdded(index)}
                              className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9 bottom-2"
                            >
                              x
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              )}
              {/* {values.type === "Paid" && (
                <div className="space-y-6">
                  <SelectModuleType
                    text={"Course Availability:"}
                    name={"availability"}
                    value={values.availability}
                    onBlur={handleBlur}
                    onChange={selectSectionType}
                    JSON={SelfAndInstructorContent}
                    disabled={mode === "view"}
                  />
                  <div className="flex items-center justify-between w-full gap-14">
                    <div className="flex items-end gap-3 w-1/2">
                      <InputBox
                        label="Course Fee"
                        placeholder="Self Paced"
                        topDivStyle={"flex flex-col w-1/2"}
                        classNameInput={"w-full numberClass"}
                        mandatory={true}
                        mandatoryClass={`text-black`}
                        name={"course_fee"}
                        value={
                          values.availability === "Self Paced"
                            ? values.course_fee
                            : ""
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        type="text"
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={
                          values.availability !== "Self Paced" ||
                          mode === "view"
                        }
                        error={
                          values.type === "Paid" &&
                          values.availability === "Self Paced" &&
                          errors.course_fee
                        }
                        isTouched={
                          values.type === "Paid" &&
                          values.availability === "Self Paced" &&
                          touched.course_fee
                        }
                        index={0}
                        check={"course_fee"}
                      />
                      <InputBox
                        placeholder="Instructor Led"
                        topDivStyle={"flex flex-col w-1/2"}
                        classNameInput={"w-full numberClass"}
                        name={"course_fee"}
                        value={
                          values.availability === "Instructor Led"
                            ? values.course_fee
                            : ""
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        type="text"
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={
                          values.availability !== "Instructor Led" ||
                          mode === "view"
                        }
                        error={
                          values.type === "Paid" &&
                          values.availability === "Instructor Led" &&
                          errors.course_fee
                        }
                        isTouched={
                          values.type === "Paid" &&
                          values.availability === "Instructor Led" &&
                          touched.course_fee
                        }
                        index={0}
                        check={"course_fee"}
                      />
                    </div>
                    <div className="flex items-end gap-3 w-1/2">
                      <InputBox
                        label="Discounted Course Fee"
                        placeholder="Self Paced"
                        topDivStyle={"flex flex-col w-1/2"}
                        classNameInput={"w-full numberClass"}
                        mandatory={true}
                        mandatoryClass={`text-black`}
                        name={"discounted_fee"}
                        value={
                          values.availability === "Self Paced"
                            ? values.discounted_fee
                            : ""
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        type="text"
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={
                          values.availability !== "Self Paced" ||
                          mode === "view"
                        }
                        error={
                          values.type === "Paid" &&
                          values.availability === "Self Paced" &&
                          errors.discounted_fee
                        }
                        isTouched={
                          values.type === "Paid" &&
                          values.availability === "Self Paced" &&
                          touched.discounted_fee
                        }
                        index={0}
                        check={"discounted_fee"}
                      />
                      <InputBox
                        placeholder="Instructor Led"
                        topDivStyle={"flex flex-col w-1/2"}
                        classNameInput={"w-full numberClass"}
                        name={"discounted_fee"}
                        value={
                          values.availability === "Instructor Led"
                            ? values.discounted_fee
                            : ""
                        }
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        type="text"
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={
                          values.availability !== "Instructor Led" ||
                          mode === "view"
                        }
                        error={
                          values.type === "Paid" &&
                          values.availability === "Instructor Led" &&
                          errors.discounted_fee
                        }
                        isTouched={
                          values.type === "Paid" &&
                          values.availability === "Instructor Led" &&
                          touched.discounted_fee
                        }
                        index={0}
                        check={"discounted_fee"}
                      />
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className="w-11/12 mx-auto space-y-8">
              <div className="space-y-0.5 flex flex-col w-full relative">
                <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
                  Description <span>*</span>
                </label>
                <textarea
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Description"
                  value={values.description}
                  disabled={mode === "view"}
                  className="min-h-[200px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
                >
                  {values.description}
                </textarea>
                {touched.description && errors.description && (
                  <span className="text-red-500 absolute -bottom-6 right-0">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="flex items-end justify-between gap-2">
                {/* // <SelectModuleType
                //   text={"Course Mode:"}
                //   name={"course_mode"}
                //   value={values.course_mode}
                //   onBlur={handleBlur}
                //   onChange={selectSectionType}
                //   JSON={CourseMode}
                //   disabled={mode === "view"}
                // /> */}
                {values.type === "Paid" && (
                  <DropdownInputWithCheckbox
                    topDivStyle={"flex flex-col w-1/2"}
                    classNameInput={"w-full"}
                    label="Course Mode"
                    placeholder="course_mode"
                    options={CourseMode}
                    name={"course_mode"}
                    value={formik.values.course_mode}
                    onBlur={formik.handleBlur}
                    mapData={branchData}
                    mapThrough={"course_mode"}
                    formik={formik}
                    disabled={false}
                    zIndex={"z-30"}
                  />
                  // <div className={`flex items-center justify-center gap-4`}>
                  //   <h1 className="text-[20px] font-Roboto font-[500]">
                  //     Course Mode:
                  //   </h1>
                  //   <div className="flex items-center gap-3">
                  //     {CourseMode.map((type: any, i: number) => (
                  //       <div
                  //         key={i}
                  //         className={`flex items-center gap-2 cursor-pointer`}
                  //         onClick={() => CheckValue(type.label)}
                  //       >
                  //         <img
                  //           src={
                  //             values?.course_mode?.includes(type?.label)
                  //               ? selectedCheckbox
                  //               : emptyCheckbox
                  //           }
                  //           alt="CheckType"
                  //           className={`w-5 h-5`}
                  //         />
                  //         <label
                  //           className={`font-500 font-Roboto cursor-pointer`}
                  //         >
                  //           {type.label}
                  //         </label>
                  //       </div>
                  //     ))}
                  //   </div>
                  // </div>
                )}
                {values.type === "Paid" && (
                  <DropdownInputWithCheckbox
                    topDivStyle={"flex flex-col w-1/2"}
                    classNameInput={"w-full"}
                    label="Branch"
                    placeholder="Branch"
                    options={branchNames}
                    name={"branches_name"}
                    forId={"branches"}
                    value={values.branches_name}
                    onBlur={handleBlur}
                    mapData={branchData}
                    mapThrough={"branch_name"}
                    formik={formik}
                    disabled={mode === "view"}
                    zIndex={"z-30"}
                  />
                )}
              </div>
              <div
                className={`space-y-5 rounded-[3px] min-h-[150px] flex flex-col items-center justify-center ${
                  !values.thumbnail &&
                  "border-dashed border-[1px] border-[#707070]"
                }`}
              >
                {/* <div className="relative flex items-center justify-evenly gap-2 bg-[#036AD1] rounded-[20px] cursor-pointer p-1 px-4">
                  <label htmlFor={id} className="text-white cursor-pointer font-Roboto font-[500]">
                    Upload Thumbnail
                  </label>
                  <input
                    id={id}
                    name={"thumbnail"}
                    disabled={false}
                    type="file"
                    accept={"image/*"} // "image/*, video/*" 
                    style={{ display: "none" }}
                    // ref={checkUploadRef}
                    onChange={handleFileChange}
                  />
                  <img
                    src={upload}
                    alt="Upload Icon"
                    onClick={handleUploadClick}
                    style={{ cursor: "pointer" }}
                  />
                </div> */}
                <UploadOrUpdateBucket
                  mode={mode}
                  id={"course_thumbnail"}
                  labelText={"Upload Thumbnail"}
                  accept={"image/*"}
                  fileSize={800 * 400}
                  sizeInText={"Image Size 800x400"}
                  Method={values.thumbnail.url === "" ? "Upload" : "Update"}
                  bucketName={"courses"}
                  subBucket={"thumbnails"}
                  forValue={handleValues}
                  // fileNameData={values.syllabus.fileName}
                  fileNameKey={values.thumbnail.key}
                  preview={values.thumbnail.url}
                  name={`thumbnail`}
                  error={
                    formik.errors.thumbnail && formik.errors.thumbnail?.url
                  }
                  isTouched={
                    formik.touched.thumbnail && formik.touched.thumbnail?.url
                  }
                />
              </div>
              <div className="space-y-8">
                <div className="flex items-center gap-6">
                  <div
                    className={`flex items-center gap-6 ${
                      values.type === "Paid" ? "w-3/4" : "w-full"
                    }`}
                  >
                    {FieldData?.map((data: any) => (
                      <InputBox
                        label={data}
                        placeholder={
                          data === "Duration (Hrs)" ? "Duration" : data
                        }
                        topDivStyle={"flex flex-col w-1/2"}
                        classNameInput={"w-full numberClass"}
                        mandatory={true}
                        mandatoryClass={`text-black`}
                        name={
                          data === "Students Enrolled"
                            ? "students_enrolled"
                            : data.toLowerCase()
                        }
                        value={
                          data === "Duration (Hrs)"
                            ? values.duration
                            : data === "Ratings"
                            ? values.ratings
                            : values.students_enrolled
                        }
                        onChange={
                          data === "Ratings" ? handleInputChange : data === "Duration (Hrs)" ? handleDuration : handleChange
                        }
                        onBlur={handleBlur}
                        type={data === "Duration (Hrs)" ? "number" : "text"}
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        error={
                          data === "Duration (Hrs)"
                            ? errors.duration
                            : data === "Ratings"
                            ? errors.ratings
                            : errors.students_enrolled
                        }
                        isTouched={
                          data === "Duration (Hrs)"
                            ? touched.duration
                            : data === "Ratings"
                            ? touched.ratings
                            : touched.students_enrolled
                        }
                        disabled={mode === "view"}
                        index={data === "Students Enrolled" ? null : 0}
                        check={data === "Duration (Hrs)" ? 'duration' : "ratings"}
                      />
                    ))}
                  </div>
                  {values.type === "Paid" && (
                    <DropdownInputWithCheckbox
                      topDivStyle={"flex flex-col w-1/4"}
                      classNameInput={"w-full"}
                      label="Course Schedule"
                      placeholder="Course Schedule"
                      options={courseShedule}
                      name={"schedule"}
                      value={values.schedule}
                      onBlur={handleBlur}
                      mapThrough={"schedule"}
                      formik={formik}
                      zIndex={"z-20"}
                      disabled={mode === "view"}
                    />
                  )}
                </div>
                <DropdownInputWithCheckbox
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  label="Course Features:"
                  placeholder="Course Features"
                  options={courseFeatureNames}
                  name={"course_feature_names"}
                  forId={"course_features"}
                  value={values.course_feature_names}
                  onBlur={handleBlur}
                  mapData={courseFeatureData}
                  mapThrough={"feature"}
                  formik={formik}
                  disabled={mode === "view"}
                />
              </div>
              <AddFAQ
                addMoreFAQ={addMoreFAQ}
                deleteFAQ={deleteFAQ}
                scrollRef={scrollRef}
                name={"faqs"}
                dataSetup={handleChangeForFaqs}
                formik={formik}
                disabled={mode === "view"}
              />
              {values.type === "Paid" && (
                <div className="space-y-0.5 flex flex-col w-full relative">
                  <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
                    Certificate Description <span>*</span>
                  </label>
                  <textarea
                    name="certificate_description"
                    value={values.certificate_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={mode === "view"}
                    placeholder="Certificate Description"
                    className="min-h-[200px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
                  >
                    {values.certificate_description}
                  </textarea>
                  {touched.certificate_description &&
                    errors.certificate_description && (
                      <span className="text-red-500 absolute -bottom-6 right-0">
                        {errors.certificate_description}
                      </span>
                    )}
                </div>
              )}
              {values.type === "Paid" && (
                <div className="rounded-[3px] border-dashed border-[1px] border-[#707070] min-h-[150px] flex flex-col items-center justify-center">
                  <UploadOrUpdateBucket
                    mode={mode}
                    id={"syllabus"}
                    labelText={"Upload Syllabus"}
                    accept={"application/pdf"}
                    fileSize={20}
                    sizeInText={"Image Size 20Mb"}
                    Method={values.syllabus.url === "" ? "Upload" : "Update"}
                    bucketName={"courses"}
                    subBucket={"syllabus"}
                    forValue={handleValues}
                    fileNameData={values.syllabus.fileName}
                    fileNameKey={values.syllabus.key}
                    preview={values.syllabus.url}
                    name={`syllabus`}
                    error={
                      formik.errors.syllabus && formik.errors.syllabus?.url
                    }
                    isTouched={
                      formik.touched.syllabus && formik.touched.syllabus?.url
                    }
                    // buttonContainer={'w-4/12 mx-auto'}
                    // previewImgStyle={'w-full'}
                    // index={0}
                    // containerStyle={"absolute top-0 -left-40"}
                  />
                </div>
              )}
            </div>
            <div className="w-11/12 mx-auto space-y-4">
              <SelectModuleType
                text={"Course Material (PDF):"}
                name={"material"}
                value={values?.material}
                onBlur={handleBlur}
                onChange={selectSectionType}
                JSON={conditionStatement}
                disabled={mode === "view"}
              />
              {values.type === "Free" && (
                <div className="space-y-0.5 flex flex-col w-full py-5 relative">
                  <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
                    Certificate Description <span>*</span>
                  </label>
                  <textarea
                    name="certificate_description"
                    value={values.certificate_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={mode === "view"}
                    placeholder="Certificate Description"
                    className="min-h-[200px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
                  >
                    {values.certificate_description}
                  </textarea>
                  {touched.certificate_description &&
                    errors.certificate_description && (
                      <span className="text-red-500 absolute -bottom-2 right-0">
                        {errors.certificate_description}
                      </span>
                    )}
                </div>
              )}
              {values.material === true && (
                <AddCourseMaterials
                  addMoreMaterials={addMoreMaterials}
                  deleteMaterials={deleteMaterials}
                  scrollRef={scrollRef}
                  name={"course_materials"}
                  dataSetup={handleChangeForCourseMaterial}
                  formik={formik}
                  disabled={mode === "view"}
                  handleValues={handleValues}
                  mode={mode}
                  id={id}
                />
              )}
              {values.type === "Free" && (
                <AddVideo id={id} mode={mode} formik={formik} />
              )}
            </div>
            {mode !== "view" && (
              <div className="flex justify-center items-center mt-12 w-4/12 mx-auto">
                <button
                  type="submit"
                  className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-6 font-Roboto font-[400]"
                >
                  {id === "new-course" ? "Add New Course" : "Update Course"}
                </button>
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
