import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchDataById, UpdateData } from "../../crud";
import { FetchData, InsertData } from "../../crud"
import { formatMenuData, isRoleAlreadyExists } from "./helpers";
import { RoleInterface } from "./interfaces";

export const getAllRoles = async (requiredFields:string[]=[]) => {
    const { data, error } = await FetchData(TableNames.roles,requiredFields);
    // console.log({ data, error });
    return data;
}

export const getRoleById = async (id: string | number) => {
    try {
        const { data, error } = await FetchDataById(TableNames.roles, id);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getPrivilegesData = async () => {
    const { data, error }: any = await FetchData(TableNames.menus);
    const { data: priveliges, error: privelegeError }: any = await FetchData(TableNames.privileges);
    if (error || privelegeError) {
        return { error, privelegeError }
    }
    const formattedData = formatMenuData(data, priveliges);
    // await InsertData(TableNames.menutesting, { menudata: formattedData })
    return formattedData;
}

export const getRoles = async () => {
    const { data, error }:any = await FetchData(TableNames.roles);
    const mapRoleNames = data?.map((ft: any) => ft?.role);
    return mapRoleNames;
}

export const createNewRole = async (data:RoleInterface) => {
    try {
        const { data: RoleData, error: RoleError }: any = await FetchData(TableNames.roles);
        if (RoleError) throw RoleError;
        const isRoleExists = isRoleAlreadyExists(RoleData, data.role);
        if (isRoleExists) {
            let error = { message: "role already exists" };
            throw error;
        }
        const { error } = await InsertData(TableNames.roles, data);
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateRole = async (id: string | number, data: Partial<RoleInterface>) => {
    try {
        const { error } = await UpdateData(TableNames.roles, data, { conditionKey: "id", conditionValue: id });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}