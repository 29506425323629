import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Student Actions/ActionComponent";
import { getStudentActionsTableData } from "../../functions/admin/Action center/Student Actions/studentActions";

export const studentAction = {
    headers: [
        {
            name: "Registration Date",
            apiKey: "registration_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2",
        },
        {
            name: "Student Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Phone Number",
            apiKey: "student_phone_number",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Student Email",
            apiKey: "student_email",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start px-2 break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[140px] text-start px-2",
            className: "w-[140px] text-start px-2 break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2",
        },
        {
            name: "Course Progress",
            apiKey: "courseProgress",
            headerClass: "w-[100px] text-center px-2",
            className: "w-[100px] text-center break-words px-2",
        },
        {
            name: "Total Fee",
            apiKey: "total_fee",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Balance Due",
            apiKey: "balanceDue",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start break-words px-2",
        },
        {
            name: "Branch",
            apiKey: "branchName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "City",
            apiKey: "cityName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Action",
            apiKey: "Courses",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center",
        },
    ],
    BackendFunctionCall: (params:any) => {
        return getStudentActionsTableData(params)
    },
    filters: {
        dateFilters: [
            {
                name: "Registration Date",
                apiKey: "registration_date",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
        ],
        checkBox: [
            // {
            //     name: "Registration Date",
            //     apiKey: "registration_date",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[200px] xl:w-[170px] 2xl:w-[180px]",
            // },
            {
                name: "City",
                apiKey: "cityName",
                isSearchNeeded: true,
                className: "w-[180px]",
            },
            {
                name: "Branch",
                apiKey: "branchName",
                isSearchNeeded: true,
                className: "w-[180px]",
            },
            {
                name: "Ownership",
                apiKey: "ownership",
                isSearchNeeded: true,
                className: "w-[170px]"       
              },

        ],
        searchBox: [
            {
                matchFields: ["student_name", "course_name", "student_phone_number"],
                searchKey: "student_nameCourse",
                name: "Student Search...",
                searchStyle: "w-[220px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};