import { getImports } from "./ModuleImports";

export const CustomizationCourse = ({
  formik, initialCourseFee, setInitialCourseFeeForCustomization
}: any) => {
  const { InputBox }: any = getImports;

  const handleCourseInput = (index:number, val:any, check:any) => {
    formik.setFieldValue("customization_course_name", val);
    if(val==="") {
      formik.setFieldValue("customization_duration", "")
    }
  }

  const handleCourseDurationInput = (index: number, val: any, check: any) => {
    formik.setFieldValue("customization_duration", val);
    formik.setFieldValue("discount_percentage", 0);
    if(val!=="") {
      const new_course_fee = (formik.values.hourly_fee * val).toFixed(2);
      const final_value = new_course_fee?.toString()?.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )
      formik.setFieldValue("course_fee", final_value);
      formik.setFieldValue("customization_fee", true);
      setInitialCourseFeeForCustomization(final_value)
    } else {
      const final_value = initialCourseFee?.toString()?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )
      formik.setFieldValue("course_fee", final_value);
      formik.setFieldValue("customization_fee", false);
      formik.setFieldValue("cd_comment", "");
      setInitialCourseFeeForCustomization(final_value)
    }
  };

  return (
    <div className="space-y-2">
      <h1 className="text-[22px] font-Roboto font-[500]">
        Customization Course
      </h1>
      <div className="grid grid-cols-2 gap-4">
        <InputBox
          label="Course Name"
          placeholder="Course Name"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"customization_course_name"}
          value={formik.values.customization_course_name}
          onChange={handleCourseInput}
          onBlur={formik.handleBlur}
          error={formik.errors.customization_course_name}
          isTouched={formik.touched.customization_course_name}
          disabled={formik.values.course_id === null || formik.values.city_id === null}
          index={0}
        />
        <InputBox
          label="Course Duration"
          placeholder="Course Duration"
          type="number"
          topDivStyle={"flex flex-col w-full"}
          name={"customization_duration"}
          value={formik.values.customization_duration}
          onChange={handleCourseDurationInput}
          onBlur={formik.handleBlur}
          error={formik.errors.customization_duration}
          isTouched={formik.touched.customization_duration}
          disabled={formik.values.course_id === null || formik.values.city_id === null || formik.values.customization_course_name === ""}
          index={0}
        />
      </div>
    </div>
  );
};
