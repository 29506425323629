import { Components } from "./ModuleImports";
import { CompModal } from "../../UI_Modal/CompModal";

export const SessionCompensation = ({ sessionDataCompensate }: any) => {
  const { SessionData, useState }: any = Components;
  const [show, setShow] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const SessionCompensationHeader = [
    "Session Date",
    "Trainer Name",
    "Trainer Phone Number",
    "Topic",
    "Start Time",
    "End Time",
    "Duration",
    "Feedback",
  ];

  const columnWidths = [
    "w-[12%]", // Session Date
    "w-[12%]", // Trainer Name
    "w-[15%]", // Trainer Phone Number
    "w-[20%]", // Topic
    "w-[10%]", // Start Time
    "w-[10%]", // End Time
    "w-[8%]",  // Duration
    "w-[13%]", // Feedback
  ];

  const compensationClassName = (index: number, isClickable?: boolean) =>
    `${columnWidths[index]} ${isClickable ? "text-[#036AD1] cursor-pointer" : "text-[#686868]"
    } py-1 text-[14px] font-Roboto font-[400] text-center select-none`;

  const openFeedbackModal = (feedback: string) => {
    setFeedbackContent(feedback);
    setModalTitle("Feedback Details");
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setFeedbackContent("");
  };

  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Session Compensation 
      </h1>

      <CompModal
        show={show}
        fun={closeModal}
        content={feedbackContent}
        title={modalTitle}
      />

      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
        <div className="flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4">
          {SessionCompensationHeader.map((head: any, index: number) => (
            <h1
              key={head}
              className={`text-[#000000] text-[16px] font-Roboto font-[500] text-center ${columnWidths[index]}`}
            >
              {head}
            </h1>
          ))}
        </div>
        <div>
          {sessionDataCompensate.map((arr: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-between py-1.5 px-4 ${index % 2 !== 0 && "bg-[#EFF7FF]"}`}
              >
                <p className={compensationClassName(0)}>{arr.session_date}</p>
                <p className={compensationClassName(1)}>{arr.trainer_name}</p>
                <p className={compensationClassName(2)}>{arr.trainer_mobile}</p>
                <p className={compensationClassName(3)}>
                  {arr.details?.topic}
                </p>
                <p className={compensationClassName(4)}>{arr.start_time}</p>
                <p className={compensationClassName(5)}>{arr.end_time}</p>
                <p className={compensationClassName(6)}>{`${arr.duration} ${Number(arr.duration) > 1 ? "Hrs" : "Hr"}`}</p>

                <p
                  className={compensationClassName(7, arr.feedback && arr.feedback[0]?.response!==undefined && arr.feedback?.[0]?.comment!=="")}
                  onClick={() => arr.feedback && arr.feedback[0]?.response!==undefined && arr.feedback?.[0]?.comment!=="" && openFeedbackModal(arr.feedback?.[0]?.comment)}
                >
                  {arr.feedback && arr.feedback[0] ? arr.feedback[0].response : "No Feedback"}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
