import { Components } from "./ModuleImports";
import { CourseMode } from "../../Content Management/ManageCourse/JsonData";
import { useEffect, useState } from "react";
import {
  getManagerUser,
  getRecruiterUser,
} from "../../../functions/admin/User Mangement/userMangement";
import { useAppSelector } from "../../..";

export const UserInputs = ({
  formik,
  showChangePassword,
  isEdit,
  mode,
  cityNames,
  cityData,
  branchNames,
  branchData,
  roleNames,
  roleData,
  courseNames,
  courseData,
  scheduleData,
  CourseModeData,
  languageOptions,
  suitableOptions,
  availabilityOptions,
  scheduleOptions,
  modeOptions,
}: any) => {
  const [data, setData] = useState([] as any);
  const [recruiterData, setRecruiterData] = useState([] as any);
  const {
    InputBox,
    DropdownInputWithCheckbox,
    DropdownSingleInputWithCheckbox,
    DropdownBox,
  }: any = Components;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  useEffect(() => {
    (async () => {
      const res: any = await getManagerUser({
        branches: signInData?.data[0]?.branches,
        cities: signInData?.data[0]?.cities,
      });
      const demo: any = res?.map((item: any) => ({
        id: item?.uid,
        name: item?.name,
      }));
      setData(demo);
      const recruiterResponse: any = await getRecruiterUser(signInData?.data[0]);
      const recruiter: any = recruiterResponse?.map((item: any) => ({
        id: item?.uid,
        name: item?.name,
      }));
      setRecruiterData(recruiter);
    })();
  }, []);

  return (
    <div className="w-full grid grid-cols-2 gap-6">
      <InputBox
        label="First Name:"
        placeholder="First Name"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"first_name"}
        value={formik.values.first_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.first_name}
        isTouched={formik.touched.first_name}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      <InputBox
        label="Last Name:"
        placeholder="Last Name"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"last_name"}
        value={formik.values.last_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.last_name}
        isTouched={formik.touched.last_name}
        disabled={false}
      />
      <InputBox
        label="Phone Number:"
        placeholder="Phone Number"
        type={"number"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"phone_number"}
        value={formik.values.phone_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.phone_number}
        isTouched={formik.touched.phone_number}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      <InputBox
        label="Alternate Number:"
        placeholder="Alternate Number"
        type={"number"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"alternate_phone_number"}
        value={formik.values.alternate_phone_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.alternate_phone_number}
        isTouched={formik.touched.alternate_phone_number}
        disabled={false}
      />
      <InputBox
        label="Trainer Location:"
        placeholder="Trainer Location"
        type={"text"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"trainer_location"}
        value={formik.values.trainer_location}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.trainer_location}
        isTouched={formik.touched.trainer_location}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      <InputBox
        label="Trainer Address:"
        placeholder="Trainer Address"
        type={"text"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"trainer_address"}
        value={formik.values.trainer_address}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.trainer_address}
        isTouched={formik.touched.trainer_address}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      {/* <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Schedule:"
            placeholder="Course Schedule"
            options={scheduleData}
            name={"course_schedule"}
            value={formik.values.course_schedule}
            onBlur={formik.handleBlur}
            error={formik.errors?.course_schedule}
            isTouched={formik.touched?.course_schedule}
            mapThrough={"schedule"}
            formik={formik}
            zIndex={"z-30"}
            // disabled={mode === "view"}
      /> */}
      {/* <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Mode"
            placeholder="Course Mode"
            options={CourseModeData}
            name={"course_mode"}
            value={formik.values.course_mode}
            onBlur={formik.handleBlur}
            error={formik.errors?.course_mode}
            isTouched={formik.touched?.course_mode}
            mapThrough={"course_mode"}
            formik={formik}
            // zIndex={"z-10"}
            disabled={false}
          /> */}
      <DropdownBox
        label="Demo Attended by:"
        placeholder="Demo Attended by"
        OptionsGiven={data}
        value={formik.values.demo_attended_by}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10"}
        formik={formik}
        name="demo_attended_by"
        id="demo_attended_by"
        mandatory={true}
        mandatoryClass="text-red-500"
        single={true}
        error={formik.errors.demo_attended_by}
        isTouched={formik.touched.demo_attended_by}
      />
      <DropdownBox
        label="Commercials Spoken by:"
        placeholder="Commercials Spoken by"
        OptionsGiven={data}
        value={formik.values.commercials_spoken_by}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="commercials_spoken_by"
        id="commercials_spoken_by"
        mandatory={true}
        mandatoryClass="text-red-500"
        single={true}
        error={formik.errors.commercials_spoken_by}
        isTouched={formik.touched.commercials_spoken_by}
      />
      <DropdownBox
        label="Recruiter:"
        placeholder="Recruiter"
        OptionsGiven={recruiterData}
        value={formik.values.recruiter}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="recruiter"
        id="recruiter"
        mandatory={true}
        mandatoryClass="text-red-500"
        single={true}
        error={formik.errors.recruiter}
        isTouched={formik.touched.recruiter}
      />
      <DropdownBox
        label="Language:"
        placeholder="Languages"
        OptionsGiven={languageOptions}
        value={formik.values.languages}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="languages"
        id="languages"
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.languages}
        isTouched={formik.touched.languages}
      />
      <DropdownBox
        label="Suitable For:"
        name="suitable_for"
        id="suitable_for"
        placeholder="Suitable For"
        OptionsGiven={suitableOptions}
        value={formik.values.suitable_for}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        single={true}
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.suitable_for}
        isTouched={formik.touched.suitable_for}
      />
      <DropdownBox
        label="Current Availability:"
        placeholder="Current Availability"
        OptionsGiven={availabilityOptions}
        value={formik.values.current_availability}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="current_availability"
        id="current_availability"
        mandatory={true}
        mandatoryClass="text-red-500"
        single={true}
        error={formik.errors.current_availability}
        isTouched={formik.touched.current_availability}
      />
      <DropdownBox
        label="Course Schedule:"
        placeholder="Course Schedule"
        OptionsGiven={scheduleOptions}
        value={formik.values.course_schedule}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="course_schedule"
        id="course_schedule"
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.course_schedule}
        isTouched={formik.touched.course_schedule}
      />
      <DropdownBox
        label="Course Mode:"
        placeholder="Course Mode"
        OptionsGiven={modeOptions}
        value={formik.values.mode}
        onChange={formik.handleChange}
        classNameInput={"w-full border border-slate-500 h-10 A "}
        formik={formik}
        name="mode"
        id="mode"
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.mode}
        isTouched={formik.touched.mode}
      />
      <InputBox
        label="Email:"
        placeholder="Email"
        type={"email"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"email"}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email}
        isTouched={formik.touched.email}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="City:"
        placeholder="City"
        options={cityNames}
        name={"city_names"}
        forId={"cities"}
        value={formik.values.city_names}
        onBlur={formik.handleBlur}
        mapData={cityData}
        mapThrough={"city_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-30"}
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.city_names}
        isTouched={formik.touched.city_names}
      />

      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Branch:"
        placeholder="Branch"
        options={branchNames}
        name={"branch_names"}
        forId={"branches"}
        value={formik.values.branch_names}
        onBlur={formik.handleBlur}
        mapData={branchData}
        mapThrough={"branch_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-30"}
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.branch_names}
        isTouched={formik.touched.branch_names}
      />
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Roles:"
        placeholder="Roles"
        options={roleNames}
        name={"role_names"}
        forId={"roles_array"}
        value={formik.values.role_names}
        onBlur={formik.handleBlur}
        mapData={roleData}
        mapThrough={"role"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-20"}
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.role_names}
        isTouched={formik.touched.role_names}
      />
      {/* <InputBox
        label="Roles:"
        placeholder="Roles"
        type={"text"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"role_names"}
        value={formik.values.role_names}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.role_names}
        isTouched={formik.touched.role_names}
        disabled={true}
      /> */}
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Courses:"
        placeholder="Courses"
        options={courseNames}
        name={"course_names"}
        forId={"courses"}
        value={formik.values.course_names}
        onBlur={formik.handleBlur}
        mapData={courseData}
        mapThrough={"course_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-20"}
        mandatory={true}
        mandatoryClass="text-red-500"
        error={formik.errors.course_names}
        isTouched={formik.touched.course_names}
      />
      <InputBox
        label="Professional From:"
        placeholder="Professional From"
        type="date"
        topDivStyle={"flex flex-col w-full"}
        // classNameInput={"w-full"}
        name={"professional_from"}
        value={formik.values.professional_from}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.professional_from}
        isTouched={formik.touched.professional_from}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
      <div>
        <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
          Commercials: <span className="text-red-500">*</span>
        </label>
        <textarea
          placeholder="Commercial"
          className="w-full border border-slate-500 rounded p-2"
          name={"commercials"}
          value={formik.values.commercials}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={false}
          rows={3}
          style={{ whiteSpace: "pre-wrap" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation(); // Stop event from bubbling up to form
            }
          }}
        />
        {formik.touched?.commercials && formik.errors?.commercials && (
          <span className="text-red-500 text-right block">
            {formik.errors?.commercials}
          </span>
        )}
      </div>

      <div>
        <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
          Comments: <span className="text-red-500">*</span>
        </label>
        <textarea
          placeholder="Comments"
          className="w-full border border-slate-500 rounded p-2"
          name={"comment"}
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={false}
          rows={3}
          style={{ whiteSpace: "pre-wrap" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation(); // Stop event from bubbling up to form
            }
          }}
        />
        {formik.touched?.comment && formik.errors?.comment && (
          <span className="text-red-500 text-right block">
            {formik.errors?.comment}
          </span>
        )}
      </div>
      {((isEdit && showChangePassword) || !isEdit) && (
        <>
          <InputBox
            label="Password:"
            placeholder="Password"
            type={"password"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"password"}
            mandatory={true}
            mandatoryClass="text-red-500"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            isTouched={formik.touched.password}
            disabled={false}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e: any) => {
              e.preventDefault();
              return false;
            }}
          />
          <InputBox
            label="Confirm Password:"
            placeholder="Confirm Password"
            type={"password"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"confirm_password"}
            mandatory={true}
            mandatoryClass="text-red-500"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.confirm_password}
            isTouched={formik.touched.confirm_password}
            disabled={false}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e: any) => {
              e.preventDefault();
              return false;
            }}
          />
        </>
      )}
      <InputBox
        label="Onboarding Date:"
        placeholder="Onboarding Date"
        type="date"
        topDivStyle={"flex flex-col w-full"}
        // classNameInput={"w-full"}
        name={"onboarding_date"}
        value={formik.values.onboarding_date}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.onboarding_date}
        isTouched={formik.touched.onboarding_date}
        disabled={false}
        mandatory={true}
        mandatoryClass="text-red-500"
      />
    </div>
  );
};
