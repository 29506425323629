import React, { useEffect, useRef, useState } from 'react';

interface DropdownProps {
  name: string;
  options: string[];
  placeholder?: string;
  value: string | string[];
  onChange: (field: string, value: string | string[], shouldValidate?: boolean) => void;
  onBlur: (field: string) => void;
  useCheckbox?: boolean;
  multiSelect?: boolean;
  error?: string | string[] | never[] | null | undefined;
  touched?: boolean | never[] | undefined;
  className?: string;
  dropdownClassName?: string;
  errorClassName?: string;
  label?: string;
  showLabel?: boolean;
  labelClassName?: string;
  trainerId?: any;
  disabled?: boolean;
  searchPlaceholder?: string;
}

export const BatchDropdown: React.FC<DropdownProps> = ({
  name,
  options,
  placeholder = "Select an option",
  value,
  onChange,
  onBlur,
  useCheckbox = false,
  multiSelect = false,
  error,
  touched,
  className = "w-full",
  dropdownClassName = "bg-white border-[#707070] border-[0.5px] rounded-t-[3px] p-2 cursor-pointer flex justify-between items-center",
  errorClassName = "text-red-500 text-sm mt-1 absolute -bottom-6 right-0",
  label,
  showLabel = true,
  labelClassName = "block mb-1 text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]",
  trainerId,
  disabled = false,
  searchPlaceholder = "Search..."
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const isFieldTouched = Array.isArray(touched)
    ? touched.length > 0
    : Boolean(touched);
  const errorMessage = Array.isArray(error) ? error.join(", ") : error;

  // Ensure options is an array
  const safeOptions = Array.isArray(options) ? options : [];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        onBlur(name);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [name, onBlur]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !Array.isArray(options)) {
      console.warn('BatchDropdown: The "options" prop should be an array.');
    }
  }, [options]);

  const toggleOption = (option: string) => {
    let newValue: string | string[];
    if (multiSelect) {
      newValue = option;
    } else {
      newValue = option;
      setIsOpen(false);
    }
    onChange(name, newValue, trainerId);
  };

  const displayValue = Array.isArray(value) ? value.join(", ") : value;

  const filteredOptions = safeOptions.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={`relative select-none ${className}`}>
      {showLabel && label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}
      <div ref={dropdownRef}>
        <div
          className={`${dropdownClassName} ${
            touched && error ? "border-red-500" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`${displayValue ? "" : "opacity-50"}`}>
            {displayValue || placeholder}
          </span>
          <svg
            className={`w-4 h-4 transition-transform duration-200 ${
              !disabled && isOpen ? "transform rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        {!disabled && isOpen && (
          <div className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md max-h-60 overflow-auto">
            <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={searchPlaceholder}
                className="w-full p-1 border border-gray-300 rounded outline-none"
              />
            </div>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className="p-2 hover:bg-gray-100 select-none cursor-pointer flex items-center"
                  onClick={() => toggleOption(option)}
                >
                  {useCheckbox && (
                    <input
                      type={multiSelect ? "checkbox" : "radio"}
                      checked={
                        Array.isArray(value)
                          ? value.includes(option)
                          : value === option
                      }
                      onChange={() => {}}
                      className="mr-2"
                    />
                  )}
                  {option}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">
                {safeOptions.length === 0 ? "No options available" : "No matching options"}
              </div>
            )}
          </div>
        )}
        {isFieldTouched && errorMessage && (
          <div className={errorClassName}>{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

interface DropdownProps {
  name: string;
  options: string[];
  value: string | string[];
  onChange: (field: string, value: string | string[], shouldValidate?: boolean) => void;
  placeholder?: string;
  multiSelect?: boolean;
  label?: string;
  modalClassName?: string;
  closeButtonClassName?: string;
  trainerId?: any;
}

export const BatchDropdownAsModal: React.FC<DropdownProps> = ({
  name,
  options,
  value,
  onChange,
  placeholder = "Select an option",
  multiSelect = false,
  label,
  modalClassName = "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center",
  closeButtonClassName = "absolute top-3 right-3 bg-red-500 text-white rounded-full p-2 cursor-pointer",
  trainerId
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOption = (option: string) => {
    let newValue: string | string[];
    if (multiSelect) {
      newValue = Array.isArray(value)
        ? value.includes(option)
          ? value.filter((v) => v !== option)
          : [...value, option]
        : [option];
    } else {
      newValue = option;
      setIsOpen(false);
    }
    onChange(name, newValue, trainerId);
  };

  const displayValue = Array.isArray(value) ? value.join(", ") : value;

  return (
    <div className="relative">
      {label && <label className="block mb-1 text-gray-700">{label}</label>}
      <div
        className="cursor-pointer p-2 bg-white border border-gray-300 rounded-md"
        onClick={() => setIsOpen(true)}
      >
        <span className={`${displayValue ? "" : "opacity-50"}`}>
          {displayValue || placeholder}
        </span>
      </div>

      {isOpen && (
        <div className={modalClassName}>
          <div className="bg-white rounded-lg w-80 max-h-[80vh] overflow-auto relative">
            <div
              className={closeButtonClassName}
              onClick={() => setIsOpen(false)}
            >
              ✕
            </div>
            <h3 className="text-lg font-semibold p-4 border-b">Select Option</h3>
            {options.length > 0 ? (
              <div className="p-4 flex flex-col gap-2">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={`p-2 border rounded cursor-pointer ${
                      Array.isArray(value) && value.includes(option)
                        ? "bg-blue-500 text-white"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => toggleOption(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-gray-500">No options available</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};




